import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CogedimDownloadService} from '../../../data/services/cogedim-download.service';

@Component({
    selector: 'app-downloading',
    templateUrl: './downloading.component.html',
    styleUrls: ['./downloading.component.css']
})
export class DownloadingComponent implements OnInit {

    public canceled = false;
    public errorMode: boolean;

    public title = () => this.canceled ? 'Voulez-vous vraiment annuler ce téléchargement?' : 'Votre fichier est en cours de téléchargement';
    public subTitle = () => this.canceled ? `Attention ! l'annulation est irréversible.` : 'Veuillez patienter.';

    constructor(public dialogRef: MatDialogRef<DownloadingComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _downloadService: CogedimDownloadService) {
    }

    ngOnInit() {
        this._downloadService.errorListener()
            .subscribe(hasError => {
                this.errorMode = hasError;
            });
    }

    onClick() {
        this.canceled = !this.canceled;
    }
}
