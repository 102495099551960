import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {CaracConfig, NPElement} from '@nextpage/np-sdk-data';
import {FormControl} from '@angular/forms';
import {HtmlService} from '../../../sdk/graphics/services/html.service';
import {TableRowItem} from '../../../sdk/data/Model/models';
import {CogedimElementWriterService} from '../../../sdk/data/services/cogedim-element-writer.service';

@Component({
    selector: 'app-construction-input-field',
    templateUrl: './construction-input-field.component.html',
    styleUrls: ['./construction-input-field.component.scss']
})
export class ConstructionInputFieldComponent implements OnInit, OnChanges {

    @Input() public element: NPElement;
    @Input() public config: CaracConfig;
    @Input() readonly = false;

    public tableElements: TableRowItem[] = [];
    public formControl = new FormControl('');
    public currentValue = '';

    constructor(private _htmlService: HtmlService,
                private _elementWriter: CogedimElementWriterService) {
    }

    ngOnInit() {

        this._htmlService.getTableElements()
            .subscribe(_response => {
                this.tableElements = _response;
            });

        this.formControl.valueChanges
            .subscribe(_newValue => {
                this._elementWriter.setValueText(this.element, this.config.DicoCaracExtID, _newValue);
            });
    }

    ngOnChanges(): void {
        if (this.element && this.config && this.config.DicoCaracExtID) {
            this.currentValue = this.element.getValueTextValue(this.config.DicoCaracExtID);
        }
    }
}
