import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first, map, shareReplay, switchMap} from 'rxjs/operators';
import {WSParameters, WsParamsService} from './ws-params.service';
import {NpApiResult} from '../model/np-api-result';
import {NPDicoCarac} from '../model/np-dico-carac';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class DicocaracRepository {

  private _dicoCaracs$: Observable<NPDicoCarac[]> = null;
  private _dicoCaracSync: NPDicoCarac[] = [];
  private _urlGetDicoCarac = '/api/sdk/dicocarac/GetAll/';

  // TODO  IMPLEMENTS
  constructor(private _http: HttpClient, private _wsParamsService: WsParamsService) {
    this._initialize();
  }


  private _initialize() {
    // initialisation des dicoCarac => la requete ne sera appelé que lorsqu'on s'en sert
    // on injecte les paramètres (langue) dans les paramètres url
    if (this._dicoCaracs$ == null) {
      this._dicoCaracs$ =
        this._wsParamsService.getParams().pipe(
          switchMap((params) => {
            return this._http.get<NpApiResult>(this.getURL(params));
          }),
          map((data: NpApiResult) => {
            // TODO: throw error
            if (data.Results != null && data.Results.hasOwnProperty('Caracs')) {
              this._dicoCaracSync = [];
              data.Results['Caracs'].forEach(d => this._dicoCaracSync.push(new NPDicoCarac(d)));
              return this._dicoCaracSync;
            }

          }),
          first(),
          shareReplay(1, 10000000)
        );
    }
  }

  getAll() {
    return this._dicoCaracs$;
  }

  getAllSync() {
    return this._dicoCaracSync;
  }

  getDicoCaracSync(ExtID: string): NPDicoCarac {
    return this._dicoCaracSync.find((value) => value.ExtID === ExtID);
  }

  getDicoCarac(ExtID: string): Observable<NPDicoCarac> {
    return this.getAll().pipe(
      map((data: NPDicoCarac[]) => {
        const arrResult = data.filter((value) => {
          return value.ExtID === ExtID;
        });
        if (arrResult.length === 1) {
          return arrResult[0];
        } else {
          return null;
        }
      })
    );
  }

  private getURL(params: WSParameters) {
    return this._urlGetDicoCarac + params.LangID;
  }

  getDicoCaracs(ExtIDs: string[]) {
    return this.getAll().pipe(
      map((data: NPDicoCarac[]) => {
        return data.reduce((acc: Map<string, NPDicoCarac>, value: NPDicoCarac) => {
          if (ExtIDs.indexOf(value.ExtID) > -1) {
            acc.set(value.ExtID, value);
          }
          return acc;
        }, new Map<string, NPDicoCarac>());
      })
    );
  }


  getDicoCaracsAndReflexives(ExtIDs: string[]) {
    return this.getAll().pipe(
      map((data: NPDicoCarac[]) => {
        return data.reduce((acc: Map<string, NPDicoCarac>, value: NPDicoCarac) => {
          if (ExtIDs.indexOf(value.ExtID) > -1 || (value.ReflexiveCharExtID != null && ExtIDs.indexOf(value.ReflexiveCharExtID) > -1)) {
            acc.set(value.ExtID, value);
          }
          return acc;
        }, new Map<string, NPDicoCarac>());
      })
    );
  }
}
