import {Injectable} from '@angular/core';
import {CaracConfig, DicoCarac, NPElement} from '@next-page/np-sdk-data';
import {CogedimDicocarcs} from '../../data/constantes/cogedim-dicocarcs';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';
import { TableRowItem } from '../../data/Model/models';

@Injectable({
    providedIn: 'root'
})
export class HtmlService {

    private _values: SafeHtml;
    private _values$ = new BehaviorSubject<SafeHtml>(null);
    private tableElements: TableRowItem[] = [];
    private _tableElements$ = new BehaviorSubject<TableRowItem[]>([]);
    private _hasBeenModified: boolean;


    constructor(private _sanitizer: DomSanitizer) {
    }

    getTableValue(element: NPElement) {
        if (element) {
            const tmpValue = element.getValueTextValue(CogedimDicocarcs.DC_P_TABLEAU_HTML_CONCTRUCTION);
            if (tmpValue && tmpValue !== '') {
                this._values = this._sanitizer.bypassSecurityTrustHtml(tmpValue);
            } else {
                this._values = this._sanitizer.bypassSecurityTrustHtml(INITIAL_TABLE);
            }
        }
        this._values$.next(this._values);
        return this._values;
    }

    getTable() {
        return this._values$.asObservable();
    }

    getTableElements() {
        return this._tableElements$.asObservable();
    }

    setDataFromHtmlTable(_tableRef: HTMLCollection) {
        this.tableElements = [];
        const trElements = _tableRef.item(0).getElementsByTagName('tr');
        Array.from(trElements).forEach(item => {
            const tdElements = item.getElementsByTagName('td');
            const rowItem: TableRowItem = new TableRowItem();
            rowItem.productExtId = tdElements.item(0).innerText;
            rowItem.productLabel = tdElements.item(1).innerText;
            rowItem.posedCaracLabel = tdElements.item(2).innerText;
            rowItem.whyCaracLabel = tdElements.item(3).innerText;
            this.tableElements.push(rowItem);
        });
        this._update();
    }

    private _update() {
        this._tableElements$.next(this.tableElements);
    }

    isNotPosed(_currentKit: NPElement) {
        const tableValue = this.tableElements.find(c => c.productExtId === _currentKit.ExtID);
        return (tableValue && tableValue.posedCaracLabel === 'Non') || !tableValue;
    }

    setPosedValue(_currentKit: NPElement, _selectedValue: string) {
        let _currentItem = this.tableElements.find(c => c.productExtId === _currentKit.ExtID);
        if (!_currentItem) {
            _currentItem = new TableRowItem();
            _currentItem.productExtId = _currentKit.ExtID;
            _currentItem.productLabel = _currentKit.getValueTextValue(DicoCarac.PRODUCT_LABEL);
            this.tableElements.push(_currentItem);
        }
        _currentItem.posedCaracLabel = _selectedValue;
        if (_selectedValue === POSED.Yes) {
            _currentItem.whyCaracLabel = '';
        }
        this._hasBeenModified = true;
        this._update();
    }

    hasSomePosed(_kits: NPElement[]) {
        return _kits.some(_currentKit => this.isNotPosed(_currentKit));
    }

    validateAllProducts(_kits: NPElement[]) {
        if (_kits) {
            _kits.forEach(_currentKit => {
                this.setPosedValue(_currentKit, POSED.Yes);
            });
        }
    }

    setPosedWhy(_currentKit: NPElement, _newValue: string) {
        let _currentItem = this.tableElements.find(c => c.productExtId === _currentKit.ExtID);
        if (!_currentItem) {
            _currentItem = new TableRowItem();
            _currentItem.posedCaracLabel = POSED.No;
            _currentItem.productExtId = _currentKit.ExtID;
            _currentItem.productLabel = _currentKit.getValueTextValue(DicoCarac.PRODUCT_LABEL);
            this.tableElements.push(_currentItem);
        }
        _currentItem.whyCaracLabel = _newValue;
        this._hasBeenModified = true;
        this._update();
    }

    public hasModifications() {
        return this._hasBeenModified;
    }

    generateHtml() {
        const _newTable = document.createElement('table');
        this.tableElements.forEach(_currentItem => {
            const dataRow = document.createElement('tr');
            HtmlTableHeaderSummary.forEach(header => {
                const _dataCell = document.createElement('td');
                _dataCell.appendChild(document.createTextNode(_currentItem[header.propertyName]));
                _dataCell.setAttribute('Type', 'texte');
                dataRow.appendChild(_dataCell);
            });
            _newTable.appendChild(dataRow);
        });
        return _newTable.outerHTML;
    }

    reset() {
        this.tableElements = [];
        this._values = null;
        this.resetModifications();
        this._update();
    }

    resetModifications() {
        this._hasBeenModified = false;
    }
}


export class TabSummary {
    cracConfig: CaracConfig;
    label: string;

    constructor(caracConfig: CaracConfig) {
        if (caracConfig) {
            this.cracConfig = caracConfig;
            this.label = caracConfig.Label;
        }
    }
}

export class PosedITem {
    posed: TabSummary;
    posedWhy: TabSummary;

    constructor(_posed: CaracConfig, _posedWhy: CaracConfig) {
        this.posed = new TabSummary(_posed);
        this.posedWhy = new TabSummary(_posedWhy);
    }
}

export const HtmlTableHeaderSummary = [
    {propertyName: 'productExtId', label: 'ExtID produit'},
    {propertyName: 'productLabel', label: 'libellé produit'},
    {propertyName: 'posedCaracLabel', label: 'Posé'},
    {propertyName: 'whyCaracLabel', label: 'Pourquoi ?'}
];

export enum POSED {
    No = 'Non',
    Yes = 'Oui'
}

export const INITIAL_TABLE = `<table><tr><td Type="texte">ExtID produit</td><td Type="texte">Libellé produit</td><td Type="texte">Posé ?</td><td Type="texte">Pourquoi ?</td></tr></table>`;

