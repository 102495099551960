import {Injectable} from '@angular/core';
import {FacetValue, FilterCriteria, NpwPagination} from '../model';
import {map} from 'rxjs/operators';
import {NpApiResult, NPCaracSearchRankLevel, NPElement} from '../../data/model';
import {HttpClient} from '@angular/common/http';
import {ElementRepository} from '../../data/services/element-repository';

@Injectable()
export class NpwSearchService {

  private _baseUrlSearch = '/api/sdk-web/element';
  private _baseUrlSearchElements = this._baseUrlSearch + '/SearchElementsAndLinks';
  private _baseUrlSearchFacets = this._baseUrlSearch + '/getfacets';
  private _baseUrlSearchCount = this._baseUrlSearch + '/SearchElementsCount';

  constructor(private _http: HttpClient, private _elementRepository: ElementRepository) {}

  public search(filters: FilterCriteria[], LangID: number, linksPaths: string[][]= null, pagination: NpwPagination) {
    const postParameters = {
      Criterias: filters,
      LangID: LangID,
      ContextID: 1,
      Paths: linksPaths,
      Pagination: pagination
    };

    return this._http.post(this._baseUrlSearchElements, postParameters)
      .pipe(
        map((data) => {
            if (data.hasOwnProperty('Results')) {
            if (data['Results'] != null) {
              return this._rebuildAfterSearch(data['Results']);
            }
          }
        })
      );
  }

  public searchCount(filters: FilterCriteria[]) {
    const postParameters = {
      Criterias: filters,
      LangID: 2,
      ContextID: 1
    };
    return this._http.post(this._baseUrlSearchCount, postParameters)
      .pipe(
          map((data: NpApiResult) => {
              if (data.hasOwnProperty('Results')) {
                  if (data.Results != null && data.Results.hasOwnProperty('Count') != null) {
                      return data.Results['Count'] as number;
                  }
              }
          })
      );
  }

  public _rebuildAfterSearch(requestResult: any): Map<string, NPElement> {

      const res = this._elementRepository._rebuild(requestResult);
    if (requestResult.hasOwnProperty('ResultsScores')) {
      // ajout des scores aux objets => un peu bourin, mais ca fonctionne vite...
      const scores = requestResult['ResultsScores'];
      res.forEach(elem => {
        if (scores.hasOwnProperty(elem.ID.toString())) {
          const rk = new NPCaracSearchRankLevel();
          rk.SearchRanking = scores[elem.ID.toString()];
          rk.Element = elem;
          rk.ElementExtID = elem.ExtID;
          rk.ElementID = elem.ID;
          elem.Values.set(rk.DicoCaracExtID, rk);
        }
      });
    }
    return res;
  }

  public getFacets(criterias: FilterCriteria[], facets: string[]) {
    const postParameters = {
      Criterias: criterias,
      Facets: facets,
      LangID: 2
    };

    return this._http.post(this._baseUrlSearchFacets, postParameters)
      .pipe(
        map((data: NpApiResult) => {
          console.log('Facets', data);
          if (data.hasOwnProperty('Results')) {
            if (data.Results != null && data.Results.hasOwnProperty('Facets') != null) {
              return data.Results['Facets'] as FacetValue[];
            }
          }
        })
      );
  }

}
