import {AddedDataMocker} from '../../utils/Data/Mocking';
import {NpApiResult} from '../../model';


export class NpModelNaosUserStatusAccess extends AddedDataMocker {
  /**
   * Le pattern d'url pour les dicoCaracs
   */
  getUrlPattern(): string {
    return '/api/sdk/workflow/GetAllWithUserRights/';
  }

  /**
   * Retourne les objets dicoCarac
   */
  getData(): NpApiResult {
    // le code est sensé être récupéré par la console chrome, onglet netWork
    let data: NpApiResult =
      {
        "Results": {
          "Workflows": [
            {"ID": "1", "Name": "_InProgress", "Access": "0"},
            {"ID": "2", "Name": "_ToValidate", "Access": "0"},
            {"ID": "4", "Name": "_ToValidateByReglementaire", "Access": "0"},
            {"ID": "5", "Name": "_ToValidateByMarket", "Access": "0"},
            {"ID": "6", "Name": "_Validated", "Access": "0"},
            {"ID": "7", "Name": "_Archived", "Access": "0"},
          ]
        },
        "Warnings": [],
        "Errors": []
      }

    ;
    return data;
    //return super.BuildAPIResult(data);
  }


}
