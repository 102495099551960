import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {KitFamilySummary} from '../../sdk/data/Model/models';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-prestation-selector',
    templateUrl: './prestation-selector.component.html',
    styleUrls: ['./prestation-selector.component.scss']
})
export class PrestationSelectorComponent implements OnInit, OnChanges {

    @Input() data: KitFamilySummary[] = [];
    @Output() selected: EventEmitter<KitFamilySummary> = new EventEmitter();

    public dropDownControl = new FormControl();
    private _formCtrlSub: Subscription;

    private _currValue = (valueID) => this.data.find(value => value.value === valueID);

    constructor() {
    }

    ngOnInit() {
        this._saveChanges();
    }

    ngOnChanges() {
        const tmpValue = this.data[0];
        if (tmpValue) {
            this.dropDownControl.setValue(tmpValue.value); // On initialise la première valeur de la liste
            this._selectOption(tmpValue);
        }
    }

    private _saveChanges() {
        this._formCtrlSub = this.dropDownControl.valueChanges
            .subscribe(selectedValueExtID => {
                this._selectOption(this._currValue(selectedValueExtID));
            });
    }

    private _selectOption(value: KitFamilySummary) {
        this.selected.emit(value);
    }
}
