import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {NPElement} from '@nextpage/np-sdk-data';
import {RESULT_PAGE_SIZE} from '../../sdk/data/constantes/cogedim-constantes';

@Component({
    selector: 'app-results-view',
    templateUrl: './results-view.component.html',
    styleUrls: ['./results-view.component.scss']
})
export class ResultsViewComponent implements OnInit, OnChanges {

    @Input() operations: NPElement[] = [];

    public _copyOfOperations: NPElement[] = [];
    public displayLess = true;
    public nbResults = 0;
    private _resultsToDisplay = RESULT_PAGE_SIZE;

    constructor() {}

    ngOnInit() {
    }

    ngOnChanges() {
        this._resultsToDisplay = RESULT_PAGE_SIZE;
        if (this.operations && this.operations.length > 0 && this.operations.length < RESULT_PAGE_SIZE) {
            if (this.operations) {
                this.nbResults = this.operations.length;
            }
            for (let i = 1; i <= RESULT_PAGE_SIZE - this.nbResults; i++) {
                this.operations.push(undefined);
            }
        } else {
            this._copyOfOperations = Object.assign([], this.operations);
            this.operations.splice(RESULT_PAGE_SIZE);
            if (this._copyOfOperations) {
                this.nbResults = this._copyOfOperations.length;
            }
        }

    }

    get copyOfOperations(): NPElement[] {
        return this._copyOfOperations;
    }

    displayAllOperations() {
        this._resultsToDisplay += RESULT_PAGE_SIZE;
        this.operations = this.copyOfOperations.filter((value, index) => index < this._resultsToDisplay);
    }

    isMax() {
        return this._resultsToDisplay >= this._copyOfOperations.length;
    }
}
