import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CaracInfo} from '../Model';

@Component({
    selector: 'lib-app-value-link-read-view',
    templateUrl: './np-value-link-read-view.component.html',
    styleUrls: ['./np-value-link-read-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpValueLinkReadViewComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: any[];

    constructor() {
    }

    ngOnInit() {
        console.log('caracInfo : ', this.caracInfo);
        console.log('value : ', this.value);
    }

}
