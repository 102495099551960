import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CogedimConstantes} from '../../sdk/data/constantes/cogedim-constantes';
import {SynopticHelper} from '../../sdk/data/helpers/synoptic-helper';
import {Synoptic} from '../../sdk/data/Model/synoptic-model';
import {SynopticDownloadService} from '../../sdk/data/services/synoptic-download.service';
import {SynopticReaderService} from '../../sdk/data/services/synoptic-reader.service';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {SynopticMessages} from '../../sdk/data/constantes/synoptic-constants';

@Component({
    selector: 'app-dialog-synoptic',
    templateUrl: './dialog-synoptic.component.html',
    styleUrls: ['./dialog-synoptic.component.scss']
})
export class DialogSynopticComponent implements OnInit {

    public synoptics: Synoptic[] = [];
    private initSynoptics: Synoptic[] = [];
    public searchFormControl = new FormControl('');

    constructor(public dialogRef: MatDialogRef<DialogSynopticComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _synopticDownloadService: SynopticDownloadService,
                private _synopticReaderService: SynopticReaderService,
                @Inject(forwardRef(() => ModalService)) private _modalService: ModalService
    ) {
    }

    ngOnInit() {
        this.initData();
        this._search();
    }

    initData() {
        this._synopticReaderService.getSynoptics()
            .subscribe(synoptics => {
                this.synoptics = SynopticHelper.generateTree(synoptics);
                this.initSynoptics = Object.assign([], this.synoptics);
            });
    }

    private _search() {
        this.searchFormControl.valueChanges
            .subscribe(valueToSearch => {
                if (valueToSearch && valueToSearch.trim() !== '') {
                    this.synoptics = this.initSynoptics.filter(s => s && s.parentName.toLocaleUpperCase()
                        && s.parentName.toLocaleUpperCase().indexOf(valueToSearch.toLocaleUpperCase()) !== -1);
                } else {
                    this.synoptics = this.initSynoptics;
                }
            });
    }

    downloadSynoptic(synoptic: Synoptic) {
        this._modalService.openPreloadDialog(SynopticMessages.SYNOPTIC_DOWNLOARD);
        this._synopticDownloadService.download(synoptic)
            .subscribe(() => {
                this._modalService.closePreloadDialog();

            }, error => {
                this._modalService.openSnackBar(error.Message || error.Messages, CogedimConstantes.SNACK_CLASS);
            });
    }
}
