import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {UiTranslationService} from '@nextpage/np-sdk-data';


@Directive({
    selector: '[npUiTranslate]'
})
export class UiTranslateDirective implements OnInit {

    @Input('npUiTranslate') key: string;
    @Input('translateIn') target = 'innerHTML';

    private _tanslation = '';

    constructor(private _uiTranslationService: UiTranslationService, private _elemRef: ElementRef, private _renderer: Renderer2) {
    }

    ngOnInit() {
        this._uiTranslationService.translate(this.key).subscribe((value: string) => {
            this._tanslation = value;
            this.target.split(',').forEach(target => {
                this._renderer.setProperty(this._elemRef.nativeElement, target, this._tanslation);
            });
        });
    }
}

/*samples
*transalate in htmlDom element
* <h4 npUiTranslate="_Search"></h4>
*   => <h4>Search</h4>
*
* translate in title & placeholder attribute
* <input type="text"  npUiTranslate="_Search" translateIn="placeholder,title" />
*   => <input type="text"  title="Search" placeholder="Search" />
*
*
* *translate in htmlDom element and title attribute
* <h4 npUiTranslate="_Search" translateIn="innerHTML,title"></h4>
*   => <h4 title="Search">Search</h4>
**/

