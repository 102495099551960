import {Injectable} from '@angular/core';
import {SummaryElement} from '../graphics/select-list-value-lien/select-list-value-lien.component';
import {MatSnackBar} from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    private _version: SummaryElement;
    private _hasBeenChanged: boolean;

    constructor(private _snackBar: MatSnackBar) {
    }

    public setVersion(currentVersion: SummaryElement) {
        if (currentVersion) {
            this._version = currentVersion;
            this._setChanged(true);
        } else {
            this._setChanged(false);
        }
        this._openSnackBar(`Vous avez basculé vers la version ${currentVersion ? currentVersion.elementName : 'actuelle du produit'}`);
    }

    public getVersion() {
        return this._version ? this._version.element : null;
    }

    public hasBeenChanged() {
        return this._hasBeenChanged;
    }

    private _setChanged(value: boolean) {
        this._hasBeenChanged = value;
    }

    private _openSnackBar(message: string) {
        this._snackBar.open(message, 'Fermer', {
            duration: 90000,
            verticalPosition: 'top',
            panelClass: ['blue-snackbar']
        });
    }

    getPackagingElement() {
        if (this._version && this._version.element) {
            const _tmpPackaging = this._version.element.getValueLien('DC_DS_CONDITIONNEMENT_DEPUIS_ARTICLE_VERSIONNE');
            return _tmpPackaging && _tmpPackaging.RebuildLinkedElements && _tmpPackaging.RebuildLinkedElements[0] ?
                _tmpPackaging.RebuildLinkedElements[0].Element : null;
        } else {
            return null;
        }
    }
}
