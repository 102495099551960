import {Component, Input, OnInit} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {CaracConfig, NPCaracLien, ValueOneElementHelper} from '@nextpage/np-sdk-data';

@Component({
    selector: 'lib-cross-table',
    templateUrl: './cross-table.component.html',
    styleUrls: ['./cross-table.component.css'],
})
export class CrossTableComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    private _voe = new ValueOneElementHelper();
    public headers: CaracConfig[] = [];
    public rows: CaracConfig[] = [];
    public data: CaracConfig[] = [];

    constructor() {
    }

    ngOnInit() {
        this._init();
    }

    public isReady() {
        return this.value && this.caracConfig && this.caracInfo;
    }
    private _init() {
        if (this.caracConfig) {
            this.data = this.caracConfig.ConfigurationObject.find(cc => cc.DicoCaracExtID === 'CONF_DATA').ConfigurationObject;
            this.rows = this.caracConfig.ConfigurationObject.find(cc => cc.DicoCaracExtID === 'CONF_ROWS').ConfigurationObject;
            this.headers = this.caracConfig.ConfigurationObject.find(cc => cc.DicoCaracExtID === 'CONF_HEADERS').ConfigurationObject;
        }
    }

    getData(column: CaracConfig, header: CaracConfig) {
        if (!header.DicoCarac) {
            return column.DicoCarac.Name;
        }
        const tmp: Specific[] = column.Specific;
        const tmpData = tmp.find(s => s.header === header.DicoCaracExtID);
        return this.data.find(d => d.DicoCaracExtID === tmpData.data);
    }

    getCISV(currElement, caracConfig) {
        return {
            value: this._voe.getCaracValue(currElement, caracConfig),
            config: caracConfig
        };
    }
}

interface Specific {
    header: string;
    data: string;
}
