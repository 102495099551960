import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '@sdk/data/services/breadcrumb.service';
import {OperationService} from '@sdk/data/services/operation.service';
import {Router} from '@angular/router';
import {COGIDIM_ROUTES} from '@sdk/data/constantes/routes';

@Component({
    selector: 'app-back-to-operation-btn',
    templateUrl: './back-to-operation-btn.component.html',
    styleUrls: ['./back-to-operation-btn.component.scss']
})
export class BackToOperationBtnComponent implements OnInit {

    private _currOperationExtID: string;

    constructor(
        private _breadcrumbService: BreadcrumbService,
        private _operationService: OperationService,
        private _route: Router) {
    }

    ngOnInit() {
        this._operationService.getCurrentOperation()
            .subscribe(currOperation => {
                this._currOperationExtID = currOperation ? currOperation.ExtID : '';
            });
    }

    gotoBack() {
        this._breadcrumbService.setFromBreadcrumb(true);
        this._route.navigate([`${COGIDIM_ROUTES.Operation.route}`], {
            queryParams: {operationExtID: this._currOperationExtID},
            queryParamsHandling: 'merge'
        });
    }
}
