import {Component, Input, OnInit} from '@angular/core';
import {CaracConfig, NPElement, NPListeValues, ValueOneElementHelper} from '@nextpage/np-sdk-data';
import {CatalogueSummary, KitSummary} from '../../sdk/data/Model/models';
import {CogedimDicocarcs} from '../../sdk/data/constantes/cogedim-dicocarcs';
import {FormControl} from '@angular/forms';
import {CogedimElementWriterService} from '../../sdk/data/services/cogedim-element-writer.service';
import {CogedimDownloadService} from '../../sdk/data/services/cogedim-download.service';
import {CaracConfigRepositoryService} from '../../sdk/data/services/carac-config-repository.service';
import {saveAs} from 'file-saver';
import {Subscription} from 'rxjs';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {DownloadingComponent} from '../../sdk/graphics/components/downloading/downloading.component';
import {MatDialogRef} from '@angular/material';
import {CogedimBlockHelper} from '../../sdk/data/helpers/cogedim-block-helper';
import {CogedimConstantes, CogedimHomeEditionItems} from '../../sdk/data/constantes/cogedim-constantes';
import {NpUser} from '../../sdk/data/Model/np-user';
import {BuildingModuleService} from '../../sdk/data/services/building-module.service';

@Component({
    selector: 'app-kit-view',
    templateUrl: './kit-view.component.html',
    styleUrls: ['./kit-view.component.scss']
})
export class KitViewComponent implements OnInit {

    @Input() kit: NPElement;
    @Input() kitConfig: KitSummary;
    @Input() collection: NPElement;
    @Input() currentUser: NpUser;
    @Input() chosenCollection: NPListeValues;
    @Input() buildingModuleParent: NPElement;
    @Input() newBuildingModuleConfigs: CaracConfig[];

    fcCheck = new FormControl();
    private _valueHelper = new ValueOneElementHelper();
    private _sub: Subscription;
    private _dialogRef: MatDialogRef<DownloadingComponent, any>;
    private _objectToSend = new CatalogueSummary();


    constructor(private _cogEltWriterService: CogedimElementWriterService,
                private _downloadService: CogedimDownloadService,
                private _caracRepository: CaracConfigRepositoryService,
                private _modalService: ModalService,
                private _buildingModuleService: BuildingModuleService) {
    }

    ngOnInit() {
        this.fcCheck.valueChanges
            .subscribe(value => {
                this._onChanges(value);
            });
    }

    checked() {
        if (this.collection) {
            const links = this.collection.getValueLien(CogedimDicocarcs.LPP_KIT_DEPUIS_COLLECTIONS_OPERATIONS);
            return links && links.RebuildLinkedElements && links.RebuildLinkedElements.some(currKit => currKit.Element.ExtID === this.kit.ExtID);
        }
        return false;
    }

    private _onChanges(checked: boolean) {
        if (checked && this.collection && this.kit) {
            this._cogEltWriterService.concatValueLink(this.collection, CogedimDicocarcs.LPP_KIT_DEPUIS_COLLECTIONS_OPERATIONS, this.kit);
            // On crée le module de construction lié
            this._buildingModuleService.generateBuildingModuleElements(this.collection, this.buildingModuleParent, this.newBuildingModuleConfigs, this.kit)
                .subscribe(response => {
                    console.log('response ---- : ', response);
                });

        } else if (this.collection && this.kit) {
            this._cogEltWriterService.removeValueFromLinks(this.collection, CogedimDicocarcs.LPP_KIT_DEPUIS_COLLECTIONS_OPERATIONS, this.kit);
            // On supprime le module de construction lié
            this._cogEltWriterService.removeBuildingModuleElementByKitExtId(this.kit.ExtID, this.collection);
        }
    }

    download() {
        this._downloadService.sendError(false); // Initialisation des erreurs
        const hasPath = this.kit && this.kit.getValueTextValue(CogedimDicocarcs.DC_DOSSIER_KIT)
            && this.kit.getValueTextValue(CogedimDicocarcs.DC_DOSSIER_KIT) !== '';
        if (hasPath) {
            this._unsubscribeDownloading();
            const _fileName = `${this._valueHelper.getLabel(this.kit).replace(/\/\//g, '_')}`;
            this._startDownloading();
            this._sub = this._downloadService.downloadObjectsFiles(this.kit, CogedimDicocarcs.DC_DOSSIER_KIT, _fileName)
                .subscribe(data => {
                    this._stopDownloading();
                    saveAs(data, _fileName);
                }, err => {
                    this._downloadService.sendError(true);
                    // this._stopDownloading();
                });
        } else {
            this._modalService.openInfoModal();
        }

    }

    private _startDownloading() {
        this._dialogRef = this._modalService.openDownloading();
        // Si l"utilisateur annule le téléchargement
        this._dialogRef.afterClosed()
            .subscribe(isCanceled => {
                if (isCanceled) {
                    this._unsubscribeDownloading();
                }
            });
    }

    private _stopDownloading() {
        this._dialogRef.close();
    }

    private _unsubscribeDownloading() {
        if (this._sub) {
            this._sub.unsubscribe();
        }
    }

    hasTextValue(caracConfig: CaracConfig) {
        const tmpValue = CogedimBlockHelper.getValue(caracConfig, this.kit);
        return tmpValue && tmpValue.value && tmpValue.value.Value && tmpValue.value.Value !== '';
    }

    hasImages() {
        const tmpValue = this.kit ? this.kit.getValueLien(this.kitConfig.PortailImage.DicoCaracExtID) : undefined;
        return tmpValue && tmpValue.RebuildLinkedElements.length > 0;
    }

    downloadKitSheet() {
        this._objectToSend.Mode = 2;
        this._objectToSend.Title = this.chosenCollection ? this.chosenCollection.Valeur : 'Titre inconnu';
        if (this.currentUser && this.kit) {
            this._objectToSend.References = [this.kit.ID.toString()];
            this._objectToSend.Mail = this.currentUser.Email;
        }

        this._modalService.openCatalogueEditName(this._objectToSend, CogedimHomeEditionItems.KIT_SHEET, null).afterClosed()
            .subscribe(response => {
                if (response !== 0) {
                    this._modalService.openSnackBar(response, CogedimConstantes.SNACK_CLASS);
                }
            });
    }

}
