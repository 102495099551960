import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    Input, OnChanges,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

import {CogedimValueComponentResolverService} from '@sdk/graphics/services/cogedim-value-component-resolver.service';
import {CogedimBlockHelper} from '@sdk/data/helpers/cogedim-block-helper';
import {CaracConfig, NPElement} from "@nextpage/np-sdk-data";
import {CaracIdStateValue} from "@nextpage/np-sdk-graphics";


@Component({
    selector: 'app-cogedim-value-view',
    templateUrl: './cogedim-value-view.component.html',
    styleUrls: ['./cogedim-value-view.component.css']
})
export class CogedimValueViewComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() config: CaracConfig;
    @Input() element: NPElement;
    @ViewChild('componentContainer', {read: ViewContainerRef}) componentRef: ViewContainerRef;

    public isSpecific = () => this.config && this.config.Specific && this.config.Specific['Component'];
    private _componentName = () => this.isSpecific() ? this.config.Specific['Component'] : undefined;

    constructor(private _resolverService: CogedimValueComponentResolverService,
                private _resolver: ComponentFactoryResolver) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {

        if (this._componentName()) {
            this._createComponent();
        }
    }

    ngOnChanges() {
        // console.log('element:::::::::::::::: ', this.element)
        // if (this._componentName()) {
        //     this._createComponent();
        // }
    }

    private _createComponent() {
        if (this.componentRef) {
            // this.componentRef.clear();
            const factory = this._resolver.resolveComponentFactory(this._resolverService.getComponent(this._componentName()));
            const component = this.componentRef.createComponent(factory);
            component.instance['config'] = this.config;
            component.instance['element'] = this.element;
        }

    }

    getCISV(caracConfig: CaracConfig): CaracIdStateValue {
        return CogedimBlockHelper.getValue(caracConfig, this.element);
    }

    hasData() {
        return this.config && this.config.ConfigurationObject;
    }
}
