import {NgModule} from '@angular/core';
import {ValueVideoViewComponent} from './value-video-view/value-video-view.component';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
  ],
  exports: [
    ValueVideoViewComponent
  ],
  declarations: [
    ValueVideoViewComponent
  ]
})
export class NPVideoModule {}
