import {DataMockerError, DataModelMocker} from '../../utils/Data/Mocking';
import {NpModelNaosDicoCarac} from './np-model-naos-dicoCarac';
import {NpModelNaosSearchOne} from './np-model-naos-search-one';
import {NpModelNaosFGC1} from './np-model-naos-FGC1';
import {NpModelNaosCaracConfig} from './np-model-naos-carac-config';
import {NpModelNaosUserStatusAccess} from './np-model-naos-user-status-access';
import {Injectable} from '@angular/core';

@Injectable()
export class NpModeleNaosDataMocker extends DataModelMocker {
    constructor() {
        super();
        super.AddMocker(new NpModelNaosDicoCarac());
        super.AddMocker(new NpModelNaosSearchOne());
        super.AddMocker(new NpModelNaosFGC1());
        super.AddMocker(new NpModelNaosCaracConfig());
        super.AddMocker(new NpModelNaosUserStatusAccess());
        super.AddMocker(new DataMockerError());

    }
}
