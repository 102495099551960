import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CogedimDownloadService} from '../../../data/services/cogedim-download.service';

@Component({
  selector: 'app-dialog-preload',
  templateUrl: './dialog-preload.component.html',
  styleUrls: ['./dialog-preload.component.css']
})
export class DialogPreloadComponent implements OnInit {

  public title =  'Chargement des données en cours';
  public subTitle = 'Veuillez patienter.';

  constructor(public dialogRef: MatDialogRef<DialogPreloadComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit() {
    if (this.data && this.data.message && this.data.message.trim() !== -1) {
      this.title = this.data.message;
    }
  }

}
