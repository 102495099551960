import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {CaracInfo, PhotosInfos} from '../np-value/Model';
import {MatDialog} from '@angular/material';
import {NpMediasSelectionComponent} from '../np-medias-selection/np-medias-selection.component';
import {filter, first} from 'rxjs/operators';
import {FileReaderService, Sheet, ThemingService} from '../../services';
import {MediaFillRateService} from '../../services/media-fill-rate.service';
import {
    Access,
    CaracConfig,
    DicoCarac,
    ElementWriterService,
    ManagementRulesCheckerService,
    NPCaracLien,
    NPCaracLienRebuildValue,
    NpDownloadService,
    NPElement
} from '@nextpage/np-sdk-data';
import {saveAs} from 'file-saver';
import jss from 'jss';

@Component({
    selector: 'lib-visual-media',
    templateUrl: './visual-media.component.html',
    styleUrls: ['./visual-media.component.css']
})
export class VisualMediaComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    public photoUrls: PhotosInfos[];
    public val: NPCaracLien;
    private _cardinality: number;
    public canAddMore = true;

    public isValid = true;
    public readOnlyAccess: boolean;

    public classes: Object;

    constructor(private _fileReader: FileReaderService, private _dialog: MatDialog, private _elementWriter: ElementWriterService,
                private _mediaFillRateService: MediaFillRateService,
                private _ManagementRulesChecker: ManagementRulesCheckerService,
                private _downloadService: NpDownloadService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService
    ) {
        this.photoUrls = [];
    }

    ngOnInit() {
        this.readOnlyAccess = this.caracInfo.authorization === Access.LECTURESEULE;
        // [ngClass]="classes && classes['title'] ? classes['title']: ''"
        const override_css = this._theming.getComponentConfigStyle('VisualMediaComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
            // console.log(this.classes);
        }
        this._buildState();
        this._ManagementRulesChecker.isChecking()
            .subscribe(isSaving => {
                if (isSaving) {
                    this._initRequiedError();
                }
            });
    }

    private _buildState() {
        this.val = this.value.Element.getValueLien(this.value.DicoCaracExtID);
        this.photoUrls = [];
        this._cardinality = this.caracConfig.DicoCarac.Cardinality;
        if (this._cardinality == null) {
            this._cardinality = 9999;
        }
        if (this.val.RebuildLinkedElements) {
            this.val.RebuildLinkedElements.forEach(link => {
                if (this._cardinality == null || this._cardinality > this.photoUrls.length) {
                    const filePath = this._fileReader.toUrlThumbImage(link.Element);
                    const file = link.Element.getValueTextValue(DicoCarac.MEDIA_FILE);
                    const fileExploded = file.replace(new RegExp(/\\/i, 'g'), '/').split('/');
                    const fileName = fileExploded[fileExploded.length - 1];

                    this.photoUrls.push(new PhotosInfos(filePath, this._fileReader.toUrlThumbImage(link.Element), fileName, link.Order));
                }
            });
            this.canAddMore = this._cardinality > this.photoUrls.length;
            this.photoUrls = this.photoUrls.sort((a, b) => {
                return a.order - b.order;
            });
        }
    }

    deletePicture(picture: PhotosInfos) {
        const mediaToDelete: NPCaracLienRebuildValue = this._getLinkedMedia(picture);
        this._elementWriter.deleteValueLink(this.value.Element, this.value.DicoCaracExtID, mediaToDelete.Element);
        this._buildState();
        this._initRequiedError();
    }

    changePicture(picture: PhotosInfos) {
        const mediaToChange: NPCaracLienRebuildValue = this._getLinkedMedia(picture);
        this._selectMedia(mediaToChange.Element.ID)
            .subscribe((result) => {
                this._elementWriter.changeValueLink(this.value.Element, this.value.DicoCaracExtID, result, mediaToChange.Order);
                this._buildState();
            });
    }

    addPicture() {
        this._selectMedia()
            .subscribe((result: NPElement) => {
                if (result != null) {
                    this._elementWriter.concatValueLink(this.value.Element, this.value.DicoCaracExtID, result);
                    this._buildState();
                    this._initRequiedError();
                }
            });
    }

    private _selectMedia(selectedMediaID: number = -1) {
        return this._dialog.open(NpMediasSelectionComponent, {
            width: '80%',
            height: '70%',
            data: {
                selectedMediaID: selectedMediaID,
                dicoCaracExtID: this.value.DicoCaracExtID
            }
        }).afterClosed().pipe(
            first(),
            filter(picture => picture !== undefined)
        );
    }

    private _getLinkedMedia(picture: PhotosInfos) {
        const value = this.val.RebuildLinkedElements.filter((val: NPCaracLienRebuildValue) => {
            return picture.order === val.Order;
        });
        if (value.length === 1) {
            return value[0];
        }
        return null;
    }

    private _initRequiedError() {
        this.isValid = this._ManagementRulesChecker.isValueValide(this.value.Element, this.caracConfig);
    }

    downloadImage(imageInfos: PhotosInfos) {
        this._downloadService.getBase64ImageFromURL(imageInfos.src)
            .subscribe(base64data => {
                const blob = this._downloadService.getDownload(base64data, 'image/png');
                saveAs(blob, imageInfos.src);
            });
    }
}
