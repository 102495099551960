import {NPElement} from '../model/np-element';
import {NPAPIElementLinksResult} from '../model/NPAPIElementLinksResult';
import {NPCaracLien, NPCaracLienRebuildValue, NPCaracValeur} from '../model/np-carac-valeur';

export class ElementsHelper {
  public static rebuild(requestResult: any): Map<string, NPElement> {
    const requestResultCasted = new NPAPIElementLinksResult(requestResult);
    const result = new Map<string, NPElement>();

    // il faut recaster proprement tous les éléments, c'est pénible, mais il peut manquer des méthodes ou les values peuvent devenir un objet contenant des propriétés au lieu d'un Map
    const recasted = new Map<string, NPElement>();
    requestResultCasted.Elements.forEach((eltData) => {
      // reconstruction propre des objets
      const element = new NPElement(eltData);
      recasted.set(element.ID.toString(), element);
    });
    requestResultCasted.Elements = recasted;
    // une fois recasté, on reconstuit le contenu
    requestResultCasted.Elements.forEach((element) => {
      // reconstruction propre des objets
      // reconstruction des liens entre les éléments
      element.Values.forEach((value: NPCaracValeur) => {
        value.Element = element;
        if ((<NPCaracLien>value).LinkedElements != null) { // Si caracLien
          (<NPCaracLien>value).RebuildLinkedElements = (<NPCaracLien>value).LinkedElements
            .reduce((accumulator, val) => {
              if (requestResultCasted.Elements.has(val.ElementID.toString())) {
                const rebuildedVal = new NPCaracLienRebuildValue();
                rebuildedVal.Order = val.Order;
                rebuildedVal.Element = requestResultCasted.Elements.get(val.ElementID.toString());
//                val.RebuildElement = rebuildedVal.Element; // attention, si on fait ça, ca risque de coincer à l'écriture
                accumulator.push(rebuildedVal);
              }
              return accumulator;
            }, []);
        }
      });
      // reconstruction des parents
      if (requestResultCasted.Elements.has(element.ParentID.toString())) {
        element.Parent = requestResultCasted.Elements.get(element.ParentID.toString());
        if (element.ParentExtID == null) {
          element.ParentExtID = element.Parent.ExtID;
        }
        if (element.Parent.Children == null) {
          element.Parent.Children = [];
        }
        element.Parent.Children.push(element);
      }

    });
    // récupération des objets correctement "recastés"
    requestResultCasted.Results.map((ElementID) => {
      const element = requestResultCasted.Elements.get(ElementID.toString());
      if (element) {
          result.set(element.ExtID, requestResultCasted.Elements.get(ElementID.toString()));
      }
    });
    return result;
  }
}
