import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {DicoCarac, NPCaracLien, NPElement} from '@nextpage/np-sdk-data';
import {CaracInfo} from '../Model';
import {FileReaderService} from '../../../services';


export interface ImageObject {
    src: string;
    thumbSrc: string;
    alt: string;
    element?: NPElement;
}

@Component({
    selector: 'lib-app-value-image-read-view',
    templateUrl: './np-value-image-read-view.component.html',
    styleUrls: ['./np-value-image-read-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NpValueImageReadViewComponent implements OnChanges {
    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;

    photosUrl: ImageObject[];

    constructor(private _fileReader: FileReaderService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.photosUrl = [];
        if (this.value.RebuildLinkedElements == null) {
            return;
        }
        this.value.RebuildLinkedElements.forEach(link => {
            this.photosUrl.push({
                src: `${this._fileReader.toUrlThumbImage(link.Element)}`,
                thumbSrc: this._fileReader.toUrlThumbImage(link.Element),
                alt: link.Element.getValueTextValue(DicoCarac.MEDIA_FILE)
            });
        });
    }

}
