import {EditionHomeItem, ItemCaracLie} from '../Model/models';

export enum CogedimConstantes {
    VALIDATE_BUILDING_MODULE_KIT = 'VALIDATE_BUILDING_MODULE_KIT',
    POSED = 'Oui',
    NOT_POSED = 'Non',
    TYPE_COLLECTIONS_DES_OPERATIONS = 'TYPE_COLLECTIONS_DES_OPERATIONS',
    HOME_EDITION = 'Accueil_Edition',
    FAMILY_ID = 'FamilyID',
    RESEARCH_CONFIG = 'ResearchConfig',
    PASSWORD = 'password',
    LOGIN = 'login',
    SNACK_CLASS = 'cogedimSnakBarClass',
    COLLECTION_VALUES = 'CollectionValues',
    NAV_BAR_ITEM_PRESTATIONS_EDITION = 'NAV_BAR_ITEM_PRESTATIONS_EDITION',
    KitObject = 'KitObject',
    COLLECTION_EXTID_PARAM = 'collection',
    PRESTATION_EXTID_PARAM = 'prestationExtID',
    SAVE_MODE = 'SAVE_MODE',
    DC_DIRECTION_REGIONALE_OPERATION = 'DC_DIRECTION_REGIONALE_OPERATION',
    DC_COLLECTION = 'DC_COLLECTION',
    DC_ACTIF_INACTIF = 'DC_ACTIF_INACTIF',
    DC_DIRECTION_REGIONALE_PRODUITS = 'DC_DIRECTION_REGIONALE_PRODUITS',
    WARNIG_MODE = 'WARNIG_MODE',
    DELETE_MODE = 'DELETE_MODE',
    NewCollectionConfig = 'NewCollectionConfig',
    F_COLLECTIONS_OPERATIONS = 'COLLECTIONS_OPERATIONS',
    TYPE_COLLECTIONS_OPERATIONS = 'TYPE_COLLECTIONS_DES_OPERATIONS',
    SPECIFIC_FAMILY_FIELD = 'FamilyExtID',
    DC_NB_T = 'DC_NB_T',
    LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION = 'LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION',
    DC_LIBELLE_COLLECTION = 'DC_LIBELLE_COLLECTION',
    DC_NOM_COLLECTION_CHOISIE_COLLECTION = 'DC_NOM_COLLECTION_CHOISIE_COLLECTION',
    DC_LIBELLE = 'DC_LIBELLE',
    DC_DATE_DEBUT_TRAVAUX_DROC = 'DC_DATE_DEBUT_TRAVAUX_DROC',
    DC_DATE_TEMOIN = 'DC_DATE_TEMOIN',
    COLUMN = 'column',
    DISPLAY_FULL = 'full',
    DISPLAY_FIELD = 'display',
    OPERATION_EXTID_PARAM = 'operationExtID',
    OPERATION_NAV_BAR = 'OperationNavBar',
    CONTENU_WEB = 'ContenusWeb',
    PAGE_WEB = 'PAGE_WEB_CONTENUS_WEB_PRODUCT',
    IMAGE_NAME = 'imgName',
    IS_FONT_AWESOME = 'isFontAwesome',
    ACTION = 'Action',
    LEFT_COLUMN = 'left',
    RIGHT_COLUMN = 'right',
    EDITION_NAV_BAR = 'EditionNavBar',
    INTRODUCTION_TO_DISPLAY = 'IntroductionToDisplay',
    WITNESS_CONTROL = 'WitnessControl',
    BuildingModule = 'BuildingModule',
    NewBuildingModuleCong = 'NewBuildingModuleCong',
}

export enum Messages {
    NO_DATA_TO_PRINT = 'Aucune données à imprimer.',
    INVALID_EMAIL = 'Adresse e-mail incorrecte',
    EMPTY_PWD = 'Mot de passe obligatoire',
    EMPTY_MAIL = 'Adresse e-mail obligatoire',
    FORGOTTEN_PAWD = 'Un e-mail vous a été envoyé récapitulant votre login et mot de passe',
    NO_CHANGES_TO_SAVE = 'Aucune modification à enregistrer!',
    SAVE_QUESTION = 'Voulez-vous vraiment enregistrer toutes les modifications?',
    SAVE_SUCCES = 'Enregistrement effectué avec succès !',
    SAVE_ERROR = 'Une erreur s\'est produite lors de l\'enregistrement',
    DELETE_COLLECTION = 'Voulez-vous vraiment supprimer cette collection ?'
}

export enum CogedimHomeEditionItems {
    DESCRIPTIVE_NOTICE = 'Descriptive_Notice_Item',
    GENERAL_AND_OTHER_CUSTOMIZATION = 'General_And_Other_Customizations_Item',
    EXCEL_EXPORT = 'Excel_Export_Item',
    DOWNLOAD_NOTICE = 'Download_Notice_Item',
    KIT_SHEET = 'Download_Kit_Sheet_Item',
    WITNESS_CONTROL = 'Witness_control_Item',
    SYNOPTIC = 'Synoptique_Item',
}

export const HOME_EDITION_VIGNETTES: EditionHomeItem[] = [
    {vignetteName: 'Notice_descriptive.png', itemType: CogedimHomeEditionItems.DESCRIPTIVE_NOTICE, buttonLabel: 'Editer', toolTip: 'Générer le fichier'},
    {vignetteName: 'General_and_customization.png', itemType: CogedimHomeEditionItems.GENERAL_AND_OTHER_CUSTOMIZATION, buttonLabel: 'Editer', toolTip: 'Générer le fichier'},
    {vignetteName: 'Export_excel.png', itemType: CogedimHomeEditionItems.EXCEL_EXPORT, buttonLabel: 'Editer', toolTip: 'Générer le fichier'},
    {vignetteName: 'Upload_notice_def.png', itemType: CogedimHomeEditionItems.DOWNLOAD_NOTICE, buttonLabel: 'Télécharger', toolTip: 'Télécharger des notices descriptives définitives'},
    {vignetteName: 'Witness_control.png', itemType: CogedimHomeEditionItems.WITNESS_CONTROL, buttonLabel: 'Controler', toolTip: 'Confirmer la pose des produits'},
    {vignetteName: 'synoptique.png', itemType: CogedimHomeEditionItems.SYNOPTIC, buttonLabel: 'Editer', toolTip: 'Télécharger votre Book des produits sélectionnés'}
];


export const EXPORT_LINKED_CARACS: ItemCaracLie[] = [
    new ItemCaracLie(2384, 1),
    new ItemCaracLie(2437, 2),
    new ItemCaracLie(2385, 3),
];

export const EXPORT_CARACS: number[] = [
    1909,
    2386,
    1958,
    2262,
    2409,
    2218,
    2010,
    2016,
    2416,
    2417,
    2418,
    2419,
    2420,
    2323,
    2029,
    2214,
    2096,
    2384,
    2385
];

export const EXPORT_OBJECT = {
    Config: {
        ExportType: 'Elements',
        OutFileName: '',
        OneFileByLangue: false,
        Produits: [],
        DicoCaracteristiques: [],
        CaracLie: [],
        User: {
            ID: 0,
            Email: ''
        },
        Langues: [1],
        Format: 'TXT',
        ContexteID: 1,
        LangID: 1,
        JobImportExportConfigExtID: 'Export_Feature_Excel_BIS',
        AuthorizeDownload: {
            Enable: true,
            keepDays: 1
        }
    },
    Name: 'Web_Test',
    SchedulerStatus: 1,
    IsForSSIS: true
};

export const RESULT_PAGE_SIZE = 5;

// Used in synoptic module
export enum ActionType {
    OPEN_SYNOPTIC = 'OPEN_SYNOPTIC'
}

export enum CogedimElementExtIDs {
    SYNOPTIC_FOLDER = 'SYNOPTIC_FOLDER'
}
