import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {DialogWarningComponent} from '../components/dialog-warning/dialog-warning.component';
import {DialogErrorsComponent} from '../components/dialog-errors/dialog-errors.component';
import {DialogWarningAskComponent} from '../components/dialog-warning-ask/dialog-warning-ask.component';
import {DownloadingComponent} from '../components/downloading/downloading.component';
import {DialogGenericComponent} from '../components/dialog-generic/dialog-generic.component';
import {DialogCatalogueNameComponent} from '../components/dialog-catalogue-name/dialog-catalogue-name.component';
import {CatalogueSummary} from '../../data/Model/models';
import {DialogDownloadNoticeComponent} from '../../../cogedim-spe/dialog-download-notice/dialog-download-notice.component';
import {DialogOverlayComponent} from '../components/dialog-overlay/dialog-overlay.component';
import {CogedimHomeEditionItems} from '../../data/constantes/cogedim-constantes';
import {DialogPreloadComponent} from '../components/dialog-preload/dialog-preload.component';
import {DialogWitnessControlComponent} from '../../../cogedim-spe/dialog-witness-control/dialog-witness-control.component';
import {NPElement, NPListeValues} from '@next-page/np-sdk-data';
import {DialogSynopticComponent} from '../../../cogedim-spe/dialog-synoptic/dialog-synoptic.component';
import {DialogSynopticPreloaderComponent} from '../../../cogedim-spe/dialog-synoptic-preloader/dialog-synoptic-preloader.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private _overlay: MatDialogRef<DialogOverlayComponent, any>;
    private _preloader: MatDialogRef<DialogPreloadComponent, any>;
    private _synopticSub$: MatDialogRef<DialogSynopticComponent, any>;
    private _synopticPreloaderSub$: MatDialogRef<DialogSynopticPreloaderComponent, any>;

    constructor(private _matDialog: MatDialog, private _snackBar: MatSnackBar) {
    }

    openCatalogueEditName(object: CatalogueSummary, documentType: CogedimHomeEditionItems, operationId: number) {
        return this._matDialog.open(DialogCatalogueNameComponent, {
            width: '500px',
            data: {object: object, documentType: documentType, operationId: operationId},
            disableClose: true,
            closeOnNavigation: false,
            hasBackdrop: false,
            autoFocus: false
        });
    }

    openInfoModal() {
        this._matDialog.open(DialogGenericComponent, {
            width: '500px',
            data: {}
        });
    }

    openWarningAskDialog() {
        return this._matDialog.open(DialogWarningAskComponent, {
            width: '760px',
            data: {}
        }).afterClosed();
    }

    openConfirmModal(mode: string, message?: string) {
        return this._matDialog.open(DialogWarningComponent, {
            width: '500px',
            data: {mode: mode, message: message}
        }).afterClosed();
    }

    openErrorModal() {
        this._matDialog.open(DialogErrorsComponent, {
            width: '700px',
            data: {}
        });
    }


    openSnackBar(message: string, cssClass: string, duration = 10000) {
        this._snackBar.open(message, 'Fermer', {
            duration: duration,
            verticalPosition: 'top',
            panelClass: cssClass
        });
    }

    openDownloading() {
        return this._matDialog.open(DownloadingComponent, {
            width: '500px',
            data: {},
            disableClose: true
        });
    }

    openDownloadNotice() {
        return this._matDialog.open(DialogDownloadNoticeComponent, {
            width: '70%',
            data: {},
            disableClose: true
        });
    }

    openWaitingOverlayDialog() {
        this._overlay = this._matDialog.open(DialogOverlayComponent, {
            maxWidth: '50em',
            disableClose: true
        });
        return this._overlay;
    }

    closeWaitingOverlayDialog() {
        this._overlay.close();
    }

    openPreloadDialog(message?: string) {
        this._preloader = this._matDialog.open(DialogPreloadComponent, {
            disableClose: true,
            hasBackdrop: false,
            data: {message: message}
        });
        return this._overlay;
    }

    closePreloadDialog() {
        this._preloader.close();
        this._matDialog.closeAll();
    }

    openWitnessControlDialog(currentOperation: NPElement, chosenCollection: NPListeValues) {
        return this._matDialog.open(DialogWitnessControlComponent, {
            disableClose: true,
            hasBackdrop: false,
            data: {currentOperation, chosenCollection},
            closeOnNavigation: false,
            autoFocus: false
        });
    }

    openSynopticDialog() {

        if (this._synopticSub$) {
            this._synopticSub$.close();
        }

        this._synopticSub$ = this._matDialog.open(DialogSynopticComponent, {
            disableClose: false,
            hasBackdrop: true,
            autoFocus: false,
            width: '80%',
            minHeight: 'calc(100vh - 200px)',
            height: 'auto',
            closeOnNavigation: false
        });
        return this._synopticSub$;
    }

    openSynopticPreloaderDialog(currentOperationID: number,
                                currentUserID: number,
                                currentCollectionID: number,
                                chosenCollection: string) {

        if (this._synopticPreloaderSub$) {
            this._synopticPreloaderSub$.close();
        }
        this._synopticPreloaderSub$ = this._matDialog.open(DialogSynopticPreloaderComponent, {
            disableClose: false,
            hasBackdrop: false,
            autoFocus: false,
            closeOnNavigation: false,
            data: {
                currentOperationID: currentOperationID,
                chosenCollection: chosenCollection,
                currentUserID: currentUserID,
                currentCollectionID: currentCollectionID
            }
        });
        return this._synopticSub$;
    }
}
