import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {SynopticFileResponse, SynopticQueryBody} from '../Model/synoptic';
import {flatMap} from 'rxjs/operators';
import {FILE_DATA} from '../constantes/testFile';
import {of} from 'rxjs/internal/observable/of';
import {DownloadHelper} from '../helpers/download-helpet';
import {FileConvereterHelper} from '../helpers/file-helper';
import {SynopticMessages} from '../constantes/synoptic-constants';

@Injectable({
  providedIn: 'root'
})
export class SynopticWriterService {

  private _url = '/api/sdk-ext/synoptic/publishbook';

  private static _downloadSynopticFile(response: SynopticFileResponse): SynopticFileResponse {
    if (response) {
      if (response && response.file && response.file.trim() !== '') {
        try {
          DownloadHelper.saveFileAsPdf(FileConvereterHelper.convertStringToBlob(response.file), response.fileName || 'Unknown file name');
        } catch (error) {
          response.success = false;
          response.errors.push(error.toString());
        }
        return response;
      }
    }
    response = new SynopticFileResponse();
    response.errors.push('Api response is null ou undefined.');
    return response;
  }

  constructor(private _http: HttpClient) {
  }


  generateTestData() {
    return of(FILE_DATA)
        .pipe(
            flatMap((response: SynopticFileResponse) => {
              if (response) {
                if (response.success || (response.file && response.file.trim() !== '')) {
                  response = SynopticWriterService._downloadSynopticFile(response);
                  response.message = SynopticMessages.GENERATING_SUCCESS;
                  return of(response);
                }
                return throwError(response);
              }

            }));
  }

  generate(productId: number, collectionId: number, collectionType: string, userId: number): Observable<SynopticFileResponse> {
    if (productId && collectionId && collectionType) {
      const queryBody = new SynopticQueryBody(productId, collectionId, collectionType, userId);
      return this._http.post<SynopticFileResponse>(this._url, queryBody)
          .pipe(
              flatMap((response: SynopticFileResponse) => {
                if (response) {
                  if (response.success || (response.file && response.file.trim() !== '')) {
                    response = SynopticWriterService._downloadSynopticFile(response);
                    response.message = SynopticMessages.GENERATING_SUCCESS;
                    return of(response);
                  }
                  return throwError(response);
                }

              }));
    }
    return throwError({messages: SynopticMessages.BAD_PARAMETERS, success: false});
  }

 /* generate(productId: number, collectionId: number, collectionType: string, userId: number): Observable<ApiResponse> {
    if (productId && collectionId && collectionType) {
      const queryBody = new SynopticQueryBody(productId, collectionId, collectionType, userId);
      const response = new ApiResponse();
      return this._http.post<SynopticFileResponse>(this._url, queryBody)
          .pipe(
              catchError(error => {
                response.Message = SynopticMessages.GENERATING_ERROR;
                response.Error = error;
                return throwError(response);
              }),
              flatMap(result => {
                result = SynopticWriterService._downloadSynopticFile(result);
                if (result && result.success) {
                  response.Success = true;
                  response.Message = SynopticMessages.GENERATING_SUCCESS;
                  response.Response = result;
                  return of(response);
                }
                response.Success = false;
                response.Error = result.errors;
                return throwError(response);
              })
          );
    }
    return throwError({Messages: SynopticMessages.BAD_PARAMETERS, Success: false});
  }*/
}
