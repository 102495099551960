export class FileConvereterHelper {
    /**
     * Perrmet de convertir une chaine (base64) de caractères en objet Blob
     * Attention : Si la chaine provient d'une conversion direction en base64, elle ne doit pas contenir la partie "base64" ni "data:contentType"
     * Format accpeté : 'iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4'
     * param str
     * param contentType
     */
    public static convertStringToBlob(strContent, contentType = 'application/pdf') {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(strContent);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);
            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    static pdfFileNameResolver(fileName: string) {

    }
}
