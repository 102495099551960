import {DicoCarac, NPElement} from '@nextpage/np-sdk-data';

export class Synoptic {
    fileName: string;
    parentName: string;
    comment: string;
    element: NPElement;
    childToDownload: NPElement;

    private static _getName(element: NPElement) {
        return element ? element.getValueTextValue(DicoCarac.MEDIA_LABEL) : '';
    }

    constructor(mediaElement: NPElement) {
        if (mediaElement) {
            // Nom du dossier ##MediaDirectoryLabel MEDIADIRECTORY_LABEL
            this.parentName = mediaElement.getValueTextValue(DicoCarac.MEDIADIRECTORY_LABEL) || '';
            this.comment = 'Description of synoptic ' + this.parentName;
            this.element = mediaElement;
            // On prend les media dont le nom ne contient pas le mot "Price", donc Synoptique sans prix.
            this.childToDownload = mediaElement && mediaElement.Children ?
            mediaElement.Children.find(child => Synoptic._getName(child).toLocaleUpperCase().indexOf('_PRICE') === -1) : null;
            if (this.childToDownload) {
                this.fileName = Synoptic._getName(this.childToDownload);
            }
        }
    }


}
