export class PhotosInfos {
    public src: string;
    public thumbSrc: string;
    public alt: string;
    public order?: number = null;

    constructor(src: string, thumbSrc: string, alt: string, order: number) {
        this.src = src;
        this.thumbSrc = thumbSrc;
        this.alt = alt;
        this.order = order;
    }
}
