import {
    NiveauAccesDonnee,
    NPCaracCharTemplate,
    NPCaracLien,
    NPCaracListe, NPCaracSearchRankLevel,
    NpCaracStatus,
    NPCaracStd,
    NPCaracValeur
} from './np-carac-valeur';
import {DicoCarac} from './np-dico-carac';
import {Guid} from 'guid-typescript';
import {DicoCaracExtID} from "@nextpage/np-sdk-data";

/**
 * Classe Element correspondant à un élément dans nextPage
 */
export class NPElement {
    ExtID?: string;
    ElementType: NPElementType;
    ID?: number;
    ParentExtID?: string;
    ParentID?: number;
    Inf?: number;
    Sup?: number;
    Values?: Map<string, NPCaracValeur> = new Map<string, NPCaracValeur>();
    // le parent est reconstruit après réception. Il est souvent null
    Parent?: NPElement;
    Access: NiveauAccesDonnee;
    Action = NPElementTypeAction.CreateUpdate;
    Children?: NPElement[];
    Ordre?: number;

    constructor(param) {
        if (param == null) {
            this.ExtID = NPElement.newGuid();
            this.Values = new Map<string, NPCaracValeur>();
        } else if (typeof param === 'string') {
            this.ExtID = param;
            this.Values = new Map<string, NPCaracValeur>();
        } else {
            if (param.hasOwnProperty('ExtID')) {
                this.ExtID = param.ExtID;
            }
            if (param.hasOwnProperty('ElementType')) {
                this.ElementType = param.ElementType;
            }
            if (param.hasOwnProperty('ID')) {
                this.ID = param.ID;
            }
            if (param.hasOwnProperty('ParentExtID')) {
                this.ParentExtID = param.ParentExtID;
            }
            if (param.hasOwnProperty('Parent')) {
                this.Parent = new NPElement(param.Parent);
            }
            if (param.hasOwnProperty('ParentID')) {
                this.ParentID = param.ParentID;
            }
            if (param.hasOwnProperty('Inf')) {
                this.Inf = param.Inf;
            }
            if (param.hasOwnProperty('Sup')) {
                this.Sup = param.Sup;
            }
            if (param.hasOwnProperty('Values')) {
                if (param.Values.forEach != null) {
                    param.Values.forEach((value, key) => {
                        if (value == null) {
                            const t = 'mince, cest nul';
                        } else {
                            this.Values.set(key, NPCaracValeur.instanciate(value));
                        }
                    });
                } else {
                    Object.keys(param.Values).map((key) => {
                        this.Values.set(key, NPCaracValeur.instanciate(param.Values[key]));
                    });
                }

            }
            if (param.hasOwnProperty('Parent')) {
                this.Parent = param.Parent;
            }
            if (param.hasOwnProperty('Access')) {
                this.Access = param.Access;
            }
            if (param.hasOwnProperty('Action')) {
                this.Action = param.Action;
            }
            if (param.hasOwnProperty('Children')) {
                this.Children = param.Children;
            }
            if (param.hasOwnProperty('Ordre')) {
                this.Ordre = param.Ordre;
            }

        }

    }


    private static newGuid() {
        return Guid.create().toString();
    }

    /**
     * Retourne le NPCaracValeur par sa clé
     */
    getValue(DicoCaracExtID: string): NPCaracValeur {
        return this.Values.get(DicoCaracExtID);
    }


    /**
     * Retourne le NPCaracValeur par sa clé et le convertit en valeur de type texte
     */
    getValueText(DicoCaracExtID: string): NPCaracStd {
        return this.Values.get(DicoCaracExtID) as NPCaracStd;
    }


    /**
     * Retourne le NPCaracValeur par sa clé et le convertit en valeur de type texte
     */
    getValueTextValue(DicoCaracExtID: string): string {
        const val = this.getValueText(DicoCaracExtID);
        if (val != null) {
            return val.Value;
        } else {
            return '';
        }
    }

    /**
     * Retourne le NPCaracValeur par sa clé et le convertit en valeur de type lien
     */
    getValueLien(DicoCaracExtID: string): NPCaracLien {
        return this.Values.get(DicoCaracExtID) as NPCaracLien;
    }

    /**
     * Retourne le NPCaracValeur par sa clé et le convertit en valeur de type lien
     */
    getValueListe(DicoCaracExtID: string): NPCaracListe {
        return this.Values.get(DicoCaracExtID) as NPCaracListe;
    }

    /**
     * Retourne les types de produtis associés au produit
     */
    getValueCharTemplate(): NPCaracCharTemplate {
        return this.Values.get(DicoCarac.DTO_SYSTEM_CHAR_TEMPLATE) as NPCaracCharTemplate;
    }

    /**
     * Retourne le Statut
     */
    getValueStatus(): NpCaracStatus {
        return this.Values.get(DicoCarac.DTO_SYSTEM_STATUS) as NpCaracStatus;
    }

    setValue(DicoCaracExtID: string, caracValue: NPCaracValeur) {
        const newCarac = Object.assign({}, caracValue) as NPCaracValeur;
        newCarac.ElementID = this.ID;
        this.Values.set(DicoCaracExtID, newCarac);
    }

    deleteValue(DicoCaracExtID: string) {
        if (this.Values.has(DicoCaracExtID)) {
            this.Values.delete(DicoCaracExtID);
        }
    }

    hasValue(DicoCaracExtID: string) {
        return this.Values.has(DicoCaracExtID);
    }

    getValueSearchRankLevelSearchRanking(): number {
        if (!this.Values.has(DicoCarac.DTO_SYSTEM_SEARCH_RANKING)) {
            return 0;
        } else {
            return this.getValueSearchRankLevel().SearchRanking;
        }
    }

    getValueSearchRankLevel(): NPCaracSearchRankLevel{
        return this.Values.get(DicoCarac.DTO_SYSTEM_SEARCH_RANKING) as NPCaracSearchRankLevel;
    }

    isActive(): boolean | null {
      return this.Values.get(DicoCaracExtID.DTO_SYSTEM_STATUS).StatusExtID === '_Active';
    }
}

export enum NPElementType {
    Root = 0,
    Family = 1,
    Product = 2,
    Reference = 3,
    DicoCharacteristic = 4,
    Notification = 50,
    ChannelDirectory = 101,
    Channel = 102,
    ChannelNode = 103,
    MediaDirectory = 201,
    Media = 202
}

export enum NPElementTypeAction {
    CreateUpdate = 0,
    Delete = 1
}
