export enum CogedimDicocarcs {
    LPP_KIT_DEPUIS_MODULE_CONSTRUCTION = 'LPP_KIT_DEPUIS_MODULE_CONSTRUCTION',
  SF_MODULE_CONSTRUCTION  = 'SF_MODULE_CONSTRUCTION',
  DC_P_MODULE_CONSTRUCTION_POURQUOI = 'DC_P_MODULE_CONSTRUCTION_POURQUOI',
  LPP_MODULE_CONSTRUCTION = 'LPP_MODULE_CONSTRUCTION',
  DC_P_CONSTRUCTION_POURQUOI = 'DC_P_CONSTRUCTION_POURQUOI',
  DC_P_POSE_LISTE = 'DC_P_POSE_LISTE',
  DC_P_TABLEAU_HTML_CONCTRUCTION = 'DC_P_TABLEAU_HTML_CONCTRUCTION',
  WEB_EDITION_GENERALITES_ET_AUTRES_PERSONNALISATIONS= 'WEB_EDITION_GENERALITES_ET_AUTRES_PERSONNALISATIONS',
  CW_PRODUITS = 'CW_PRODUITS',
  DC_WEB_OPERATION_DE_NOTICE = 'DC_WEB_OPERATION_DE_NOTICE',
  NOTICE_MEDIA_FILE = 'NOTICE_MEDIA_FILE',
  DC_WEB_NOTICE_DESCRIPTIVE_MODIFICATIONS = 'DC_WEB_NOTICE_DESCRIPTIVE_MODIFICATIONS',
  NOTICE_FILE = 'NOTICE_FILE',
  DC_WEB_NOTICE_DESCRIPTIVE_FILE = 'DC_WEB_NOTICE_DESCRIPTIVE_FILE',
  F_NOTICE_DESCRIPTIVE_DEFINITIVES = 'F_NOTICE_DESCRIPTIVE_DEFINITIVES',
  DC_DATE_UPLOAD_NOTICE = 'DC_DATE_UPLOAD_NOTICE',
  DC_DIRECTION_REGIONALE_USER = 'DC_DIRECTION_REGIONALE_USER',
  DC_OTHERS_PRODUCTS = 'nav_autres_produits',
  DC_VILLE = 'DC_VILLE',
  NO_USED_COLLECTION_SELECTOR = 'NO_USED_COLLECTION_SELECTOR',
  DC_NOM_COLLECTION_CHOISIE_BLOC_CONTENT = 'DC_NOM_COLLECTION_CHOISIE_BLOC_CONTENT',
  DC_WEBVISIBILITY_Family = 'DC_WEBVISIBILITY_Family',
  DC_INTRODUCTION = 'DC_INTRODUCTION',
  DC_NOM_COMMERCIAL = 'DC_NOM_COMMERCIAL',
  DC_PRIX_PLUS_PRODUIT = 'DC_PRIX_PLUS_PRODUIT',
  LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION = 'LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION',
  F_OPERATIONS = 'F_OPERATIONS',
  DC_ETAT_OPERATION = 'DC_ETAT_OPERATION',
  DC_ADRESSE = 'DC_ADRESSE',
  DC_N_PRIMPROMO = 'DC_N_PRIMPROMO',
  DC_LIBELLE_COLLECTION = 'DC_LIBELLE_COLLECTION',
  LPP_KIT_DEPUIS_COLLECTIONS_OPERATIONS = 'LPP_KIT_DEPUIS_COLLECTIONS_OPERATIONS',
  DC_NOM_COLLECTION_CHOISIE_COLLECTION = 'DC_NOM_COLLECTION_CHOISIE_COLLECTION',
  DC_MARQUE_AUTRES = 'DC_MARQUE_AUTRES',
  DC_NOTA_PORTAIL = 'DC_NOTA_PORTAIL',
  DC_PORTAIL_IMAGES = 'DC_PORTAIL_IMAGES',
  DC_NOM_DU_PRODUIT_OU_KIT = 'DC_NOM_DU_PRODUIT_OU_KIT',
  DC_WEB_DESCRIPTIF = 'DC_WEB_DESCRIPTIF',
  DC_DATE_FIN_FABRICATION = 'DC_DATE_FIN_FABRICATION',
  DC_DOSSIER_KIT = 'DC_DOSSIER_KIT',
  DC_DIRECTION_REGIONALE_OPERATION = 'DC_DIRECTION_REGIONALE_OPERATION',
  DC_DIRECTION_REGIONALE_PRODUITS = 'DC_DIRECTION_REGIONALE_PRODUITS',
  DC_CHANNEL_LABEL = '##ChannelNodeLabel',
  DC_LINK_NODE_FPR = '##LinkNodeFPR',
  LR_MODULE_CONSTRUCTION = 'LR_MODULE_CONSTRUCTION',
  DC_P_MODULE_CONSTRUCTION_POSE = 'DC_P_MODULE_CONSTRUCTION_POSE',
  DC_P_MODULE_CONSTRUCTION_NOM_DU_KIT = 'DC_P_MODULE_CONSTRUCTION_NOM_DU_KIT'
}
