import {Injectable} from '@angular/core';
import {NPElement} from '@next-page/np-sdk-data';
import {BehaviorSubject, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {SummaryNavBar} from '../Model/models';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {
  private _currentItem: SummaryNavBar;
  private _subItem = new Subject<SummaryNavBar>();
  private _homeEditionConfig: SummaryNavBar;
  private _subData = new BehaviorSubject<NPElement[]>([]);

  constructor() {
  }

  getCurrentItem() {
    return this._subItem.asObservable()
      .pipe(
        filter(menu => menu !== null)
      );
  }

  setItem(item) {
    this._currentItem = item;
    this._subItem.next(this._currentItem);
  }

  isCurrentItem(extID: string) {
    return this._currentItem && this._currentItem.extId === extID;
  }

  /**
   * Permet de sauvegarder la config de la page d'accueil Edition
   */
  saveHomeEditionConfig(homeEditionConfig: SummaryNavBar) {
    if (homeEditionConfig) {
      this._homeEditionConfig = homeEditionConfig;
    }
  }

  /**
   * Permet d'accéder à la page d'accueil Edition
   */
  gotoHomeEditionPage() {
    this.setItem(this._homeEditionConfig);
  }

  sendTreeData(data: NPElement[]) {
    this._subData.next(data);
  }

  getTreeData() {
    return this._subData.asObservable();
  }
}
