import {NPElement, NPElementTypeAction} from '../model/np-element';
import {WSParameters, WsParamsService} from './ws-params.service';
import {Injectable} from '@angular/core';
import {
    NPCaracCharTemplate,
    NPCaracCharTemplateValue,
    NPCaracLien,
    NPCaracLienRebuildValue,
    NPCaracLienValue,
    NPCaracListe,
    NPCaracListeValue,
    NpCaracStatus,
    NPCaracStd,
    NPCaracValeur
} from '../model/np-carac-valeur';
import {HttpClient} from '@angular/common/http';
import {first, flatMap, map} from 'rxjs/operators';
import {DicoCarac, NPDicoCarac} from '../model/np-dico-carac';
import {Guid} from 'guid-typescript';
import {DicocaracRepository} from './dicocarac-repository';
import {ElementHelper} from './element-helper';
import {NPAPIElementLinksResult} from '../model/NPAPIElementLinksResult';
import {NpApiResult} from '../model/np-api-result';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/internal/operators/tap';

@Injectable({
    providedIn: 'root',
})
export class ElementWriterService {
    private readonly _urlWriteElement: string = '/api/sdk/element/UpdateElements';
    private readonly _urlCreateMedia: string = '/api/sdk/DAM/UpdateMedias';
    private _oldModifList: Map<string, Map<string, NPCaracValeur>>;
    private _modifListElts = new Map<string, NPElement>();
    private _oldModifListElts: Map<string, NPElement>;

    modifListElts: number[] = []; /// A supprimer !!!!!!!!!!!!!!!!!!!!!

    constructor(private _wsParamsService: WsParamsService, private _http: HttpClient, private _dicoRep: DicocaracRepository) {
        this._wsParamsService.getParams().subscribe((params) => {
            this._langID = params.LangID;
            this._contextID = params.ContextID;
        });
        this._modifList = new Map<string, Map<string, NPCaracValeur>>();
    }

    private _langID = 1;
    private _contextID = 1;
    private _modifList: Map<string, Map<string, NPCaracValeur>>;

    /**
     * Copy des valeurs depuis un NpCaracValeur vers un autre en reprécisant l'élément et le dico de Carac
     */
    public setCopyValue(element: NPElement, dicoCaracExtID: string, ValueSrc: NPCaracValeur): void {
        if (ValueSrc['Value'] != null) {
            const v = ValueSrc as NPCaracStd;
            this.setValueText(element, dicoCaracExtID, v.Value);
            return;
        }
        if (ValueSrc['LinkedElements'] != null) {
            const v = ValueSrc as NPCaracLien;
            this.setValueLink(element, dicoCaracExtID, v.RebuildLinkedElements.map(val => val.Element) as NPElement[]);
            return;
        }
        if (ValueSrc['StatusExtID'] != null) {
            const v = ValueSrc as NpCaracStatus;
            this.setValueStatus(element, v.StatusExtID);
            return;
        }
        throw Error('setCopyValue: valueType not handled');
    }

    /**
     * Ajoute une nouvelle NPCaracStd
     */
    public setValueText(element: NPElement, dicoCaracExtID: string, value: string): void {
        // Gérer la valeur nulle
        if (value === null) {
            throw new Error('setValueText doesn\'t handle null value');
        }
        let npCaracStd: NPCaracStd;
        const oldValue = element.getValue(dicoCaracExtID);
        if (oldValue != null) {
            npCaracStd = Object.assign({}, oldValue) as NPCaracStd;
        } else {
            npCaracStd = new NPCaracStd();
            npCaracStd.DicoCaracExtID = dicoCaracExtID;
        }
        npCaracStd.Value = value;
        this._addValue(element, dicoCaracExtID, npCaracStd);
    }

    /**
     * Ajoute une nouvelle NPCaracStd
     */
    public setValueStatus(element: NPElement, newStatusExtID: string): void {
        // Gérer la valeur nulle
        if (newStatusExtID === null) {
            throw new Error('setValueStatus doesn\'t handle null value');
        }
        const dicoCaracExtID = DicoCarac.DTO_SYSTEM_STATUS;
        let npCaracStatus: NpCaracStatus;
        const oldValue = element.getValue(dicoCaracExtID);
        if (oldValue != null) {
            npCaracStatus = Object.assign({}, oldValue) as NpCaracStatus;
        } else {
            npCaracStatus = new NpCaracStatus();
            npCaracStatus.DicoCaracExtID = dicoCaracExtID;
        }
        npCaracStatus.StatusExtID = newStatusExtID;
        npCaracStatus.StatusID = null;
        this._addValue(element, dicoCaracExtID, npCaracStatus);
    }

    /**
     * Ajoute une nouvelle NPCaracStd
     */
    public setValueCharTemplate(element: NPElement, newCTExtID: string[]): void {
        // Gérer la valeur nulle
        if (newCTExtID === null) {
            throw new Error('setValueStatus doesn\'t handle null value');
        }
        const dicoCaracExtID = DicoCarac.DTO_SYSTEM_CHAR_TEMPLATE;
        let npCaracCT: NPCaracCharTemplate;
        const oldValue = element.getValue(dicoCaracExtID);
        if (oldValue != null) {
            npCaracCT = Object.assign({}, oldValue) as NPCaracCharTemplate;
            npCaracCT.CharTemplates = [];
        } else {
            npCaracCT = new NPCaracCharTemplate();
            npCaracCT.DicoCaracExtID = dicoCaracExtID;
        }
        newCTExtID.forEach((ct) => {
            const ctVal = new NPCaracCharTemplateValue();
            ctVal.CharTemplateExtID = ct;
            npCaracCT.CharTemplates.push(ctVal);
        });

        this._addValue(element, dicoCaracExtID, npCaracCT);
    }

    /**
     * Ajoute une nouvelle Caractéristique liste, ne fait pas de vérification sur le contenu
     */
    public setValueListeByIDValues(element: NPElement, dicoCaracExtID: string, newValues: number[]): void {
        // Gérer la valeur nulle => possible, si on ne veut rien dedans
        //if (newStatusExtID=== null) {
        //  throw new Error("setValueStatus doesn't handle null value");
        //}

        let npCarac: NPCaracListe;
        const oldValue = element.getValue(dicoCaracExtID);
        if (oldValue != null) {
            npCarac = Object.assign({}, oldValue) as NPCaracListe;
        } else {
            npCarac = new NPCaracListe();
            npCarac.DicoCaracExtID = dicoCaracExtID;
        }
        // reconstruction d'un tableau de valeurs
        const NewVal = [];
        for (let val of newValues) {
            let listeValue = new NPCaracListeValue();
            listeValue.ValueID = val;
            NewVal.push(listeValue);
        }
        npCarac.Values = NewVal;
        npCarac.StatusExtID = null;
        this._addValue(element, dicoCaracExtID, npCarac);
    }

    /**
     * Ajoute la valeur à la liste des modification à enregistrer
     */
    private _addValue(element: NPElement, dicoCaracExtID: string, value: NPCaracValeur) {
        value.ElementID = element.ID;
        value.ElementExtID = element.ExtID;
        value.Element = element;
        value.LangID = this._langID;
        value.ContextID = this._contextID;
        element.setValue(dicoCaracExtID, value);
        this.addToModifList(value);
    }

    // TODO: pass on ElementExtID
    private addToModifList(value: NPCaracValeur) {
        if (value.ElementID == null && value.ElementExtID === '') {
        }

        if (!this._modifList.has(this._getKeyFor_modifList(value))) {
            this._modifList.set(this._getKeyFor_modifList(value), new Map<string, NPCaracValeur>());
        }
        this._modifList.get(this._getKeyFor_modifList(value)).set(value.DicoCaracExtID, value);
        const notifier = this.getElementModificationsNotifier({
            elementExtID: value.ElementExtID,
            dicoCaracExtID: value.DicoCaracExtID
        });
        if (notifier != null) {
            notifier.next(value);
        }
        this.modifListElts.push(1);
    }

    private _getKeyFor_modifList(value: NPCaracValeur) {
        if (value.ElementID != null && value.ElementID > 0) {
            return 'ID:' + value.ElementID.toString();
        } else {
            return 'ExtID:' + value.ElementExtID;
        }
    }

    private _addElementToModifList(elt: NPElement) {
        // traiter ici les cas de demande de création / suppression ?
        // si création puis suppression => il faut retirer l'objet de la liste des éléments puis le remettre
        if (this._modifListElts.has(elt.ExtID)) {
            this._modifListElts.delete(elt.ExtID);
        } else {
            this._modifListElts.set(elt.ExtID, elt);
        }
    }

    /**
     * Ajoute une nouvelle NPCaracLien avec ses éléments liés
     */
    public setValueLink(element: NPElement, dicoCaracExtID: string, value: NPElement[]): void {
        // Gérer la valeur nulle
        if (value === null) {
            throw new Error('setValueLink doesn\'t handle null value');
        }


        let npCaracLien: NPCaracLien;
        if (element.hasValue(dicoCaracExtID)) {
            npCaracLien = <NPCaracLien>element.getValue(dicoCaracExtID);

        } else {
            // Création de la Carac de type lien
            npCaracLien = new NPCaracLien();
            npCaracLien.DicoCaracExtID = dicoCaracExtID;
        }
        npCaracLien.LinkedElements = [];
        npCaracLien.RebuildLinkedElements = [];
        value.forEach((el, index) => {
            // Ajout de l'élément lié
            const npCaracLienValue = new NPCaracLienValue();
            npCaracLienValue.ElementID = el.ID;
            npCaracLienValue.ElementExtID = el.ExtID;
            npCaracLienValue.Order = index + 1; // Order commence à 1 dans NextPage
            npCaracLien.LinkedElements.push(npCaracLienValue);
            // Ajout de l'élément lié et reconstruit
            const npCaracLienRebuildValue = new NPCaracLienRebuildValue();
            npCaracLienRebuildValue.Element = el;
            npCaracLienRebuildValue.Order = index + 1; // Order commence à 1 dans NextPage
            npCaracLien.RebuildLinkedElements.push(npCaracLienRebuildValue);
        });
        this._addValue(element, dicoCaracExtID, npCaracLien);
    }

    /***
     * Modifie l'ordre des éléments dans la liste en les repositionnant dans l'ordre voulu
     */
    public modifyOrderValueList(CaracLien: NPCaracLien, elt: NPElement, newOrder: number) {
        // On refait le sort de manière à travailler sur la liste dans le même ordre que dans les écrans
        CaracLien.LinkedElements = CaracLien.LinkedElements.sort((v1, v2) => v1.Order - v2.Order);
        CaracLien.RebuildLinkedElements = CaracLien.RebuildLinkedElements.sort((v1, v2) => v1.Order - v2.Order);

        let index = 1;
        // Gérer le cas où le 2e element passe en premier
        if (newOrder === 1) {
            index++;
        }
        // Si l'ordre demandé est plus grand (ou égal) que le nombre d'elements dans la list alors on le force au maximum de la liste
        // L'ordre commence à 1 donc pas besoin de -1 sur le length
        if (newOrder >= CaracLien.LinkedElements.length) {
            newOrder = CaracLien.LinkedElements.length;
        }

        CaracLien.LinkedElements.forEach((cl) => {
            if (!ElementHelper.IDExtIDAreSame(cl.ElementID, cl.ElementExtID, elt.ID, elt.ExtID)) {
                cl.Order = index;
                index++;
                // si l'index correspond à la nouvelle position, alors on passe à la suite
                if (index === newOrder) {
                    index++;
                }
            } else {
                cl.Order = newOrder;
            }
            // dans Rebuild=> il faut modifier l'ordre
            let rebuildItem = CaracLien.RebuildLinkedElements.filter((rle) =>
                ElementHelper.IDExtIDAreSame(rle.Element.ID, rle.Element.ExtID, cl.ElementID, cl.ElementExtID)
            );
            if (rebuildItem.length > 0) {
                rebuildItem[0].Order = cl.Order;
            }
        });
        // on retrie les éléments dans les listes de valeurs
        CaracLien.LinkedElements = CaracLien.LinkedElements.sort((a, b) => a.Order - b.Order);
        CaracLien.RebuildLinkedElements = CaracLien.RebuildLinkedElements.sort((a, b) => a.Order - b.Order);

        this._addValue(CaracLien.Element, CaracLien.DicoCaracExtID, CaracLien);
    }

    /**
     * Ajout d'un élément lié à une NPCaracLien. C'est possible d'ajouter le même élément plus d'une fois.
     * On lui attribue cependant un ordre différent à chaque ajout.
     * keepOldValues : Efface les valeurs existantes de la liste pour les listes NP de type NpCaracLien (spécificité bioccoop sinon liste = type liste dans NP)
     */
    public concatValueLink(element: NPElement, dicoCaracExtID: string, value: NPElement, keepOldValues = true): void {
        // Gérer la valeur nulle
        if (value === null) {
            throw new Error('concatValueLink doesn\'t handle null value');
        }
        // Si la NPCaracLien n'existe pas alors on la crée et on lui rajoute le premier élément lié
        if (element.hasValue(dicoCaracExtID) === false || !keepOldValues) {
            this.setValueLink(element, dicoCaracExtID, [value]);
            return;
        }

        const npCaracLien: NPCaracLien = Object.assign({}, <NPCaracLien>element.getValue(dicoCaracExtID));
        let newItemOrder = 1;
        if (npCaracLien.LinkedElements.length > 0) {
            newItemOrder = Math.max(...npCaracLien.LinkedElements.map(v => v.Order)) + 1;
        }

        const npCaracLienValue = new NPCaracLienValue();
        npCaracLienValue.Order = newItemOrder;
        npCaracLienValue.ElementID = value.ID;
        npCaracLienValue.ElementExtID = value.ExtID;
        if (!keepOldValues) {
            npCaracLien.LinkedElements = [];
        }
        npCaracLien.LinkedElements.push(npCaracLienValue);
        const npCaracLienRebuildValue = new NPCaracLienRebuildValue();
        npCaracLienRebuildValue.Order = newItemOrder;
        npCaracLienRebuildValue.Element = value;
        if (!keepOldValues) {
            npCaracLien.RebuildLinkedElements = [];
        }
        npCaracLien.RebuildLinkedElements.push(npCaracLienRebuildValue);
        this._addValue(element, dicoCaracExtID, npCaracLien);
    }

    public changeValueLink(element: NPElement, dicoCaracExtID: string, value: NPElement, order: number = 1): void {
        // Gérer la valeur nulle
        if (value === null) {
            throw new Error('concatValueLink doesn\'t handle null value');
        }
        // Si la NPCaracLien n'existe pas alors on la crée et on lui rajoute le premier élément lié
        if (element.hasValue(dicoCaracExtID) === false) {
            this.setValueLink(element, dicoCaracExtID, [value]);
            return;
        }

        let npCaracLien: NPCaracLien = Object.assign({}, <NPCaracLien>element.getValue(dicoCaracExtID));

        const npCaracLienValueOld: NPCaracLienValue = npCaracLien.LinkedElements.filter(v => v.Order === order)[0];
        if (npCaracLienValueOld == null) {
            this.concatValueLink(element, dicoCaracExtID, value);
            return;
        }

        npCaracLien.LinkedElements = npCaracLien.LinkedElements.filter(v => v.Order !== order);
        const npCaracLienValue = new NPCaracLienValue();
        npCaracLienValue.Order = order;
        npCaracLienValue.ElementID = value.ID;
        npCaracLienValue.ElementExtID = value.ExtID;
        npCaracLien.LinkedElements.push(npCaracLienValue);

        npCaracLien.RebuildLinkedElements = npCaracLien.RebuildLinkedElements.filter(v => v.Order !== order);
        const npCaracLienRebuildValue = new NPCaracLienRebuildValue();
        npCaracLienRebuildValue.Order = order;
        npCaracLienRebuildValue.Element = value;
        npCaracLien.RebuildLinkedElements.push(npCaracLienRebuildValue);
        this._addValue(element, dicoCaracExtID, npCaracLien);
    }

    /**
     * Supprime un élément dans la liste Values de la NPCaracLien
     */
    public deleteValueLink(element: NPElement, dicoCaracExtID: string, value: NPElement): void {
        if (element.hasValue(dicoCaracExtID)) {
            let npCaracLien: NPCaracLien = Object.assign({}, <NPCaracLien>element.getValue(dicoCaracExtID));
            npCaracLien.LinkedElements = npCaracLien.LinkedElements.filter(linkedElement => !ElementHelper.IDExtIDAreSame(linkedElement.ElementID, linkedElement.ElementExtID, value.ID, value.ExtID)); // || ((value.ID==0 || linkedElement.ElementID ) &&  linkedElement.ElementExtID !== value.ExtID)
            npCaracLien.RebuildLinkedElements = npCaracLien.RebuildLinkedElements.filter(rebuiltElement => !ElementHelper.elementsAreSame(rebuiltElement.Element, value)); //  || ((rebuiltElement.Element.ID == 0 ||  value.ID==0) && rebuiltElement.Element.ExtID !== value.ExtID)

            this._addValue(element, dicoCaracExtID, npCaracLien);
        }
    }

    /**
     * Création d'une donnée structurée
     * ATTENTION => RETOURNE UN OBSERVABLE, c'est pénible, on est obligé de s'abonner pour que l'action soit lancée.
     */
    public createStructuredDataRQ(CharTemplateExtID: string, DataDicoLeftExtID: string, ElementLeft: NPElement, DataDicoRightExtID: string, ElementRight: NPElement) {
        // recherche des caractéristiques réflexives et association
        // le return est fait pour pouvoir faire passer du test => on renvoit
        const result = this._dicoRep.getDicoCaracsAndReflexives([DataDicoLeftExtID, DataDicoRightExtID])
            .pipe(
                map((dicoCaracs: Map<string, NPDicoCarac>) => {

                        // récupération des caractéristiques pour positionner la famille et traiter les extIDS
                        const leftDataCarac = dicoCaracs.get(DataDicoLeftExtID);
                        const rightDataCarac = dicoCaracs.get(DataDicoRightExtID);
                        let leftDSCarac: NPDicoCarac = null;
                        let rightDSCarac: NPDicoCarac = null;
                        // recjercje de la caractéristique reflexive portée par les DS
                        dicoCaracs.forEach(d => {
                            if (d.ReflexiveCharExtID == DataDicoLeftExtID) {
                                leftDSCarac = d;
                            }
                            if (d.ReflexiveCharExtID == DataDicoRightExtID) {
                                rightDSCarac = d;
                            }
                        });
                        // création de la données, affectation des liens...
                        const ds = new NPElement(CharTemplateExtID + '-' + Guid.create().toString());
                        ds.ElementType = 2; // toujours un produit
                        this.setValueCharTemplate(ds, [CharTemplateExtID]);
                        this.setValueText(ds, DicoCarac.PRODUCT_LABEL, ElementLeft.getValueTextValue(DicoCarac.PRODUCT_LABEL) + ' // ' + ElementRight.getValueTextValue(DicoCarac.PRODUCT_LABEL));

                        ds.ParentExtID = leftDataCarac.ElementLinkExtID;
                        this.concatValueLink(ElementLeft, leftDataCarac.ExtID, ds);
                        this.concatValueLink(ds, leftDSCarac.ExtID, ElementLeft);
                        this.concatValueLink(ElementRight, rightDataCarac.ExtID, ds);
                        this.concatValueLink(ds, rightDSCarac.ExtID, ElementRight);
                        this._addElementToModifList(ds);
                        return ds;
                    }
                )
            );

        return result;

    }


    /**
     * Création d'une donnée structurée
     * ATTENTION => RETOURNE UN OBSERVABLE, c'est pénible, on est obligé de s'abonner pour que l'action soit lancée.
     */
    public prepareStructuredDataRQ(CharTemplateExtID: string, DataDicoLeftExtID: string, ElementLeft: NPElement) {
        // recherche des caractéristiques réflexives et association
        // le return est fait pour pouvoir faire passer du test => on renvoit
        const result = this._dicoRep.getDicoCaracsAndReflexives([DataDicoLeftExtID])
            .pipe(
                map((dicoCaracs: Map<string, NPDicoCarac>) => {
                        // récupération des caractéristiques pour positionner la famille et traiter les extIDS
                        const leftDataCarac = dicoCaracs.get(DataDicoLeftExtID);
                        let leftDSCarac: NPDicoCarac = null;
                        // recjercje de la caractéristique reflexive portée par les DS
                        dicoCaracs.forEach(d => {
                            if (d.ReflexiveCharExtID == DataDicoLeftExtID) {
                                leftDSCarac = d;
                            }
                        });
                        // création de la données, affectation des liens...
                        const ds = new NPElement(CharTemplateExtID + '-' + Guid.create().toString());
                        ds.ElementType = 2; // toujours un produit
                        this.setValueCharTemplate(ds, [CharTemplateExtID]);
                        this.setValueText(ds, DicoCarac.PRODUCT_LABEL, ElementLeft.getValueTextValue(DicoCarac.PRODUCT_LABEL) + ' // ');

                        ds.ParentExtID = leftDataCarac.ElementLinkExtID;
                        this.concatValueLink(ElementLeft, leftDataCarac.ExtID, ds);
                        this.concatValueLink(ds, leftDSCarac.ExtID, ElementLeft);
                        this._addElementToModifList(ds);
                        return ds;
                    }
                )
            );

        return result;

    }

    /**
     * Affecte l'élémnet de droit à une donnée structurée
     *  DataDicoReflexiveExtID : Caractéristique réflexive de la donnée structurée dans laquelle il faut écrire
     * DSElement : élément portant la donnée structurée
     * ElementLinked : élément auquel est liée la donnée structurée
     */
    public setRightElementStructuredDataRQ(DicoCaracLeftExtID: string, DSElement: NPElement, ElementLinked: NPElement) {
        // TODO :
        // Recherche de la carac de droite
        let leftDicoCaracDS: NPDicoCarac;
        let leftDicoCaracReflexive: NPDicoCarac;
        let rightDicoCaracDS: NPDicoCarac;
        let rightDicoCaracReflexive: NPDicoCarac;


        const result = this._dicoRep.getDicoCaracsAndReflexives([DicoCaracLeftExtID])
            .pipe(
                flatMap((leftDicoCaracs) => {
                    // Récupération des 2 caractéristiques de gauche
                    leftDicoCaracDS = leftDicoCaracs.get(DicoCaracLeftExtID);
                    leftDicoCaracReflexive = Array.from(leftDicoCaracs.values()).find(v => v.ReflexiveCharExtID === DicoCaracLeftExtID);
                    // demande de récupération de la caractéristique réflexive de droite
                    return this._dicoRep.getDicoCarac(leftDicoCaracDS.TargetCharExtID);
                }),
                flatMap((dc) => {
                    // récupération des 2 caractéristiques de droite
                    rightDicoCaracReflexive = dc;
                    return this._dicoRep.getDicoCarac(rightDicoCaracReflexive.ReflexiveCharExtID);
                }), tap((dc) => {
                    rightDicoCaracDS = dc;
                    // fin de la récupération des 4 caractéristiques
                }),
                tap(() => {
                    const rebuidLink = DSElement.getValueLien(leftDicoCaracReflexive.ExtID).RebuildLinkedElements;
                    if (rebuidLink && rebuidLink.length > 0) {
                        const leftElement = DSElement.getValueLien(leftDicoCaracReflexive.ExtID).RebuildLinkedElements[0].Element;
                        // on retire la donnée précédente (si la donnée structurée était déjà liée
                        if (DSElement.getValueLien(rightDicoCaracReflexive.ExtID)
                            && DSElement.getValueLien(rightDicoCaracReflexive.ExtID).RebuildLinkedElements.length) {
                            const oldRightElement = DSElement.getValueLien(rightDicoCaracReflexive.ExtID).RebuildLinkedElements[0].Element;
                            this.deleteValueLink(oldRightElement, rightDicoCaracDS.ExtID, DSElement);
                        }
                        // modification du libellé comme dns createStructuredData avec la concaténaation du libellé gauche et droite
                        this.setValueText(DSElement, DicoCarac.PRODUCT_LABEL, leftElement.getValueTextValue(DicoCarac.PRODUCT_LABEL) + ' // ' + ElementLinked.getValueTextValue(DicoCarac.PRODUCT_LABEL));
                        // écriture dans la caractéristique réflexive de la caractérsitique donnée e
                        this.setValueLink(DSElement, rightDicoCaracReflexive.ExtID, [ElementLinked]);
                        this.concatValueLink(ElementLinked, rightDicoCaracDS.ExtID, DSElement);


                        return DSElement;
                    }

                }));
        return result;
    }

    /**
     * Création d'une donnée structurée
     * ATTENTION => RETOURNE UN OBSERVABLE, c'est pénible, on est obligé de s'abonner pour que l'action soit lancée.
     */
    public createElement(params): NPElement {
        const elt = new NPElement(params);
        this._addElementToModifList(elt);
        return elt;
    }

    public deleteElement(element: NPElement) {
        if (this._modifListElts.has(element.ExtID)) {
            this._modifListElts.delete(element.ExtID);
        }
        if (this._modifList.has(element.ExtID)) {
            this._modifList.delete(element.ExtID);
        }
    }

    // Supprime un élément de nextPage
    public deleteElementFromNP(element: NPElement) {
        element.Action = 1;
        this._modifListElts.set(element.ExtID, element);
    }


    /**
     * Objet stockant tous les emiters de modification
     * C'est un objet et pas un Map parceque les objets sont mieux que les map
     */
    private modificationEmiters: any = {};

    /**
     * Permet l'envoi de notification en cas de changements de valeurs sur un propriété
     */
    public getElementModificationsNotifier(value: { elementExtID: string, dicoCaracExtID: string }, createIfNotExists: boolean = false) {
        const emiterName = value.elementExtID + '$DC$' + value.dicoCaracExtID;
        if (!this.modificationEmiters.hasOwnProperty(emiterName)) {
            if (!createIfNotExists) {
                return null;
            }
            this.modificationEmiters[emiterName] = new BehaviorSubject<NPCaracValeur>(null);
        }
        return <BehaviorSubject<NPCaracValeur>>this.modificationEmiters[emiterName];
    }

    /**
     * Suppression d'une donnée structurée
     */
    public deleteStructuredDataRQ(ds: NPElement, DataDicoLeftExtID: string, DataDicoRightExtID: string) {
        const result = this._dicoRep.getDicoCaracsAndReflexives([DataDicoLeftExtID, DataDicoRightExtID])
            .pipe(
                map((dicoCaracs: Map<string, NPDicoCarac>) => {
                        // récupération des caractéristiques pour positionner la famille et traiter les extIDS
                        const leftDataCarac = dicoCaracs.get(DataDicoLeftExtID);
                        const rightDataCarac = dicoCaracs.get(DataDicoRightExtID);
                        let leftDSCarac: NPDicoCarac = null;
                        let rightDSCarac: NPDicoCarac = null;
                        // recjercje de la caractéristique reflexive portée par les DS
                        dicoCaracs.forEach(d => {
                            if (d.ReflexiveCharExtID == DataDicoLeftExtID) {
                                leftDSCarac = d;
                            }
                            if (d.ReflexiveCharExtID == DataDicoRightExtID) {
                                rightDSCarac = d;
                            }
                        });
                        // récupération des éléments à gauche et à droite
                        let elementLeft: NPElement;
                        let elementRight: NPElement;
                        if (ds.getValueLien(leftDSCarac.ExtID) != null && ds.getValueLien(leftDSCarac.ExtID).RebuildLinkedElements.length > 0) {
                            elementLeft = ds.getValueLien(leftDSCarac.ExtID).RebuildLinkedElements[0].Element;
                        }
                        if (ds.getValueLien(rightDSCarac.ExtID) != null && ds.getValueLien(rightDSCarac.ExtID).RebuildLinkedElements.length > 0) {
                            elementRight = ds.getValueLien(rightDSCarac.ExtID).RebuildLinkedElements[0].Element;
                        }
                        // suppression de la données, désaffectation des liens...
                        if (elementLeft != null) {
                            this.deleteValueLink(elementLeft, leftDataCarac.ExtID, ds);
                            this.deleteValueLink(ds, leftDSCarac.ExtID, elementLeft);
                        }
                        if (elementRight != null) {
                            this.deleteValueLink(elementRight, rightDataCarac.ExtID, ds);
                            this.deleteValueLink(ds, rightDSCarac.ExtID, elementRight);
                        }
                        ds.Action = NPElementTypeAction.Delete;
                        this._addElementToModifList(ds);
                        return ds;
                    }
                )
            );

        return result;
    }

    public publishToNP(): Observable<Object> {
        const params = this._wsParamsService.getSyncParams();
        let Values = [];
        this._modifList.forEach((elementModifs) => {
            elementModifs.forEach((modif) => {
                Values.push(ElementWriterService._treatValueForCyclical(modif));
            });
        });
        const Elts = [];
        this._modifListElts.forEach((elementModifs) => {
            Elts.push(ElementWriterService._treatElementForCyclical(elementModifs));
        });
        if (Values.length === 0 && Elts.length === 0) {
            return;
        }
        this._oldModifListElts = this._modifListElts;
        this._oldModifList = this._modifList;
        this._modifList = new Map<string, Map<string, NPCaracValeur>>();
        this._modifListElts = new Map<string, NPElement>();
        const body = {
            ContextID: params.ContextID,
            LangID: params.LangID,
            Values: Values,
            Elements: Elts
        };
        return this._http.post(this._urlWriteElement, body).pipe(
            first()
        );
    }

    public hasModifications(): boolean {
        return this._modifList.size > 0 || this._modifListElts.size > 0;
    }

    public cancelAllModifications() {
        this._modifList = new Map<string, Map<string, NPCaracValeur>>();
        this._modifListElts = new Map<string, NPElement>();
    }

    public cancelAllModificationsByElement(elementExtId: string, elementID?: number) {
        this._modifListElts.delete(elementExtId);
        const tmpExtId = 'ExtID:' + elementExtId;
        this._modifList.delete(tmpExtId);
        if (elementID) {
            const tmpId = 'ID:' + elementID.toString();
            this._modifList.delete(tmpId);
        }
    }

    public createMedia(file: File, folderID: number) {
        return this._wsParamsService.getParams().pipe(
            flatMap((params: WSParameters) => {
                const formData: FormData = new FormData();
                formData.append('file', file, file.name);
                formData.append('ContextId', params.ContextID.toString());
                formData.append('LangID', params.LangID.toString());
                formData.append('NodeFolderID', folderID.toString());
                formData.append('Action', '0');
                return this._http.post(this._urlCreateMedia, formData);
            }),
            map((result: NpApiResult) => {
                let APIResult = new NPAPIElementLinksResult(result.Results);
                if (APIResult != null) {
                    return APIResult.Elements.get(APIResult.Results[0].toString());
                }
                return null;
            })
        );
    }

    private static _treatValueForCyclical(caracValeur: NPCaracValeur): NPCaracValeur {
        let newCarac: NPCaracValeur = Object.assign({}, caracValeur) as NPCaracValeur;
        if ((newCarac as NPCaracLien) != null && (newCarac as NPCaracLien).RebuildLinkedElements != null) {
            delete (newCarac as NPCaracLien).RebuildLinkedElements;
        }
        delete newCarac.Element;
        return newCarac;
    }

    private static _treatElementForCyclical(element: NPElement): NPElement {
        let newElement: NPElement = Object.assign({}, element) as NPElement;
        if (newElement.Children != null) {
            delete newElement.Children;
        }
        const newValues: Map<string, NPCaracValeur> = new Map<string, NPCaracValeur>();
        newElement.Values.forEach((caracValeur, key) => {
            newValues.set(key, ElementWriterService._treatValueForCyclical(caracValeur));
        });
        newElement.Values = newValues;
        return newElement;
    }
}
