import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {CaracConfigRepositoryService} from '../../sdk/data/services/carac-config-repository.service';
import {NavBarService} from '../../sdk/data/services/nav-bar.service';
import {BreadcrumbService} from '../../sdk/data/services/breadcrumb.service';
import {NavBarItemComponent} from '../nav-bar-item/nav-bar-item.component';
import {AfterViewInit} from '@angular/core/src/metadata/lifecycle_hooks';
import {CogedimConstantes} from '../../sdk/data/constantes/cogedim-constantes';
import {filter, flatMap, map, tap} from 'rxjs/operators';
import {SummaryNavBar} from '../../sdk/data/Model/models';
import {CogedimDicocarcs} from '../../sdk/data/constantes/cogedim-dicocarcs';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, AfterViewInit {

  @Input() navbarID: string;
  @Input() isEditMode: boolean;

  @ViewChildren(NavBarItemComponent) viewChildren !: QueryList<NavBarItemComponent>;

  public dataElements: SummaryNavBar[] = [];

  constructor(private CaracConfigRepository: CaracConfigRepositoryService,
              private _navBarService: NavBarService,
              private _breadcrumbService: BreadcrumbService) {
  }

  ngOnInit() {
    // Si la liste vient idu JSON (caracs)
    if (!this.isEditMode) {
      this.CaracConfigRepository.getConfigsByKeyName(this.navbarID)
        .pipe(
          filter(data => data !== undefined && data !== null),
          tap(data => {
            // On récupère la config permettant d'afficher la page d'Accueil Edition
          /*  const tmpHomeEditionConfig = data.find(c => c.BlocInfo === CogedimConstantes.HOME_EDITION);
            this._navBarService.saveHomeEditionConfig(tmpHomeEditionConfig);*/
          })
        )
        .subscribe(data => {
          this.dataElements = data.map(value => new SummaryNavBar(value));
          this._navBarService.setItem(this.dataElements[0]);
        });
    } else {
      this._navBarService.getTreeData()
          .pipe(
              flatMap(data => {
                this.dataElements = data.map(element => new SummaryNavBar(element));
                return this.CaracConfigRepository.getConfigsByKeyName(this.navbarID)
                    .pipe(
                        map(configs => {
                          // On récupère la config permettant d'afficher la page d'Accueil Edition
                          const tmpHomeEditionConfig = configs.find(c => c.BlocInfo === CogedimConstantes.HOME_EDITION);
                          const tmpHomeEditionItem = new SummaryNavBar(tmpHomeEditionConfig);
                          tmpHomeEditionItem.isHomeEditionBlock = true;
                          tmpHomeEditionItem.label = tmpHomeEditionConfig.DicoCaracExtIDLabel;
                          this._navBarService.saveHomeEditionConfig(tmpHomeEditionItem);
                          return configs.find(c => c.DicoCaracExtID === CogedimDicocarcs.DC_OTHERS_PRODUCTS);
                        })
                    );
              })
          )
        .subscribe( otherProductConfig => {
          const otherProductItem = new SummaryNavBar(otherProductConfig);
          otherProductItem.label = 'Autres produits';
          otherProductItem.isOtherProductBlock = true;
          this.dataElements.push(otherProductItem);
          this._navBarService.gotoHomeEditionPage();
        });
    }
  }

  isCurrentSelectedElement(item: SummaryNavBar) {
    return this._navBarService.isCurrentItem(item.extId);
  }

  ngAfterViewInit() {
   /* if (this._breadcrumbService.getFromBreadcrumb()) {
      if (this.viewChildren) {
        const child = this.viewChildren.find(v => v.item.element && v.item.caracConfig.DicoCaracExtID === CogedimConstantes.NAV_BAR_ITEM_PRESTATIONS_EDITION);
        if (child) {
          child.onClick();
        } else {
          this._navBarService.setItem(this.dataElements[0]);
        }
      }
    }*/
  }

}
