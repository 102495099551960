import {DataMocker} from './data-mocker';
import {NpApiResult} from '../../../model/np-api-result';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/materialize';
import 'rxjs/add/operator/dematerialize';

/**
 * Cette classe gère une liste de DataMocker
 * Chacun sera testé l'un après l'autre pour vérifier si l'url match
 * Si aucun ne fonctionne => on renvoi le résultat de l'appel
 */
export class DataModelMocker extends DataMocker {



  public AllMockers: DataMocker[] = [];

  /**
   * Méthode qui va mocker la réponse, uniquement si il y a matching d'url
   */
  mokeResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // recherche d'un mock qui correspond au besoin
    for (let i = 0; i < this.AllMockers.length; i++) {
      const curMock = this.AllMockers[i];
      if (curMock.matchUrl(request))
        return curMock.mokeResponse(request, next);
    }
  }

  protected AddMocker(mock: DataMocker) {
    this.AllMockers.push(mock);
  }

  getData(): NpApiResult {
    return undefined;
  }

  getUrlPattern(): string {
    return '';
  }

}

