import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {filter} from "rxjs/operators";
import {Observable} from 'rxjs';

@Injectable()
export class ContentStateControllerService {
  private static _mapContentStates: Map<string, BehaviorSubject<any>> = new Map<string, BehaviorSubject<any>>();
  /**
   * Il s'agit d'un hack : si observable n'est pas importé (alors qu'il s'agit d'un type de retour) => il y a une erreur
   */
  private static hackforneededObservableImport: Observable<string> = undefined;

  public pushControllerState(controllerKey: string, state) {
    if (ContentStateControllerService._mapContentStates.has(controllerKey)) {
      ContentStateControllerService._mapContentStates.get(controllerKey).next(state);
    }
    else {
      ContentStateControllerService._mapContentStates.set(controllerKey, new BehaviorSubject<any>(state));
    }
  }

  public getControllerState(controllerKey: string) {
    if (!ContentStateControllerService._mapContentStates.has(controllerKey)) {
      ContentStateControllerService._mapContentStates.set(controllerKey, new BehaviorSubject<any>(null));
    }
    return ContentStateControllerService._mapContentStates.get(controllerKey)
      .pipe(
        filter((value) => {
          return value != null;
        })
      );
  }
}
