import {Component, forwardRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CogedimElementWriterService} from '../../sdk/data/services/cogedim-element-writer.service';
import {CaracConfigRepositoryService} from '../../sdk/data/services/carac-config-repository.service';
import {UploadNotice, UploadNoticeConfigs} from '../../sdk/data/Model/models';
import {UploadNoticeService} from '../../sdk/data/services/upload-notice.service';

import {flatMap, map, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {CogedimConstantes} from '../../sdk/data/constantes/cogedim-constantes';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {CogedimDicocarcs} from '../../sdk/data/constantes/cogedim-dicocarcs';
import {CaracConfig, MediaCreationService} from "@nextpage/np-sdk-data";



@Component({
    selector: 'app-dialog-download-notice',
    templateUrl: './dialog-download-notice.component.html',
    styleUrls: ['./dialog-download-notice.component.scss']
})
export class DialogDownloadNoticeComponent implements OnInit, OnDestroy {

    public noticeElements: UploadNotice[] = [];
    public existingNotices: UploadNotice[] = [];
    private _uploadNoticeKey = 'uploadNotices';
    private configs: CaracConfig[] = [];
    private _saveData$: Subscription;
    public uploadNoticeConfig: UploadNoticeConfigs;

    constructor(public dialogRef: MatDialogRef<DialogDownloadNoticeComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                @Inject(forwardRef(() => CogedimElementWriterService)) private _elementWriter: CogedimElementWriterService,
                @Inject(forwardRef(() => CogedimElementWriterService)) private _elementWriterService: CogedimElementWriterService,
                private _uploadNoticeService: UploadNoticeService,
                private _configRepository: CaracConfigRepositoryService,
                private _mediaService: MediaCreationService,
                @Inject(forwardRef(() => ModalService)) private _modalService: ModalService,
    ) {
    }


    ngOnInit() {
        this._initExistingNotices();
        this._initConfigs();
        this._initCurrentOperation();
    }

    private _initCurrentOperation() {
        this._uploadNoticeService.initCurrentOperation()
            .subscribe(() => {
            });
    }

    private _initExistingNotices() {
        this._uploadNoticeService.getExistingNotices()
            .subscribe(existingNotices => {
                if (existingNotices) {
                    this.existingNotices = this._uploadNoticeService.rebuildNoticesFromElements(existingNotices);
                }
            });
    }

    addNotice() {
        this._elementWriter.createElement(this.configs, false)
            .pipe(
                map(newElement => {
                    if (newElement) {
                        this._elementWriter.setValueLink(newElement,
                            CogedimDicocarcs.DC_WEB_OPERATION_DE_NOTICE,
                            [this._uploadNoticeService.getCurrentOperation()]);
                    }
                    return newElement;
                })
            )
            .subscribe(element => {
                if (element) {
                    this._uploadNoticeService.addNotice(this.noticeElements, element);
                }
            });
    }

    private _initConfigs() {
        this._configRepository.getConfig(this._uploadNoticeKey)
            .pipe(
                tap(dataConfigs => {
                    if (dataConfigs) {
                        this.configs = dataConfigs;
                        this.uploadNoticeConfig = new UploadNoticeConfigs(this.configs);
                    }
                })
            )
            .subscribe(() => {
            });
    }

    onDeleteNotice(uploadNotice: UploadNotice) {
        if (uploadNotice && uploadNotice.element) {
            const tmpElementExtIdToDelete = uploadNotice.element.ExtID;
            this.noticeElements = this.noticeElements.filter(value => value.element.ExtID !== tmpElementExtIdToDelete);
            this._mediaService.cancelAllModifications(tmpElementExtIdToDelete);
            const tmpElementIdToDelete = uploadNotice.element.ID ? uploadNotice.element.ID : null;
            this._elementWriter.cancelAllModificationsByElement(tmpElementExtIdToDelete, tmpElementIdToDelete);
            if (this.noticeElements.length <= 0) {
                this._clean();
            }
        }
    }

    saveData() {
        if (this._elementWriter.hasModifications()) {
            this._modalService.openWaitingOverlayDialog();
            this.noticeElements.map(currentNotice => {
                this._elementWriter.setNoticeLabel(currentNotice.element);
            });
            this._saveData$ = this._elementWriterService.publishToNP(false)
                .pipe(
                    flatMap(() => {
                        return this._mediaService.publishMedias();
                    })
                ).subscribe(response => {
                    // console.log(response);
                }, () => {
                    this._modalService.closeWaitingOverlayDialog();
                }, () => {
                    this._clean();
                    this._initExistingNotices();
                    this._modalService.closeWaitingOverlayDialog();
                });
        } else {
            this._modalService.openSnackBar('Aucune modification à enregistrer !', CogedimConstantes.SNACK_CLASS);
        }
    }

    private _clean() {
        this.noticeElements = [];
        this._mediaService.flush();
        this._elementWriterService.cancelAllModifications();
    }

    public closeDialog() {
        if (this._elementWriter.hasModifications()) {
            this._modalService.openWarningAskDialog()
                .pipe(
                    tap(response => {
                        if (response === '1') { // L'utilisateur clique sur 'Enregistrer...', on déclenche donc la procédure de sauvegarde
                            this.saveData();
                        } else if (response === '-1') {
                            // L'utilisateur clique sur 'Annuler les modifications et quitter la page', on annule toutes modifications et on quite la page
                            this._clean();
                        }
                    })
                ).subscribe(response => {
                if (response !== '0') {
                    this.dialogRef.close();
                }
            });
        } else {
            this.dialogRef.close();
        }
    }

    ngOnDestroy(): void {
        this._clean();
        if (this._saveData$) {
            this._saveData$.unsubscribe();
        }
    }
}
