import {Component, Input, OnInit} from '@angular/core';
import {CaracConfig, NPElement} from '@nextpage/np-sdk-data';
import {CaracIdStateValue} from '../np-value/Model';
import {RepeaterItem, RepeaterService} from '../../services';

@Component({
    selector: 'lib-np-repeater-item',
    templateUrl: './np-repeater-item.component.html',
    styleUrls: ['./np-repeater-item.component.css']
})
export class NpRepeaterItemComponent implements OnInit {

    @Input() treeviewData: RepeaterItem[] = [];
    @Input() caracConfig = [];

    constructor(private _repeaterService: RepeaterService) {
    }

    ngOnInit() {
        // console.log('treeviewData item', this.treeviewData);
    }

    getCISV(caracConfig: CaracConfig, element: NPElement): CaracIdStateValue {
        return this._repeaterService.getCISV(caracConfig, element);
    }

    filterByProductType(caracConfig: CaracConfig[], productTypeExtID) {
        return caracConfig.filter(item => item.ShowCondition === 'ShowIfTP:' + productTypeExtID);
    }
}
