import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';

import {MatListOption} from '@angular/material';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs/Subscription';
import {debounceTime} from 'rxjs/operators';
import {CaracInfo} from '../np-value/Model';
import {
    CaracConfig,
    ElementRepository,
    ElementWriterService,
    ManagementRulesCheckerService,
    NPCaracLien,
    NPCaracStd,
    NPElement,
    UiTranslationService,
    ValueOneElementHelper
} from '@nextpage/np-sdk-data';
import {Sheet, ThemingService} from '../../services';
import jss from 'jss';

interface SummaryElement {
    elementName: string;
    elementID: number;
    elementOrder: number;
}

@Component({
    selector: 'lib-link-carac-write',
    templateUrl: './link-carac-write.component.html',
    styleUrls: ['./link-carac-write.component.css']
})
export class LinkCaracWriteComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracStd;
    @Input() caracConfig: CaracConfig;

    listPossibles: SummaryElement[] = [];
    listPossiblesFiltered: SummaryElement[] = [];
    listLinked: SummaryElement[] = [];
    voe: ValueOneElementHelper = new ValueOneElementHelper();
    public searchTextControl = new FormControl();
    private _formCtrlSub: Subscription;
    public isValid = true;
    public classes: Object;
    private _translations = new Map<string, string>();

    constructor(private _elementRepository: ElementRepository,
                private _elementWriter: ElementWriterService,
                private _translateSrv: UiTranslationService,
                private _ManagementRulesChecker: ManagementRulesCheckerService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService) {
    }

    ngOnInit() {
        // [ngClass]="classes && classes['title'] ? classes['title']: ''"
        const override_css = this._theming.getComponentConfigStyle('LinkCaracWriteComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
            // console.log(this.classes);
        }

        this._initializeListeLies();
        this._initializeListePossibles();

        this._formCtrlSub = this.searchTextControl.valueChanges.pipe(
            debounceTime(300)
        )
            .subscribe((searchText: string) => {
                this._updateListePossible(searchText);
            });

        this._ManagementRulesChecker.subjectCheck
            .subscribe(isSaving => {
                if (isSaving) {
                    this._initRequiedError();
                }
            });
    }

    /**
     * Initialisation de la liste des éléments possibles à lier
     */
    private _updateListePossible(searchText: string) {
        this.listPossibles = [];
        this._elementRepository.searchForLink(this.caracConfig.DicoCarac, searchText, this.value.Element)
            .subscribe((result: NPElement[]) => {
                this.listPossibles = result.map(rel => {
                    // On ajoute l'élément à la liste s'il n'est pas lié
                    return {
                        elementName: this.voe.getLabel(rel),
                        elementID: rel.ID,
                        elementOrder: rel.getValueSearchRankLevelSearchRanking(),
                        element: rel
                    };
                });
                this._filterListPossibles();
                this._sortListPossibleByName();
            });
        this._sortListPossibleByName();
    }

    /**
     * Initialisation de la liste des éléments liés
     */
    private _initializeListeLies() {
        const value: NPCaracLien = <NPCaracLien>this.voe.getCaracValue(this.value.Element, this.caracConfig);
        if (value != null) {
            const tmpRebuildLinkedElements = (<NPCaracLien>this.voe.getCaracValue(this.value.Element, this.caracConfig));
            if (tmpRebuildLinkedElements && tmpRebuildLinkedElements.RebuildLinkedElements) {
                this.listLinked = tmpRebuildLinkedElements.RebuildLinkedElements
                    .map(rel => {
                        return {
                            elementName: this.voe.getLabel(rel.Element),
                            elementID: rel.Element.ID,
                            elementOrder: rel.Order,
                            element: rel.Element
                        };
                    });
                this._sortListLinkedByOrder();
            }

        } else {
            this.listLinked = [];
        }
    }

    /**
     * Initilisation de la liste des éléments possibles
     */
    private _initializeListePossibles() {
        this._updateListePossible('');
    }

    /**
     * Gestion clic bouton vers la droite
     */
    moveRight(elementsList: MatListOption[]) {
        const listSelected = elementsList.map(option => option.value);
        listSelected.forEach((selected) => {
            // Si l'élément n'est pas déjà lié
            if (this._isNotLinked(selected.elementID)) {
                // Ajout de l'élément lié
                this._elementWriter.concatValueLink(this.value.Element, this.caracConfig.DicoCaracExtID, selected.element);
                // Ajout dans la liste à droite
                this.listLinked.push(selected);
                // Suppression de la liste à gauche
                this.listPossiblesFiltered.forEach((possible, index) => {
                    if (selected.elementID === possible.elementID) {
                        this.listPossiblesFiltered.splice(index, 1);
                    }
                });
                this._sortListLinkedByOrder();
            }
        });
        this._initRequiedError();
    }

    /**
     * Gestion clic bouton vers la gauche.
     * Il suffit de l'enlever de la liste des éléments liés pour être affiché dans la liste des éléments possibles.
     */
    moveLeft(elementsList: MatListOption[]) {
        const listSelected = elementsList.map(option => option.value);
        listSelected.forEach((selected) => {
            // Suppression de l'élément lié
            const elementToRemove = this.value.Element.getValueLien(this.caracConfig.DicoCarac.ExtID)
                .RebuildLinkedElements.filter(d => d.Element.ID === selected.elementID)
                .map(d => d.Element);
            if (elementToRemove.length) {
                this._elementWriter.deleteValueLink(this.value.Element, this.caracConfig.DicoCaracExtID, elementToRemove[0]);
            }
            // Ajout dans la liste à droite (liste filtrée seulement)
            this.listPossiblesFiltered.push(selected);
            // Suppression de la liste à gauche
            this.listLinked = this.listLinked.filter((val) => !(selected.elementID === val.elementID));
            this._sortListPossibleByName();
        });
        this._initRequiedError();
    }

    /**
     * Tri de liste des éléments possibles
     */
    private _sortListPossibleByName() {
        this.listPossibles.sort((e1, e2) => e1.elementName < e2.elementName ? -1 : 1);
        this.listPossiblesFiltered.sort((e1, e2) => e1.elementOrder > e2.elementOrder ? -1 : 1);
    }

    /**
     * Tri de la liste des éléments liés
     */
    private _sortListLinkedByOrder() {
        this.listLinked.sort((e1, e2) => e1.elementOrder > e2.elementOrder ? -1 : 1);
    }

    /**
     * Vérifier si l'élément est lié.
     */
    private _isNotLinked(id: number) {
        return this.listLinked.every(lie => lie.elementID !== id);
    }

    /**
     * Filtrer la liste des éléments possibles pour éviter d'afficher les éléments qui sont aussi liés
     */
    private _filterListPossibles() {
        const listIDs = this.listLinked.map((val) => val.elementID);
        this.listPossiblesFiltered = this.listPossibles.filter((val) => !listIDs.includes(val.elementID));
    }

    public translate(key: string, defaultvalue: string): string {
        if (this._translations.has(key)) {
            return this._translations.get(key);
        } else {

            this._translateSrv.translate(key).subscribe(v =>
                this._translations.set(key, v)
            );
            return defaultvalue;
        }
    }

    private _initRequiedError() {
        this.isValid = this._ManagementRulesChecker.isValueValide(this.value.Element, this.caracConfig);
    }
}
