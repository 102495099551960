export class NpUser {

    public ID: number;
    public Name: string;
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public Group: string;
    public CustomFields?: CustomField[] = [];

}

export interface CustomFieldItem {
    ID: number;
    ID_Field: number;
    ID_User: number;
    ID_List: number;
    Value: string;
}

export interface CustomField {
    ID: number;
    Label: string;
    Type: string;
    ExtID: string;
    Values: CustomFieldItem[];
    ID_List?: any;
}
