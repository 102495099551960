import {Constantes, DataMocker, WsParamsService} from '@next-page/np-sdk-data';
import {ThemingService} from '@next-page/np-sdk-graphics';
import {WsCogedimParamsService} from '../app/sdk/data/services/ws-cogedim-params.service';
import {DataMockerSdkdebugCogedimFull} from '../app/sdk/data/Mocking/data-mocker-sdkdebug-cogedimfull';
import {TOKEN_NAME} from '../app/sdk/data/services/auth/cogedim-authentication.service';
import {ComponentGraphicsStyleConfig} from '../assets/conf/component-graphics-style-config';

export const environment = {
    production: false,
    dataMocker: [
        {provide: ThemingService, useClass: ComponentGraphicsStyleConfig},
        {provide: WsParamsService, useClass: WsCogedimParamsService},
        {provide: DataMocker, useClass: DataMockerSdkdebugCogedimFull},
        {
            provide: Constantes, useValue: {
                // baseUrl: 'https://altarea.nextpage.fr',
                // baseUrl: 'https://localhost:44347/',
                // baseUrl: 'http://cogedim.dev.nextpage.fr',
                baseUrl: 'https://preprod-cogedim.nextpage.fr',
                screenConfig: 'CogedimScreen.json',
                token: localStorage.getItem(TOKEN_NAME),
                primaryColor: '#53226B'
            }
        }
    ],
    researchFamilyID: 12998,
    // researchFamilyID: 5
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
