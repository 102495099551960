import {Component, ElementRef, forwardRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {filter, flatMap, map} from 'rxjs/operators';
import {CogedimConstantes, Messages} from '../../sdk/data/constantes/cogedim-constantes';
import {CaracConfig, DicoCarac, NPElement, NPListeValues} from '@nextpage/np-sdk-data';
import {CogedimDicocarcs} from '../../sdk/data/constantes/cogedim-dicocarcs';
import {CollectionService} from '../../sdk/data/services/collection.service';
import {OperationService} from '../../sdk/data/services/operation.service';
import {ActivatedRoute} from '@angular/router';
import {CaracConfigRepositoryService} from '../../sdk/data/services/carac-config-repository.service';

import {ElementRepositoryService} from '../../sdk/data/services/element-repository.service';
import {tap} from 'rxjs/internal/operators';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {DialogPreloadComponent} from '../../sdk/graphics/components/dialog-preload/dialog-preload.component';
import {KitService} from '../../sdk/data/services/kit.service';
import {HtmlService, PosedITem, TabSummary} from '../../sdk/graphics/services/html.service';
import {SafeHtml} from '@angular/platform-browser';
import {CogedimElementWriterService} from '../../sdk/data/services/cogedim-element-writer.service';
import {of, throwError} from 'rxjs';
import {BuildingElementWrapper, TableRowItem, WarningDialogResponse} from '../../sdk/data/Model/models';
import {UploadNoticeService} from '../../sdk/data/services/upload-notice.service';
import {CogedimFileWriterService} from '../../sdk/data/services/cogedim-file-writer.service';
import {BuildingModuleHelper} from '../../sdk/data/helpers/building-module-helper';
import {zip} from 'rxjs/internal/observable/zip';
import {EventService} from '../../sdk/data/services/event.service';


@Component({
    selector: 'app-dialog-witness-control',
    templateUrl: './dialog-witness-control.component.html',
    styleUrls: ['./dialog-witness-control.component.scss']
})
export class DialogWitnessControlComponent implements OnInit, OnDestroy {

    @ViewChild('htmlTableContainer') _htmlTableContainer: ElementRef;

    public chosenCollection: NPListeValues;
    public currentOperation: NPElement;
    private kits: NPElement[] = [];
    public tabSummaries: TabSummary[] = [];
    public imageTabSummary: TabSummary;
    public operationName = '';
    public collectionOfOperation: NPElement;
    public tableValue: SafeHtml;
    public tableElements: TableRowItem[] = [];
    public posedITem: PosedITem;
    public containsData = true;
    public buildingModuleElements: BuildingElementWrapper[] = [];
    private buildingModuleParent: NPElement;
    public posedCaracConfig: CaracConfig;

    // Famille dans laquelle seront stockées les éléments du module de construction

    constructor(public dialogRef: MatDialogRef<DialogWitnessControlComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _collectionService: CollectionService,
                private _operationService: OperationService,
                private _router: ActivatedRoute,
                private _caracConfigRepository: CaracConfigRepositoryService,
                private _elementRepository: ElementRepositoryService,
                private _matDialog: MatDialog,
                @Inject(forwardRef(() => ModalService)) private _modalService: ModalService,
                @Inject(forwardRef(() => CogedimElementWriterService)) private _elementWriter: CogedimElementWriterService,
                private _preloader: MatDialogRef<DialogPreloadComponent>,
                protected _kitService: KitService,
                private _htmlService: HtmlService,
                private _uploadNoticeService: UploadNoticeService,
                private _cogedimFileWriterService: CogedimFileWriterService,
                private _eventService: EventService
    ) {
    }

    ngOnInit() {
        this._buildingModule();

        this.chosenCollection = this.data.chosenCollection || null;

        this._initData();

    }

    ngOnDestroy() {
        this.closeDialog();
    }

    private _buildingModule() {
        this._caracConfigRepository.getConfigsByKeyName(CogedimConstantes.BuildingModule)
            .subscribe(data => {
                if (data && data.length > 0) {
                    this.tabSummaries = data.filter(c => c.BlocInfo !== 'Image' && c.BlocInfo !== 'Posed')
                        .map(_caracConfig => new TabSummary(_caracConfig));
                    const imageCarac = data.find(c => c.BlocInfo === 'Image');
                    this.posedCaracConfig = data.find(c => c.BlocInfo === 'Posed' && c.DicoCaracExtID === CogedimDicocarcs.DC_P_MODULE_CONSTRUCTION_POSE);
                    const whyCaracs = data.find(c => c.BlocInfo === 'Posed' && c.DicoCaracExtID === CogedimDicocarcs.DC_P_MODULE_CONSTRUCTION_POURQUOI);
                    this.posedITem = new PosedITem(this.posedCaracConfig, whyCaracs);
                    this.imageTabSummary = new TabSummary(imageCarac);
                }
            });
    }

    saveData(confirmation = false) {
        this._save(confirmation);
    }

    private _save(confirmation = false) {
        if (this._elementWriter.hasModifications()) {
            this._elementWriter.canSaveData()
                .pipe(
                    filter(canSave => canSave),
                    flatMap(() => {
                        return !confirmation ? this._modalService.openConfirmModal(CogedimConstantes.SAVE_MODE, Messages.SAVE_QUESTION) : of(true);
                    }),
                    filter(response => response),
                    flatMap(() => {
                        return this._elementWriter.publishToNP();
                    })
                )
                .subscribe(() => {
                    this._modalService.openSnackBar(Messages.SAVE_SUCCES, CogedimConstantes.SNACK_CLASS);
                    this._htmlService.resetModifications();
                    if (confirmation) {
                        this.dialogRef.close();
                    }
                }, err => {
                    if (err === 0) { // Aucune donnée à enregistrer
                        if (confirmation) {
                            this.dialogRef.close();
                        }
                        this._modalService.openSnackBar(Messages.NO_CHANGES_TO_SAVE, CogedimConstantes.SNACK_CLASS);
                    } else if (err === -1) { // Erreurs ! données invalides
                        if (confirmation) {
                            this.dialogRef.close();
                        }
                        this._modalService.openErrorModal();
                    }
                });
        } else {
            this._modalService.openSnackBar(Messages.NO_CHANGES_TO_SAVE, CogedimConstantes.SNACK_CLASS);
        }
    }

    closeDialog() {
        if (this._elementWriter.hasModifications()) {
            this._modalService.openWarningAskDialog()
                .pipe(
                    flatMap(response => {
                        switch (response) {
                            // L'utilisateur clique sur 'Enregistrer...', on déclenche donc la procédure de sauvegarde
                            case WarningDialogResponse.SAVE_MODIFICATIONS: {
                                this.saveData(true);
                                break;
                            }
                            case WarningDialogResponse.CANCEL_MODIFICATIONS: {
                                // L'utilisateur clique sur 'Annuler les modifications et quitter la page', on annule toutes modifications et on quite la page
                                this._elementWriter.cancelAllModifications();
                                this.dialogRef.close();
                                break;
                            }
                            default: {

                            }
                        }
                        return of(true);
                    })
                )
                .subscribe(() => {

                });
        } else {
            this.dialogRef.close();
        }
    }

    getElementName(element: NPElement) {
        return element ? element.getValueTextValue(DicoCarac.FAMILY_LABEL) : '';
    }

    hasSomePosed() {
        return this._htmlService.hasSomePosed(this.kits);
    }

    validateAllProducts() {
        if (this.posedCaracConfig && this.posedCaracConfig.DicoCarac && this.buildingModuleElements) {
            const selectedValue = this.posedCaracConfig.DicoCarac.Values.find(x => x.Valeur === CogedimConstantes.POSED);
            this._eventService.setEvenByKey(CogedimConstantes.VALIDATE_BUILDING_MODULE_KIT, selectedValue);
        }
    }

    downloadDocument() {
        if (this.kits && this.kits.length > 0) {
            const wordDocumentTitle = `${this.operationName} - ${this.chosenCollection.Valeur}`;
            this._cogedimFileWriterService.generateWordDocument(this.kits, this.tableElements, wordDocumentTitle, this.buildingModuleElements);
        } else {
            this._modalService.openSnackBar(Messages.NO_DATA_TO_PRINT, CogedimConstantes.SNACK_CLASS);
        }
    }

    private _initData() {
        this._operationService.getCurrentOperation()
            .pipe(
                flatMap(_currentOperation => {
                    this.currentOperation = _currentOperation;
                    return this._router.queryParamMap
                        .pipe(
                            map(_params => {
                                const collectionOperationExtId = _params.get(CogedimConstantes.PRESTATION_EXTID_PARAM);
                                return collectionOperationExtId ? collectionOperationExtId : throwError(`Cannot find ExtID of operation collection in url`);
                            })
                        );
                }),
                flatMap(_collectionOperationExtId => {
                    this.operationName = this.currentOperation ?
                        this.currentOperation.getValueTextValue(CogedimDicocarcs.DC_NOM_COMMERCIAL) : '';
                    // Récupère la collection en cours
                    this.collectionOfOperation = this._operationService.getCollectionByExtId(this.currentOperation, _collectionOperationExtId);

                    // Récupère le tableau HTML
                    this.tableValue = this._htmlService.getTableValue(this.collectionOfOperation);
                    // Recupère la liste des kits associés à la collection en cours
                    this.kits = this._kitService.extractKitsFromCollection(this.collectionOfOperation);
                    this.containsData = this.kits && this.kits.length > 0;
                    // Récupère les éléments du module de construction
                    this.buildingModuleElements = BuildingModuleHelper.getBuildingModuleElementsFormatted(this.collectionOfOperation, this.posedCaracConfig);

                    // this.buildingModuleElements = this._elementRepository.getBuildingModuleElements(this.collectionOfOperation, this.kits);
                    this.mapBuildingModuleElements();
                    // Recupère les familles et sous-familles des kits
                    // Attention: à vérifier s'il y un service NP qui retourne les Elements et leurs parents
                    const _parentRequests = [];
                    this.kits.forEach(_kit => {
                        const tmpRequest$ = this._elementRepository
                            .getElements([_kit.ParentExtID])
                            .pipe(
                                flatMap(parent => {
                                    _kit.Parent = parent.get(_kit.ParentExtID);
                                    return this._elementRepository.getElements([_kit.Parent.ParentExtID])
                                        .pipe(
                                            tap(response => {
                                                _kit.Parent.Parent = response.get(_kit.Parent.ParentExtID);
                                            })
                                        );
                                })
                            );
                        _parentRequests.push(tmpRequest$);
                    });
                    return forkJoin(_parentRequests);
                })
            )
            .subscribe(() => {
            }, error => {
                console.log('error : ', error);
                this.containsData = false;
            });
    }

    private mapBuildingModuleElements() {
        // Récupère la famille qui contient les modules de construction
        // et la carcacConfig pour pour lka création des modules de construction
        zip(
            this._caracConfigRepository.getConfigsByKeyName(CogedimConstantes.NewBuildingModuleCong),
            this._elementRepository.getElementByExtID(CogedimDicocarcs.SF_MODULE_CONSTRUCTION)
        )
            .subscribe(([config, _parent]) => {
                this.buildingModuleParent = _parent;
                if (this.buildingModuleParent) {
                    this._elementWriter.createBuildingModuleElementByKit(config, this.collectionOfOperation, this.buildingModuleParent.ID);
                }
                // Récupère (de nouveau) les modules de construction de la collection en cours
                this.buildingModuleElements = BuildingModuleHelper.getBuildingModuleElementsFormatted(this.collectionOfOperation, this.posedCaracConfig);
            });
    }
}
