/**
 * Permet de faciliter l'affichage d'une valeur de caractéristique
 */
import {CaracConfig, DicoCarac, NPCaracCharTemplate, NPCaracLien, NPCaracStd, NPCaracValeur, NPElement} from '../model';


export class ValueOneElementHelper {
    static CaracParentInfo = '$$Parent';

    /**
     * Retourne l'objet "CaracValue" à partir d'une configuration
     */
    getCaracValue(elt: NPElement, config: CaracConfig): NPCaracValeur {
        let curElt = elt;
        // recherche de l'élément par rapport au chemin
        if (config == null) {
            return null;
        }
        if (config.Links != null) {
            for (const DCLink of config.Links) {


                if (DCLink == ValueOneElementHelper.CaracParentInfo) {
                    curElt = curElt.Parent;
                    if (curElt == null) {
                        return null;
                    }
                } else {
                    // récupération du lien
                    const CaracLink = this.getElementValue(curElt, DCLink) as NPCaracLien;
                    // si il y a une valeur et que celle ci contient au moins un élément
                    if (CaracLink != null
                        && CaracLink.RebuildLinkedElements != null
                        && CaracLink.RebuildLinkedElements.length) {
                        curElt = CaracLink.RebuildLinkedElements[0].Element;
                    } else {
                        return null;
                    }
                }

            }
        }
        // recherche de la caractéristique sur l'élément
        if (curElt != null) {
            return this.getElementValue(curElt, config.DicoCaracExtID);
        }
        return null;
    }

    /**
     * retourne la valeur d'une caractéristique texte
     */
    getCaracTxtValue(elt: NPElement, config: CaracConfig): string {
        let result = this.getCaracValue(elt, config) as NPCaracStd;
        if (result != null) {
            return (<NPCaracStd>result).Value;
        } else {
            return '';
        }
    }

    /**
     * Donne la liste des ExtID templates d'un produit
     */
    getCharTemplateExtID(elt: NPElement): string[] {
        return (<NPCaracCharTemplate>this.getElementValue(elt, DicoCarac.DTO_SYSTEM_CHAR_TEMPLATE)).CharTemplates
            .map(ct => ct.CharTemplateExtID);
    }

    /// Retourn la valeur de l'élément
    private getElementValue(elt: NPElement, DicoCaracExtID: string) {
        let r: NPCaracValeur = null;
        if (DicoCaracExtID !== DicoCarac.ANY_LABEL) {
            r = elt.getValue(DicoCaracExtID);
        } else {
            Object.getOwnPropertyNames(DicoCarac)
                .filter(att => att.includes('LABEL'))
                .forEach((pName) => {
                    if (r == null) {
                        r = elt.getValue(DicoCarac[pName]);
                    }
                });
        }
        return r;
    }

    /**
     * indique si le produit est d'un type donné
     */
    isOfCharTemplate(elt: NPElement, CharTemplateExtID: string): boolean {
        return this.getCharTemplateExtID(elt).filter(d => d === CharTemplateExtID).length > 0;
    }


    /**
     * Retourne l'objet NPElement à partir d'une configuration
     */
    getElement(elt: NPElement, config: CaracConfig): NPElement {
        if (config == null) {
            return null;
        }
        let curElt = elt;

        // recherche de l'élément par rapport au chemin
        if (config.Links != null) {
            for (const DCLink of config.Links) {
                // récupération du lien
                const CaracLink = this.getElementValue(curElt, DCLink) as NPCaracLien;
                // si il y a une valeur et que celle ci contient au moins un élément
                if (CaracLink != null
                    && CaracLink.RebuildLinkedElements != null
                    && CaracLink.RebuildLinkedElements.length) {
                    curElt = CaracLink.RebuildLinkedElements[0].Element;
                } else {
                    return null;
                }
            }
        }

        return curElt;
    }

    /**
     * Retourne l'objet NPElement à partir d'une configuration
     */
    getElements(elt: NPElement, config: CaracConfig): NPElement[] {
        if (elt == null) {
            return [];
        }
        let curElt = [elt];

        // recherche de l'élément par rapport au chemin
        if (config.Links != null) {
            curElt = config.Links.reduce((accumulator: NPElement[], link) => {
                const result = accumulator.reduce((acc, element) => {
                    const carLink = this.getElementValue(element, link) as NPCaracLien;
                    let res = [];
                    if (carLink != null && carLink.RebuildLinkedElements != null) {
                        res = [...acc, ...carLink.RebuildLinkedElements.map(lv => lv.Element)];
                        res = res.filter((elmt: NPElement, key, array) => {
                            return (array.filter((e: NPElement) => e.ID === elmt.ID));
                        });
                    }
                    return res;
                }, []);
                return result;
            }, curElt);
        }
        return curElt;
    }

    getLabel(elt: NPElement) {
        if (elt == null || elt.Values == null) {
            return null;
        }
        if (elt.Values.has(DicoCarac.PRODUCT_LABEL)) {
            return (elt.Values.get(DicoCarac.PRODUCT_LABEL) as NPCaracStd).Value;
        }
        if (elt.Values.has(DicoCarac.FAMILY_LABEL)) {
            return (elt.Values.get(DicoCarac.FAMILY_LABEL) as NPCaracStd).Value;
        }
        if (elt.Values.has(DicoCarac.NOTIFICATION_LABEL)) {
            return (elt.Values.get(DicoCarac.NOTIFICATION_LABEL) as NPCaracStd).Value;
        }
        if (elt.Values.has(DicoCarac.REFERENCE_LABEL)) {
            return (elt.Values.get(DicoCarac.REFERENCE_LABEL) as NPCaracStd).Value;
        }
        if (elt.Values.has(DicoCarac.MEDIA_LABEL)) {
            return (elt.Values.get(DicoCarac.MEDIA_LABEL) as NPCaracStd).Value;
        }
        if (elt.Values.has(DicoCarac.MEDIADIRECTORY_LABEL)) {
            return (elt.Values.get(DicoCarac.MEDIADIRECTORY_LABEL) as NPCaracStd).Value;
        }
        //Hack pour pallier au souci sur searchForLink
        let result: string = 'no label';
        elt.Values.forEach((val) => {
            let temp = (val as NPCaracStd).Value;
            if (temp != null) {
                result = temp;
            }
        });
        return result;
    }

    /**
     * Retourne des éléments distinct d'un talbeau d'éméents
     */
    distinctElements(elts: NPElement[]): NPElement[] {
        const dictElts = elts.reduce((acc, value) => {
                if (!acc.has(value.ExtID)) {
                    acc.set(value.ExtID, value);
                }
                return acc;
            }
            , new Map<string, NPElement>());

        return Array.from(dictElts.values());
    }

    hasUnite(caracConfig: CaracConfig) {
        return caracConfig && caracConfig.DicoCarac && caracConfig.DicoCarac.Unite !== '';
    }


}
