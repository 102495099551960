
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {WSParameters, WsParamsService} from '@next-page/np-sdk-data';
import {Injectable} from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class WsCogedimParamsService extends WsParamsService {

    private _resParameters$: BehaviorSubject<WSParameters>;

    constructor(private _r: ActivatedRoute) {
        super(_r);
    }

    protected Init() {

        const result = new WSParameters();
        result.ContextID = 1;
        result.LangID = 1;
        this._resParameters$ = new BehaviorSubject(null);
        this._params$ = this._resParameters$;
        this._resParameters$.next(result);
    }
}
