import {Injectable} from '@angular/core';

import {Constantes, DicoCarac, NPCaracStd, NPElement, ValueOneElementHelper} from '@nextpage/np-sdk-data';


@Injectable()
export class FileReaderService {

    protected _urlFileHandlerMedia: string;
    protected _urlFileHandlerMediaThumb: string;
    protected readonly _valueHelper: ValueOneElementHelper;

    constructor(private constantes: Constantes) {
        if (constantes.token == null) {
            // this._urlFileHandlerMedia = constantes.baseUrl + '/filehandler.ashx?cd=inline&t=original&ft=undefined&p=';
            // this._urlFileHandlerMediaThumb = constantes.baseUrl + '/filehandler.ashx?cd=inline&t=thumb&ft=undefined&p=';
            this._urlFileHandlerMedia = '/filehandler.ashx?cd=inline&t=original&ft=undefined&p=';
            this._urlFileHandlerMediaThumb = '/filehandler.ashx?cd=inline&t=thumb&ft=undefined&p=';
        } else {
            this._urlFileHandlerMedia = this.constantes.baseUrl + `/filehandlerapi.ashx?authtoken=${this.constantes.token}&cd=inline&t=original&ft=undefined&p=`;
            this._urlFileHandlerMediaThumb = this.constantes.baseUrl + `/filehandlerapi.ashx?authtoken=${this.constantes.token}&cd=inline&t=thumb&ft=undefined&p=`;
        }
        this._valueHelper = new ValueOneElementHelper();
    }

    toUrlMedia(media: NPElement) {
        const value = this._valueHelper.getCaracValue(media, {DicoCaracExtID: DicoCarac.MEDIA_FILE}) as NPCaracStd;
        if (value != null) {
            return this._urlFileHandlerMedia
                + this.encodeUri((<NPCaracStd>this._valueHelper.getCaracValue(media, {DicoCaracExtID: DicoCarac.MEDIA_FILE})).Value);
        } else {
            return '';
        }
    }

    toFilePath(path: string) {
        return this.constantes.baseUrl + `/filehandlerapi.ashx?authtoken=${this.constantes.token}&cd=inline&t=original&ft=undefined&p=` + path;
    }

    toUrlThumbImage(media: NPElement) {
        const value = this._valueHelper.getCaracValue(media, {DicoCaracExtID: DicoCarac.MEDIA_FILE}) as NPCaracStd;
        if (value != null) {
            return this._urlFileHandlerMediaThumb
                + this.encodeUri((<NPCaracStd>this._valueHelper.getCaracValue(media, {DicoCaracExtID: DicoCarac.MEDIA_FILE})).Value);
        } else {
            return '';
        }
    }

    toUrlCustomImage(media: NPElement, dicoCaracExtID: string) {
        const value = this._valueHelper.getCaracValue(media, {
            DicoCaracExtID: DicoCarac.MEDIA_FILE,
            Links: [dicoCaracExtID]
        }) as NPCaracStd;
        if (value != null) {
            return this._urlFileHandlerMedia + this.encodeUri((<NPCaracStd>this._valueHelper.getCaracValue(media, {
                DicoCaracExtID: DicoCarac.MEDIA_FILE,
                Links: [dicoCaracExtID]
            })).Value);
        } else {
            return '';
        }
    }

    toUrlCustomImageFromPath(path: string) {
        return this._urlFileHandlerMedia + path;
    }

    toUrlCustomImageThumbFromPath(path: string) {
        return this._urlFileHandlerMediaThumb + path;
    }

    getUrlMedia(path: string) {
        return `${this._urlFileHandlerMediaThumb}=${path}`;
    }

    private encodeUri(uri: string): string {
        let encodedUri = encodeURI(uri);
        // It happens that filenames contains the + sign. Unfortunately the + sign is also a valid url character
        // (acting as a space), so it is not replaced by encodeURI function. Function encodeURIComponent encodes it
        // with a %20. So I decided to make a basic replacement of the + sign with its encoded value %2B.
        encodedUri = encodedUri.replace('+', '%2B');

        return encodedUri;
    }
}
