import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {CaracInfo} from '../Model';
import {CaracConfig, NPCaracListe, NPElement, ValueOneElementHelper} from '@nextpage/np-sdk-data';


@Component({
    selector: 'lib-app-value-multi-link-bullet-view',
    templateUrl: './np-value-multi-link-bullet-view.component.html',
    styleUrls: ['./np-value-multi-link-bullet-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpValueMultiLinkBulletViewComponent implements OnChanges {
    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracListe;
    @Input() caracConfig: CaracConfig;
    @Input() element: NPElement;

    valueHelper: ValueOneElementHelper;
    links: string[];

    constructor() {
        this.valueHelper = new ValueOneElementHelper();
        this.links = [];
    }

    private initValues() {
        this.valueHelper.getElements(this.element, {
            DicoCaracExtID: '',
            Links: this.caracConfig.Links
        }).forEach(npelement =>
            this.links.push(npelement.getValueText(this.caracConfig.DicoCaracExtID).Value)
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initValues();
    }
}
