import {AdvancedSearchDTO} from './search-model';

export class AdvSearchArgs {
    public Type = 0;
    public AdvancedSearchID?: number;
    public Config: AdvancedSearchDTO;
    public CurrentPage?: number;
    public PageSize?: number;
    public Paths: string[][];
    public DCExtIDs: string[];
    public LangID = 1;
    public ContexteID = 1;
    public UILangCode: 'fr';
}
