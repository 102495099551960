import {NpApiResult} from '../../model';
import {DataMocker} from '../../utils/Data/Mocking';



export class NpModelNaosSearchOne extends DataMocker
{
  /**
   * Le pattern d'url pour les dicoCaracs
   */
  getUrlPattern(): string {
    return 'api/sdk/Search/GetConfig/123';
  }

  /**
   * Retourne les objets dicoCarac
   */
  getData(): NpApiResult {
    const data ={"Properties":{"ID":1,"Name":"Toto","State":"private","CreatorID":8,"CreationDate":"2018-04-26T12:09:29.833","ModifierID":8,"ModificationDate":"2018-04-26T12:09:44.817","ExtID":"6868aad6-be49-4dc3-a707-620f3dd2b6a7","AdvanceSearchType":0},"Filters":{"ElementType":2,"CreatorID":null,"CreationDate":"","CreationDateOperator":"avant","ModificationDate":"","ModificationDateOperator":"avant","WFStatusID":null,"WFStatusOperator":"=","Family":{"ID":0,"label":""},"ProductsTypeID":11,"Keywords":"","Chars":{"OperatorValue":"and","Items":[{"ID":74,"OperatorValue":"=","Value":"123","SelectedDico":{"ID":74,"Order":5,"Rank":0,"ExtID":"DC_CODE_VRAC","Required":false,"Editable":false,"Translatable":true,"TypeCode":"TXT","TypeCarac":"produit","TypeCodeLong":"texte","DossierDicoCaracID":4,"LibelleDossier":"BDD","OrdreDossier":5,"Name":"Code Vrac","SecondaryName":"","PublicationName":"","Groupe":"","Description":"","CalculationFormula":null,"Unite":"","MaxLength":0,"MaxValue":0,"MinValue":0,"PathFileSystem":null,"ValeurListID":null,"ReflexiveCharID":null,"DicoCaracIDCopyDataFrom":null,"TargetCharID":null,"SourceCharID":null,"idElementLien":null,"ElementLink":null,"ElType":2,"NbUsed":0,"Destination":null,"Cardinality":null,"Value":"","Access":0,"FillRateIDs":[1],"FillRateNames":null,"FillRates":null}}]}},"DisplayFields":[{"ID":3,"Name":null,"Unite":null,"AdvancedSearchID":2,"FieldType":3,"DicoCaracID":null,"DicoCaracRenderType":"Standard","DicoCaracTypeCode":null,"Order":1,"SortAllow":true,"SortEnable":false,"SortDesc":false},{"ID":4,"Name":null,"Unite":null,"AdvancedSearchID":2,"FieldType":4,"DicoCaracID":214,"DicoCaracRenderType":"Standard","DicoCaracTypeCode":null,"Order":2,"SortAllow":true,"SortEnable":false,"SortDesc":false}],"SortFields":[{"DicoCaracID":null,"FieldType":5,"SortDesc":false,"SelectedItem":null},{"DicoCaracID":51,"FieldType":4,"SortDesc":false,"SelectedItem":null}]}
    ;
    return super.BuildAPIResult(data);
  }


}
