import {NgModule} from '@angular/core';
import {DynamicModule} from 'ng-dynamic-component';
import {NPValueViewComponent} from './np-value-view/np-value-view.component';
import {NpValueImageReadViewComponent} from './np-value-image-read-view/np-value-image-read-view.component';
import {NpValueLinkReadViewComponent} from './np-value-link-read-view/np-value-link-read-view.component';
import {NpValueTextReadViewComponent} from './np-value-text-read-view/np-value-text-read-view.component';
import {NpValueTextWriteViewComponent} from './np-value-text-write-view/np-value-text-write-view.component';
import {NpValueListReadViewComponent} from './np-value-list-read-view/np-value-list-read-view.component';
import {NpValueImageWriteViewComponent} from './np-value-image-write-view/np-value-image-write-view.component';
import {NpValueListWriteViewComponent} from './np-value-list-write-view/np-value-list-write-view.component';
import {NpValueStatusReadViewComponent} from './np-value-status-read-view/np-value-status-read-view.component';
import {NpValueMultiLinkBulletViewComponent} from './np-value-multi-link-bullet-view/np-value-multi-link-bullet-view.component';
import {SharedModule} from '../../shared/shared.module';
import {ValueComponentResolver} from '../../services/value-component-resolver';


@NgModule({
  imports: [
    SharedModule,
    DynamicModule.withComponents([
      NpValueImageReadViewComponent,
      NpValueImageWriteViewComponent,
      NpValueLinkReadViewComponent,
      NpValueListReadViewComponent,
      NpValueListWriteViewComponent,
      NpValueTextReadViewComponent,
      NpValueTextWriteViewComponent,
      NpValueStatusReadViewComponent,
      NpValueMultiLinkBulletViewComponent
    ])
  ],
  exports: [
    NPValueViewComponent
  ],
  declarations: [
    NPValueViewComponent,
    NpValueImageReadViewComponent,
    NpValueImageWriteViewComponent,
    NpValueLinkReadViewComponent,
    NpValueListReadViewComponent,
    NpValueListWriteViewComponent,
    NpValueTextReadViewComponent,
    NpValueTextWriteViewComponent,
    NpValueStatusReadViewComponent,
    NpValueMultiLinkBulletViewComponent
  ],
    providers: [ValueComponentResolver]
})
export class NPValueModule {}
