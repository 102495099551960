import {NpApiResult} from '../../model/np-api-result';
import {DataMocker} from '../../utils/Data/Mocking/data-mocker';
import {HttpRequest} from '@angular/common/http';

export class NpModelNaos2FGC_01NEP044 extends DataMocker {
  /**
   * Le pattern d'url pour les dicoCaracs
   */
  getUrlPattern(): string {
    return "TOTO";
  }

  matchUrl(request: HttpRequest<any>): boolean {

    return (request.url.indexOf('ElementsAndLinks') > -1);
  }

  /**
   * Obtenu dans Fiddler avec les paramètres suivants :
{
"ElementsExtIDs":["P_FGC_01NEP044"],
"Paths":[
    ["DC_FORMULE_DEPUIS_FGC_REV", "DC_MP-FLE_DEPUIS_FORMULE", "DC_MP_DEPUIS_MP-FLE", "DC_ING-MP_DEPUIS_MP", "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE", "$$Parents"],
    ["DC_FORMULE_DEPUIS_FGC_REV", "DC_MP-FLE_DEPUIS_FORMULE", "DC_MP_DEPUIS_MP-FLE", "DC_ING-MP_DEPUIS_MP", "DC_ROLE_DEPUIS_INGREDIENT", "$$Parents"],
    ["DC_FORMULE_DEPUIS_FGC_REV", "DC_MP-FLE_DEPUIS_FORMULE", "DC_MP_DEPUIS_MP-FLE", "DC_ING-MP_DEPUIS_MP", "DC_INGREDIENT_DEPUIS_ING-MP"],
    ["DC_FORMULE_DEPUIS_FGC_REV", "DC_MP-FLE_DEPUIS_FORMULE", "DC_MP_DEPUIS_MP-FLE", "DC_ING-MP_DEPUIS_MP", "DC_ROLE_GENCODE_ING-MP", "$$Parents"],
    ["DC_PF_DEPUIS_FGC", "DC_FGC_DEPUIS_PF"],
    ["DC_PF_DEPUIS_FGC", "DC_PHOTOS"],
    ["DC_FORMULE_DEPUIS_FGC_REV", "$$Parents"],
    ["DC_ING-FGC_DEPUIS_FGC"],
  ],
"LangID":"1",
"ContextID":"1"
}
   */

  /**
   * Retourne les objets dicoCarac
   */
  getData(): NpApiResult {
    let data =
      {
        "Results": {
          "ResultsExtIDs": [13028],
          "Elements": {
            "10": {
              "ID": 10,
              "ExtID": "F_REFERENCE_DATA",
              "ElementType": 1,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 0,
              "Ordre": 2,
              "Inf": 2.0,
              "Sup": 3.0,
              "Values": {
                "FamilyFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 10,
                  "DicoCaracID": 214,
                  "DicoCaracExtID": "FamilyFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##FamilyLabel": {
                  "Value": "Données de références",
                  "ElementID": 10,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##FamilyLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12": {
              "ID": 12,
              "ExtID": "F_DR_Ingredient",
              "ElementType": 1,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 10,
              "Ordre": 2,
              "Inf": 2.5,
              "Sup": 2.6666666666666665,
              "Values": {
                "FamilyFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12,
                  "DicoCaracID": 214,
                  "DicoCaracExtID": "FamilyFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##FamilyLabel": {
                  "Value": "Ingrédient",
                  "ElementID": 12,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##FamilyLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13": {
              "ID": 13,
              "ExtID": "F_DR_Famille_Role",
              "ElementType": 1,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 10,
              "Ordre": 3,
              "Inf": 2.6666666666666665,
              "Sup": 2.75,
              "Values": {
                "FamilyFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 13,
                  "DicoCaracID": 214,
                  "DicoCaracExtID": "FamilyFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##FamilyLabel": {
                  "Value": "Famille (rôles)",
                  "ElementID": 13,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##FamilyLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "18": {
              "ID": 18,
              "ExtID": "F_Formule",
              "ElementType": 1,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 0,
              "Ordre": 4,
              "Inf": 4.0,
              "Sup": 5.0,
              "Values": {
                "FamilyFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 18,
                  "DicoCaracID": 214,
                  "DicoCaracExtID": "FamilyFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##FamilyLabel": {
                  "Value": "Formule",
                  "ElementID": 18,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##FamilyLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "19": {
              "ID": 19,
              "ExtID": "F_Produit_Fini",
              "ElementType": 1,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 0,
              "Ordre": 5,
              "Inf": 5.0,
              "Sup": 6.0,
              "Values": {
                "FamilyFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 19,
                  "DicoCaracID": 214,
                  "DicoCaracExtID": "FamilyFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##FamilyLabel": {
                  "Value": "Produit fini",
                  "ElementID": 19,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##FamilyLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "9505": {
              "ID": 9505,
              "ExtID": "DS_IngMP_AQUA/WATER/EAU_01AAU9136",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 64,
              "Inf": 1.4960629921259843,
              "Sup": 1.4961240310077519,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 9505,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 9505,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 9505,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 9505,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Minérale",
                  "ElementID": 9505,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 9505,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "Solubilisant",
                  "ElementID": 9505,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "SOLVENT",
                  "ElementID": 9505,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SOLVENT",
                  "ElementID": 9505,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11773
                  }],
                  "ElementID": 9505,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11437
                  }],
                  "ElementID": 9505,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "WATER",
                  "ElementID": 9505,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Water",
                  "ElementID": 9505,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Water",
                  "ElementID": 9505,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 9505,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 9505,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 9505,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 9505,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 9505,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "30",
                  "ElementID": 9505,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11965
                  }],
                  "ElementID": 9505,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 9505,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 9505,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 9505,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 9505,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 9505,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "AQUA/WATER/EAU - 01AAU9136",
                  "ElementID": 9505,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "9514": {
              "ID": 9514,
              "ExtID": "DS_IngMP_AQUA/WATER/EAU_01DIV8693",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 73,
              "Inf": 1.4965517241379311,
              "Sup": 1.4965986394557824,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 9514,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 9514,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 9514,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 9514,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Minérale",
                  "ElementID": 9514,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 9514,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "Solubilisant",
                  "ElementID": 9514,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "SOLVENT",
                  "ElementID": 9514,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SOLVENT",
                  "ElementID": 9514,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11773
                  }],
                  "ElementID": 9514,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11479
                  }],
                  "ElementID": 9514,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "WATER",
                  "ElementID": 9514,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Water",
                  "ElementID": 9514,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Water",
                  "ElementID": 9514,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 9514,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 9514,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 9514,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 9514,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 9514,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "40",
                  "ElementID": 9514,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11965
                  }],
                  "ElementID": 9514,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 9514,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 9514,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 9514,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 9514,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 9514,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "AQUA/WATER/EAU - 01DIV8693",
                  "ElementID": 9514,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11416": {
              "ID": 11416,
              "ExtID": "P_MP_01GEL8707",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 4,
              "Inf": 2.4285714285714284,
              "Sup": 2.4444444444444446,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11416,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11416,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11416,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11416,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01GEL8707",
                  "ElementID": 11416,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Végétale (carbohydrates)",
                  "ElementID": 11416,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11416,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11416,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11759
                  }],
                  "ElementID": 11416,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "XANTHAN GUM",
                  "ElementID": 11416,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11416,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11416,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12638
                  },
                    {
                      "Order": 2,
                      "ElementID": 12639
                    },
                    {
                      "Order": 3,
                      "ElementID": 12640
                    },
                    {
                      "Order": 4,
                      "ElementID": 12641
                    },
                    {
                      "Order": 5,
                      "ElementID": 12642
                    },
                    {
                      "Order": 6,
                      "ElementID": 12643
                    },
                    {
                      "Order": 7,
                      "ElementID": 12644
                    },
                    {
                      "Order": 8,
                      "ElementID": 12645
                    },
                    {
                      "Order": 9,
                      "ElementID": 12646
                    },
                    {
                      "Order": 10,
                      "ElementID": 12647
                    },
                    {
                      "Order": 11,
                      "ElementID": 12648
                    },
                    {
                      "Order": 12,
                      "ElementID": 12649
                    },
                    {
                      "Order": 13,
                      "ElementID": 12650
                    },
                    {
                      "Order": 14,
                      "ElementID": 12651
                    },
                    {
                      "Order": 15,
                      "ElementID": 12652
                    },
                    {
                      "Order": 16,
                      "ElementID": 12653
                    },
                    {
                      "Order": 17,
                      "ElementID": 12654
                    },
                    {
                      "Order": 18,
                      "ElementID": 12655
                    },
                    {
                      "Order": 19,
                      "ElementID": 12656
                    },
                    {
                      "Order": 20,
                      "ElementID": 12657
                    },
                    {
                      "Order": 21,
                      "ElementID": 12658
                    },
                    {
                      "Order": 22,
                      "ElementID": 12659
                    },
                    {
                      "Order": 23,
                      "ElementID": 12660
                    },
                    {
                      "Order": 24,
                      "ElementID": 12661
                    },
                    {
                      "Order": 25,
                      "ElementID": 12662
                    },
                    {
                      "Order": 26,
                      "ElementID": 12663
                    },
                    {
                      "Order": 27,
                      "ElementID": 12664
                    },
                    {
                      "Order": 28,
                      "ElementID": 12665
                    },
                    {
                      "Order": 29,
                      "ElementID": 12666
                    },
                    {
                      "Order": 30,
                      "ElementID": 12667
                    }],
                  "ElementID": 11416,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Biotechnological",
                  "ElementID": 11416,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "Soja et Maïs###comme sources carbonées###Fermentation bactérienne###Xanthomas campestris (USA)",
                  "ElementID": 11416,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "Authorized",
                  "ElementID": 11416,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11416,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Texturing agent",
                  "ElementID": 11416,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "70",
                  "ElementID": 11416,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11416,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01GEL8707",
                  "ElementID": 11416,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11422": {
              "ID": 11422,
              "ExtID": "P_MP_01AAU2794",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 10,
              "Inf": 2.4736842105263159,
              "Sup": 2.4761904761904763,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Oui",
                  "ElementID": 11422,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "Pure molecule",
                  "ElementID": 11422,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11422,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11422,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01AAU2794",
                  "ElementID": 11422,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Végétale (graine)",
                  "ElementID": 11422,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "Escin",
                  "ElementID": 11422,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11422,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11585
                  },
                    {
                      "Order": 2,
                      "ElementID": 11681
                    }],
                  "ElementID": 11422,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "ESCIN;LIMONENE",
                  "ElementID": 11422,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11422,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11422,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12332
                  }],
                  "ElementID": 11422,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Vegetable",
                  "ElementID": 11422,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "Extraction végétale###Process : cf flow chart###Mise en présence d'EtOH lors du process (pas d'information sur biosourcé pour EtOH => pas de certificat Ecocert)###Résidu : méthyl ester d'escine###Origine du limonène non expliquée : allergène de la plante",
                  "ElementID": 11422,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "To exclude",
                  "ElementID": 11422,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11422,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Active",
                  "ElementID": 11422,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "80",
                  "ElementID": 11422,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11422,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01AAU2794",
                  "ElementID": 11422,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11426": {
              "ID": 11426,
              "ExtID": "P_MP_01AAU8538",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 14,
              "Inf": 2.4814814814814814,
              "Sup": 2.4827586206896552,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11426,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11426,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11426,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11426,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01AAU8538",
                  "ElementID": 11426,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Synthèse (pétrochimique)",
                  "ElementID": 11426,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11426,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11426,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11757
                  }],
                  "ElementID": 11426,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "UREA",
                  "ElementID": 11426,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11426,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11426,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12338
                  },
                    {
                      "Order": 2,
                      "ElementID": 12339
                    },
                    {
                      "Order": 3,
                      "ElementID": 12340
                    },
                    {
                      "Order": 4,
                      "ElementID": 12341
                    },
                    {
                      "Order": 5,
                      "ElementID": 12342
                    },
                    {
                      "Order": 6,
                      "ElementID": 12343
                    },
                    {
                      "Order": 7,
                      "ElementID": 12344
                    },
                    {
                      "Order": 8,
                      "ElementID": 12345
                    },
                    {
                      "Order": 9,
                      "ElementID": 12346
                    }],
                  "ElementID": 11426,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Synthetic",
                  "ElementID": 11426,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "PROCESS : confidentiel###Pas d'informations",
                  "ElementID": 11426,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "Authorized",
                  "ElementID": 11426,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11426,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Active",
                  "ElementID": 11426,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "70",
                  "ElementID": 11426,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11426,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01AAU8538",
                  "ElementID": 11426,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11435": {
              "ID": 11435,
              "ExtID": "P_MP_01AAU9115",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 23,
              "Inf": 2.4888888888888889,
              "Sup": 2.4893617021276597,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11435,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "Peptidic derivative",
                  "ElementID": 11435,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11435,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11435,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01AAU9115",
                  "ElementID": 11435,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Végétale (acides gras) / Synthèse (glycine)",
                  "ElementID": 11435,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11435,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11435,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11640
                  }],
                  "ElementID": 11435,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "CAPRYLOYL GLYCINE",
                  "ElementID": 11435,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11435,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11435,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12358
                  },
                    {
                      "Order": 2,
                      "ElementID": 12359
                    },
                    {
                      "Order": 3,
                      "ElementID": 12360
                    },
                    {
                      "Order": 4,
                      "ElementID": 12361
                    },
                    {
                      "Order": 5,
                      "ElementID": 12362
                    },
                    {
                      "Order": 6,
                      "ElementID": 12363
                    },
                    {
                      "Order": 7,
                      "ElementID": 12364
                    },
                    {
                      "Order": 8,
                      "ElementID": 12365
                    },
                    {
                      "Order": 9,
                      "ElementID": 12366
                    },
                    {
                      "Order": 10,
                      "ElementID": 12367
                    },
                    {
                      "Order": 11,
                      "ElementID": 12368
                    },
                    {
                      "Order": 12,
                      "ElementID": 12369
                    },
                    {
                      "Order": 13,
                      "ElementID": 12370
                    },
                    {
                      "Order": 14,
                      "ElementID": 12371
                    },
                    {
                      "Order": 15,
                      "ElementID": 12372
                    },
                    {
                      "Order": 16,
                      "ElementID": 12373
                    },
                    {
                      "Order": 17,
                      "ElementID": 12374
                    },
                    {
                      "Order": 18,
                      "ElementID": 12375
                    },
                    {
                      "Order": 19,
                      "ElementID": 12376
                    },
                    {
                      "Order": 20,
                      "ElementID": 12377
                    },
                    {
                      "Order": 21,
                      "ElementID": 12378
                    },
                    {
                      "Order": 22,
                      "ElementID": 12379
                    },
                    {
                      "Order": 23,
                      "ElementID": 12380
                    },
                    {
                      "Order": 24,
                      "ElementID": 12381
                    },
                    {
                      "Order": 25,
                      "ElementID": 12382
                    },
                    {
                      "Order": 26,
                      "ElementID": 12277
                    },
                    {
                      "Order": 27,
                      "ElementID": 12278
                    },
                    {
                      "Order": 28,
                      "ElementID": 12279
                    }],
                  "ElementID": 11435,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Vegetable and synthetic",
                  "ElementID": 11435,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "Greffage (acylation) de la glycine sur la chaîne octanoique###Acide chaîne C8 (Europe)",
                  "ElementID": 11435,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "Authorized",
                  "ElementID": 11435,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11435,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Additive",
                  "ElementID": 11435,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "75",
                  "ElementID": 11435,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11435,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01AAU9115",
                  "ElementID": 11435,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11437": {
              "ID": 11437,
              "ExtID": "P_MP_01AAU9136",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 25,
              "Inf": 2.489795918367347,
              "Sup": 2.4901960784313726,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11437,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11437,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11437,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11437,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01AAU9136",
                  "ElementID": 11437,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Minérale (eau)###Végétale (carbohydrates)###Végétale (écorce)",
                  "ElementID": 11437,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11437,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11437,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 9505
                  },
                    {
                      "Order": 2,
                      "ElementID": 11680
                    },
                    {
                      "Order": 3,
                      "ElementID": 11713
                    }],
                  "ElementID": 11437,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "AQUA/WATER/EAU;LEUCONOSTOC/RADISH ROOT FERMENT FILTRATE;SALIX NIGRA (WILLOW) BARK EXTRACT",
                  "ElementID": 11437,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11437,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11437,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12284
                  },
                    {
                      "Order": 2,
                      "ElementID": 12285
                    },
                    {
                      "Order": 3,
                      "ElementID": 12286
                    },
                    {
                      "Order": 4,
                      "ElementID": 12287
                    },
                    {
                      "Order": 5,
                      "ElementID": 12288
                    },
                    {
                      "Order": 6,
                      "ElementID": 12289
                    },
                    {
                      "Order": 7,
                      "ElementID": 12290
                    },
                    {
                      "Order": 8,
                      "ElementID": 12291
                    },
                    {
                      "Order": 9,
                      "ElementID": 12292
                    },
                    {
                      "Order": 10,
                      "ElementID": 12293
                    },
                    {
                      "Order": 11,
                      "ElementID": 12294
                    },
                    {
                      "Order": 12,
                      "ElementID": 12295
                    },
                    {
                      "Order": 13,
                      "ElementID": 12296
                    },
                    {
                      "Order": 14,
                      "ElementID": 12297
                    },
                    {
                      "Order": 15,
                      "ElementID": 12298
                    },
                    {
                      "Order": 16,
                      "ElementID": 12299
                    },
                    {
                      "Order": 17,
                      "ElementID": 12300
                    }],
                  "ElementID": 11437,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Mineral;Vegetable;Vegetable / Biotechnological",
                  "ElementID": 11437,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "Process : fermentation de racines en présence de Leuconostoc et filtrations.###Fermentation avec lyse cellulaire en présence d'extrait de Saule noir###Extraction auqueuse",
                  "ElementID": 11437,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "Authorized",
                  "ElementID": 11437,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11437,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "WATER",
                  "ElementID": 11437,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "70",
                  "ElementID": 11437,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11437,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01AAU9136",
                  "ElementID": 11437,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11475": {
              "ID": 11475,
              "ExtID": "P_MP_01DIV7726",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 63,
              "Inf": 2.496,
              "Sup": 2.4960629921259843,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11475,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11475,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11475,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11475,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01DIV7726",
                  "ElementID": 11475,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Synthèse (hydroxyde de sodium)",
                  "ElementID": 11475,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11475,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11475,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11731
                  }],
                  "ElementID": 11475,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "SODIUM HYDROXIDE",
                  "ElementID": 11475,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11475,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11475,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12459
                  },
                    {
                      "Order": 2,
                      "ElementID": 12460
                    },
                    {
                      "Order": 3,
                      "ElementID": 12461
                    },
                    {
                      "Order": 4,
                      "ElementID": 12462
                    },
                    {
                      "Order": 5,
                      "ElementID": 12463
                    },
                    {
                      "Order": 6,
                      "ElementID": 12464
                    },
                    {
                      "Order": 7,
                      "ElementID": 12465
                    },
                    {
                      "Order": 8,
                      "ElementID": 12466
                    },
                    {
                      "Order": 9,
                      "ElementID": 12467
                    },
                    {
                      "Order": 10,
                      "ElementID": 12468
                    },
                    {
                      "Order": 11,
                      "ElementID": 12469
                    },
                    {
                      "Order": 12,
                      "ElementID": 12470
                    },
                    {
                      "Order": 13,
                      "ElementID": 12471
                    },
                    {
                      "Order": 14,
                      "ElementID": 12472
                    },
                    {
                      "Order": 15,
                      "ElementID": 12473
                    },
                    {
                      "Order": 16,
                      "ElementID": 12474
                    },
                    {
                      "Order": 17,
                      "ElementID": 12475
                    },
                    {
                      "Order": 18,
                      "ElementID": 12476
                    },
                    {
                      "Order": 19,
                      "ElementID": 12477
                    },
                    {
                      "Order": 20,
                      "ElementID": 12478
                    },
                    {
                      "Order": 21,
                      "ElementID": 12479
                    },
                    {
                      "Order": 22,
                      "ElementID": 12480
                    },
                    {
                      "Order": 23,
                      "ElementID": 12481
                    },
                    {
                      "Order": 24,
                      "ElementID": 12482
                    },
                    {
                      "Order": 25,
                      "ElementID": 12483
                    },
                    {
                      "Order": 26,
                      "ElementID": 12484
                    },
                    {
                      "Order": 27,
                      "ElementID": 12485
                    }],
                  "ElementID": 11475,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Synthesis",
                  "ElementID": 11475,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "Pas d'information sur le process###CONFIDENTIEL",
                  "ElementID": 11475,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "Authorized",
                  "ElementID": 11475,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11475,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Buffer & Chelatant",
                  "ElementID": 11475,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "70",
                  "ElementID": 11475,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11475,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV7726",
                  "ElementID": 11475,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11479": {
              "ID": 11479,
              "ExtID": "P_MP_01DIV8693",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 67,
              "Inf": 2.4962406015037595,
              "Sup": 2.4962962962962965,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11479,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11479,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11479,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11479,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01DIV8693",
                  "ElementID": 11479,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Minérale (sodium) / Biotechnologie (acide lactique) ###Minérale (eau)",
                  "ElementID": 11479,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11479,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11479,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 9514
                  },
                    {
                      "Order": 2,
                      "ElementID": 11732
                    }],
                  "ElementID": 11479,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "AQUA/WATER/EAU;SODIUM LACTATE",
                  "ElementID": 11479,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11479,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11479,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12492
                  },
                    {
                      "Order": 2,
                      "ElementID": 12493
                    },
                    {
                      "Order": 3,
                      "ElementID": 12494
                    },
                    {
                      "Order": 4,
                      "ElementID": 12495
                    },
                    {
                      "Order": 5,
                      "ElementID": 12496
                    },
                    {
                      "Order": 6,
                      "ElementID": 12497
                    },
                    {
                      "Order": 7,
                      "ElementID": 12498
                    },
                    {
                      "Order": 8,
                      "ElementID": 12499
                    },
                    {
                      "Order": 9,
                      "ElementID": 12500
                    },
                    {
                      "Order": 10,
                      "ElementID": 12501
                    }],
                  "ElementID": 11479,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Mineral;Vegetable / Biotechnological",
                  "ElementID": 11479,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "Flow chart : oui###Process : Fermentation de la canne à sucre pour obtenir l'acide lactique (cf process PURAC HIPURE 90 + document \"certificat d'origine\")###Pas d'informations sur la formation du sel de sodium",
                  "ElementID": 11479,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "Authorized",
                  "ElementID": 11479,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11479,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Active",
                  "ElementID": 11479,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "70",
                  "ElementID": 11479,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11479,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV8693",
                  "ElementID": 11479,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11480": {
              "ID": 11480,
              "ExtID": "P_MP_01DIV9513",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 68,
              "Inf": 2.4962962962962965,
              "Sup": 2.4963503649635035,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11480,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11480,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11480,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11480,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01DIV9513",
                  "ElementID": 11480,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Minérale (potassium) / Synthèse (phosphate)",
                  "ElementID": 11480,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11480,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11480,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11705
                  }],
                  "ElementID": 11480,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "POTASSIUM PHOSPHATE",
                  "ElementID": 11480,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11480,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11480,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12502
                  },
                    {
                      "Order": 2,
                      "ElementID": 12503
                    },
                    {
                      "Order": 3,
                      "ElementID": 12504
                    },
                    {
                      "Order": 4,
                      "ElementID": 12505
                    },
                    {
                      "Order": 5,
                      "ElementID": 12506
                    },
                    {
                      "Order": 6,
                      "ElementID": 12507
                    },
                    {
                      "Order": 7,
                      "ElementID": 12508
                    },
                    {
                      "Order": 8,
                      "ElementID": 12509
                    },
                    {
                      "Order": 9,
                      "ElementID": 12510
                    }],
                  "ElementID": 11480,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Mineral",
                  "ElementID": 11480,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "Flow chart : oui###Process : acide phosphorique + soude => réaction : cristallisation / séchage / conrôle / packing",
                  "ElementID": 11480,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "Authorized",
                  "ElementID": 11480,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11480,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Buffer & Chelatant",
                  "ElementID": 11480,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "70",
                  "ElementID": 11480,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11480,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV9513",
                  "ElementID": 11480,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11481": {
              "ID": 11481,
              "ExtID": "P_MP_01DIV9514",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 69,
              "Inf": 2.4963503649635035,
              "Sup": 2.4964028776978417,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11481,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11481,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11481,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11481,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01DIV9514",
                  "ElementID": 11481,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Minérale (disodium) / Synthèse (phosphate)",
                  "ElementID": 11481,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11481,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11481,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11582
                  }],
                  "ElementID": 11481,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "DISODIUM PHOSPHATE",
                  "ElementID": 11481,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11481,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11481,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12511
                  },
                    {
                      "Order": 2,
                      "ElementID": 12512
                    },
                    {
                      "Order": 3,
                      "ElementID": 12513
                    },
                    {
                      "Order": 4,
                      "ElementID": 12514
                    },
                    {
                      "Order": 5,
                      "ElementID": 12515
                    },
                    {
                      "Order": 6,
                      "ElementID": 12516
                    },
                    {
                      "Order": 7,
                      "ElementID": 12517
                    },
                    {
                      "Order": 8,
                      "ElementID": 12518
                    },
                    {
                      "Order": 9,
                      "ElementID": 12519
                    }],
                  "ElementID": 11481,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Mineral",
                  "ElementID": 11481,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "Flow chart : non###Process : Réaction à base de soude caustique et acide phosphorique###CONFIDENTIEL",
                  "ElementID": 11481,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "Authorized",
                  "ElementID": 11481,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11481,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Buffer & Chelatant",
                  "ElementID": 11481,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "70",
                  "ElementID": 11481,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11481,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV9514",
                  "ElementID": 11481,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11488": {
              "ID": 11488,
              "ExtID": "P_MP_01DIV9634",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 76,
              "Inf": 2.4966887417218544,
              "Sup": 2.4967320261437909,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11488,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11488,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11488,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11488,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01DIV9634",
                  "ElementID": 11488,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Minérale (eau)###Végétale (graine)",
                  "ElementID": 11488,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11488,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11488,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11607
                  },
                    {
                      "Order": 2,
                      "ElementID": 11613
                    }],
                  "ElementID": 11488,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "ALCOHOL;AQUA/WATER/EAU",
                  "ElementID": 11488,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11488,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11488,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12549
                  },
                    {
                      "Order": 2,
                      "ElementID": 12550
                    },
                    {
                      "Order": 3,
                      "ElementID": 12551
                    },
                    {
                      "Order": 4,
                      "ElementID": 12552
                    },
                    {
                      "Order": 5,
                      "ElementID": 12553
                    },
                    {
                      "Order": 6,
                      "ElementID": 12554
                    },
                    {
                      "Order": 7,
                      "ElementID": 12555
                    },
                    {
                      "Order": 8,
                      "ElementID": 12556
                    },
                    {
                      "Order": 9,
                      "ElementID": 12557
                    },
                    {
                      "Order": 10,
                      "ElementID": 12558
                    },
                    {
                      "Order": 11,
                      "ElementID": 12559
                    },
                    {
                      "Order": 12,
                      "ElementID": 12560
                    },
                    {
                      "Order": 13,
                      "ElementID": 12561
                    },
                    {
                      "Order": 14,
                      "ElementID": 12562
                    },
                    {
                      "Order": 15,
                      "ElementID": 12563
                    },
                    {
                      "Order": 16,
                      "ElementID": 12564
                    },
                    {
                      "Order": 17,
                      "ElementID": 12565
                    },
                    {
                      "Order": 18,
                      "ElementID": 12566
                    },
                    {
                      "Order": 19,
                      "ElementID": 12567
                    },
                    {
                      "Order": 20,
                      "ElementID": 12568
                    },
                    {
                      "Order": 21,
                      "ElementID": 12569
                    },
                    {
                      "Order": 22,
                      "ElementID": 12570
                    },
                    {
                      "Order": 23,
                      "ElementID": 12571
                    },
                    {
                      "Order": 24,
                      "ElementID": 12572
                    },
                    {
                      "Order": 25,
                      "ElementID": 12573
                    },
                    {
                      "Order": 26,
                      "ElementID": 12574
                    }],
                  "ElementID": 11488,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Mineral;Vegetable / Biotechnological",
                  "ElementID": 11488,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "Flow chart : oui###Process : Le Blé est broyé dans le moulin pour obtenir une farine. Celle-ci est soumise à l’étape d’empatage (ajout d’eau et d’enzymes) puis à une liquéfaction (ajout d’enzyme pour hydrolyser l’amidon).###La pâte ainsi obtenue est fermentée à l’aide de levures (Saccharomyces cerevisiae), de nutriments et d’auxiliaires technologiques pour obtenir un vin à 12°.###Ce vin est distillé (pour obtenir de l’alcool brut à 93°) puis rectifié pour obtenir un alcool éthylique surfin de Blé à 96°.",
                  "ElementID": 11488,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "Derogeable",
                  "ElementID": 11488,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11488,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Additive",
                  "ElementID": 11488,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "70",
                  "ElementID": 11488,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11488,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV9634",
                  "ElementID": 11488,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11491": {
              "ID": 11491,
              "ExtID": "P_MP_01DIV9640",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 79,
              "Inf": 2.4968152866242037,
              "Sup": 2.49685534591195,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "",
                  "ElementID": 11491,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "Blend of ingredients",
                  "ElementID": 11491,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11491,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11491,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "01DIV9640",
                  "ElementID": 11491,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "Végétale (plante entière)###Minérale (disodium) / Biotechnologie (adénosine triphosphate)###Minérale (eau)###Synthèse (carnosine)",
                  "ElementID": 11491,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11491,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11491,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11615
                  },
                    {
                      "Order": 2,
                      "ElementID": 11642
                    },
                    {
                      "Order": 3,
                      "ElementID": 11581
                    },
                    {
                      "Order": 4,
                      "ElementID": 11675
                    }],
                  "ElementID": 11491,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "AQUA/WATER/EAU;CARNOSINE;DISODIUM ADENOSINE TRIPHOSPHATE;LAMINARIA DIGITATA EXTRACT",
                  "ElementID": 11491,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11491,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11491,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12587
                  },
                    {
                      "Order": 2,
                      "ElementID": 12588
                    },
                    {
                      "Order": 3,
                      "ElementID": 12589
                    },
                    {
                      "Order": 4,
                      "ElementID": 12590
                    },
                    {
                      "Order": 5,
                      "ElementID": 12591
                    },
                    {
                      "Order": 6,
                      "ElementID": 12592
                    },
                    {
                      "Order": 7,
                      "ElementID": 12593
                    },
                    {
                      "Order": 8,
                      "ElementID": 12594
                    },
                    {
                      "Order": 9,
                      "ElementID": 12595
                    },
                    {
                      "Order": 10,
                      "ElementID": 12596
                    },
                    {
                      "Order": 11,
                      "ElementID": 12597
                    },
                    {
                      "Order": 12,
                      "ElementID": 12598
                    },
                    {
                      "Order": 13,
                      "ElementID": 12599
                    },
                    {
                      "Order": 14,
                      "ElementID": 12600
                    },
                    {
                      "Order": 15,
                      "ElementID": 12601
                    },
                    {
                      "Order": 16,
                      "ElementID": 12602
                    },
                    {
                      "Order": 17,
                      "ElementID": 12603
                    },
                    {
                      "Order": 18,
                      "ElementID": 12604
                    },
                    {
                      "Order": 19,
                      "ElementID": 12605
                    },
                    {
                      "Order": 20,
                      "ElementID": 12606
                    },
                    {
                      "Order": 21,
                      "ElementID": 12607
                    },
                    {
                      "Order": 22,
                      "ElementID": 12608
                    },
                    {
                      "Order": 23,
                      "ElementID": 12609
                    },
                    {
                      "Order": 24,
                      "ElementID": 12610
                    },
                    {
                      "Order": 25,
                      "ElementID": 12611
                    },
                    {
                      "Order": 26,
                      "ElementID": 12612
                    },
                    {
                      "Order": 27,
                      "ElementID": 12613
                    },
                    {
                      "Order": 28,
                      "ElementID": 12614
                    },
                    {
                      "Order": 29,
                      "ElementID": 12615
                    },
                    {
                      "Order": 30,
                      "ElementID": 12616
                    },
                    {
                      "Order": 31,
                      "ElementID": 12617
                    },
                    {
                      "Order": 32,
                      "ElementID": 12618
                    },
                    {
                      "Order": 33,
                      "ElementID": 12619
                    },
                    {
                      "Order": 34,
                      "ElementID": 12620
                    },
                    {
                      "Order": 35,
                      "ElementID": 12621
                    },
                    {
                      "Order": 36,
                      "ElementID": 12622
                    },
                    {
                      "Order": 37,
                      "ElementID": 12623
                    },
                    {
                      "Order": 38,
                      "ElementID": 12624
                    },
                    {
                      "Order": 39,
                      "ElementID": 12625
                    }],
                  "ElementID": 11491,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Vegetable and synthetic;Mineral;Synthetic;Vegetable",
                  "ElementID": 11491,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "MP en A11 sur AS400###Il s'agit d'un mélange de MP internes : DIV2585 / AOV8827 / AAU9097 / AAU9099",
                  "ElementID": 11491,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "Authorized",
                  "ElementID": 11491,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11491,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Active",
                  "ElementID": 11491,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "65",
                  "ElementID": 11491,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11491,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV9640",
                  "ElementID": 11491,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11501": {
              "ID": 11501,
              "ExtID": "P_MP_01HCG5577",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 89,
              "Inf": 2.4971751412429377,
              "Sup": 2.4972067039106145,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11501,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11501,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11501,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11501,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "",
                  "ElementID": 11501,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "",
                  "ElementID": 11501,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "FATTY ACIDS;Caprylic acid C8:0 (octanoic);UNSAPONIFIABLES;Capric acid C10:0 (decanoic)",
                  "ElementID": 11501,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11501,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11639
                  }],
                  "ElementID": 11501,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "CAPRYLIC/CAPRIC TRIGLYCERIDE",
                  "ElementID": 11501,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [],
                  "ElementID": 11501,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [],
                  "ElementID": 11501,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12713
                  },
                    {
                      "Order": 2,
                      "ElementID": 12714
                    },
                    {
                      "Order": 3,
                      "ElementID": 12715
                    },
                    {
                      "Order": 4,
                      "ElementID": 12716
                    },
                    {
                      "Order": 5,
                      "ElementID": 12717
                    },
                    {
                      "Order": 6,
                      "ElementID": 12718
                    },
                    {
                      "Order": 7,
                      "ElementID": 12719
                    },
                    {
                      "Order": 8,
                      "ElementID": 12720
                    },
                    {
                      "Order": 9,
                      "ElementID": 12721
                    },
                    {
                      "Order": 10,
                      "ElementID": 12722
                    },
                    {
                      "Order": 11,
                      "ElementID": 12723
                    },
                    {
                      "Order": 12,
                      "ElementID": 12724
                    },
                    {
                      "Order": 13,
                      "ElementID": 12725
                    }],
                  "ElementID": 11501,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Vegetable",
                  "ElementID": 11501,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "",
                  "ElementID": 11501,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "",
                  "ElementID": 11501,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11501,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Texturing agent",
                  "ElementID": 11501,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "45",
                  "ElementID": 11501,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11501,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01HCG5577",
                  "ElementID": 11501,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11504": {
              "ID": 11504,
              "ExtID": "P_MP_01HCG9281",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 92,
              "Inf": 2.4972677595628414,
              "Sup": 2.4972972972972971,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11504,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11504,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11504,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11504,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "",
                  "ElementID": 11504,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "",
                  "ElementID": 11504,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "Stearic acid C18:0 (octadecanoic);Arachidic acid  C20:0 (icosanoic);Palmitic acid C16:0 (hexadecanoic);Myristic acid C14:0 (tetradecoanoic);HYDROCARBONS",
                  "ElementID": 11504,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11504,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11555
                  }],
                  "ElementID": 11504,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "CETEARYL ALCOHOL",
                  "ElementID": 11504,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [],
                  "ElementID": 11504,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [],
                  "ElementID": 11504,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12733
                  },
                    {
                      "Order": 2,
                      "ElementID": 12734
                    },
                    {
                      "Order": 3,
                      "ElementID": 12735
                    },
                    {
                      "Order": 4,
                      "ElementID": 12736
                    },
                    {
                      "Order": 5,
                      "ElementID": 12737
                    },
                    {
                      "Order": 6,
                      "ElementID": 12738
                    }],
                  "ElementID": 11504,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Vegetable / chemical",
                  "ElementID": 11504,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "",
                  "ElementID": 11504,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "",
                  "ElementID": 11504,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11504,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Texturing agent",
                  "ElementID": 11504,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "45",
                  "ElementID": 11504,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11504,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01HCG9281",
                  "ElementID": 11504,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11505": {
              "ID": 11505,
              "ExtID": "P_MP_01HCG9306",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 93,
              "Inf": 2.4972972972972971,
              "Sup": 2.4973262032085564,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11505,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11505,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11505,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11505,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "",
                  "ElementID": 11505,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "",
                  "ElementID": 11505,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11505,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11505,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11650
                  }],
                  "ElementID": 11505,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "GLYCERYL STEARATE",
                  "ElementID": 11505,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [],
                  "ElementID": 11505,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [],
                  "ElementID": 11505,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12739
                  },
                    {
                      "Order": 2,
                      "ElementID": 12740
                    },
                    {
                      "Order": 3,
                      "ElementID": 12741
                    },
                    {
                      "Order": 4,
                      "ElementID": 12742
                    }],
                  "ElementID": 11505,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Vegetable",
                  "ElementID": 11505,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "",
                  "ElementID": 11505,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "",
                  "ElementID": 11505,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11505,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "TAC",
                  "ElementID": 11505,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "40",
                  "ElementID": 11505,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11505,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01HCG9306",
                  "ElementID": 11505,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11515": {
              "ID": 11515,
              "ExtID": "P_MP_01HCG9370",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 103,
              "Inf": 2.4975609756097561,
              "Sup": 2.4975845410628019,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11515,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11515,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11515,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11515,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "",
                  "ElementID": 11515,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "",
                  "ElementID": 11515,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11515,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11515,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11707
                  }],
                  "ElementID": 11515,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "PROPANEDIOL",
                  "ElementID": 11515,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [],
                  "ElementID": 11515,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [],
                  "ElementID": 11515,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12788
                  },
                    {
                      "Order": 2,
                      "ElementID": 12789
                    },
                    {
                      "Order": 3,
                      "ElementID": 12790
                    },
                    {
                      "Order": 4,
                      "ElementID": 12791
                    },
                    {
                      "Order": 5,
                      "ElementID": 12792
                    },
                    {
                      "Order": 6,
                      "ElementID": 12793
                    },
                    {
                      "Order": 7,
                      "ElementID": 12794
                    },
                    {
                      "Order": 8,
                      "ElementID": 12795
                    },
                    {
                      "Order": 9,
                      "ElementID": 12796
                    },
                    {
                      "Order": 10,
                      "ElementID": 12797
                    },
                    {
                      "Order": 11,
                      "ElementID": 12798
                    },
                    {
                      "Order": 12,
                      "ElementID": 12799
                    },
                    {
                      "Order": 13,
                      "ElementID": 12800
                    },
                    {
                      "Order": 14,
                      "ElementID": 12801
                    },
                    {
                      "Order": 15,
                      "ElementID": 12802
                    },
                    {
                      "Order": 16,
                      "ElementID": 12803
                    },
                    {
                      "Order": 17,
                      "ElementID": 12804
                    },
                    {
                      "Order": 18,
                      "ElementID": 12805
                    },
                    {
                      "Order": 19,
                      "ElementID": 12806
                    },
                    {
                      "Order": 20,
                      "ElementID": 12807
                    },
                    {
                      "Order": 21,
                      "ElementID": 12808
                    },
                    {
                      "Order": 22,
                      "ElementID": 12809
                    },
                    {
                      "Order": 23,
                      "ElementID": 12810
                    },
                    {
                      "Order": 24,
                      "ElementID": 12811
                    },
                    {
                      "Order": 25,
                      "ElementID": 12812
                    },
                    {
                      "Order": 26,
                      "ElementID": 12813
                    }],
                  "ElementID": 11515,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Biotechnological",
                  "ElementID": 11515,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "",
                  "ElementID": 11515,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "",
                  "ElementID": 11515,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11515,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "Additive",
                  "ElementID": 11515,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "40",
                  "ElementID": 11515,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11515,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01HCG9370",
                  "ElementID": 11515,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11542": {
              "ID": 11542,
              "ExtID": "P_MP_01TAC9595",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 11,
              "Ordre": 130,
              "Inf": 2.4980694980694982,
              "Sup": 2.4980842911877397,
              "Values": {
                "DC_ALLERGÈNE_DE_LA_MP": {
                  "Value": "Non",
                  "ElementID": 11542,
                  "DicoCaracID": 51,
                  "DicoCaracExtID": "DC_ALLERGÈNE_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_CHIMIQUE": {
                  "Value": "",
                  "ElementID": 11542,
                  "DicoCaracID": 60,
                  "DicoCaracExtID": "DC_CATEGORIE_CHIMIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CATEGORIE_SAP": {
                  "Value": "",
                  "ElementID": 11542,
                  "DicoCaracID": 61,
                  "DicoCaracExtID": "DC_CATEGORIE_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CERTIFICATION_MP": {
                  "Value": "Non",
                  "ElementID": 11542,
                  "DicoCaracID": 62,
                  "DicoCaracExtID": "DC_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_MP": {
                  "Value": "",
                  "ElementID": 11542,
                  "DicoCaracID": 72,
                  "DicoCaracExtID": "DC_CODE_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMPOSES_DE_DEPART": {
                  "Value": "",
                  "ElementID": 11542,
                  "DicoCaracID": 78,
                  "DicoCaracExtID": "DC_COMPOSES_DE_DEPART",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CONSTITUANTS": {
                  "Value": "",
                  "ElementID": 11542,
                  "DicoCaracID": 79,
                  "DicoCaracExtID": "DC_CONSTITUANTS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11542,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11738
                  }],
                  "ElementID": 11542,
                  "DicoCaracID": 128,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_INCI_CONCATENATION": {
                  "Value": "SODIUM STEAROYL GLUTAMATE",
                  "ElementID": 11542,
                  "DicoCaracID": 137,
                  "DicoCaracExtID": "DC_INGREDIENT_INCI_CONCATENATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE": {
                  "Values": [],
                  "ElementID": 11542,
                  "DicoCaracID": 149,
                  "DicoCaracExtID": "DC_MP_ENTRANT_DANS_UNE_FORMULE_ACTIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_MULTISOURCEE": {
                  "Values": [],
                  "ElementID": 11542,
                  "DicoCaracID": 150,
                  "DicoCaracExtID": "DC_MP_MULTISOURCEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12899
                  },
                    {
                      "Order": 2,
                      "ElementID": 12900
                    },
                    {
                      "Order": 3,
                      "ElementID": 12901
                    },
                    {
                      "Order": 4,
                      "ElementID": 12902
                    },
                    {
                      "Order": 5,
                      "ElementID": 12903
                    },
                    {
                      "Order": 6,
                      "ElementID": 12904
                    },
                    {
                      "Order": 7,
                      "ElementID": 12905
                    }],
                  "ElementID": 11542,
                  "DicoCaracID": 152,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINES_DECLAREES_PAR_LE_FRS": {
                  "Value": "Vegetable",
                  "ElementID": 11542,
                  "DicoCaracID": 167,
                  "DicoCaracExtID": "DC_ORIGINES_DECLAREES_PAR_LE_FRS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PROCEDE_DE_FABRICATION": {
                  "Value": "",
                  "ElementID": 11542,
                  "DicoCaracID": 180,
                  "DicoCaracExtID": "DC_PROCEDE_DE_FABRICATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_MP": {
                  "Value": "",
                  "ElementID": 11542,
                  "DicoCaracID": 194,
                  "DicoCaracExtID": "DC_STATUT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_CERTIFICATION_MP": {
                  "Value": "",
                  "ElementID": 11542,
                  "DicoCaracID": 200,
                  "DicoCaracExtID": "DC_TYPE_DE_CERTIFICATION_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE": {
                  "Value": "TAC",
                  "ElementID": 11542,
                  "DicoCaracID": 202,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT__MP__FONCTION_TECHNIQUE_INTERNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "40",
                  "ElementID": 11542,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11542,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01TAC9595",
                  "ElementID": 11542,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11555": {
              "ID": 11555,
              "ExtID": "DS_IngMP_CETEARYL_ALCOHOL_01HCG9281",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 3,
              "Inf": 1.4989384288747345,
              "Sup": 1.4989429175475688,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11555,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11555,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Mimétique, car ses actions sont similaires à celles des composants naturels de la peau",
                  "ElementID": 11555,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Mélange d'alcools gras cétylique et stéarylique / Cire d'alcools gras",
                  "ElementID": 11555,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse (réduction)",
                  "ElementID": 11555,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11555,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11555,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "EMOLLIENT / EMULSIFYING / EMULSION STABILISING / FOAM BOOSTING / OPACIFYING / SURFACTANT / VISCOSITY CONTROLLING",
                  "ElementID": 11555,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "VISCOSITY CONTROLLING",
                  "ElementID": 11555,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11799
                  }],
                  "ElementID": 11555,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11504
                  }],
                  "ElementID": 11555,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "GREASY SUBSTANCES & DERIVATIVES / ALCOHOL & DERIVATIVES",
                  "ElementID": 11555,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Fatty alcohol",
                  "ElementID": 11555,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Fatty alcohols",
                  "ElementID": 11555,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11555,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11555,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11555,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11555,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11555,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11555,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11968
                  }],
                  "ElementID": 11555,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-mimétique",
                  "ElementID": 11555,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11555,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11555,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11555,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11555,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CETEARYL ALCOHOL - 01HCG9281",
                  "ElementID": 11555,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11581": {
              "ID": 11581,
              "ExtID": "DS_IngMP_DISODIUM_ADENOSINE_TRIPHOSPHATE_01DIV9640",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 29,
              "Inf": 1.4990439770554493,
              "Sup": 1.499047619047619,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11581,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12932
                  }],
                  "ElementID": 11581,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 11581,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "L'ADENOSINE TRIPHOSPHATE est une petite molécule de l’énergie cellulaire (énergie cellulaire = énergie stockée sous forme chimique). Pour qu’une cellule se développe et vive, elle doit avoir un taux d’ATP optimal. L’ATP est à l’origine de très nombreuses ",
                  "ElementID": 11581,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse",
                  "ElementID": 11581,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11581,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11581,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "SKIN CONDITIONING",
                  "ElementID": 11581,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SKIN CONDITIONING",
                  "ElementID": 11581,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11819
                  }],
                  "ElementID": 11581,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11491
                  }],
                  "ElementID": 11581,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "SUGARS & DERIV / PHOSPHORE & DERIV / NUCLEIC BASE & DERIV / SALTS",
                  "ElementID": 11581,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Amino acid derivative (ATP)",
                  "ElementID": 11581,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Glycosylamins / Nucleoside / Phosphates",
                  "ElementID": 11581,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11581,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11581,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11581,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11581,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11581,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "0,00667",
                  "ElementID": 11581,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11945
                  }],
                  "ElementID": 11581,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 11581,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11581,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11581,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11581,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11581,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "DISODIUM ADENOSINE TRIPHOSPHATE - 01DIV9640",
                  "ElementID": 11581,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11582": {
              "ID": 11582,
              "ExtID": "DS_IngMP_DISODIUM_PHOSPHATE_01DIV9514",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 30,
              "Inf": 1.499047619047619,
              "Sup": 1.4990512333965844,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11582,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11582,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 11582,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Sel inorganique / Phosphate / Molécule pure",
                  "ElementID": 11582,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse",
                  "ElementID": 11582,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11582,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "Stabilise le pH",
                  "ElementID": 11582,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "ANTICORROSIVE / BUFFERING / MASKING",
                  "ElementID": 11582,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "BUFFERING / SKIN CONDITIONING (Chine)",
                  "ElementID": 11582,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11820
                  }],
                  "ElementID": 11582,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11481
                  }],
                  "ElementID": 11582,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "PHOSPHORUS & DERIVATIVES / SALTS",
                  "ElementID": 11582,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Salt of Sodium (phosphate)",
                  "ElementID": 11582,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Phosphates",
                  "ElementID": 11582,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11582,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11582,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11582,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11582,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11582,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11582,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11965
                  }],
                  "ElementID": 11582,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 11582,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11582,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "62",
                  "ElementID": 11582,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11582,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11582,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "DISODIUM PHOSPHATE - 01DIV9514",
                  "ElementID": 11582,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11585": {
              "ID": 11585,
              "ExtID": "DS_IngMP_ESCIN_01AAU2794",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 34,
              "Inf": 1.4990583804143127,
              "Sup": 1.4990619136960601,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12943
                  }],
                  "ElementID": 11585,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11585,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Non biodermique",
                  "ElementID": 11585,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Saponine issue du Marronnier d'Inde / Molécule pure ",
                  "ElementID": 11585,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Végétale (extraction)",
                  "ElementID": 11585,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11585,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "Drainant",
                  "ElementID": 11585,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "TONIC",
                  "ElementID": 11585,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "TONIC",
                  "ElementID": 11585,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11823
                  }],
                  "ElementID": 11585,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11422
                  }],
                  "ElementID": 11585,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "SUGARS & DERIVATIVES / EXTRACTS",
                  "ElementID": 11585,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Escin extract of horse chestnut",
                  "ElementID": 11585,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Glycosides / Extracts",
                  "ElementID": 11585,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11585,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11585,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11585,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11585,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11585,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11585,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11942
                  }],
                  "ElementID": 11585,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Non biodermique",
                  "ElementID": 11585,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11585,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "62",
                  "ElementID": 11585,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11585,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11585,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "ESCIN - 01AAU2794",
                  "ElementID": 11585,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11607": {
              "ID": 11607,
              "ExtID": "DS_IngMP_ALCOHOL_01DIV9634",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 57,
              "Inf": 1.4991304347826087,
              "Sup": 1.4991334488734835,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11607,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12939
                  }],
                  "ElementID": 11607,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Utile pour la peau tout en restant neutre et inoffensif, comme un écran solaire 100% minéral",
                  "ElementID": 11607,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 11607,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Biotechnologie",
                  "ElementID": 11607,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11607,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "Solubilisant",
                  "ElementID": 11607,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "ANTIFOAMING / ANTIMICROBIAL / ASTRINGENT / MASKING / SOLVENT / VISCOSITY CONTROLLING",
                  "ElementID": 11607,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SOLVENT",
                  "ElementID": 11607,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11767
                  }],
                  "ElementID": 11607,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11488
                  }],
                  "ElementID": 11607,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "ALCOHOL & DERIVATIVES",
                  "ElementID": 11607,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Wheat Alcohol BIO",
                  "ElementID": 11607,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Alcohols",
                  "ElementID": 11607,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11607,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11607,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11607,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11607,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11607,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "96",
                  "ElementID": 11607,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11958
                  },
                    {
                      "Order": 3,
                      "ElementID": 11965
                    }],
                  "ElementID": 11607,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-mécanique",
                  "ElementID": 11607,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11607,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11607,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11607,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11607,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "ALCOHOL - 01DIV9634",
                  "ElementID": 11607,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11613": {
              "ID": 11613,
              "ExtID": "DS_IngMP_AQUA/WATER/EAU_01DIV9634",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 77,
              "Inf": 1.4991482112436116,
              "Sup": 1.4991511035653651,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11613,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11613,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 11613,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 11613,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Minérale",
                  "ElementID": 11613,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11613,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "Solubilisant",
                  "ElementID": 11613,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "SOLVENT",
                  "ElementID": 11613,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SOLVENT",
                  "ElementID": 11613,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11773
                  }],
                  "ElementID": 11613,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11488
                  }],
                  "ElementID": 11613,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "WATER",
                  "ElementID": 11613,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Water",
                  "ElementID": 11613,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Water",
                  "ElementID": 11613,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11613,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11613,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11613,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11613,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11613,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "4",
                  "ElementID": 11613,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11965
                  }],
                  "ElementID": 11613,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 11613,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11613,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11613,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11613,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11613,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "AQUA/WATER/EAU - 01DIV9634",
                  "ElementID": 11613,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11615": {
              "ID": 11615,
              "ExtID": "DS_IngMP_AQUA/WATER/EAU_01DIV9640",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 81,
              "Inf": 1.4991539763113366,
              "Sup": 1.4991568296795952,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11615,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12932
                  }],
                  "ElementID": 11615,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 11615,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 11615,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Minérale",
                  "ElementID": 11615,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11615,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11615,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "SOLVENT",
                  "ElementID": 11615,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SOLVENT",
                  "ElementID": 11615,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11773
                  }],
                  "ElementID": 11615,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11491
                  }],
                  "ElementID": 11615,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "WATER",
                  "ElementID": 11615,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Constituent water of isodermic water",
                  "ElementID": 11615,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Isodermic water",
                  "ElementID": 11615,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11615,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11615,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11615,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11615,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11615,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "99,9368",
                  "ElementID": 11615,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11947
                  }],
                  "ElementID": 11615,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 11615,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11615,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "54",
                  "ElementID": 11615,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11615,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11615,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "AQUA/WATER/EAU - 01DIV9640",
                  "ElementID": 11615,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11639": {
              "ID": 11639,
              "ExtID": "DS_IngMP_CAPRYLIC/CAPRIC_TRIGLYCERIDE_01HCG5577",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 109,
              "Inf": 1.4992175273865416,
              "Sup": 1.499219968798752,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11639,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11639,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Assimilable par la peau et offrant aux cellules les nutriments dont elles ont besoin",
                  "ElementID": 11639,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Esters d'acides gras / Triester de glycérol et des acides caprylique et caprique",
                  "ElementID": 11639,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse (estérification)",
                  "ElementID": 11639,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11639,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11639,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "MASKING / PERFUMING / SKIN CONDITIONING ",
                  "ElementID": 11639,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SKIN CONDITIONING",
                  "ElementID": 11639,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11790
                  }],
                  "ElementID": 11639,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11501
                  }],
                  "ElementID": 11639,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "GREASY SUBSTANCE & DERIVATIVES",
                  "ElementID": 11639,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Complex lipids (triglyceride)",
                  "ElementID": 11639,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Triglycerides",
                  "ElementID": 11639,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11639,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11639,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11639,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11639,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11639,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11639,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11943
                  }],
                  "ElementID": 11639,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-biologique",
                  "ElementID": 11639,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11639,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11639,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11639,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11639,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CAPRYLIC/CAPRIC TRIGLYCERIDE - 01HCG5577",
                  "ElementID": 11639,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11640": {
              "ID": 11640,
              "ExtID": "DS_IngMP_CAPRYLOYL_GLYCINE_01AAU9115",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 110,
              "Inf": 1.499219968798752,
              "Sup": 1.4992223950233281,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11640,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11640,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Assimilable par la peau et offrant aux cellules les nutriments dont elles ont besoin",
                  "ElementID": 11640,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Acide aminé glycine greffée avec une chaine grasse caprylique",
                  "ElementID": 11640,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse (pétrochimique)",
                  "ElementID": 11640,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11640,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11640,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "CLEANSING / HAIR CONDITIONING / SURFACTANT",
                  "ElementID": 11640,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SURFACTANT / CLEANSING",
                  "ElementID": 11640,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11791
                  }],
                  "ElementID": 11640,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11435
                  }],
                  "ElementID": 11640,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "AMINO ACIDS & DERIVATIVES / GREASY SUBSTANCES & DERIVATIVES",
                  "ElementID": 11640,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Lipoaminoacid (glycine)",
                  "ElementID": 11640,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Lipoaminoacids",
                  "ElementID": 11640,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11640,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11640,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11640,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11640,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11640,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11640,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11933
                  },
                    {
                      "Order": 3,
                      "ElementID": 11935
                    },
                    {
                      "Order": 6,
                      "ElementID": 11940
                    },
                    {
                      "Order": 7,
                      "ElementID": 11941
                    },
                    {
                      "Order": 9,
                      "ElementID": 11944
                    },
                    {
                      "Order": 12,
                      "ElementID": 11962
                    }],
                  "ElementID": 11640,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-biologique",
                  "ElementID": 11640,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11640,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11640,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11640,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11640,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CAPRYLOYL GLYCINE - 01AAU9115",
                  "ElementID": 11640,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11642": {
              "ID": 11642,
              "ExtID": "DS_IngMP_CARNOSINE_01DIV9640",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 112,
              "Inf": 1.4992248062015503,
              "Sup": 1.4992272024729521,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11642,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12932
                  }],
                  "ElementID": 11642,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 11642,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Dipeptide constitué de deux acides aminés (alanine et histidine). Pureté > 99%",
                  "ElementID": 11642,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse",
                  "ElementID": 11642,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11642,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11642,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "SKIN CONDITIONING",
                  "ElementID": 11642,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SKIN CONDITIONING",
                  "ElementID": 11642,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11793
                  }],
                  "ElementID": 11642,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11491
                  }],
                  "ElementID": 11642,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "AMINO ACIDS & DERIVATIVES",
                  "ElementID": 11642,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Peptide",
                  "ElementID": 11642,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Peptides",
                  "ElementID": 11642,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11642,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11642,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11642,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11642,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11642,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "0,01333",
                  "ElementID": 11642,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11936
                  }],
                  "ElementID": 11642,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 11642,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11642,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11642,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11642,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11642,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CARNOSINE - 01DIV9640",
                  "ElementID": 11642,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11650": {
              "ID": 11650,
              "ExtID": "DS_IngMP_GLYCERYL_STEARATE_01HCG9306",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 120,
              "Inf": 1.4992435703479576,
              "Sup": 1.4992458521870287,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11650,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11650,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Mimétique, car ses actions sont similaires à celles des composants naturels de la peau",
                  "ElementID": 11650,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Ester de glycérine et d'acides stéariques",
                  "ElementID": 11650,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse (transestérification)",
                  "ElementID": 11650,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11650,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11650,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "EMOLLIENT / EMULSIFYING",
                  "ElementID": 11650,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "EMULSIFYING",
                  "ElementID": 11650,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11831
                  }],
                  "ElementID": 11650,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11505
                  }],
                  "ElementID": 11650,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "GREASY SUBSTANCE & DERIVATIVES",
                  "ElementID": 11650,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Polyol ester and fatty acid",
                  "ElementID": 11650,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Glycerolipids",
                  "ElementID": 11650,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11650,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11650,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11650,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11650,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11650,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11650,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11944
                  }],
                  "ElementID": 11650,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-mimétique",
                  "ElementID": 11650,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11650,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11650,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11650,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11650,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "GLYCERYL STEARATE - 01HCG9306",
                  "ElementID": 11650,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11675": {
              "ID": 11675,
              "ExtID": "DS_IngMP_LAMINARIA_DIGITATA_EXTRACT_01DIV9640",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 146,
              "Inf": 1.49929676511955,
              "Sup": 1.4992987377279103,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11675,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12932
                  }],
                  "ElementID": 11675,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 11675,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Extrait aqueux d'Algue brune Laminaria digitata",
                  "ElementID": 11675,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Végétale (Extraction)",
                  "ElementID": 11675,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11675,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11675,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "SKIN PROTECTING",
                  "ElementID": 11675,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SKIN PROTECTING",
                  "ElementID": 11675,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11851
                  }],
                  "ElementID": 11675,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11491
                  }],
                  "ElementID": 11675,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "EXTRACTS",
                  "ElementID": 11675,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Brown algae extract Laminaria",
                  "ElementID": 11675,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Extracts (plant, flower, root, lead, algae, phytoplancton,...)",
                  "ElementID": 11675,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11675,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11675,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11675,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11675,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11675,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "0,0432",
                  "ElementID": 11675,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11945
                  }],
                  "ElementID": 11675,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 11675,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11675,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11675,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11675,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11675,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "LAMINARIA DIGITATA EXTRACT - 01DIV9640",
                  "ElementID": 11675,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11680": {
              "ID": 11680,
              "ExtID": "DS_IngMP_LEUCONOSTOC/RADISH_ROOT_FERMENT_FILTRATE_01AAU9136",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 151,
              "Inf": 1.4993065187239945,
              "Sup": 1.4993084370677732,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11680,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11680,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Non biodermique",
                  "ElementID": 11680,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 11680,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Biotechnologie",
                  "ElementID": 11680,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11680,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11680,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "ANTIDRUFF / ANTIMICROBIAL",
                  "ElementID": 11680,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "ANTIMICROBIAL",
                  "ElementID": 11680,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11855
                  }],
                  "ElementID": 11680,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11437
                  }],
                  "ElementID": 11680,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "EXTRACTS",
                  "ElementID": 11680,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Black radish extract",
                  "ElementID": 11680,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Extracts (plant, flower, root, lead, algae, phytoplancton,...)",
                  "ElementID": 11680,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11680,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11680,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11680,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11680,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11680,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "50",
                  "ElementID": 11680,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11935
                  }],
                  "ElementID": 11680,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Non biodermique",
                  "ElementID": 11680,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11680,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "54",
                  "ElementID": 11680,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11680,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11680,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "LEUCONOSTOC/RADISH ROOT FERMENT FILTRATE - 01AAU9136",
                  "ElementID": 11680,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11681": {
              "ID": 11681,
              "ExtID": "DS_IngMP_LIMONENE_01AAU2794",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 152,
              "Inf": 1.4993084370677732,
              "Sup": 1.4993103448275862,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11681,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11681,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Non biodermique",
                  "ElementID": 11681,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Alcène",
                  "ElementID": 11681,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Végétale (extraction)",
                  "ElementID": 11681,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11681,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11681,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "DEODORANT / PERFUMING / SOLVENT",
                  "ElementID": 11681,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "PERFUMING",
                  "ElementID": 11681,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11856
                  }],
                  "ElementID": 11681,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11422
                  }],
                  "ElementID": 11681,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "ODORANTS COMPOUNDS",
                  "ElementID": 11681,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Odorant compound",
                  "ElementID": 11681,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Odorant compounds",
                  "ElementID": 11681,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11681,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11681,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11681,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11681,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11681,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "0,00001",
                  "ElementID": 11681,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11930
                  }],
                  "ElementID": 11681,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Non biodermique",
                  "ElementID": 11681,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 2
                  }],
                  "ElementID": 11681,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11681,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11681,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11681,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "LIMONENE - 01AAU2794",
                  "ElementID": 11681,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11705": {
              "ID": 11705,
              "ExtID": "DS_IngMP_POTASSIUM_PHOSPHATE_01DIV9513",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 178,
              "Inf": 1.4993514915693904,
              "Sup": 1.499353169469599,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11705,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11705,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 11705,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Sel inorganique / Molécule pure",
                  "ElementID": 11705,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse (pétrochimique)",
                  "ElementID": 11705,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11705,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "Stabilise le pH",
                  "ElementID": 11705,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "BUFFERING",
                  "ElementID": 11705,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "BUFFERING / SKIN CONDITIONING (Chine)",
                  "ElementID": 11705,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11878
                  }],
                  "ElementID": 11705,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11480
                  }],
                  "ElementID": 11705,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "PHOSPHORUS & DERIVATIVES / SALTS",
                  "ElementID": 11705,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Salt of potassium (phosphate)",
                  "ElementID": 11705,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Phosphates",
                  "ElementID": 11705,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11705,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11705,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11705,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11705,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11705,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11705,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11965
                  }],
                  "ElementID": 11705,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 11705,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11705,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "62",
                  "ElementID": 11705,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11705,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11705,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "POTASSIUM PHOSPHATE - 01DIV9513",
                  "ElementID": 11705,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11707": {
              "ID": 11707,
              "ExtID": "DS_IngMP_PROPANEDIOL_01HCG9370",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 180,
              "Inf": 1.4993548387096773,
              "Sup": 1.4993564993564994,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11707,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12939
                  }],
                  "ElementID": 11707,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Utile pour la peau tout en restant neutre et inoffensif, comme un écran solaire 100% minéral",
                  "ElementID": 11707,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 11707,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Biotechnologie",
                  "ElementID": 11707,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11707,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "Vecteur pro-pénétrant",
                  "ElementID": 11707,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "SOLVENT / VISCOSITY CONTROLLING",
                  "ElementID": 11707,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SOLVENT",
                  "ElementID": 11707,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11880
                  }],
                  "ElementID": 11707,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11515
                  }],
                  "ElementID": 11707,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "ALCOHOL & DERIVATIVES",
                  "ElementID": 11707,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Polyol",
                  "ElementID": 11707,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Glycols & derivatives",
                  "ElementID": 11707,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11707,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11707,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11707,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11707,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11707,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11707,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11949
                  },
                    {
                      "Order": 3,
                      "ElementID": 11965
                    }],
                  "ElementID": 11707,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-mécanique",
                  "ElementID": 11707,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11707,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11707,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11707,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11707,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "PROPANEDIOL - 01HCG9370",
                  "ElementID": 11707,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11713": {
              "ID": 11713,
              "ExtID": "DS_IngMP_SALIX_NIGRA_(WILLOW)_BARK_EXTRACT_01AAU9136",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 186,
              "Inf": 1.4993646759847523,
              "Sup": 1.4993662864385298,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11713,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11713,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Non biodermique",
                  "ElementID": 11713,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 11713,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Végétale (extraction)",
                  "ElementID": 11713,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11713,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11713,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "SKIN PROTECTING",
                  "ElementID": 11713,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "SKIN PROTECTING",
                  "ElementID": 11713,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11885
                  }],
                  "ElementID": 11713,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11437
                  }],
                  "ElementID": 11713,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "EXTRACTS",
                  "ElementID": 11713,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Black willow extract (bark)",
                  "ElementID": 11713,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Extracts (plant, flower, root, lead, algae, phytoplancton,...)",
                  "ElementID": 11713,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11713,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11713,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11713,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11713,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11713,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "20",
                  "ElementID": 11713,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11935
                  }],
                  "ElementID": 11713,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Non biodermique",
                  "ElementID": 11713,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11713,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "54",
                  "ElementID": 11713,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11713,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11713,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SALIX NIGRA (WILLOW) BARK EXTRACT - 01AAU9136",
                  "ElementID": 11713,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11731": {
              "ID": 11731,
              "ExtID": "DS_IngMP_SODIUM_HYDROXIDE_01DIV7726",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 209,
              "Inf": 1.4993924665856622,
              "Sup": 1.4993939393939395,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11731,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11731,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 11731,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Soude caustique",
                  "ElementID": 11731,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse",
                  "ElementID": 11731,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11731,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "Stabilise le pH",
                  "ElementID": 11731,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "BUFFERING / DENATURANT",
                  "ElementID": 11731,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "BUFFERING",
                  "ElementID": 11731,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11900
                  }],
                  "ElementID": 11731,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11475
                  }],
                  "ElementID": 11731,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "METAL DERIVATIVES",
                  "ElementID": 11731,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Salt of sodium (hydroxyde)",
                  "ElementID": 11731,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Sodium & derivatives",
                  "ElementID": 11731,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11731,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11731,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11731,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11731,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11731,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11731,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11965
                  }],
                  "ElementID": 11731,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 11731,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11731,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "62",
                  "ElementID": 11731,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11731,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11731,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SODIUM HYDROXIDE - 01DIV7726",
                  "ElementID": 11731,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11732": {
              "ID": 11732,
              "ExtID": "DS_IngMP_SODIUM_LACTATE_01DIV8693",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 210,
              "Inf": 1.4993939393939395,
              "Sup": 1.4993954050785974,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12939
                  }],
                  "ElementID": 11732,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11732,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 11732,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Molécule pure",
                  "ElementID": 11732,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse",
                  "ElementID": 11732,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11732,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11732,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "BUFFERING / HUMECTANT / KERATOLYTIC",
                  "ElementID": 11732,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "HUMECTANT",
                  "ElementID": 11732,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11901
                  }],
                  "ElementID": 11732,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11479
                  }],
                  "ElementID": 11732,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "ACIDS & DERIVATIVES / SALTS",
                  "ElementID": 11732,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Salt of lactic acid",
                  "ElementID": 11732,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Lactic acid & derivatives",
                  "ElementID": 11732,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11732,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11732,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11732,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11732,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11732,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "60",
                  "ElementID": 11732,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11953
                  }],
                  "ElementID": 11732,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 11732,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11732,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11732,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11732,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11732,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SODIUM LACTATE - 01DIV8693",
                  "ElementID": 11732,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11738": {
              "ID": 11738,
              "ExtID": "DS_IngMP_SODIUM_STEAROYL_GLUTAMATE_01TAC9595",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 216,
              "Inf": 1.4994026284348865,
              "Sup": 1.4994040524433849,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11738,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11738,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Mimétique, car ses actions sont similaires à celles des composants naturels de la peau",
                  "ElementID": 11738,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Ester d'acide glutamique et d'acide stéarique (sel)",
                  "ElementID": 11738,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse (estérification)",
                  "ElementID": 11738,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11738,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11738,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "CLEANSING / EMULSIFYING / HAIR CONDITIONING / SKIN CONDITIONING",
                  "ElementID": 11738,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "EMULSIFYING / CLEANSING",
                  "ElementID": 11738,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11907
                  }],
                  "ElementID": 11738,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11542
                  }],
                  "ElementID": 11738,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "AMINO ACIDS & DERIVATIVES / GREASY SUBSTANCES & DERIVATIVES / SALTS",
                  "ElementID": 11738,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Lipoaminoacid (glutamate)",
                  "ElementID": 11738,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Lipoaminoacids",
                  "ElementID": 11738,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11738,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11738,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11738,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11738,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11738,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11738,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11944
                  }],
                  "ElementID": 11738,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-mimétique",
                  "ElementID": 11738,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11738,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11738,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11738,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11738,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SODIUM STEAROYL GLUTAMATE - 01TAC9595",
                  "ElementID": 11738,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11757": {
              "ID": 11757,
              "ExtID": "DS_IngMP_UREA_01AAU8538",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 240,
              "Inf": 1.4994285714285713,
              "Sup": 1.499429874572406,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12939
                  }],
                  "ElementID": 11757,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11757,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Biologiquement identique aux composants de la peau",
                  "ElementID": 11757,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Carbamide",
                  "ElementID": 11757,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Synthèse (pétrochimique)",
                  "ElementID": 11757,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11757,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11757,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "ANTISTATIC / BUFFERING / HUMECTANT / SKIN CONDITIONING",
                  "ElementID": 11757,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "HUMECTANT",
                  "ElementID": 11757,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11919
                  }],
                  "ElementID": 11757,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11426
                  }],
                  "ElementID": 11757,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "AMINO & DERIVATIVES",
                  "ElementID": 11757,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Urea",
                  "ElementID": 11757,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Diamines",
                  "ElementID": 11757,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11757,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11757,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11757,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11757,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11757,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11757,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11950
                  },
                    {
                      "Order": 3,
                      "ElementID": 11953
                    }],
                  "ElementID": 11757,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-identique",
                  "ElementID": 11757,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11757,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11757,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11757,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11757,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "UREA - 01AAU8538",
                  "ElementID": 11757,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11759": {
              "ID": 11759,
              "ExtID": "DS_IngMP_XANTHAN_GUM_01GEL8707",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 4,
              "Ordre": 242,
              "Inf": 1.4994311717861206,
              "Sup": 1.4994324631101021,
              "Values": {
                "DC_BREVET_DEPUIS_ING-MP_FACULTATIF": {
                  "LinkedElements": [],
                  "ElementID": 11759,
                  "DicoCaracID": 57,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_FACULTATIF",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE": {
                  "LinkedElements": [],
                  "ElementID": 11759,
                  "DicoCaracID": 58,
                  "DicoCaracExtID": "DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "Utile pour la peau tout en restant neutre et inoffensif, comme un écran solaire 100% minéral",
                  "ElementID": 11759,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "Gomme moléculaire à haut poids hétéropolysaccharide produit par une fermentation en culture pure d'un hydrate de carbone avec Xanthomonas campestris.",
                  "ElementID": 11759,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE": {
                  "Value": "Biotechnologie",
                  "ElementID": 11759,
                  "DicoCaracID": 100,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 11759,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11759,
                  "DicoCaracID": 102,
                  "DicoCaracExtID": "DC_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_COSING": {
                  "Value": "BINDING / EMULSIFYING / EMULSION STABILISING / GEL FORMING / SKIN CONDITIONING / SURFACTANT / VISCOSITY CONTROLLING",
                  "ElementID": 11759,
                  "DicoCaracID": 113,
                  "DicoCaracExtID": "DC_FONCTION_COSING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FONCTION_DIP": {
                  "Value": "VISCOSITY CONTROLLING",
                  "ElementID": 11759,
                  "DicoCaracID": 114,
                  "DicoCaracExtID": "DC_FONCTION_DIP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11921
                  }],
                  "ElementID": 11759,
                  "DicoCaracID": 134,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_ING-MP": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11416
                  }],
                  "ElementID": 11759,
                  "DicoCaracID": 146,
                  "DicoCaracExtID": "DC_MP_DEPUIS_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_CATEGORIE": {
                  "Value": "SUGARS & DERIVATIVES",
                  "ElementID": 11759,
                  "DicoCaracID": 153,
                  "DicoCaracExtID": "DC_NATURE_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_GENCODE": {
                  "Value": "Sugars macrolecule",
                  "ElementID": 11759,
                  "DicoCaracID": 154,
                  "DicoCaracExtID": "DC_NATURE_GENCODE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NATURE_SOUS_CATEGORIE": {
                  "Value": "Polysaccharide",
                  "ElementID": 11759,
                  "DicoCaracID": 155,
                  "DicoCaracExtID": "DC_NATURE_SOUS_CATEGORIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 11759,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_FRANÇAIS": {
                  "Value": "",
                  "ElementID": 11759,
                  "DicoCaracID": 168,
                  "DicoCaracExtID": "DC_PLANTE__NOM_FRANÇAIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_LATIN": {
                  "Value": "",
                  "ElementID": 11759,
                  "DicoCaracID": 169,
                  "DicoCaracExtID": "DC_PLANTE__NOM_LATIN",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__NOM_PLANTE": {
                  "Value": "",
                  "ElementID": 11759,
                  "DicoCaracID": 170,
                  "DicoCaracExtID": "DC_PLANTE__NOM_PLANTE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__TYPE_DE_VEGETAL": {
                  "Value": "",
                  "ElementID": 11759,
                  "DicoCaracID": 173,
                  "DicoCaracExtID": "DC_PLANTE__TYPE_DE_VEGETAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_INGREDIENT_MP": {
                  "Value": "100",
                  "ElementID": 11759,
                  "DicoCaracID": 177,
                  "DicoCaracExtID": "DC_POURCENTAGE_INGREDIENT_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-MP": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 11948
                  }],
                  "ElementID": 11759,
                  "DicoCaracID": 185,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_BIODERMIQUE_INGREDIENT": {
                  "Value": "Dermo-mécanique",
                  "ElementID": 11759,
                  "DicoCaracID": 191,
                  "DicoCaracExtID": "DC_STATUT_BIODERMIQUE_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SUBSTANCE_ACTIVE": {
                  "Values": [{
                    "Order": 1,
                    "ValueID": 1
                  }],
                  "ElementID": 11759,
                  "DicoCaracID": 195,
                  "DicoCaracExtID": "DC_SUBSTANCE_ACTIVE",
                  "TypeValue": 2,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "58",
                  "ElementID": 11759,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 11759,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PLANTE__PARTIES_UTILISEES": {
                  "Value": "",
                  "ElementID": 11759,
                  "DicoCaracID": 220,
                  "DicoCaracExtID": "DC_PLANTE__PARTIES_UTILISEES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "XANTHAN GUM - 01GEL8707",
                  "ElementID": 11759,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11767": {
              "ID": 11767,
              "ExtID": "P_INCI_ALCOHOL",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 4,
              "Inf": 2.6363636363636362,
              "Sup": 2.6428571428571428,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11767,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11767,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11767,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11767,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11767,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 12987
                  },
                    {
                      "Order": 4,
                      "ElementID": 13007
                    }],
                  "ElementID": 11767,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 9495
                  },
                    {
                      "Order": 2,
                      "ElementID": 11606
                    },
                    {
                      "Order": 3,
                      "ElementID": 11607
                    }],
                  "ElementID": 11767,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11767,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "ALCOHOL",
                  "ElementID": 11767,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11767,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11767,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11767,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11767,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11767,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11767,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "ALCOHOL",
                  "ElementID": 11767,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11773": {
              "ID": 11773,
              "ExtID": "P_INCI_AQUA/WATER/EAU",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 10,
              "Inf": 2.6551724137931036,
              "Sup": 2.65625,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11773,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11773,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11773,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11773,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11773,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12988
                  },
                    {
                      "Order": 2,
                      "ElementID": 13008
                    }],
                  "ElementID": 11773,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11612
                  },
                    {
                      "Order": 2,
                      "ElementID": 9504
                    },
                    {
                      "Order": 3,
                      "ElementID": 9505
                    },
                    {
                      "Order": 4,
                      "ElementID": 9506
                    },
                    {
                      "Order": 5,
                      "ElementID": 9507
                    },
                    {
                      "Order": 6,
                      "ElementID": 9508
                    },
                    {
                      "Order": 7,
                      "ElementID": 9509
                    },
                    {
                      "Order": 8,
                      "ElementID": 9510
                    },
                    {
                      "Order": 9,
                      "ElementID": 9511
                    },
                    {
                      "Order": 10,
                      "ElementID": 9512
                    },
                    {
                      "Order": 11,
                      "ElementID": 9513
                    },
                    {
                      "Order": 12,
                      "ElementID": 9514
                    },
                    {
                      "Order": 13,
                      "ElementID": 9515
                    },
                    {
                      "Order": 14,
                      "ElementID": 9516
                    },
                    {
                      "Order": 15,
                      "ElementID": 11613
                    },
                    {
                      "Order": 16,
                      "ElementID": 11614
                    },
                    {
                      "Order": 17,
                      "ElementID": 11615
                    },
                    {
                      "Order": 18,
                      "ElementID": 9520
                    },
                    {
                      "Order": 19,
                      "ElementID": 9521
                    },
                    {
                      "Order": 20,
                      "ElementID": 11616
                    },
                    {
                      "Order": 21,
                      "ElementID": 9523
                    },
                    {
                      "Order": 22,
                      "ElementID": 9524
                    },
                    {
                      "Order": 23,
                      "ElementID": 11617
                    },
                    {
                      "Order": 24,
                      "ElementID": 9526
                    },
                    {
                      "Order": 25,
                      "ElementID": 9527
                    }],
                  "ElementID": 11773,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11773,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "AQUA/WATER/EAU",
                  "ElementID": 11773,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11773,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11773,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11773,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11773,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11773,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11773,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "AQUA/WATER/EAU",
                  "ElementID": 11773,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11790": {
              "ID": 11790,
              "ExtID": "P_INCI_CAPRYLIC/CAPRIC_TRIGLYCERIDE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 27,
              "Inf": 2.6625,
              "Sup": 2.6626506024096384,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11790,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11790,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11790,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11790,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11790,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 13009
                  }],
                  "ElementID": 11790,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11636
                  },
                    {
                      "Order": 2,
                      "ElementID": 11637
                    },
                    {
                      "Order": 3,
                      "ElementID": 11638
                    },
                    {
                      "Order": 4,
                      "ElementID": 11639
                    }],
                  "ElementID": 11790,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11790,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "CAPRYLIC/CAPRIC TRIGLYCERIDE",
                  "ElementID": 11790,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11790,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11790,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11790,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11790,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11790,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11790,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CAPRYLIC/CAPRIC TRIGLYCERIDE",
                  "ElementID": 11790,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11791": {
              "ID": 11791,
              "ExtID": "P_INCI_CAPRYLOYL_GLYCINE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 28,
              "Inf": 2.6626506024096384,
              "Sup": 2.6627906976744184,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11791,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11791,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11791,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11791,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11791,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 12991
                  },
                    {
                      "Order": 3,
                      "ElementID": 13010
                    }],
                  "ElementID": 11791,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11640
                  }],
                  "ElementID": 11791,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11791,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "CAPRYLOYL GLYCINE",
                  "ElementID": 11791,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11791,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11791,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11791,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11791,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11791,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11791,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CAPRYLOYL GLYCINE",
                  "ElementID": 11791,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11793": {
              "ID": 11793,
              "ExtID": "P_INCI_CARNOSINE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 30,
              "Inf": 2.6629213483146068,
              "Sup": 2.6630434782608696,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11793,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11793,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11793,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11793,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11793,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12992
                  },
                    {
                      "Order": 3,
                      "ElementID": 13011
                    }],
                  "ElementID": 11793,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11642
                  }],
                  "ElementID": 11793,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11793,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "CARNOSINE",
                  "ElementID": 11793,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11793,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11793,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11793,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11793,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11793,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11793,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CARNOSINE",
                  "ElementID": 11793,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11799": {
              "ID": 11799,
              "ExtID": "P_INCI_CETEARYL_ALCOHOL",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 36,
              "Inf": 2.6635514018691588,
              "Sup": 2.6636363636363636,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11799,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11799,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11799,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11799,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11799,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13012
                  }],
                  "ElementID": 11799,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11555
                  },
                    {
                      "Order": 2,
                      "ElementID": 11556
                    },
                    {
                      "Order": 3,
                      "ElementID": 11557
                    }],
                  "ElementID": 11799,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11799,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "CETEARYL ALCOHOL",
                  "ElementID": 11799,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11799,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11799,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11799,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11799,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11799,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11799,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CETEARYL ALCOHOL",
                  "ElementID": 11799,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11819": {
              "ID": 11819,
              "ExtID": "P_INCI_DISODIUM_ADENOSINE_TRIPHOSPHATE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 56,
              "Inf": 2.6646706586826348,
              "Sup": 2.664705882352941,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11819,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11819,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11819,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11819,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11819,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 12993
                  },
                    {
                      "Order": 4,
                      "ElementID": 13013
                    }],
                  "ElementID": 11819,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11581
                  }],
                  "ElementID": 11819,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11819,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "DISODIUM ADENOSINE TRIPHOSPHATE",
                  "ElementID": 11819,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11819,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11819,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11819,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11819,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11819,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11819,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "DISODIUM ADENOSINE TRIPHOSPHATE",
                  "ElementID": 11819,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11820": {
              "ID": 11820,
              "ExtID": "P_INCI_DISODIUM_PHOSPHATE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 57,
              "Inf": 2.664705882352941,
              "Sup": 2.6647398843930636,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11820,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11820,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11820,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11820,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11820,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13014
                  }],
                  "ElementID": 11820,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11582
                  }],
                  "ElementID": 11820,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11820,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "DISODIUM PHOSPHATE",
                  "ElementID": 11820,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11820,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11820,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11820,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11820,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11820,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11820,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "DISODIUM PHOSPHATE",
                  "ElementID": 11820,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11823": {
              "ID": 11823,
              "ExtID": "P_INCI_ESCIN",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 60,
              "Inf": 2.6648044692737431,
              "Sup": 2.6648351648351647,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11823,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11823,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11823,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11823,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11823,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 13015
                  }],
                  "ElementID": 11823,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11585
                  }],
                  "ElementID": 11823,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11823,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "ESCIN",
                  "ElementID": 11823,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11823,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11823,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11823,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11823,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11823,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11823,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "ESCIN",
                  "ElementID": 11823,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11831": {
              "ID": 11831,
              "ExtID": "P_INCI_GLYCERYL_STEARATE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 68,
              "Inf": 2.6650246305418719,
              "Sup": 2.6650485436893203,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11831,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11831,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11831,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11831,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11831,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13016
                  }],
                  "ElementID": 11831,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11650
                  }],
                  "ElementID": 11831,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11831,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "GLYCERYL STEARATE",
                  "ElementID": 11831,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11831,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11831,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11831,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11831,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11831,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11831,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "GLYCERYL STEARATE",
                  "ElementID": 11831,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11851": {
              "ID": 11851,
              "ExtID": "P_INCI_LAMINARIA_DIGITATA_EXTRACT",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 88,
              "Inf": 2.665399239543726,
              "Sup": 2.6654135338345863,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11851,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11851,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11851,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11851,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11851,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 12997
                  },
                    {
                      "Order": 4,
                      "ElementID": 13017
                    }],
                  "ElementID": 11851,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11675
                  }],
                  "ElementID": 11851,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11851,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "LAMINARIA DIGITATA EXTRACT",
                  "ElementID": 11851,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11851,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11851,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11851,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11851,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11851,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11851,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "LAMINARIA DIGITATA EXTRACT",
                  "ElementID": 11851,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11855": {
              "ID": 11855,
              "ExtID": "P_INCI_LEUCONOSTOC/RADISH_ROOT_FERMENT_FILTRATE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 92,
              "Inf": 2.6654545454545455,
              "Sup": 2.6654676258992804,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11855,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11855,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11855,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11855,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11855,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13018
                  }],
                  "ElementID": 11855,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11680
                  }],
                  "ElementID": 11855,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11855,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "LEUCONOSTOC/RADISH ROOT FERMENT FILTRATE",
                  "ElementID": 11855,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11855,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11855,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11855,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11855,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11855,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11855,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "LEUCONOSTOC/RADISH ROOT FERMENT FILTRATE",
                  "ElementID": 11855,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11856": {
              "ID": 11856,
              "ExtID": "P_INCI_LIMONENE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 93,
              "Inf": 2.6654676258992804,
              "Sup": 2.6654804270462633,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11856,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11856,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11856,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11856,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11856,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 13019
                  }],
                  "ElementID": 11856,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11681
                  }],
                  "ElementID": 11856,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11856,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "LIMONENE",
                  "ElementID": 11856,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11856,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11856,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11856,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11856,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11856,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11856,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "LIMONENE",
                  "ElementID": 11856,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11878": {
              "ID": 11878,
              "ExtID": "P_INCI_POTASSIUM_PHOSPHATE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 115,
              "Inf": 2.6656976744186047,
              "Sup": 2.6657060518731988,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11878,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11878,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11878,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11878,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11878,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13020
                  }],
                  "ElementID": 11878,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11705
                  }],
                  "ElementID": 11878,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11878,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "POTASSIUM PHOSPHATE",
                  "ElementID": 11878,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11878,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11878,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11878,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11878,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11878,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11878,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "POTASSIUM PHOSPHATE",
                  "ElementID": 11878,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11880": {
              "ID": 11880,
              "ExtID": "P_INCI_PROPANEDIOL",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 117,
              "Inf": 2.6657142857142859,
              "Sup": 2.6657223796033995,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11880,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11880,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11880,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11880,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11880,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 12999
                  },
                    {
                      "Order": 4,
                      "ElementID": 13021
                    }],
                  "ElementID": 11880,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11707
                  },
                    {
                      "Order": 2,
                      "ElementID": 11708
                    }],
                  "ElementID": 11880,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11880,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "PROPANEDIOL",
                  "ElementID": 11880,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11880,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11880,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11880,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11880,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11880,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11880,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "PROPANEDIOL",
                  "ElementID": 11880,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11885": {
              "ID": 11885,
              "ExtID": "P_INCI_SALIX_NIGRA_(WILLOW)_BARK_EXTRACT",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 122,
              "Inf": 2.6657534246575341,
              "Sup": 2.6657608695652173,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11885,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11885,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11885,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11885,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11885,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13022
                  }],
                  "ElementID": 11885,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11713
                  }],
                  "ElementID": 11885,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11885,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "SALIX NIGRA (WILLOW) BARK EXTRACT",
                  "ElementID": 11885,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11885,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11885,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11885,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11885,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11885,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11885,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SALIX NIGRA (WILLOW) BARK EXTRACT",
                  "ElementID": 11885,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11900": {
              "ID": 11900,
              "ExtID": "P_INCI_SODIUM_HYDROXIDE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 137,
              "Inf": 2.6658536585365855,
              "Sup": 2.665859564164649,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11900,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11900,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11900,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11900,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11900,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13002
                  },
                    {
                      "Order": 3,
                      "ElementID": 13023
                    }],
                  "ElementID": 11900,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11731
                  }],
                  "ElementID": 11900,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11900,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "SODIUM HYDROXIDE",
                  "ElementID": 11900,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11900,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11900,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11900,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11900,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11900,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11900,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SODIUM HYDROXIDE",
                  "ElementID": 11900,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11901": {
              "ID": 11901,
              "ExtID": "P_INCI_SODIUM_LACTATE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 138,
              "Inf": 2.665859564164649,
              "Sup": 2.6658653846153846,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11901,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11901,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11901,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11901,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11901,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13024
                  }],
                  "ElementID": 11901,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11732
                  }],
                  "ElementID": 11901,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11901,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "SODIUM LACTATE",
                  "ElementID": 11901,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11901,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11901,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11901,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11901,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11901,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11901,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SODIUM LACTATE",
                  "ElementID": 11901,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11907": {
              "ID": 11907,
              "ExtID": "P_INCI_SODIUM_STEAROYL_GLUTAMATE",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 144,
              "Inf": 2.6658932714617167,
              "Sup": 2.6658986175115209,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11907,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11907,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11907,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11907,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11907,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 13025
                  }],
                  "ElementID": 11907,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11738
                  }],
                  "ElementID": 11907,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11907,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "SODIUM STEAROYL GLUTAMATE",
                  "ElementID": 11907,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11907,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11907,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11907,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11907,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11907,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11907,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SODIUM STEAROYL GLUTAMATE",
                  "ElementID": 11907,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11919": {
              "ID": 11919,
              "ExtID": "P_INCI_UREA",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 156,
              "Inf": 2.6659528907922914,
              "Sup": 2.6659574468085108,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11919,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11919,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11919,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11919,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11919,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13026
                  }],
                  "ElementID": 11919,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11757
                  }],
                  "ElementID": 11919,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11919,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "UREA",
                  "ElementID": 11919,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11919,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11919,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11919,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11919,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11919,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11919,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "UREA",
                  "ElementID": 11919,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11921": {
              "ID": 11921,
              "ExtID": "P_INCI_XANTHAN_GUM",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12,
              "Ordre": 158,
              "Inf": 2.6659619450317127,
              "Sup": 2.6659663865546217,
              "Values": {
                "DC_ARGUMENTAIRE_INGREDIENTS_NAOS": {
                  "Value": "",
                  "ElementID": 11921,
                  "DicoCaracID": 52,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_INGREDIENTS_NAOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES": {
                  "Value": "",
                  "ElementID": 11921,
                  "DicoCaracID": 53,
                  "DicoCaracExtID": "DC_ARGUMENTAIRE_TECHNIQUE_SUBSTANCES_POLEMIQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE": {
                  "Value": "",
                  "ElementID": 11921,
                  "DicoCaracID": 85,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NATURE_DETAILLEE": {
                  "Value": "",
                  "ElementID": 11921,
                  "DicoCaracID": 86,
                  "DicoCaracExtID": "DC_COPYWRITING_NATURE_DETAILLEE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_SUBSTANCE_SENSIBLE": {
                  "Value": "",
                  "ElementID": 11921,
                  "DicoCaracID": 93,
                  "DicoCaracExtID": "DC_COPYWRITING_SUBSTANCE_SENSIBLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 13005
                  },
                    {
                      "Order": 4,
                      "ElementID": 13027
                    }],
                  "ElementID": 11921,
                  "DicoCaracID": 123,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_INGREDIENT": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11759
                  },
                    {
                      "Order": 2,
                      "ElementID": 11760
                    }],
                  "ElementID": 11921,
                  "DicoCaracID": 127,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_ALLERGISANT": {
                  "Values": [],
                  "ElementID": 11921,
                  "DicoCaracID": 132,
                  "DicoCaracExtID": "DC_INGREDIENT_ALLERGISANT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP": {
                  "Value": "XANTHAN GUM",
                  "ElementID": 11921,
                  "DicoCaracID": 157,
                  "DicoCaracExtID": "DC_NOM_INCI_DE_CHAQUE_INGREDIENT_DE_LA_MP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11921,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 11921,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROV_DEPUIS_INGREDIENT": {
                  "LinkedElements": [],
                  "ElementID": 11921,
                  "DicoCaracID": 187,
                  "DicoCaracExtID": "DC_ROV_DEPUIS_INGREDIENT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 11921,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 11921,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "20",
                  "ElementID": 11921,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "XANTHAN GUM",
                  "ElementID": 11921,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11930": {
              "ID": 11930,
              "ExtID": "P_RG_Allergen",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 2,
              "Inf": 2.7142857142857144,
              "Sup": 2.7272727272727271,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11930,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11930,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11930,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11930,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11930,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11681
                  }],
                  "ElementID": 11930,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11930,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11930,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11930,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Allergen",
                  "ElementID": 11930,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11933": {
              "ID": 11933,
              "ExtID": "P_RG_Anti-dandruff agent",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 5,
              "Inf": 2.736842105263158,
              "Sup": 2.7391304347826089,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11933,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11933,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11933,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11933,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11933,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11756
                  },
                    {
                      "Order": 1,
                      "ElementID": 11640
                    }],
                  "ElementID": 11933,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11933,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11933,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11933,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Anti-dandruff agent",
                  "ElementID": 11933,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11935": {
              "ID": 11935,
              "ExtID": "P_RG_Anti-microbial",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 7,
              "Inf": 2.7482993197278911,
              "Sup": 2.7483443708609272,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11935,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11935,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11935,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11935,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11935,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11734
                  },
                    {
                      "Order": 1,
                      "ElementID": 11680
                    },
                    {
                      "Order": 1,
                      "ElementID": 11713
                    },
                    {
                      "Order": 1,
                      "ElementID": 11722
                    },
                    {
                      "Order": 1,
                      "ElementID": 11762
                    },
                    {
                      "Order": 1,
                      "ElementID": 11640
                    },
                    {
                      "Order": 1,
                      "ElementID": 11756
                    }],
                  "ElementID": 11935,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11935,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11935,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11935,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Anti-microbial",
                  "ElementID": 11935,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11936": {
              "ID": 11936,
              "ExtID": "P_RG_Antioxydant",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 8,
              "Inf": 2.7483443708609272,
              "Sup": 2.7483870967741937,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11936,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11936,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11936,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11936,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11936,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 9671
                  },
                    {
                      "Order": 1,
                      "ElementID": 11620
                    },
                    {
                      "Order": 1,
                      "ElementID": 11642
                    },
                    {
                      "Order": 1,
                      "ElementID": 11694
                    },
                    {
                      "Order": 1,
                      "ElementID": 11748
                    },
                    {
                      "Order": 1,
                      "ElementID": 11750
                    },
                    {
                      "Order": 1,
                      "ElementID": 11752
                    },
                    {
                      "Order": 1,
                      "ElementID": 9668
                    },
                    {
                      "Order": 1,
                      "ElementID": 9674
                    },
                    {
                      "Order": 1,
                      "ElementID": 11747
                    },
                    {
                      "Order": 1,
                      "ElementID": 11749
                    },
                    {
                      "Order": 1,
                      "ElementID": 11751
                    },
                    {
                      "Order": 1,
                      "ElementID": 11753
                    },
                    {
                      "Order": 1,
                      "ElementID": 11758
                    },
                    {
                      "Order": 1,
                      "ElementID": 11611
                    }],
                  "ElementID": 11936,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11936,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11936,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11936,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Antioxydant",
                  "ElementID": 11936,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11940": {
              "ID": 11940,
              "ExtID": "P_RG_Cleansing",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 12,
              "Inf": 2.7485029940119761,
              "Sup": 2.7485380116959064,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11940,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11940,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11940,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11940,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11940,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11566
                  },
                    {
                      "Order": 1,
                      "ElementID": 11727
                    },
                    {
                      "Order": 1,
                      "ElementID": 11569
                    },
                    {
                      "Order": 1,
                      "ElementID": 11610
                    },
                    {
                      "Order": 1,
                      "ElementID": 11641
                    },
                    {
                      "Order": 1,
                      "ElementID": 11726
                    },
                    {
                      "Order": 1,
                      "ElementID": 11733
                    },
                    {
                      "Order": 1,
                      "ElementID": 11640
                    }],
                  "ElementID": 11940,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11940,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11940,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11940,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Cleansing",
                  "ElementID": 11940,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11941": {
              "ID": 11941,
              "ExtID": "P_RG_Deodorant",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 13,
              "Inf": 2.7479674796747968,
              "Sup": 2.7480314960629921,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11941,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11941,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11941,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11941,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11941,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11640
                  }],
                  "ElementID": 11941,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11941,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11941,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11941,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Deodorant",
                  "ElementID": 11941,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11942": {
              "ID": 11942,
              "ExtID": "P_RG_Draining",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 14,
              "Inf": 2.7480314960629921,
              "Sup": 2.7480916030534353,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11942,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11942,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11942,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11942,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11942,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11585
                  },
                    {
                      "Order": 1,
                      "ElementID": 11711
                    }],
                  "ElementID": 11942,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11942,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11942,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11942,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Draining",
                  "ElementID": 11942,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11943": {
              "ID": 11943,
              "ExtID": "P_RG_Emollient",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 15,
              "Inf": 2.7480916030534353,
              "Sup": 2.748148148148148,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11943,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11943,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11943,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11943,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11943,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11560
                  },
                    {
                      "Order": 1,
                      "ElementID": 11568
                    },
                    {
                      "Order": 1,
                      "ElementID": 11571
                    },
                    {
                      "Order": 1,
                      "ElementID": 11579
                    },
                    {
                      "Order": 1,
                      "ElementID": 11600
                    },
                    {
                      "Order": 1,
                      "ElementID": 11602
                    },
                    {
                      "Order": 1,
                      "ElementID": 11639
                    },
                    {
                      "Order": 1,
                      "ElementID": 11652
                    },
                    {
                      "Order": 1,
                      "ElementID": 11654
                    },
                    {
                      "Order": 1,
                      "ElementID": 11658
                    },
                    {
                      "Order": 1,
                      "ElementID": 11691
                    },
                    {
                      "Order": 1,
                      "ElementID": 9570
                    },
                    {
                      "Order": 1,
                      "ElementID": 11567
                    },
                    {
                      "Order": 1,
                      "ElementID": 11578
                    },
                    {
                      "Order": 1,
                      "ElementID": 11599
                    },
                    {
                      "Order": 1,
                      "ElementID": 11601
                    },
                    {
                      "Order": 1,
                      "ElementID": 11636
                    },
                    {
                      "Order": 1,
                      "ElementID": 11653
                    },
                    {
                      "Order": 1,
                      "ElementID": 11655
                    },
                    {
                      "Order": 1,
                      "ElementID": 11659
                    },
                    {
                      "Order": 1,
                      "ElementID": 11661
                    },
                    {
                      "Order": 1,
                      "ElementID": 11669
                    },
                    {
                      "Order": 1,
                      "ElementID": 11670
                    },
                    {
                      "Order": 1,
                      "ElementID": 11671
                    },
                    {
                      "Order": 1,
                      "ElementID": 11683
                    },
                    {
                      "Order": 1,
                      "ElementID": 11690
                    },
                    {
                      "Order": 1,
                      "ElementID": 11692
                    },
                    {
                      "Order": 1,
                      "ElementID": 11693
                    },
                    {
                      "Order": 1,
                      "ElementID": 11697
                    },
                    {
                      "Order": 1,
                      "ElementID": 11720
                    },
                    {
                      "Order": 1,
                      "ElementID": 11721
                    },
                    {
                      "Order": 1,
                      "ElementID": 11694
                    }],
                  "ElementID": 11943,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11943,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11943,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11943,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Emollient",
                  "ElementID": 11943,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11944": {
              "ID": 11944,
              "ExtID": "P_RG_Emulsifier",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 16,
              "Inf": 2.748148148148148,
              "Sup": 2.7482014388489207,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11944,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11944,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11944,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11944,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11944,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11633
                  },
                    {
                      "Order": 1,
                      "ElementID": 11651
                    },
                    {
                      "Order": 1,
                      "ElementID": 11679
                    },
                    {
                      "Order": 1,
                      "ElementID": 11701
                    },
                    {
                      "Order": 1,
                      "ElementID": 11703
                    },
                    {
                      "Order": 1,
                      "ElementID": 11739
                    },
                    {
                      "Order": 1,
                      "ElementID": 11742
                    },
                    {
                      "Order": 1,
                      "ElementID": 11558
                    },
                    {
                      "Order": 1,
                      "ElementID": 11565
                    },
                    {
                      "Order": 1,
                      "ElementID": 11570
                    },
                    {
                      "Order": 1,
                      "ElementID": 11619
                    },
                    {
                      "Order": 1,
                      "ElementID": 11632
                    },
                    {
                      "Order": 1,
                      "ElementID": 11650
                    },
                    {
                      "Order": 1,
                      "ElementID": 11678
                    },
                    {
                      "Order": 1,
                      "ElementID": 11695
                    },
                    {
                      "Order": 1,
                      "ElementID": 11700
                    },
                    {
                      "Order": 1,
                      "ElementID": 11702
                    },
                    {
                      "Order": 1,
                      "ElementID": 11704
                    },
                    {
                      "Order": 1,
                      "ElementID": 11738
                    },
                    {
                      "Order": 1,
                      "ElementID": 11741
                    },
                    {
                      "Order": 1,
                      "ElementID": 11743
                    },
                    {
                      "Order": 1,
                      "ElementID": 11569
                    },
                    {
                      "Order": 1,
                      "ElementID": 11640
                    }],
                  "ElementID": 11944,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11944,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11944,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11944,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Emulsifier",
                  "ElementID": 11944,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11945": {
              "ID": 11945,
              "ExtID": "P_RG_Energizing",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 17,
              "Inf": 2.7482014388489207,
              "Sup": 2.7482517482517483,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11945,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11945,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11945,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11945,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11945,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11581
                  },
                    {
                      "Order": 1,
                      "ElementID": 11675
                    }],
                  "ElementID": 11945,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11945,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11945,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11945,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Energizing",
                  "ElementID": 11945,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11947": {
              "ID": 11947,
              "ExtID": "P_RG_Formulating water",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 19,
              "Inf": 2.7474747474747474,
              "Sup": 2.7475728155339807,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11947,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11947,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11947,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11947,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11947,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11615
                  }],
                  "ElementID": 11947,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11947,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11947,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11947,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Formulating water",
                  "ElementID": 11947,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11948": {
              "ID": 11948,
              "ExtID": "P_RG_Gellant",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 20,
              "Inf": 2.7475728155339807,
              "Sup": 2.7476635514018692,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11948,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11948,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11948,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11948,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11948,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11576
                  },
                    {
                      "Order": 1,
                      "ElementID": 11646
                    },
                    {
                      "Order": 1,
                      "ElementID": 11648
                    },
                    {
                      "Order": 1,
                      "ElementID": 11686
                    },
                    {
                      "Order": 1,
                      "ElementID": 11760
                    },
                    {
                      "Order": 1,
                      "ElementID": 11575
                    },
                    {
                      "Order": 1,
                      "ElementID": 11577
                    },
                    {
                      "Order": 1,
                      "ElementID": 11643
                    },
                    {
                      "Order": 1,
                      "ElementID": 11645
                    },
                    {
                      "Order": 1,
                      "ElementID": 11647
                    },
                    {
                      "Order": 1,
                      "ElementID": 11685
                    },
                    {
                      "Order": 1,
                      "ElementID": 11687
                    },
                    {
                      "Order": 1,
                      "ElementID": 11714
                    },
                    {
                      "Order": 1,
                      "ElementID": 11759
                    }],
                  "ElementID": 11948,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11948,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11948,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11948,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Gellant",
                  "ElementID": 11948,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11949": {
              "ID": 11949,
              "ExtID": "P_RG_Humectant",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 21,
              "Inf": 2.7476635514018692,
              "Sup": 2.7477477477477477,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11949,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11949,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11949,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11949,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11949,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11591
                  },
                    {
                      "Order": 1,
                      "ElementID": 11593
                    },
                    {
                      "Order": 1,
                      "ElementID": 11698
                    },
                    {
                      "Order": 1,
                      "ElementID": 11592
                    },
                    {
                      "Order": 1,
                      "ElementID": 11594
                    },
                    {
                      "Order": 1,
                      "ElementID": 11699
                    },
                    {
                      "Order": 1,
                      "ElementID": 11707
                    }],
                  "ElementID": 11949,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11949,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11949,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11949,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Humectant",
                  "ElementID": 11949,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11950": {
              "ID": 11950,
              "ExtID": "P_RG_Keratolytic",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 22,
              "Inf": 2.7477477477477477,
              "Sup": 2.7478260869565219,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11950,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11950,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11950,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11950,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11950,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11562
                  },
                    {
                      "Order": 1,
                      "ElementID": 11673
                    },
                    {
                      "Order": 1,
                      "ElementID": 11674
                    },
                    {
                      "Order": 1,
                      "ElementID": 11712
                    },
                    {
                      "Order": 1,
                      "ElementID": 11757
                    }],
                  "ElementID": 11950,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11950,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11950,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11950,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Keratolytic",
                  "ElementID": 11950,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11953": {
              "ID": 11953,
              "ExtID": "P_RG_Moisturizing",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 25,
              "Inf": 2.7466666666666666,
              "Sup": 2.7468354430379747,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11953,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11953,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11953,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11953,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11953,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11595
                  },
                    {
                      "Order": 1,
                      "ElementID": 11627
                    },
                    {
                      "Order": 1,
                      "ElementID": 11696
                    },
                    {
                      "Order": 1,
                      "ElementID": 11729
                    },
                    {
                      "Order": 1,
                      "ElementID": 11732
                    },
                    {
                      "Order": 1,
                      "ElementID": 11596
                    },
                    {
                      "Order": 1,
                      "ElementID": 11609
                    },
                    {
                      "Order": 1,
                      "ElementID": 11730
                    },
                    {
                      "Order": 1,
                      "ElementID": 11735
                    },
                    {
                      "Order": 1,
                      "ElementID": 11761
                    },
                    {
                      "Order": 1,
                      "ElementID": 11757
                    },
                    {
                      "Order": 1,
                      "ElementID": 11662
                    }],
                  "ElementID": 11953,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11953,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11953,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11953,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Moisturizing",
                  "ElementID": 11953,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11958": {
              "ID": 11958,
              "ExtID": "P_RG_Refreshing",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 30,
              "Inf": 2.7473684210526317,
              "Sup": 2.7474747474747474,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11958,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11958,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11958,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11958,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11958,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11607
                  }],
                  "ElementID": 11958,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11958,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11958,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11958,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Refreshing",
                  "ElementID": 11958,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11962": {
              "ID": 11962,
              "ExtID": "P_RG_Sebum regulating",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 34,
              "Inf": 2.746031746031746,
              "Sup": 2.7462686567164178,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11962,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11962,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11962,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11962,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11962,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11709
                  },
                    {
                      "Order": 1,
                      "ElementID": 11762
                    },
                    {
                      "Order": 1,
                      "ElementID": 11640
                    }],
                  "ElementID": 11962,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11962,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11962,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11962,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Sebum regulating",
                  "ElementID": 11962,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11965": {
              "ID": 11965,
              "ExtID": "P_RG_Stabilizing",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 37,
              "Inf": 2.7407407407407409,
              "Sup": 2.7419354838709675,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11965,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11965,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11965,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11965,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11965,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 9495
                  },
                    {
                      "Order": 1,
                      "ElementID": 9504
                    },
                    {
                      "Order": 1,
                      "ElementID": 9506
                    },
                    {
                      "Order": 1,
                      "ElementID": 9508
                    },
                    {
                      "Order": 1,
                      "ElementID": 9510
                    },
                    {
                      "Order": 1,
                      "ElementID": 9512
                    },
                    {
                      "Order": 1,
                      "ElementID": 9514
                    },
                    {
                      "Order": 1,
                      "ElementID": 9516
                    },
                    {
                      "Order": 1,
                      "ElementID": 9521
                    },
                    {
                      "Order": 1,
                      "ElementID": 9524
                    },
                    {
                      "Order": 1,
                      "ElementID": 9527
                    },
                    {
                      "Order": 1,
                      "ElementID": 11561
                    },
                    {
                      "Order": 1,
                      "ElementID": 11572
                    },
                    {
                      "Order": 1,
                      "ElementID": 11582
                    },
                    {
                      "Order": 1,
                      "ElementID": 11612
                    },
                    {
                      "Order": 1,
                      "ElementID": 11614
                    },
                    {
                      "Order": 1,
                      "ElementID": 11616
                    },
                    {
                      "Order": 1,
                      "ElementID": 11663
                    },
                    {
                      "Order": 1,
                      "ElementID": 11705
                    },
                    {
                      "Order": 1,
                      "ElementID": 11724
                    },
                    {
                      "Order": 1,
                      "ElementID": 11725
                    },
                    {
                      "Order": 1,
                      "ElementID": 11731
                    },
                    {
                      "Order": 1,
                      "ElementID": 11736
                    },
                    {
                      "Order": 1,
                      "ElementID": 9472
                    },
                    {
                      "Order": 1,
                      "ElementID": 9505
                    },
                    {
                      "Order": 1,
                      "ElementID": 9507
                    },
                    {
                      "Order": 1,
                      "ElementID": 9509
                    },
                    {
                      "Order": 1,
                      "ElementID": 9511
                    },
                    {
                      "Order": 1,
                      "ElementID": 9513
                    },
                    {
                      "Order": 1,
                      "ElementID": 9515
                    },
                    {
                      "Order": 1,
                      "ElementID": 9520
                    },
                    {
                      "Order": 1,
                      "ElementID": 9523
                    },
                    {
                      "Order": 1,
                      "ElementID": 9526
                    },
                    {
                      "Order": 1,
                      "ElementID": 11563
                    },
                    {
                      "Order": 1,
                      "ElementID": 11606
                    },
                    {
                      "Order": 1,
                      "ElementID": 11613
                    },
                    {
                      "Order": 1,
                      "ElementID": 11617
                    },
                    {
                      "Order": 1,
                      "ElementID": 11708
                    },
                    {
                      "Order": 1,
                      "ElementID": 11562
                    },
                    {
                      "Order": 1,
                      "ElementID": 11607
                    },
                    {
                      "Order": 1,
                      "ElementID": 11673
                    },
                    {
                      "Order": 1,
                      "ElementID": 11674
                    },
                    {
                      "Order": 1,
                      "ElementID": 11707
                    },
                    {
                      "Order": 1,
                      "ElementID": 11661
                    }],
                  "ElementID": 11965,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11965,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11965,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11965,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Stabilizing",
                  "ElementID": 11965,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "11968": {
              "ID": 11968,
              "ExtID": "P_RG_Thickener",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 40,
              "Inf": 2.7435897435897436,
              "Sup": 2.7441860465116279,
              "Values": {
                "DC_COPYWRITING_DETAIL_ROLE": {
                  "Value": "",
                  "ElementID": 11968,
                  "DicoCaracID": 82,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ROLE": {
                  "Value": "",
                  "ElementID": 11968,
                  "DicoCaracID": 90,
                  "DicoCaracExtID": "DC_COPYWRITING_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FAMILLE_": {
                  "Value": "",
                  "ElementID": 11968,
                  "DicoCaracID": 104,
                  "DicoCaracExtID": "DC_FAMILLE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ROLE_ORDONNE": {
                  "LinkedElements": [],
                  "ElementID": 11968,
                  "DicoCaracID": 109,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ROLE_ORDONNE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_ROLE": {
                  "LinkedElements": [],
                  "ElementID": 11968,
                  "DicoCaracID": 126,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-MP_DEPUIS_ROLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11555
                  },
                    {
                      "Order": 1,
                      "ElementID": 11557
                    },
                    {
                      "Order": 1,
                      "ElementID": 11597
                    },
                    {
                      "Order": 1,
                      "ElementID": 11618
                    },
                    {
                      "Order": 1,
                      "ElementID": 11623
                    },
                    {
                      "Order": 1,
                      "ElementID": 11630
                    },
                    {
                      "Order": 1,
                      "ElementID": 11677
                    },
                    {
                      "Order": 1,
                      "ElementID": 11719
                    },
                    {
                      "Order": 1,
                      "ElementID": 11754
                    },
                    {
                      "Order": 1,
                      "ElementID": 11556
                    },
                    {
                      "Order": 1,
                      "ElementID": 11559
                    },
                    {
                      "Order": 1,
                      "ElementID": 11598
                    },
                    {
                      "Order": 1,
                      "ElementID": 11624
                    },
                    {
                      "Order": 1,
                      "ElementID": 11631
                    },
                    {
                      "Order": 1,
                      "ElementID": 11740
                    },
                    {
                      "Order": 1,
                      "ElementID": 11755
                    },
                    {
                      "Order": 1,
                      "ElementID": 11600
                    },
                    {
                      "Order": 1,
                      "ElementID": 11601
                    },
                    {
                      "Order": 1,
                      "ElementID": 11724
                    },
                    {
                      "Order": 1,
                      "ElementID": 11695
                    },
                    {
                      "Order": 1,
                      "ElementID": 11739
                    }],
                  "ElementID": 11968,
                  "DicoCaracID": 131,
                  "DicoCaracExtID": "DC_ING-MP_DEPUIS_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS_FAMILLE": {
                  "Value": "",
                  "ElementID": 11968,
                  "DicoCaracID": 189,
                  "DicoCaracExtID": "DC_SOUS_FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PICTOS_SOUS-FAMILLE": {
                  "LinkedElements": [],
                  "ElementID": 11968,
                  "DicoCaracID": 211,
                  "DicoCaracExtID": "DC_PICTOS_SOUS-FAMILLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "22",
                  "ElementID": 11968,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Thickener",
                  "ElementID": 11968,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12053": {
              "ID": 12053,
              "ExtID": "F_FLE_Actif_corps_minceur",
              "ElementType": 1,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12077,
              "Ordre": 2,
              "Inf": 4.333333333333333,
              "Sup": 4.4,
              "Values": {
                "FamilyFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12053,
                  "DicoCaracID": 214,
                  "DicoCaracExtID": "FamilyFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##FamilyLabel": {
                  "Value": "Actif corps minceur",
                  "ElementID": 12053,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##FamilyLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12077": {
              "ID": 12077,
              "ExtID": "F_FLE_ETAT_PUR",
              "ElementType": 1,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 18,
              "Ordre": 1,
              "Inf": 4.0,
              "Sup": 4.5,
              "Values": {
                "FamilyFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12077,
                  "DicoCaracID": 214,
                  "DicoCaracExtID": "FamilyFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##FamilyLabel": {
                  "Value": "ETAT PUR",
                  "ElementID": 12077,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##FamilyLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12132": {
              "ID": 12132,
              "ExtID": "P_FLE_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12053,
              "Ordre": 2,
              "Inf": 4.375,
              "Sup": 4.384615384615385,
              "Values": {
                "DC_CODE_ESSAI": {
                  "Value": "10 N 20 CG 17",
                  "ElementID": 12132,
                  "DicoCaracID": 69,
                  "DicoCaracExtID": "DC_CODE_ESSAI",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_FORMULE_COMPLET_AVEC_VERSIONING": {
                  "Value": "01 NEP 044 V1",
                  "ElementID": 12132,
                  "DicoCaracID": 70,
                  "DicoCaracExtID": "DC_CODE_FORMULE_COMPLET_AVEC_VERSIONING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_FORMULE_SANS_VERSIONING": {
                  "Value": "01 NEP 044",
                  "ElementID": 12132,
                  "DicoCaracID": 71,
                  "DicoCaracExtID": "DC_CODE_FORMULE_SANS_VERSIONING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_VRAC": {
                  "Value": "",
                  "ElementID": 12132,
                  "DicoCaracID": 74,
                  "DicoCaracExtID": "DC_CODE_VRAC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_FORMULE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 12132,
                  "DicoCaracID": 106,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_FORMULE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_LISTE_INGREDIENTS_INCI": {
                  "Value": "AQUA/WATER/EAU#CAPRYLIC/CAPRIC TRIGLYCERIDE#PROPANEDIOL#ALCOHOL#GLYCERYL STEARATE#DISODIUM PHOSPHATE#UREA#CAPRYLOYL GLYCINE#ESCIN#POTASSIUM PHOSPHATE#SODIUM LACTATE#SODIUM STEAROYL GLUTAMATE#CETEARYL ALCOHOL#XANTHAN GUM#LEUCONOSTOC/RADISH ROOT FERMENT FILTRATE#SODIUM HYDROXIDE#SALIX NIGRA (WILLOW) BARK EXTRACT#LAMINARIA DIGITATA EXTRACT#CARNOSINE#DISODIUM ADENOSINE TRIPHOSPHATE#LIMONENE",
                  "ElementID": 12132,
                  "DicoCaracID": 140,
                  "DicoCaracExtID": "DC_LISTE_INGREDIENTS_INCI",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP-FLE_DEPUIS_FORMULE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12601
                  },
                    {
                      "Order": 2,
                      "ElementID": 12467
                    },
                    {
                      "Order": 3,
                      "ElementID": 12367
                    },
                    {
                      "Order": 4,
                      "ElementID": 12512
                    },
                    {
                      "Order": 5,
                      "ElementID": 12503
                    },
                    {
                      "Order": 6,
                      "ElementID": 12340
                    },
                    {
                      "Order": 7,
                      "ElementID": 12494
                    },
                    {
                      "Order": 8,
                      "ElementID": 12645
                    },
                    {
                      "Order": 9,
                      "ElementID": 12740
                    },
                    {
                      "Order": 10,
                      "ElementID": 12901
                    },
                    {
                      "Order": 11,
                      "ElementID": 12717
                    },
                    {
                      "Order": 12,
                      "ElementID": 12734
                    },
                    {
                      "Order": 13,
                      "ElementID": 12332
                    },
                    {
                      "Order": 14,
                      "ElementID": 12797
                    },
                    {
                      "Order": 15,
                      "ElementID": 12288
                    },
                    {
                      "Order": 16,
                      "ElementID": 12558
                    }],
                  "ElementID": 12132,
                  "DicoCaracID": 151,
                  "DicoCaracExtID": "DC_MP-FLE_DEPUIS_FORMULE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_DU_PROJET": {
                  "Value": "Actif pur / Escine V1",
                  "ElementID": 12132,
                  "DicoCaracID": 156,
                  "DicoCaracExtID": "DC_NOM_DU_PROJET",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOM_PRODUIT": {
                  "Value": "ACTIF PUR ESCINE",
                  "ElementID": 12132,
                  "DicoCaracID": 158,
                  "DicoCaracExtID": "DC_NOM_PRODUIT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NOMBRE_DINGREDIENTS_COPTIS": {
                  "Value": "21",
                  "ElementID": 12132,
                  "DicoCaracID": 159,
                  "DicoCaracExtID": "DC_NOMBRE_DINGREDIENTS_COPTIS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_DE_L’ARTICLE": {
                  "Value": "",
                  "ElementID": 12132,
                  "DicoCaracID": 192,
                  "DicoCaracExtID": "DC_STATUT_DE_L’ARTICLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_DE_LA_FORMULE_": {
                  "Value": "Active Manufacture",
                  "ElementID": 12132,
                  "DicoCaracID": 193,
                  "DicoCaracExtID": "DC_STATUT_DE_LA_FORMULE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT": {
                  "Value": "",
                  "ElementID": 12132,
                  "DicoCaracID": 201,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_IMAGE_TYPE_PRODUIT": {
                  "LinkedElements": [],
                  "ElementID": 12132,
                  "DicoCaracID": 208,
                  "DicoCaracExtID": "DC_IMAGE_TYPE_PRODUIT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "67",
                  "ElementID": 12132,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "ACTIF PUR ESCINE",
                  "ElementID": 12132,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12288": {
              "ID": 12288,
              "ExtID": "DS_MPFLE_01AAU9136_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 12,
              "Inf": 1.7446808510638299,
              "Sup": 1.7450980392156863,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12288,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11437
                  }],
                  "ElementID": 12288,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "0,9",
                  "ElementID": 12288,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12288,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01AAU9136 - 10N20CG17",
                  "ElementID": 12288,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12332": {
              "ID": 12332,
              "ExtID": "DS_MPFLE_01AAU2794_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 56,
              "Inf": 1.7488789237668161,
              "Sup": 1.748898678414097,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12332,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11422
                  }],
                  "ElementID": 12332,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "1",
                  "ElementID": 12332,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12332,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01AAU2794 - 10N20CG17",
                  "ElementID": 12332,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12340": {
              "ID": 12340,
              "ExtID": "DS_MPFLE_01AAU8538_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 64,
              "Inf": 1.7490196078431373,
              "Sup": 1.7490347490347491,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12340,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11426
                  }],
                  "ElementID": 12340,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "2",
                  "ElementID": 12340,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12340,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01AAU8538 - 10N20CG17",
                  "ElementID": 12340,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12367": {
              "ID": 12367,
              "ExtID": "DS_MPFLE_01AAU9115_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 91,
              "Inf": 1.7493112947658402,
              "Sup": 1.7493188010899183,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12367,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11435
                  }],
                  "ElementID": 12367,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "1",
                  "ElementID": 12367,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12367,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01AAU9115 - 10N20CG17",
                  "ElementID": 12367,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12467": {
              "ID": 12467,
              "ExtID": "DS_MPFLE_01DIV7726_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 191,
              "Inf": 1.7496723460026213,
              "Sup": 1.7496740547588006,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12467,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11475
                  }],
                  "ElementID": 12467,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "0,18",
                  "ElementID": 12467,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12467,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV7726 - 10N20CG17",
                  "ElementID": 12467,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12494": {
              "ID": 12494,
              "ExtID": "DS_MPFLE_01DIV8693_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 218,
              "Inf": 1.7497129735935706,
              "Sup": 1.7497142857142858,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12494,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11479
                  }],
                  "ElementID": 12494,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "1",
                  "ElementID": 12494,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12494,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV8693 - 10N20CG17",
                  "ElementID": 12494,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12503": {
              "ID": 12503,
              "ExtID": "DS_MPFLE_01DIV9513_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 227,
              "Inf": 1.7497243660418964,
              "Sup": 1.7497255762897914,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12503,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11480
                  }],
                  "ElementID": 12503,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "0,75",
                  "ElementID": 12503,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12503,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV9513 - 10N20CG17",
                  "ElementID": 12503,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12512": {
              "ID": 12512,
              "ExtID": "DS_MPFLE_01DIV9514_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 236,
              "Inf": 1.7497348886532345,
              "Sup": 1.7497360084477296,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12512,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11481
                  }],
                  "ElementID": 12512,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "2,61",
                  "ElementID": 12512,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12512,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV9514 - 10N20CG17",
                  "ElementID": 12512,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12558": {
              "ID": 12558,
              "ExtID": "DS_MPFLE_01DIV9634_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 282,
              "Inf": 1.7497781721384207,
              "Sup": 1.7497789566755084,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12558,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11488
                  }],
                  "ElementID": 12558,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "5",
                  "ElementID": 12558,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12558,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV9634 - 10N20CG17",
                  "ElementID": 12558,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12601": {
              "ID": 12601,
              "ExtID": "DS_MPFLE_01DIV9640_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 325,
              "Inf": 1.7498075442648191,
              "Sup": 1.7498081350729087,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12601,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11491
                  }],
                  "ElementID": 12601,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "71,11",
                  "ElementID": 12601,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12601,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01DIV9640 - 10N20CG17",
                  "ElementID": 12601,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12645": {
              "ID": 12645,
              "ExtID": "DS_MPFLE_01GEL8707_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 369,
              "Inf": 1.7498305084745762,
              "Sup": 1.7498309668695065,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12645,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11416
                  }],
                  "ElementID": 12645,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "0,45",
                  "ElementID": 12645,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12645,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01GEL8707 - 10N20CG17",
                  "ElementID": 12645,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12717": {
              "ID": 12717,
              "ExtID": "DS_MPFLE_01HCG5577_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 441,
              "Inf": 1.7498581962563811,
              "Sup": 1.7498585172608943,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12717,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11501
                  }],
                  "ElementID": 12717,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "5",
                  "ElementID": 12717,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12717,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01HCG5577 - 10N20CG17",
                  "ElementID": 12717,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12734": {
              "ID": 12734,
              "ExtID": "DS_MPFLE_01HCG9281_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 458,
              "Inf": 1.7498634625887493,
              "Sup": 1.7498637602179836,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12734,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11504
                  }],
                  "ElementID": 12734,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "0,5",
                  "ElementID": 12734,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12734,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01HCG9281 - 10N20CG17",
                  "ElementID": 12734,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12740": {
              "ID": 12740,
              "ExtID": "DS_MPFLE_01HCG9306_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 464,
              "Inf": 1.7498652291105121,
              "Sup": 1.7498655190962884,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12740,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11505
                  }],
                  "ElementID": 12740,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "3",
                  "ElementID": 12740,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12740,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01HCG9306 - 10N20CG17",
                  "ElementID": 12740,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12797": {
              "ID": 12797,
              "ExtID": "DS_MPFLE_01HCG9370_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 521,
              "Inf": 1.7498799807969274,
              "Sup": 1.7498802108289411,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12797,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11515
                  }],
                  "ElementID": 12797,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "5",
                  "ElementID": 12797,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12797,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01HCG9370 - 10N20CG17",
                  "ElementID": 12797,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12901": {
              "ID": 12901,
              "ExtID": "DS_MPFLE_01TAC9595_10N20CG17",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 6,
              "Ordre": 625,
              "Inf": 1.7498999599839935,
              "Sup": 1.7499001198561726,
              "Values": {
                "DC_FORMULE_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 12901,
                  "DicoCaracID": 116,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MP_DEPUIS_MP-FLE": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11542
                  }],
                  "ElementID": 12901,
                  "DicoCaracID": 147,
                  "DicoCaracExtID": "DC_MP_DEPUIS_MP-FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_MP_-_FLE": {
                  "Value": "0,5",
                  "ElementID": 12901,
                  "DicoCaracID": 178,
                  "DicoCaracExtID": "DC_POURCENTAGE_MP_-_FLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 12901,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "01TAC9595 - 10N20CG17",
                  "ElementID": 12901,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12932": {
              "ID": 12932,
              "ExtID": "P_BT_Eau isodermique",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 49,
              "Inf": 2.7488789237668163,
              "Sup": 2.748898678414097,
              "Values": {
                "DC_COPYWRITING_BREVET": {
                  "Value": "",
                  "ElementID": 12932,
                  "DicoCaracID": 80,
                  "DicoCaracExtID": "DC_COPYWRITING_BREVET",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NOM_DU_BREVET": {
                  "Value": "",
                  "ElementID": 12932,
                  "DicoCaracID": 87,
                  "DicoCaracExtID": "DC_COPYWRITING_NOM_DU_BREVET",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DESIGNATION_BREVET__TECHNOLOGIE": {
                  "Value": "Eau isodermique",
                  "ElementID": 12932,
                  "DicoCaracID": 97,
                  "DicoCaracExtID": "DC_DESIGNATION_BREVET__TECHNOLOGIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC-BREVET_DEPUIS_BREVET": {
                  "LinkedElements": [],
                  "ElementID": 12932,
                  "DicoCaracID": 111,
                  "DicoCaracExtID": "DC_FGC-BREVET_DEPUIS_BREVET",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_BREVET": {
                  "LinkedElements": [],
                  "ElementID": 12932,
                  "DicoCaracID": 121,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_BREVET",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_FACULTATIF_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL": {
                  "LinkedElements": [],
                  "ElementID": 12932,
                  "DicoCaracID": 136,
                  "DicoCaracExtID": "DC_INGREDIENT_FACULTATIF_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_OBLIGATOIRE_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11615
                  },
                    {
                      "Order": 2,
                      "ElementID": 11642
                    },
                    {
                      "Order": 3,
                      "ElementID": 11581
                    },
                    {
                      "Order": 4,
                      "ElementID": 11675
                    }],
                  "ElementID": 12932,
                  "DicoCaracID": 138,
                  "DicoCaracExtID": "DC_INGREDIENT_OBLIGATOIRE_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MARQUE__GAMME": {
                  "Value": "",
                  "ElementID": 12932,
                  "DicoCaracID": 143,
                  "DicoCaracExtID": "DC_MARQUE__GAMME",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 12932,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 12932,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_TECHNOLOGIE": {
                  "Value": "Patent",
                  "ElementID": 12932,
                  "DicoCaracID": 203,
                  "DicoCaracExtID": "DC_TYPE_DE_TECHNOLOGIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 12932,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 12932,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "29",
                  "ElementID": 12932,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "Eau isodermique",
                  "ElementID": 12932,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "12939": {
              "ID": 12939,
              "ExtID": "P_BT_IN-SKIN",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13,
              "Ordre": 56,
              "Inf": 2.7487684729064039,
              "Sup": 2.7487922705314012,
              "Values": {
                "DC_COPYWRITING_BREVET": {
                  "Value": "",
                  "ElementID": 12939,
                  "DicoCaracID": 80,
                  "DicoCaracExtID": "DC_COPYWRITING_BREVET",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_NOM_DU_BREVET": {
                  "Value": "",
                  "ElementID": 12939,
                  "DicoCaracID": 87,
                  "DicoCaracExtID": "DC_COPYWRITING_NOM_DU_BREVET",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DESIGNATION_BREVET__TECHNOLOGIE": {
                  "Value": "IN-SKIN",
                  "ElementID": 12939,
                  "DicoCaracID": 97,
                  "DicoCaracExtID": "DC_DESIGNATION_BREVET__TECHNOLOGIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC-BREVET_DEPUIS_BREVET": {
                  "LinkedElements": [],
                  "ElementID": 12939,
                  "DicoCaracID": 111,
                  "DicoCaracExtID": "DC_FGC-BREVET_DEPUIS_BREVET",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_BREVET": {
                  "LinkedElements": [],
                  "ElementID": 12939,
                  "DicoCaracID": 121,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_BREVET",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_FACULTATIF_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11732
                  },
                    {
                      "Order": 2,
                      "ElementID": 11757
                    }],
                  "ElementID": 12939,
                  "DicoCaracID": 136,
                  "DicoCaracExtID": "DC_INGREDIENT_FACULTATIF_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_OBLIGATOIRE_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11607
                  },
                    {
                      "Order": 2,
                      "ElementID": 11707
                    }],
                  "ElementID": 12939,
                  "DicoCaracID": 138,
                  "DicoCaracExtID": "DC_INGREDIENT_OBLIGATOIRE_DE_LA_TECHNOLOGIE_BREVETEE_LIEN_DATA_MODEL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MARQUE__GAMME": {
                  "Value": "",
                  "ElementID": 12939,
                  "DicoCaracID": 143,
                  "DicoCaracExtID": "DC_MARQUE__GAMME",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 12939,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 12939,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_TECHNOLOGIE": {
                  "Value": "Patent",
                  "ElementID": 12939,
                  "DicoCaracID": 203,
                  "DicoCaracExtID": "DC_TYPE_DE_TECHNOLOGIE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 12939,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 12939,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "36",
                  "ElementID": 12939,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "IN-SKIN",
                  "ElementID": 12939,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13006": {
              "ID": 13006,
              "ExtID": "P_FGC_01NEP043",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12029,
              "Ordre": 1,
              "Inf": 3.3333333333333335,
              "Sup": 3.375,
              "Values": {
                "DC_COPYWRITING_DISCLAIMER_LISTE_INCI": {
                  "Value": "",
                  "ElementID": 13006,
                  "DicoCaracID": 83,
                  "DicoCaracExtID": "DC_COPYWRITING_DISCLAIMER_LISTE_INCI",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_DISCLAIMER_ORIGINE_": {
                  "Value": "",
                  "ElementID": 13006,
                  "DicoCaracID": 84,
                  "DicoCaracExtID": "DC_COPYWRITING_DISCLAIMER_ORIGINE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_PRODUIT": {
                  "Value": "",
                  "ElementID": 13006,
                  "DicoCaracID": 89,
                  "DicoCaracExtID": "DC_COPYWRITING_PRODUIT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DESIGNATION_COMMERCIALE_SANS_LA_CONTENANCE": {
                  "Value": "",
                  "ElementID": 13006,
                  "DicoCaracID": 98,
                  "DicoCaracExtID": "DC_DESIGNATION_COMMERCIALE_SANS_LA_CONTENANCE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC-BREVET_DEPUIS_FGC": {
                  "LinkedElements": [],
                  "ElementID": 13006,
                  "DicoCaracID": 112,
                  "DicoCaracExtID": "DC_FGC-BREVET_DEPUIS_FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FORMULE_DEPUIS_FGC_REV": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12108
                  }],
                  "ElementID": 13006,
                  "DicoCaracID": 115,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_FGC_REV",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12987
                  },
                    {
                      "Order": 2,
                      "ElementID": 12988
                    },
                    {
                      "Order": 3,
                      "ElementID": 12989
                    },
                    {
                      "Order": 4,
                      "ElementID": 12990
                    },
                    {
                      "Order": 5,
                      "ElementID": 12991
                    },
                    {
                      "Order": 6,
                      "ElementID": 12992
                    },
                    {
                      "Order": 7,
                      "ElementID": 12993
                    },
                    {
                      "Order": 8,
                      "ElementID": 12994
                    },
                    {
                      "Order": 9,
                      "ElementID": 12995
                    },
                    {
                      "Order": 10,
                      "ElementID": 12996
                    },
                    {
                      "Order": 11,
                      "ElementID": 12997
                    },
                    {
                      "Order": 12,
                      "ElementID": 12998
                    },
                    {
                      "Order": 13,
                      "ElementID": 12999
                    },
                    {
                      "Order": 14,
                      "ElementID": 13000
                    },
                    {
                      "Order": 15,
                      "ElementID": 13001
                    },
                    {
                      "Order": 16,
                      "ElementID": 13002
                    },
                    {
                      "Order": 17,
                      "ElementID": 13003
                    },
                    {
                      "Order": 18,
                      "ElementID": 13004
                    },
                    {
                      "Order": 19,
                      "ElementID": 13005
                    }],
                  "ElementID": 13006,
                  "DicoCaracID": 122,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13006,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PREUVE_DE_L’ALLEGATION": {
                  "Value": "",
                  "ElementID": 13006,
                  "DicoCaracID": 179,
                  "DicoCaracExtID": "DC_PREUVE_DE_L’ALLEGATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PF_DEPUIS_FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13337
                  }],
                  "ElementID": 13006,
                  "DicoCaracID": 181,
                  "DicoCaracExtID": "DC_PF_DEPUIS_FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_ORDONNE_DEPUIS_FGC": {
                  "LinkedElements": [],
                  "ElementID": 13006,
                  "DicoCaracID": 186,
                  "DicoCaracExtID": "DC_ROLE_ORDONNE_DEPUIS_FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOURCE_PREUVE_DU_COPYWRITING": {
                  "Value": "",
                  "ElementID": 13006,
                  "DicoCaracID": 188,
                  "DicoCaracExtID": "DC_SOURCE_PREUVE_DU_COPYWRITING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS-FAMILLE_DEPUIS_FGC": {
                  "LinkedElements": [],
                  "ElementID": 13006,
                  "DicoCaracID": 190,
                  "DicoCaracExtID": "DC_SOUS-FAMILLE_DEPUIS_FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_URL_SITE_MARQUE": {
                  "Value": "",
                  "ElementID": 13006,
                  "DicoCaracID": 204,
                  "DicoCaracExtID": "DC_URL_SITE_MARQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "27",
                  "ElementID": 13006,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "ACTIF PUR CAFEINE 5%",
                  "ElementID": 13006,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13007": {
              "ID": 13007,
              "ExtID": "DS_IngFGC_ALCOHOL_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 20,
              "Inf": 1.797979797979798,
              "Sup": 1.7980769230769231,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13007,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13007,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13007,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13007,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13007,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13007,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13007,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13007,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13007,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11767
                  }],
                  "ElementID": 13007,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13007,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13007,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13007,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13007,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13007,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13007,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13007,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "ALCOHOL - ACTIF PUR ESCINE",
                  "ElementID": 13007,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13008": {
              "ID": 13008,
              "ExtID": "DS_IngFGC_AQUA/WATER/EAU_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 21,
              "Inf": 1.7980769230769231,
              "Sup": 1.798165137614679,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13008,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13008,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13008,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13008,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13008,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13008,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13008,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13008,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13008,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11773
                  }],
                  "ElementID": 13008,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13008,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13008,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13008,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13008,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13008,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13008,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13008,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "AQUA/WATER/EAU - ACTIF PUR ESCINE",
                  "ElementID": 13008,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13009": {
              "ID": 13009,
              "ExtID": "DS_IngFGC_CAPRYLIC/CAPRIC TRIGLYCERIDE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 22,
              "Inf": 1.798165137614679,
              "Sup": 1.7982456140350878,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13009,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13009,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13009,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13009,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13009,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13009,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13009,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13009,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13009,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11790
                  }],
                  "ElementID": 13009,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13009,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13009,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13009,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13009,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13009,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13009,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13009,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CAPRYLIC/CAPRIC TRIGLYCERIDE - ACTIF PUR ESCINE",
                  "ElementID": 13009,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13010": {
              "ID": 13010,
              "ExtID": "DS_IngFGC_CAPRYLOYL GLYCINE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 23,
              "Inf": 1.7982456140350878,
              "Sup": 1.7983193277310925,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13010,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13010,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13010,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13010,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13010,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13010,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13010,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13010,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13010,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11791
                  }],
                  "ElementID": 13010,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13010,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13010,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13010,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13010,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13010,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13010,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13010,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CAPRYLOYL GLYCINE - ACTIF PUR ESCINE",
                  "ElementID": 13010,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13011": {
              "ID": 13011,
              "ExtID": "DS_IngFGC_CARNOSINE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 24,
              "Inf": 1.7983193277310925,
              "Sup": 1.7983870967741935,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13011,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13011,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13011,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13011,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13011,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13011,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13011,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13011,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13011,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11793
                  }],
                  "ElementID": 13011,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13011,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13011,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13011,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13011,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13011,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13011,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13011,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CARNOSINE - ACTIF PUR ESCINE",
                  "ElementID": 13011,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13012": {
              "ID": 13012,
              "ExtID": "DS_IngFGC_CETEARYL ALCOHOL_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 25,
              "Inf": 1.7983870967741935,
              "Sup": 1.7984496124031009,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13012,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13012,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13012,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13012,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13012,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13012,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13012,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13012,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13012,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11799
                  }],
                  "ElementID": 13012,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13012,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13012,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13012,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13012,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13012,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13012,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13012,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "CETEARYL ALCOHOL - ACTIF PUR ESCINE",
                  "ElementID": 13012,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13013": {
              "ID": 13013,
              "ExtID": "DS_IngFGC_DISODIUM ADENOSINE TRIPHOSPHATE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 26,
              "Inf": 1.7984496124031009,
              "Sup": 1.7985074626865671,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13013,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13013,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13013,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13013,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13013,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13013,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13013,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13013,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13013,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11819
                  }],
                  "ElementID": 13013,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13013,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13013,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13013,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13013,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13013,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13013,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13013,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "DISODIUM ADENOSINE TRIPHOSPHATE - ACTIF PUR ESCINE",
                  "ElementID": 13013,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13014": {
              "ID": 13014,
              "ExtID": "DS_IngFGC_DISODIUM PHOSPHATE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 27,
              "Inf": 1.7985074626865671,
              "Sup": 1.7985611510791366,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13014,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13014,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13014,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13014,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13014,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13014,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13014,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13014,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13014,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11820
                  }],
                  "ElementID": 13014,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13014,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13014,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13014,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13014,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13014,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13014,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13014,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "DISODIUM PHOSPHATE - ACTIF PUR ESCINE",
                  "ElementID": 13014,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13015": {
              "ID": 13015,
              "ExtID": "DS_IngFGC_ESCIN_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 28,
              "Inf": 1.7985611510791366,
              "Sup": 1.7986111111111112,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13015,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13015,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13015,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13015,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13015,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13015,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13015,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13015,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13015,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11823
                  }],
                  "ElementID": 13015,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13015,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13015,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13015,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13015,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13015,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13015,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13015,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "ESCIN - ACTIF PUR ESCINE",
                  "ElementID": 13015,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13016": {
              "ID": 13016,
              "ExtID": "DS_IngFGC_GLYCERYL STEARATE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 29,
              "Inf": 1.7986111111111112,
              "Sup": 1.7986577181208054,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13016,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13016,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13016,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13016,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13016,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13016,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13016,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13016,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13016,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11831
                  }],
                  "ElementID": 13016,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13016,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13016,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13016,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13016,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13016,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13016,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13016,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "GLYCERYL STEARATE - ACTIF PUR ESCINE",
                  "ElementID": 13016,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13017": {
              "ID": 13017,
              "ExtID": "DS_IngFGC_LAMINARIA DIGITATA EXTRACT_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 30,
              "Inf": 1.7986577181208054,
              "Sup": 1.7987012987012987,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13017,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13017,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13017,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13017,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13017,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13017,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13017,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13017,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13017,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11851
                  }],
                  "ElementID": 13017,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13017,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13017,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13017,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13017,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13017,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13017,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13017,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "LAMINARIA DIGITATA EXTRACT - ACTIF PUR ESCINE",
                  "ElementID": 13017,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13018": {
              "ID": 13018,
              "ExtID": "DS_IngFGC_LEUCONOSTOC/RADISH ROOT FERMENT FILTRATE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 31,
              "Inf": 1.7987012987012987,
              "Sup": 1.79874213836478,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13018,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13018,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13018,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13018,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13018,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13018,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13018,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13018,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13018,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11855
                  }],
                  "ElementID": 13018,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13018,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13018,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13018,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13018,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13018,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13018,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13018,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "LEUCONOSTOC/RADISH ROOT FERMENT FILTRATE - ACTIF PUR ESCINE",
                  "ElementID": 13018,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13019": {
              "ID": 13019,
              "ExtID": "DS_IngFGC_LIMONENE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 32,
              "Inf": 1.79874213836478,
              "Sup": 1.7987804878048781,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13019,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13019,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13019,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13019,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13019,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13019,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13019,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13019,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13019,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11856
                  }],
                  "ElementID": 13019,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13019,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13019,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13019,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13019,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13019,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13019,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13019,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "LIMONENE - ACTIF PUR ESCINE",
                  "ElementID": 13019,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13020": {
              "ID": 13020,
              "ExtID": "DS_IngFGC_POTASSIUM PHOSPHATE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 33,
              "Inf": 1.7987804878048781,
              "Sup": 1.7988165680473374,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13020,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13020,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13020,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13020,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13020,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13020,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13020,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13020,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13020,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11878
                  }],
                  "ElementID": 13020,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13020,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13020,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13020,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13020,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13020,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13020,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13020,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "POTASSIUM PHOSPHATE - ACTIF PUR ESCINE",
                  "ElementID": 13020,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13021": {
              "ID": 13021,
              "ExtID": "DS_IngFGC_PROPANEDIOL_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 34,
              "Inf": 1.7988165680473374,
              "Sup": 1.7988505747126438,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13021,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13021,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13021,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13021,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13021,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13021,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13021,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13021,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13021,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11880
                  }],
                  "ElementID": 13021,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13021,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13021,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13021,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13021,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13021,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13021,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13021,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "PROPANEDIOL - ACTIF PUR ESCINE",
                  "ElementID": 13021,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13022": {
              "ID": 13022,
              "ExtID": "DS_IngFGC_SALIX NIGRA (WILLOW) BARK EXTRACT_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 35,
              "Inf": 1.7988505747126438,
              "Sup": 1.7988826815642458,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13022,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13022,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13022,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13022,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13022,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13022,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13022,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13022,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13022,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11885
                  }],
                  "ElementID": 13022,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13022,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13022,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13022,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13022,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13022,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13022,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13022,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SALIX NIGRA (WILLOW) BARK EXTRACT - ACTIF PUR ESCINE",
                  "ElementID": 13022,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13023": {
              "ID": 13023,
              "ExtID": "DS_IngFGC_SODIUM HYDROXIDE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 36,
              "Inf": 1.7988826815642458,
              "Sup": 1.798913043478261,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13023,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13023,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13023,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13023,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13023,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13023,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13023,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13023,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13023,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11900
                  }],
                  "ElementID": 13023,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13023,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13023,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13023,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13023,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13023,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13023,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13023,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SODIUM HYDROXIDE - ACTIF PUR ESCINE",
                  "ElementID": 13023,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13024": {
              "ID": 13024,
              "ExtID": "DS_IngFGC_SODIUM LACTATE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 37,
              "Inf": 1.798913043478261,
              "Sup": 1.7989417989417988,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13024,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13024,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13024,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13024,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13024,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13024,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13024,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13024,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13024,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11901
                  }],
                  "ElementID": 13024,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13024,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13024,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13024,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13024,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13024,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13024,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13024,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SODIUM LACTATE - ACTIF PUR ESCINE",
                  "ElementID": 13024,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13025": {
              "ID": 13025,
              "ExtID": "DS_IngFGC_SODIUM STEAROYL GLUTAMATE_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 38,
              "Inf": 1.7989417989417988,
              "Sup": 1.7989690721649485,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13025,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13025,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13025,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13025,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13025,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13025,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13025,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13025,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13025,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11907
                  }],
                  "ElementID": 13025,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13025,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13025,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13025,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13025,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13025,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13025,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13025,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "SODIUM STEAROYL GLUTAMATE - ACTIF PUR ESCINE",
                  "ElementID": 13025,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13026": {
              "ID": 13026,
              "ExtID": "DS_IngFGC_UREA_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 39,
              "Inf": 1.7989690721649485,
              "Sup": 1.7989949748743719,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13026,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13026,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13026,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13026,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13026,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13026,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13026,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13026,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13026,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11919
                  }],
                  "ElementID": 13026,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13026,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13026,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13026,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13026,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13026,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13026,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13026,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "UREA - ACTIF PUR ESCINE",
                  "ElementID": 13026,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13027": {
              "ID": 13027,
              "ExtID": "DS_IngFGC_XANTHAN GUM_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 7,
              "Ordre": 40,
              "Inf": 1.7989949748743719,
              "Sup": 1.7990196078431373,
              "Values": {
                "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13027,
                  "DicoCaracID": 59,
                  "DicoCaracExtID": "DC_BREVETS_ET_TECHNOLOGIES_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE": {
                  "Value": "",
                  "ElementID": 13027,
                  "DicoCaracID": 76,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DE_LORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE": {
                  "Value": "",
                  "ElementID": 13027,
                  "DicoCaracID": 77,
                  "DicoCaracExtID": "DC_COMMENTAIRE_SUR_LE_CHOIX_DU_ROLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_ORIGINE": {
                  "Value": "",
                  "ElementID": 13027,
                  "DicoCaracID": 88,
                  "DicoCaracExtID": "DC_COPYWRITING_ORIGINE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_": {
                  "Value": "",
                  "ElementID": 13027,
                  "DicoCaracID": 91,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON": {
                  "Value": "",
                  "ElementID": 13027,
                  "DicoCaracID": 92,
                  "DicoCaracExtID": "DC_COPYWRITING_STATUT_BIODERMIQUE_OUINON",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_NATURE": {
                  "Value": "",
                  "ElementID": 13027,
                  "DicoCaracID": 99,
                  "DicoCaracExtID": "DC_DETAIL_NATURE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DETAIL_ORIGINE_GEO": {
                  "Value": "",
                  "ElementID": 13027,
                  "DicoCaracID": 101,
                  "DicoCaracExtID": "DC_DETAIL_ORIGINE_GEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13028
                  }],
                  "ElementID": 13027,
                  "DicoCaracID": 107,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_DEPUIS_ING-FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 11921
                  }],
                  "ElementID": 13027,
                  "DicoCaracID": 133,
                  "DicoCaracExtID": "DC_INGREDIENT_DEPUIS_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13027,
                  "DicoCaracID": 162,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13027,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE": {
                  "Value": "",
                  "ElementID": 13027,
                  "DicoCaracID": 176,
                  "DicoCaracExtID": "DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_GENCODE_ING-FGC": {
                  "LinkedElements": [],
                  "ElementID": 13027,
                  "DicoCaracID": 184,
                  "DicoCaracExtID": "DC_ROLE_GENCODE_ING-FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "18",
                  "ElementID": 13027,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GEOGRAPHIQUE": {
                  "Values": [],
                  "ElementID": 13027,
                  "DicoCaracID": 219,
                  "DicoCaracExtID": "DC_ORIGINE_GEOGRAPHIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INGREDIENT_PRIORITAIRE": {
                  "LinkedElements": [],
                  "ElementID": 13027,
                  "DicoCaracID": 223,
                  "DicoCaracExtID": "DC_INGREDIENT_PRIORITAIRE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "XANTHAN GUM - ACTIF PUR ESCINE",
                  "ElementID": 13027,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13028": {
              "ID": 13028,
              "ExtID": "P_FGC_01NEP044",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 12029,
              "Ordre": 2,
              "Inf": 3.375,
              "Sup": 3.3846153846153846,
              "Values": {
                "DC_COPYWRITING_DISCLAIMER_LISTE_INCI": {
                  "Value": "",
                  "ElementID": 13028,
                  "DicoCaracID": 83,
                  "DicoCaracExtID": "DC_COPYWRITING_DISCLAIMER_LISTE_INCI",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_DISCLAIMER_ORIGINE_": {
                  "Value": "",
                  "ElementID": 13028,
                  "DicoCaracID": 84,
                  "DicoCaracExtID": "DC_COPYWRITING_DISCLAIMER_ORIGINE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_PRODUIT": {
                  "Value": "",
                  "ElementID": 13028,
                  "DicoCaracID": 89,
                  "DicoCaracExtID": "DC_COPYWRITING_PRODUIT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DESIGNATION_COMMERCIALE_SANS_LA_CONTENANCE": {
                  "Value": "",
                  "ElementID": 13028,
                  "DicoCaracID": 98,
                  "DicoCaracExtID": "DC_DESIGNATION_COMMERCIALE_SANS_LA_CONTENANCE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC-BREVET_DEPUIS_FGC": {
                  "LinkedElements": [],
                  "ElementID": 13028,
                  "DicoCaracID": 112,
                  "DicoCaracExtID": "DC_FGC-BREVET_DEPUIS_FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FORMULE_DEPUIS_FGC_REV": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 12132
                  }],
                  "ElementID": 13028,
                  "DicoCaracID": 115,
                  "DicoCaracExtID": "DC_FORMULE_DEPUIS_FGC_REV",
                  "TypeValue": 1,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ING-FGC_DEPUIS_FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13007
                  },
                    {
                      "Order": 2,
                      "ElementID": 13008
                    },
                    {
                      "Order": 3,
                      "ElementID": 13009
                    },
                    {
                      "Order": 4,
                      "ElementID": 13010
                    },
                    {
                      "Order": 5,
                      "ElementID": 13011
                    },
                    {
                      "Order": 6,
                      "ElementID": 13012
                    },
                    {
                      "Order": 7,
                      "ElementID": 13013
                    },
                    {
                      "Order": 8,
                      "ElementID": 13014
                    },
                    {
                      "Order": 9,
                      "ElementID": 13015
                    },
                    {
                      "Order": 10,
                      "ElementID": 13016
                    },
                    {
                      "Order": 11,
                      "ElementID": 13017
                    },
                    {
                      "Order": 12,
                      "ElementID": 13018
                    },
                    {
                      "Order": 13,
                      "ElementID": 13019
                    },
                    {
                      "Order": 14,
                      "ElementID": 13020
                    },
                    {
                      "Order": 15,
                      "ElementID": 13021
                    },
                    {
                      "Order": 16,
                      "ElementID": 13022
                    },
                    {
                      "Order": 17,
                      "ElementID": 13023
                    },
                    {
                      "Order": 18,
                      "ElementID": 13024
                    },
                    {
                      "Order": 19,
                      "ElementID": 13025
                    },
                    {
                      "Order": 20,
                      "ElementID": 13026
                    },
                    {
                      "Order": 21,
                      "ElementID": 13027
                    }],
                  "ElementID": 13028,
                  "DicoCaracID": 122,
                  "DicoCaracExtID": "DC_ING-FGC_DEPUIS_FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ORIGINE_GENCODE_ING": {
                  "LinkedElements": [],
                  "ElementID": 13028,
                  "DicoCaracID": 163,
                  "DicoCaracExtID": "DC_ORIGINE_GENCODE_ING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PREUVE_DE_L’ALLEGATION": {
                  "Value": "",
                  "ElementID": 13028,
                  "DicoCaracID": 179,
                  "DicoCaracExtID": "DC_PREUVE_DE_L’ALLEGATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PF_DEPUIS_FGC": {
                  "LinkedElements": [{
                    "Order": 1,
                    "ElementID": 13337
                  }],
                  "ElementID": 13028,
                  "DicoCaracID": 181,
                  "DicoCaracExtID": "DC_PF_DEPUIS_FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_ROLE_ORDONNE_DEPUIS_FGC": {
                  "LinkedElements": [],
                  "ElementID": 13028,
                  "DicoCaracID": 186,
                  "DicoCaracExtID": "DC_ROLE_ORDONNE_DEPUIS_FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOURCE_PREUVE_DU_COPYWRITING": {
                  "Value": "",
                  "ElementID": 13028,
                  "DicoCaracID": 188,
                  "DicoCaracExtID": "DC_SOURCE_PREUVE_DU_COPYWRITING",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_SOUS-FAMILLE_DEPUIS_FGC": {
                  "LinkedElements": [],
                  "ElementID": 13028,
                  "DicoCaracID": 190,
                  "DicoCaracExtID": "DC_SOUS-FAMILLE_DEPUIS_FGC",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_URL_SITE_MARQUE": {
                  "Value": "",
                  "ElementID": 13028,
                  "DicoCaracID": 204,
                  "DicoCaracExtID": "DC_URL_SITE_MARQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "27",
                  "ElementID": 13028,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "ACTIF PUR ESCINE",
                  "ElementID": 13028,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13087": {
              "ID": 13087,
              "ExtID": "F_PF_ACTIFS PURS",
              "ElementType": 1,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13114,
              "Ordre": 1,
              "Inf": 5.5,
              "Sup": 5.6,
              "Values": {
                "FamilyFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 13087,
                  "DicoCaracID": 214,
                  "DicoCaracExtID": "FamilyFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##FamilyLabel": {
                  "Value": "ACTIFS PURS",
                  "ElementID": 13087,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##FamilyLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13114": {
              "ID": 13114,
              "ExtID": "F_PF_Etat Pur",
              "ElementType": 1,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 19,
              "Ordre": 2,
              "Inf": 5.5,
              "Sup": 5.666666666666667,
              "Values": {
                "FamilyFillRate_GLOBAL": {
                  "Value": "100",
                  "ElementID": 13114,
                  "DicoCaracID": 214,
                  "DicoCaracExtID": "FamilyFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##FamilyLabel": {
                  "Value": "Etat Pur",
                  "ElementID": 13114,
                  "DicoCaracID": -1,
                  "DicoCaracExtID": "##FamilyLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            },
            "13337": {
              "ID": 13337,
              "ExtID": "P_PF_06EA8035001S",
              "ElementType": 2,
              "Access": 0,
              "ParentExtID": null,
              "ParentID": 13087,
              "Ordre": 1,
              "Inf": 5.5,
              "Sup": 5.5714285714285712,
              "Values": {
                "DC_BENEFICES_PRODUIT": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 55,
                  "DicoCaracExtID": "DC_BENEFICES_PRODUIT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CHAMPS_POUR_NOM_DE_PRODUIT_EX__CANADA_": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 63,
                  "DicoCaracExtID": "DC_CHAMPS_POUR_NOM_DE_PRODUIT_EX__CANADA_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CIBLE__INDICATIONS": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 64,
                  "DicoCaracExtID": "DC_CIBLE__INDICATIONS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CIBLE__SUJET": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 65,
                  "DicoCaracExtID": "DC_CIBLE__SUJET",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CIBLE__UTILISATION": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 66,
                  "DicoCaracExtID": "DC_CIBLE__UTILISATION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_AC_1": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 67,
                  "DicoCaracExtID": "DC_CODE_AC_1",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_EAN13": {
                  "Value": "3760201231067",
                  "ElementID": 13337,
                  "DicoCaracID": 68,
                  "DicoCaracExtID": "DC_CODE_EAN13",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_CODE_SKU": {
                  "Value": "06EA8035001S",
                  "ElementID": 13337,
                  "DicoCaracID": 73,
                  "DicoCaracExtID": "DC_CODE_SKU",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_COPYWRITING_DETAIL_PRODUIT": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 81,
                  "DicoCaracExtID": "DC_COPYWRITING_DETAIL_PRODUIT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_DATE_PEREMPTION": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 95,
                  "DicoCaracExtID": "DC_DATE_PEREMPTION",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_FGC_DEPUIS_PF": {
                  "LinkedElements": [{
                    "Order": 2,
                    "ElementID": 13006
                  },
                    {
                      "Order": 4,
                      "ElementID": 13028
                    }],
                  "ElementID": 13337,
                  "DicoCaracID": 108,
                  "DicoCaracExtID": "DC_FGC_DEPUIS_PF",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_GAMME": {
                  "LinkedElements": [],
                  "ElementID": 13337,
                  "DicoCaracID": 117,
                  "DicoCaracExtID": "DC_GAMME",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_GAMME__SAP": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 118,
                  "DicoCaracExtID": "DC_GAMME__SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_INDICE_DE_MODIFICATION_SAP": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 120,
                  "DicoCaracExtID": "DC_INDICE_DE_MODIFICATION_SAP",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_LISTE_INGREDIENTS_SITE_DE_MARQUES": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 141,
                  "DicoCaracExtID": "DC_LISTE_INGREDIENTS_SITE_DE_MARQUES",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MARQUE": {
                  "LinkedElements": [],
                  "ElementID": 13337,
                  "DicoCaracID": 142,
                  "DicoCaracExtID": "DC_MARQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_MODE_DACTION_BIOLOGIQUE": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 145,
                  "DicoCaracExtID": "DC_MODE_DACTION_BIOLOGIQUE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_NUMERO_DE_LOT": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 160,
                  "DicoCaracExtID": "DC_NUMERO_DE_LOT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QUESTIONS_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 182,
                  "DicoCaracExtID": "DC_QUESTIONS_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_REPONSES_CONSOMMATEURS": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 183,
                  "DicoCaracExtID": "DC_REPONSES_CONSOMMATEURS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_STATUT_DE_L’ARTICLE": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 192,
                  "DicoCaracExtID": "DC_STATUT_DE_L’ARTICLE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TEST_DE_TOLERANCE": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 197,
                  "DicoCaracExtID": "DC_TEST_DE_TOLERANCE",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TEST_DEFFICACITE_PRODUIT": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 198,
                  "DicoCaracExtID": "DC_TEST_DEFFICACITE_PRODUIT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_TYPE_DE_PRODUIT": {
                  "Value": "",
                  "ElementID": 13337,
                  "DicoCaracID": 201,
                  "DicoCaracExtID": "DC_TYPE_DE_PRODUIT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_BAT__BAG": {
                  "LinkedElements": [],
                  "ElementID": 13337,
                  "DicoCaracID": 207,
                  "DicoCaracExtID": "DC_BAT__BAG",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_IMAGE_TYPE_PRODUIT": {
                  "LinkedElements": [],
                  "ElementID": 13337,
                  "DicoCaracID": 208,
                  "DicoCaracExtID": "DC_IMAGE_TYPE_PRODUIT",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_PHOTOS": {
                  "LinkedElements": [],
                  "ElementID": 13337,
                  "DicoCaracID": 209,
                  "DicoCaracExtID": "DC_PHOTOS",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_QR_CODE_": {
                  "LinkedElements": [],
                  "ElementID": 13337,
                  "DicoCaracID": 212,
                  "DicoCaracExtID": "DC_QR_CODE_",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "DC_VIDEO": {
                  "LinkedElements": [],
                  "ElementID": 13337,
                  "DicoCaracID": 213,
                  "DicoCaracExtID": "DC_VIDEO",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "ProductFillRate_GLOBAL": {
                  "Value": "10",
                  "ElementID": 13337,
                  "DicoCaracID": 215,
                  "DicoCaracExtID": "ProductFillRate_GLOBAL",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                },
                "##ProductLabel": {
                  "Value": "RESVERATROL SACHET 1 ML",
                  "ElementID": 13337,
                  "DicoCaracID": 10000002,
                  "DicoCaracExtID": "##ProductLabel",
                  "TypeValue": 0,
                  "LangID": 1,
                  "ContextID": 1,
                  "Access": 0
                }
              },
              "WfStatus": null,
              "CharTemplateIDs": null,
              "Action": 0
            }
          }
        },
        "Warnings": [],
        "Errors": []
      }
    ;
    return data;
  }


}
