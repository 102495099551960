import {DataMocker} from '../../utils/Data/Mocking/data-mocker';
import {NpApiResult} from '../../model/np-api-result';
import {HttpRequest} from '@angular/common/http';


export class NpModelNaosCaracConfig extends DataMocker
{
  /**
   * Le pattern d'url pour les dicoCaracs
   */
  matchUrl(request: HttpRequest<any>): boolean {

      return (request.url.startsWith("/filehandler") && request.url.indexOf('ConfigEcran') > -1);
  }


  getUrlPattern(){
    return "";
  }
  /**
   * Retourne les objets dicoCarac
   */
  getData(): any {
    let data =
            {
                "IngPrioVueIngredient":[
                    {"Bloc":"MainTitle","DicoCaracExtID":"##ProductLabel","Links":["DC_INGREDIENT_DEPUIS_ING-FGC"],"ForcedState":"2"},
                    {"Bloc":"Title","DicoCaracExtID":"##ProductLabel","Links":["DC_MP_DEPUIS_ING-MP"],"ForcedState":"2"},
                    {"Bloc":"Repeater1","DicoCaracExtID":"DC_ING-FGC_DEPUIS_FGC"},
                    {"Bloc":"Repeater2","DicoCaracExtID":"DC_ING-MP_DEPUIS_INGREDIENT","Links":["DC_INGREDIENT_DEPUIS_ING-FGC"]},
                    {"Bloc":"Header","DicoCaracExtID":"","Component":"HeaderTitleProductNameComponent"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_NATURE_GENCODE","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_NOM_INCI_MP","Links":["DC_MP_DEPUIS_ING-MP"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_INGREDIENT-FGC_DEPUIS_INGREDIENT"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtIDLabel":"Etat (unknown)","DicoCaracExtID":"DC_SUBSTANCE_ACTIVE","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_FONCTION_DIP","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_FONCTION_COSING","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_DESIGNATION_BREVET_TECHNOLOGIE","Links":["DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_COPYWRITING_BREVET","Links":["DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_STATUT_BIODERMIQUE_INGREDIENT","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtIDLabel":"_Origine fournisseur","DicoCaracExtID":"DC_ORIGINES_DECLAREES_PAR_LE_FOURNISSEUR","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtIDLabel":"DC_ORIGINE_GENCODE_ING-ING-MP","DicoCaracExtID":"##ProductLabel","Links":["DC_ORIGINE_GENCODE_ING-ING-MP"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_DETAIL_ORIGINE","ForcedState":"2"}],
                "IngPrioVueMP":[
                    {"Bloc":"MainTitle","DicoCaracExtID":"##ProductLabel","Links":["DC_MP_DEPUIS_MP-FLE"],"ForcedState":"2"},
                    {"Bloc":"Title","DicoCaracExtID":"##ProductLabel","Links":["DC_INGREDIENT_DEPUIS_ING-MP"],"ForcedState":"2"},
                    {"Bloc":"Repeater1","DicoCaracExtID":"DC_MP-FLE_DEPUIS_FORMULE","Links":["DC_FORMULE_DEPUIS_FGC_REV"]},
                    {"Bloc":"Repeater2","DicoCaracExtID":"DC_ING-MP_DEPUIS_MP","Links":["DC_MP_DEPUIS_MP-FLE"]},
                    {"Bloc":"LinkIngredientPrioritaire","DicoCaracExtID":"DC_INGREDIENT_PRIORITAIRE","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_ING-FGC_DEPUIS_INGREDIENT"]},
                    {"Bloc":"SaisieIngredientPrioritaire","DicoCaracExtID":"DC_ORIGINE_GENCODE_ING-ING-MP","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_ING-FGC_DEPUIS_INGREDIENT","DC_INGREDIENT_PRIORITAIRE"]},
                    {"Bloc":"SaisieIngredientPrioritaire","DicoCaracExtID":"DC_DETAIL_ORIGINE","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_ING-FGC_DEPUIS_INGREDIENT"]},
                    {"Bloc":"SaisieIngredientPrioritaire","DicoCaracExtID":"DC_NATURE_GENCODE","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_ING-FGC_DEPUIS_INGREDIENT"]},
                    {"Bloc":"SaisieIngredientPrioritaire","DicoCaracExtID":"DC_DETAIL_NATURE","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_ING-FGC_DEPUIS_INGREDIENT"]},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_POURCENTAGE_INGREDIENT_MP"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_INGREDIENT-FGC_DEPUIS_INGREDIENT"]},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_NATURE_GENCODE"},
                    {"Bloc":"View_1","DicoCaracExtIDLabel":"Etat (unknown)","DicoCaracExtID":"DC_SUBSTANCE_ACTIVE","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_FONCTION_DIP","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_FONCTION_COSING","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_DESIGNATION_BREVET_TECHNOLOGIE","Links":["DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_COPYWRITING_BREVET","Links":["DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_STATUT_BIODERMIQUE_INGREDIENT","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtIDLabel":"_Origine fournisseur","DicoCaracExtID":"DC_ORIGINES_DECLAREES_PAR_LE_FOURNISSEUR","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtIDLabel":"DC_ORIGINE_GENCODE_ING-ING-MP","DicoCaracExtID":"##ProductLabel","Links":["DC_ORIGINE_GENCODE_ING-ING-MP"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_DETAIL_ORIGINE","ForcedState":"2"}],
                "NatureOrigineSaisie":[
                    {"Bloc":"MainTitle","DicoCaracExtID":"##ProductLabel","Links":["DC_INGREDIENT_DEPUIS_ING-FGC"],"ForcedState":"2"},
                    {"Bloc":"Title","DicoCaracExtID":"##ProductLabel","Links":["DC_MP_DEPUIS_ING-MP"],"ForcedState":"2"},
                    {"Bloc":"Repeater1","DicoCaracExtID":"DC_ING-FGC_DEPUIS_FGC"},
                    {"Bloc":"Repeater2","DicoCaracExtID":"DC_ING-MP_DEPUIS_INGREDIENT","Links":["DC_INGREDIENT_DEPUIS_ING-FGC"]},
                    {"Bloc":"Saisie","DicoCaracExtID":"DC_ORIGINE_GENCODE_ING-ING-MP"},
                    {"Bloc":"Saisie","DicoCaracExtID":"DC_DETAIL_ORIGINE"},
                    {"Bloc":"Saisie","DicoCaracExtID":"DC_NATURE_GENCODE"},
                    {"Bloc":"Saisie","DicoCaracExtID":"DC_DETAIL_NATURE"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_NATURE_GENCODE","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtIDLabel":"_Nom INCI MP","DicoCaracExtID":"##ProductLabel","Links":["DC_MP_DEPUIS_ING-MP"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_POURCENTAGE_INGREDIENT_MP","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_SUBSTANCE_ACTIVE","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_ROLE_DIP","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_FONCTION_COSING","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_DESIGNATION_BREVET_TECHNOLOGIE","Links":["DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_COPYWRITING_BREVET","Links":["DC_BREVET_DEPUIS_ING-MP_OBLIGATOIRE"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_STATUT_BIODERMIQUE_INGREDIENT","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_ORIGINES_DECLAREES_PAR_LE_FOURNISSEUR","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtIDLabel":"DC_ORIGINE_GENCODE_ING-ING-MP","DicoCaracExtID":"##ProductLabel","Links":["DC_ORIGINE_GENCODE_ING-ING-MP"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_DETAIL_ORIGINE","ForcedState":"2"}],
                "Roles":[
                    {"Bloc":"MainTitle","DicoCaracExtID":"##ProductLabel","Links":["DC_INGREDIENT_DEPUIS_ING-FGC"],"ForcedState":"2"},
                    {"Bloc":"Repeater1","DicoCaracExtID":"DC_ING-FGC_DEPUIS_FGC"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_NATURE_GENCODE","Links":["DC_INGREDIENT_PRIORITAIRE"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_NOM_INCI_MP","Links":["DC_INGREDIENT_PRIORITAIRE","DC_MP_DEPUIS_ING-MP"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_POURCENTAGE_CUMULE_DES_INGREDIENTS_DANS_LA_FORMULE_FORMULE_QUANTITATIVE","ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_STATUT_BIODERMIQUE_INGREDIENT","Links":["DC_INGREDIENT_PRIORITAIRE"],"ForcedState":"2"},
                    {"Bloc":"View_1","DicoCaracExtID":"##ProductLabel","Links":["DC_BREVET_DEPUIS_ING-FGC"],"ForcedState":"2"}],
                "CopyWritingProduit":[
                    {"Bloc":"1","DicoCaracExtID":"DC_COPYWRITING_PRODUIT"},
                    {"Bloc":"1","DicoCaracExtID":"DC_COPYWRITING_DETAIL_PRODUIT","Links":["DC_PF_DEPUIS_FGC"]},
                    {"Bloc":"1","DicoCaracExtID":"DC_CIBLE__INDICATION","Links":["DC_PF_DEPUIS_FGC"]},
                    {"Bloc":"1","DicoCaracExtID":"DC_CIBLE__SUJET","Links":["DC_PF_DEPUIS_FGC"]},
                    {"Bloc":"1","DicoCaracExtID":"DC_CIBLE__UTILISATION","Links":["DC_PF_DEPUIS_FGC"]},
                    {"Bloc":"2","DicoCaracExtID":"DC_PHOTOS","Links":["DC_PF_DEPUIS_FGC"]},
                    {"Bloc":"2","DicoCaracExtID":"DC_PHOTOSPACKAGING","Links":["DC_PF_DEPUIS_FGC"]},
                    {"Bloc":"2","DicoCaracExtID":"DC_QR_CODE","Links":["DC_PF_DEPUIS_FGC"]}],
                "CopywritingBrevet":[
                    {"Bloc":"MainTitle","DicoCaracExtID":"##ProductLabel","Links":["DC_BREVET_DEPUIS_FGC-BREVET"],"ForcedState":"2"},
                    {"Bloc":"Repeater1","DicoCaracExtID":"DC_FGC_BREVET_DEPUIS_FGC"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_COPYWRITING_BREVET","Links":["DC_BREVET_DEPUIS_FGC-BREVET"]},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_COPYWRITING_NOM_DU_BREVET"},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_COPYWRITING_NOM_DU_BREVET","Links":["DC_BREVET_DEPUIS_FGC-BREVET"]},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_PHOTOS","Links":["DC_BREVET_DEPUIS_FGC-BREVET"]},
                    {"Bloc":"View_1","DicoCaracExtID":"DC_VIDEO","Links":["DC_BREVET_DEPUIS_FGC-BREVET"]}],
                "CopywritingIngredient":[
                    {"Bloc":"MainTitle","DicoCaracExtID":"##ProductLabel","Links":["DC_INGREDIENT_DEPUIS_ING-FGC"],"ForcedState":"2"},
                    {"Bloc":"Title","DicoCaracExtID":"##ProductLabel","Links":["DC_MP_DEPUIS_ING-MP"],"ForcedState":"2"},
                    {"Bloc":"Repeater1","DicoCaracExtID":"DC_ING-FGC_DEPUIS_FGC"},
                    {"Bloc":"Saisie 1","DicoCaracExtIDLabel":"_DouvientCeChamps?","DicoCaracExtID":"##ProductLabel","Links":["DC_ROLE_DEPUIS_ING-FGC"],"ForcedState":"2"},
                    {"Bloc":"Saisie 1","DicoCaracExtID":"DC_COPYWRITING_NATURE","Links":["DC_PF_DEPUIS_FGC"]},
                    {"Bloc":"Saisie 1","DicoCaracExtIDLabel":"_CPYNOMBrevetInutileICI","DicoCaracExtID":"##ProductLabel","ForcedState":"2"},
                    {"Bloc":"Saisie 1","DicoCaracExtID":"##MediaFile","Links":["DC_ORIGINE_DEPUIS_ING-FGC","DC_PICTOS_ORIGINE"]},
                    {"Bloc":"Saisie 1","DicoCaracExtID":"DC_COPYWRITING_SUBSTANCE_SENSIBLE","Links":["DC_INGREDIENT_DEPUIS_ING-FGC"],"ForcedState":"2"},
                    {"Bloc":"Saisie 1","DicoCaracExtIDLabel":"_STATUTBIO EDITABLE ?","DicoCaracExtID":"DC_STATUT_BIODERMIQUE_INGREDIENT"},
                    {"Bloc":"Saisie 1","DicoCaracExtID":"DC_SOURCE_PREUVE_DU_COPYWRITING"},
                    {"Bloc":"Saisie 2","DicoCaracExtID":"DC_PHOTOS"},
                    {"Bloc":"Saisie 2","DicoCaracExtID":"DC_VIDEO"},
                    {"Bloc":"Saisie 1","DicoCaracExtID":"DC_COPYWRITING_NATURE_DETAILLE"},
                    {"Bloc":"Saisie 1","DicoCaracExtID":"DC_COPYWRITING_DETAIL_ROLE","Links":["DC_ROLE_DEPUIS_ING-FGC"],"ForcedState":"2"},
                    {"Bloc":"Saisie 1","DicoCaracExtIDLabel":"_CPYBrevetInutileICI","DicoCaracExtID":"##ProductLabel"},
                    {"Bloc":"Saisie 1","DicoCaracExtID":"DC_COPYWRITING_ORIGINE"},
                    {"Bloc":"Saisie 1","DicoCaracExtID":"DC_STATUT_COPYWRITING_BIODERMIQUE_"},
                    {"Bloc":"Saisie 1 ","DicoCaracExtIDLabel":"_CONSIGNES COPYWRITER","DicoCaracExtID":"Valeur en dur à forcer"}],
                "Synthese":[
                    {"Bloc":"Repeater1","DicoCaracExtID":"DC_ING-FGC_DEPUIS_FGC"},
                    {"Bloc":"View_1","BlocInfo":"Vue1","DicoCaracExtIDLabel":"DC_INGREDIENT_DEPUIS_ING-FGC","DicoCaracExtID":"##ProductLabel","Links":["DC_INGREDIENT_DEPUIS_ING-FGC"],"ForcedState":"2"},
                    {"Bloc":"View_1","BlocInfo":"Vue1","DicoCaracExtID":"DC_NATURE_GENCODE","ForcedState":"2"},
                    {"Bloc":"View_1","BlocInfo":"Vue1","DicoCaracExtID":"##ProductLabel","Links":["DC_ROLE_DEPUIS_ING-FGC"],"ForcedState":"2"},
                    {"Bloc":"View_1","BlocInfo":"Vue1","DicoCaracExtIDLabel":"__Statut","DicoCaracExtID":"##ProductLabel","ForcedState":"2"},
                    {"Bloc":"View_1","BlocInfo":"Vue1","DicoCaracExtID":"DC_COPYWRITING_ORIGINE","ForcedState":"2"},
                    {"Bloc":"View_1","BlocInfo":"Vue1","DicoCaracExtID":"DC_DETAIL_NATURE","ForcedState":"2"}],
                "FtIngPrio":[
                    {"Bloc":"LinkIngredientPrioritaire","DicoCaracExtID":"DC_INGREDIENT_PRIORITAIRE","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_ING-FGC_DEPUIS_INGREDIENT"]},
                    {"Bloc":"Prefill-DCOrigine","DicoCaracExtID":"DC_ORIGINE_GENCODE_ING","BlocInfo":"Source"},
                    {"Bloc":"Prefill-DCOrigine","DicoCaracExtID":"DC_ORIGINE_GENCODE_ING","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_ING-FGC_DEPUIS_INGREDIENT"],"BlocInfo":"Dest"},
                    {"Bloc":"Prefill-Origine","DicoCaracExtID":"DC_DETAIL_ORIGINE","BlocInfo":"Source"},
                    {"Bloc":"Prefill-Origine","DicoCaracExtID":"DC_DETAIL_ORIGINE","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_ING-FGC_DEPUIS_INGREDIENT"],"BlocInfo":"Dest"},
                    {"Bloc":"Prefill-NatureGencode","DicoCaracExtID":"DC_NATURE_GENCODE","BlocInfo":"Source"},
                    {"Bloc":"Prefill-NatureGencode","DicoCaracExtID":"DC_NATURE_GENCODE","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_ING-FGC_DEPUIS_INGREDIENT"],"BlocInfo":"Dest"},
                    {"Bloc":"Prefill-Nature","DicoCaracExtID":"DC_DETAIL_NATURE","BlocInfo":"Source"},
                    {"Bloc":"Prefill-Nature","DicoCaracExtID":"DC_DETAIL_NATURE","Links":["DC_INGREDIENT_DEPUIS_ING-MP","DC_ING-FGC_DEPUIS_INGREDIENT"],"BlocInfo":"Dest"}]}
          ;
    return data;
  }


}
