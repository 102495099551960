import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WsParamsService} from './ws-params.service';
import {first, flatMap, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AdvancedSearchDTO} from '../model/search-model';
import {ElementsHelper} from '../helpers/elements.helper';
import {NPSearchResult} from '../model/np-search-result';


@Injectable({
    providedIn: 'root',
})
export class SearchRepository {

    private _urlGetAdvancedSearch = '/api/sdk/AdvancedSearch/Get/';
    private _urlSearch = '/api/sdk/AdvSearch/Find';

    constructor(private _http: HttpClient, private _wsParamsService: WsParamsService) {
    }

    public getAdvancedSearch(searchID: number): Observable<AdvancedSearchDTO> {
        return this._wsParamsService.getParams().pipe(
            flatMap((params) => {
                return this._http.post(this._urlGetAdvancedSearch, {ID: searchID, TicketID: ''});
            }),
            map((result: AdvancedSearchDTO) => {
                return result;
            })
        );
    }

    public Search(config: any): Observable<NPSearchResult> {
        return this._wsParamsService.getParams().pipe(
            flatMap((params) => {
                return this._http.post(this._urlSearch, config);
            }),
            map((data) => {
                if (data.hasOwnProperty('Results')) {
                    if (data['Results'] != null) {
                        const resultCount: number = data['ResultCount'];
                        const resultsElements = ElementsHelper.rebuild(data['Results']);
                        const resultsElementsArray = [];
                        resultsElements.forEach((element => {
                            resultsElementsArray.push(element);
                        }));
                        return {
                            resultsCount: resultCount,
                            elements: resultsElementsArray
                        };
                    }
                } else {
                  return {
                    resultsCount: 0,
                    elements: []
                  };
                }
            }),
            first(),
        );
    }

}
