import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NpIntercept} from './np-intercept';


// http://jasonwatmore.com/post/2017/12/15/angular-5-mock-backend-example-for-backendless-development

@NgModule({
  providers: [
    /* HttpClient utilise un "injecteur" => dans ce cas, on lui fournit le notre. Multi=true signifie qu'il peut y en avoir plusieurs qui sont chainés */
    {provide: HTTP_INTERCEPTORS, useClass: NpIntercept, multi: true},
  ],
  imports: [
    CommonModule,
  ],
  declarations: [],

})
export class DataMockingModule {
}
