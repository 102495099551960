import {Constantes, DataMocker, NpApiResult} from '@nextpage/np-sdk-data';
import {Observable} from 'rxjs/Observable';
import {HttpEvent, HttpHandler, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
/**
 * Ce dataMocker a pour objectif de rediriger les requetes envoyées vers "sdk" vers un sdk-debug en ajoutant le token
 */
export class DataMockerSdkdebugCogedim extends DataMocker {
    /**
     * Url de base du site web=> à modifier en fonction des besoins du développeur
     * ATTENTION, il doit y avoir un / à la fin
     */
    private _baseUrl: string;

    /**
     * Header contenant le token =>à modifier en fonction des besoins du développeur
     */
    private readonly headers: HttpHeaders;

    private readonly fileHeaders: HttpHeaders;

    constructor(private _constantes: Constantes) {
        super();
        this._baseUrl = _constantes.baseUrl;

        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'ApiToken': this._constantes.token
        });
        this.fileHeaders = new HttpHeaders({
            'ApiToken': this._constantes.token,
        });
    }

    /**
     * Redirige les requetes vers les urls de debug
     * Pour utiliser cette classe, il faut spécifier l'url du projet (racine) ainsi que le tocken à envoyer
     */
    mokeResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newRequest: HttpRequest<any>;

        if (request.url.startsWith('/filehandler') && request.url.indexOf('CogedimScreen') > -1) {
            newRequest = request.clone({
                url: './assets/CogedimScreen.json'
            });
            return next.handle(newRequest);
        }

        if (request.url.startsWith('/filehandler')) {
            newRequest = request.clone({
                url: request.url,
                headers: this.fileHeaders
            });
            return next.handle(newRequest);
        }

        if (request.url.indexOf('uitranslation') > -1) {
            newRequest = request.clone({
                url: './assets/UiTranslations.json',
            });
            return next.handle(newRequest);
        }

        // Chargement du fichier word

        if (request.url.startsWith('/assets/workingFiles')) {

            newRequest = request.clone({
                url: './assets/workingFiles/tag-example.docx'
            });
            return next.handle(newRequest);
        }

        if (request.url.indexOf('GeneratePDF') !== -1) {

            newRequest = request.clone({
                url: request.url,
                headers: this.fileHeaders
            });
            return next.handle(newRequest);
        }


        /**
         * Si l'adresse commence par /api/
         * On remplace sdk par sdk-debug
         */
        const regex = /sdk/gi;

        // les APIs sendMail et SendMailPasswordForgotten n'ont pas besoin de headers
        if (request.url.startsWith('/api') && request.url !== '/api/sdk/mail/sendmail' && request.url !== '/api/sdk-debug/mail/SendMailPasswordForgotten') {
            /*newRequest = request.clone({
                url: this._baseUrl + request.url.replace(regex, 'sdk-debug'),
                headers: this.headers
            });*/

            const url = request.url.replace(regex, 'sdk-debug').replace('sdk-debug-ext', 'sdk-ext');
            if (request.url.indexOf('UpdateMedia') > -1) {
                newRequest = request.clone({
                    url: this._baseUrl + url,
                    headers: this.fileHeaders
                });
            } else {
                newRequest = request.clone({
                    url: this._baseUrl + url,
                    headers: this.headers
                });
            }

        } else {
            newRequest = request.clone({
                url: request.url
            });
        }
        return next.handle(newRequest);
    }

    matchUrl(request: HttpRequest<any>): boolean {
        return true;
    }

    getData(): NpApiResult {
        return null;
    }

    getUrlPattern(): string {
        return '';
    }

    protected getToken(): string {
        return this._constantes.token;
    }

}
