import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, Validators} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {GenCatalogueService} from '../../../data/services/gen-catalogue.service';
import {CatalogueSummary, ExportExcelSummary} from '../../../data/Model/models';
import {CogedimHomeEditionItems} from '../../../data/constantes/cogedim-constantes';
import {ExportExcelService} from '../../../data/services/export-excel/export-excel.service';

@Component({
    selector: 'app-dialog-catalogue-name',
    templateUrl: './dialog-catalogue-name.component.html',
    styleUrls: ['./dialog-catalogue-name.component.scss']
})
export class DialogCatalogueNameComponent implements OnInit {

    public fileNameForm = new FormControl(null, [
        Validators.required
    ]);
    private _fileName = '';
    private _hasError = false;
    private _noticeDescriptiveToSend: CatalogueSummary;
    private _exportExcelToSend: any;
    public isSaving = false;
    public publishError: boolean;
    public errorName: string;
    private documentType: CogedimHomeEditionItems;
    public waitingMessage = '';
    public istExcelExport = false;
  public isKitSheet = false;

    constructor(public dialogRef: MatDialogRef<DialogCatalogueNameComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _genCatService: GenCatalogueService,
                private _exportExcelService: ExportExcelService) {
    }

    ngOnInit() {
        if (this.data) {
            this.documentType = this.data.documentType ? this.data.documentType : null;
            this._noticeDescriptiveToSend = this.data.object ? this.data.object : null;
            switch (this.documentType) {
                case CogedimHomeEditionItems.EXCEL_EXPORT: {
                    this.istExcelExport = true;
                    this.waitingMessage = `Génération de l'export en cours`;
                    this._exportExcelToSend = this.data.object ? this.data.object : null;
                    break;
                }
                case CogedimHomeEditionItems.DESCRIPTIVE_NOTICE: {
                    this.waitingMessage = 'Création du catalogue en cours';
                    this._noticeDescriptiveToSend = this.data.object ? this.data.object : null;
                    break;
                }
              case CogedimHomeEditionItems.KIT_SHEET: {
                this.isKitSheet = true;
                // console.log('this.data.object : ', this.data.object);
              }
            }
        }

        this.fileNameForm.valueChanges
            .pipe(
                tap((newValue: string) => {
                    this._hasError = !(newValue && newValue.trim() !== '');
                })
            )
            .subscribe((newValue: string) => {
                this._fileName = newValue && newValue.trim() !== '' ? newValue : '';
            });

    }


    onValidate() {
        if (!(this._fileName && this._fileName.trim() !== '')) {
            this._hasError = true;
            this.fileNameForm.reset();
        } else {

            switch (this.documentType) {
                case CogedimHomeEditionItems.EXCEL_EXPORT: {
                    this._executeExportExcel();
                    break;
                }
                case CogedimHomeEditionItems.DESCRIPTIVE_NOTICE: {
                    this._executeNoticeDescriptive();
                    break;
                }
              case CogedimHomeEditionItems.KIT_SHEET: {
                this._executeNoticeDescriptive();
              }
            }

            this.fileNameForm.reset();
        }

    }

    private _executeNoticeDescriptive() {
      this._noticeDescriptiveToSend.PdfFileName =
        `${this._fileName.replace(/ /g, '_')}${this.isKitSheet ? '' : '.docx'}`;
        this._startSaving();
        this._genCatService.genCatalogue(this._noticeDescriptiveToSend)
            .subscribe(_result => {
                this._stopSaving();
                this.dialogRef.close('Votre catalogue a été généré avec succès !');
            }, (error) => {
                this._stopSaving();
                this.publishError = true;
                this.errorName = `Une erreur s'est produite lors de la création du catalogue`;
            });
    }

    onCancel() {
        this.dialogRef.close(0);
    }

    shouldShowTitleRequiredError() {
        return (this.fileNameForm.touched && this.fileNameForm.invalid) || this._hasError;
    }

    private _startSaving() {
        this.isSaving = true;
    }

    private _stopSaving() {
        this.isSaving = false;
    }

    private _executeExportExcel() {
        this._startSaving();
        const exportExcelSummary: ExportExcelSummary = {
            fileName: this._fileName.replace(/ /g, '_'),
            userEMail: this._noticeDescriptiveToSend.Mail,
            operationId: this.data.operationId
        };
        this._exportExcelService.sendObject(exportExcelSummary)
            .subscribe(() => {
                this._stopSaving();
                this.dialogRef.close(`Un mail contenant le lien de téléchargement du fichier vous a été envoyé !`);
            }, error => {
                this._stopSaving();
                this.publishError = true;
                this.errorName = error;
            });
    }
}
