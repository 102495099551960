import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {CollectionService} from '@sdk/data/services/collection.service';
import {CaracConfigRepositoryService} from '@sdk/data/services/carac-config-repository.service';
import {CogedimDicocarcs} from '@sdk/data/constantes/cogedim-dicocarcs';
import {CaracConfig, NPListeValues} from "@nextpage/np-sdk-data";


@Component({
    selector: 'app-cogemim-combobox',
    templateUrl: './cogedim-combobox.component.html',
    styleUrls: ['./cogedim-combobox.component.scss']
})
export class CogedimComboboxComponent implements OnInit, OnDestroy, AfterViewInit {

    caracConfig: CaracConfig;
    @Output() selected = new EventEmitter<NPListeValues>();

    public displayValues: NPListeValues[] = [];
    public selectedValue: NPListeValues;
    public dropDownControl = new FormControl();
    private _formCtrlSub: Subscription;

    constructor(private _collectionService: CollectionService,
                private _caracConfigRepository: CaracConfigRepositoryService) {
    }

    ngOnInit() {
        this._caracConfigRepository.getConfigsByKeyName(CogedimDicocarcs.DC_NOM_COLLECTION_CHOISIE_BLOC_CONTENT)
            .pipe(
                map(data => data.find(d => d.DicoCaracExtID === CogedimDicocarcs.NO_USED_COLLECTION_SELECTOR)),
                filter(currCarac => currCarac !== undefined)
            )
            .subscribe(currentCarac => {
                this.caracConfig = currentCarac.ConfigurationObject.find(cc => cc.DicoCaracExtID === CogedimDicocarcs.DC_NOM_COLLECTION_CHOISIE_COLLECTION);
                this.displayValues = this.caracConfig && this.caracConfig.DicoCarac ? this.caracConfig.DicoCarac.Values : [];
                this._collectionService.collections = this.displayValues; // On stocke la liste des collections
                this._saveChanges();
            });

    }

    /**
     * Initialisation des la valeur par défaut dans le Select: si une valeur a été
     * sélectionnée précédemment (cas du retours vers l'écran Opération), celle-ci est définie pat défaut
     */
    ngAfterViewInit(): void {
        const tmpPrecedentSelectedValue = this._collectionService.getSavedCollectionTypeId();
        this.selectedValue = tmpPrecedentSelectedValue ? this.displayValues.find(item => item.ValeurID === Number(tmpPrecedentSelectedValue)) : undefined;
        // this.dropDownControl.patchValue(tmpPrecedentSelectedValue ? Number(tmpPrecedentSelectedValue) : null);
        // this.selectOption(this.selectedValue);
    }

    private _saveChanges() {
        // Ecoute et applique les changements dans le champ Select
        this._formCtrlSub = this.dropDownControl.valueChanges
            .pipe(
                map(data => Number(data))
            )
            .subscribe(selectedValueID => {
                this.selectOption(selectedValueID);
            });
    }

    /**
     * Sélectionne l'option
     */
    private selectOption(selectedValueID: number) {
        this.selectedValue = isNaN(selectedValueID) ? null : this.displayValues.find(value => value.ValeurID === selectedValueID);
        this._collectionService.setCurrentCollectionType(this.selectedValue);
        this.selected.emit(this.selectedValue);
    }

    ngOnDestroy() {
        this._collectionService.setSavedCollectionTypeId(this.selectedValue ? String(this.selectedValue.ValeurID) : undefined);
        if (this._formCtrlSub) {
            this._formCtrlSub.unsubscribe();
        }
    }

}
