import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {SynopticDownloadService} from '../../sdk/data/services/synoptic-download.service';
import {SynopticReaderService} from '../../sdk/data/services/synoptic-reader.service';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {CogedimConstantes} from '../../sdk/data/constantes/cogedim-constantes';
import {SynopticWriterService} from '../../sdk/data/services/synoptic-writer.service';
import {SynopticFileResponse} from '../../sdk/data/Model/synoptic';
import {SynopticMessages} from '../../sdk/data/constantes/synoptic-constants';

class SynopticDialogParam {
    currentOperationID: number;
    chosenCollection: string;
    currentUserID: number;
    currentCollectionID: number;
}

@Component({
    selector: 'app-dialog-synoptic-preloader',
    templateUrl: './dialog-synoptic-preloader.component.html',
    styleUrls: ['./dialog-synoptic-preloader.component.scss']
})
export class DialogSynopticPreloaderComponent implements OnInit {

    public loading = true;
    public success = true;
    public response: SynopticFileResponse;
    public error: string;

    constructor(public dialogRef: MatDialogRef<DialogSynopticPreloaderComponent>,
                @Inject(MAT_DIALOG_DATA) public data: SynopticDialogParam,
                private _synopticDownloadService: SynopticDownloadService,
                private _synopticReaderService: SynopticReaderService,
                private _synopticWriterService: SynopticWriterService,
                @Inject(forwardRef(() => ModalService)) private _modalService: ModalService) {
    }

    ngOnInit() {
        this._startLoading();
        this._generateSynoptic();
    }

    private _generateSynoptic() {
        if (this.data && this.data.currentOperationID && this.data.chosenCollection && this.data.currentUserID) {
            this._startLoading();
            this._synopticWriterService.generate(this.data.currentOperationID,
                Number(this.data.currentCollectionID),
                this.data.chosenCollection,
                this.data.currentUserID)
                .subscribe(responseTest => {
                    this._stopLoading();
                    if (responseTest && responseTest.success) {
                        this._modalService.openSnackBar(responseTest.message, CogedimConstantes.SNACK_CLASS);
                        this.dialogRef.close();
                    } else {
                        this.response = responseTest;
                    }
                }, error => {
                    this._stopLoading();
                    if (error instanceof SynopticFileResponse) {
                        this.response = error;
                    } else {
                        this.response = new SynopticFileResponse();
                        this.response.errors.push(SynopticMessages.GENERATING_ERROR);
                    }
                });
        }

    }

    private _startLoading() {
        this.loading = true;
    }

    private _stopLoading() {
        this.loading = false;
    }

}
