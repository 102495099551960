import {NPElement} from '../model/np-element';
import {NiveauAccesDonnee, NPCaracLien, NPCaracLienValue} from '../model/np-carac-valeur';


export class ElementHelper {

  static getLinkedBySteps(elt: NPElement, Steps: string[]) {
    return ElementHelper.getLinkedByStepsFromMany([elt], Steps);
  }

  /**
   * Indique si l'élément contient un CharTemplate donné par l'ExtID
   */
  static isOfCharTemplate(Element: NPElement, CTExtID: string) {
    if (Element == null || Element.getValueCharTemplate() == null)
      return false;

    return (Element.getValueCharTemplate().CharTemplates
      .filter((ct) => ct.CharTemplateExtID == CTExtID).length > 0);

  }


  static getLinkedByStepsFromMany(elt: Array<NPElement>, Steps: string[]): NPElement[] {
    // création de la liste temporaire de résultats
    // cette liste permettra de renseigner les objets présents à une étape donnée.
    // au démarrage (étape 0), elle contient l'élément passé en paramètres (si il n'y a pas de chemin, on retournera cet élément)
    let tmpResults: Array<NPElement> = [];
    tmpResults = tmpResults.concat(elt);
    // parcourt des étapes
    Steps.forEach(function (step) {
      // résultat intermédiaire : parmi les objets "courant", on retourne tous les objets qui leur sont liée par l'étape en cours (DicoCarac)
      const tmpResults2: Array<NPElement> = [];
      // pour l'étape courante on va chercher les liens des éléments
      tmpResults.forEach(function (el) {
        // récupération de la caractéristique
        const Val = (el.getValue(step) as NPCaracLien);

        if (Val !== undefined) {
          const linkedElements = Val.RebuildLinkedElements;
          // pour chaque element lié on va chercher l'objet NPElement
          if (linkedElements && linkedElements.length > 0) {
            linkedElements.forEach((linkedElement) => {
              tmpResults2.push(linkedElement.Element);
            });
          }
        }
      });
      // on se déplace d'une étape : on met les résultats nouvellement trouvés dans les résultats de l'étape courante
      tmpResults = tmpResults2;
    });
    return tmpResults;
  }

  /**
   * Compare 2 éléments entre eux pour savoir si il sont identique par leurs identifiants
   */
  static elementsAreSame(elt1: NPElement, elt2: NPElement): boolean {
    return ElementHelper.IDExtIDAreSame(elt1.ID, elt1.ExtID, elt2.ID, elt2.ExtID);
  }

  /**
   * Compare 2 éléments entre eux pour savoir si il sont identique par leurs identifiants
   */
  static linksAreSame(link1: NPCaracLienValue, link2: NPCaracLienValue): boolean {
    return ElementHelper.IDExtIDAreSame(link1.ElementID, link1.ElementExtID, link2.ElementID, link2.ElementExtID);
  }

  /**
   * Compare 2 système d'identifiants entre eux (ID / ExtID)
   */
  static IDExtIDAreSame(elt1ID: number, elt1ExtID: string, elt2ID: number, elt2ExtID: string): boolean {
    if (elt1ID > 0 && elt2ID > 0) {
      return elt1ID === elt2ID;
    } else {
      return elt1ExtID === elt2ExtID;
    }
  }

  /**
   * Indique si l'utilisateur a les droits pour
   */
  static statusHelper_HasMinimumRightFor(Niveau: NiveauAccesDonnee, NiveauMinimum: NiveauAccesDonnee) {
    return (Niveau as number) <= (NiveauMinimum as number);
  }
}
