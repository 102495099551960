import {Constantes, DataModelMocker} from '@nextpage/np-sdk-data';
import {Injectable} from '@angular/core';
import {DataMockerSdkdebugCogedim} from './data-mocker-sdkdebug-cogedim';

@Injectable({providedIn: 'root'})
export class DataMockerSdkdebugCogedimFull extends DataModelMocker {
    constructor(private _constantes: Constantes) {
        super();
        super.AddMocker(new DataMockerSdkdebugCogedim(_constantes));
    }
}
