import {CaracConfig, NPElement, ValueOneElementHelper} from '@next-page/np-sdk-data';
import {CaracIdStateValue} from '@next-page/np-sdk-graphics';


export class CogedimBlockHelper {

    private static _valueElementHelper: ValueOneElementHelper = new ValueOneElementHelper();

    public static getPrefix(blockName: string): string {
        return blockName.split('_')[0];
    }

    public static getBlocks(caracConfigs: CaracConfig[]) {
        // return Object.values(caracConfigs
        //     .reduce((accumulator, currentCaracConfig) => {
        //         const prefix = BiocoopBlockHelper.getPrefix(currentCaracConfig.Bloc);
        //         if (accumulator[prefix] == null) {
        //             accumulator[prefix] = {
        //                 blockPrefix: prefix,
        //                 caracConfigs: []
        //             };
        //         }
        //         accumulator[prefix].caracConfigs.push(currentCaracConfig);
        //         return accumulator;
        //     }, {}));
        const tmpObjects =  caracConfigs
            .reduce((accumulator, currentCaracConfig) => {
                const prefix = CogedimBlockHelper.getPrefix(currentCaracConfig.Bloc);
                if (accumulator[prefix] == null) {
                    accumulator[prefix] = {
                        blockPrefix: prefix,
                        caracConfigs: []
                    };
                }
                accumulator[prefix].caracConfigs.push(currentCaracConfig);
                return accumulator;
            }, {});
        const propertyName = Object.getOwnPropertyNames(tmpObjects)[0];
        return propertyName ? tmpObjects[propertyName] : undefined;
    }

    public static getTitleCaracIdStateValue(element: NPElement, caracConfigs: CaracConfig[]): CaracIdStateValue {
        const titleCaracConfig = caracConfigs.filter((caracConfig) =>
            caracConfig.BlocInfo === 'Title'
        )[0];

        return {
            value: CogedimBlockHelper._valueElementHelper.getCaracValue(element, titleCaracConfig),
            config: titleCaracConfig,
            element: element,
            baseElement: element
        };
    }

    public static getValue(caracConfig: CaracConfig, element: NPElement): CaracIdStateValue {
        const tmpCaraValue = element.getValue(caracConfig.DicoCaracExtID);
        if (tmpCaraValue) {
            return {
                value: element.getValue(caracConfig.DicoCaracExtID),
                config: caracConfig,
                element: element,
                baseElement: element
            };
        }
        return null;
    }
}
