import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from '@sdk/shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {SdkDataModule} from '@sdk/data/sdk-data.module';
import {CogedimSpeModule} from './cogedim-spe/cogedim-spe.module';

import {NpSdkGraphicsModule} from "@nextpage/np-sdk-graphics";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    SdkDataModule,
    CogedimSpeModule,
    NpSdkGraphicsModule
  ],
  providers: environment.dataMocker,
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
