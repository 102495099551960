import {CogedimConstantes, CogedimHomeEditionItems} from '../constantes/cogedim-constantes';
import {CaracConfig, DicoCarac, NPElement} from '@nextpage/np-sdk-data';
import {DicoCaracsConfiguration} from '../configuration/dicocaracs.configuration';
import {CogedimDicocarcs} from '../constantes/cogedim-dicocarcs';
import {BuildingModuleHelper} from '../helpers/building-module-helper';

/**
 * Objet de Recherche
 */
export class SearchItem {
    DC_ADRESSE = '';
    DC_N_PRIMPROMO = '';
    DC_ETAT_OPERATION = '';
    DC_NOM_COMMERCIAL = '';
    FAMILY_ID: number;
    DC_VILLE?: '';
}

export class ActionButton {
    url: string;
    imageName: string;
    isFontAwesome: boolean;
    action?: string;
}

/**
 * Tableau de collection
 */
export class CollectionSummary {
    labelConfig: CaracConfig;
    collectionName: CaracConfig;
    witnessField: CaracConfig;
    startDateOfWork: CaracConfig;
    caracConfigs: CaracConfig[] = [];

    constructor(caracConfigs: CaracConfig[] = []) {
        this.caracConfigs = caracConfigs;
        this.labelConfig = this.getCaracByExtID(CogedimConstantes.DC_LIBELLE_COLLECTION);
        this.collectionName = this.getCaracByExtID(CogedimConstantes.DC_NOM_COLLECTION_CHOISIE_COLLECTION);
        this.witnessField = this.getCaracByExtID(CogedimConstantes.DC_DATE_TEMOIN);
        this.startDateOfWork = this.getCaracByExtID(CogedimConstantes.DC_DATE_DEBUT_TRAVAUX_DROC);
    }

    private getCaracByExtID(pExtID: string) {
        return this.caracConfigs.find(cc => cc.DicoCaracExtID === pExtID);
    }
}

/**
 * Résultat de recherche
 */
export class ResultItemSummary {
    public name: string;
    public code: string;
    public city: string;
    public address: string;
    private _getTextValue = (CaracExtId: string, element: NPElement) => element ? element.getValueTextValue(CaracExtId) : '';

    constructor(element: NPElement) {
        // this.name = this._getTextValue(DicoCarac.PRODUCT_LABEL, element);
        this.name = this._getTextValue(CogedimDicocarcs.DC_NOM_COMMERCIAL, element);
        this.code = this._getTextValue(DicoCaracsConfiguration.COG_PRIMPROMO, element);
        this.city = this._getTextValue(DicoCaracsConfiguration.COG_VILLE, element);
        this.address = this._getTextValue(DicoCaracsConfiguration.COG_ADRESSE, element);
    }
}

export interface KitFamilySummary {
    value: string;
    label: string;
    children: NPElement[];
    description: string;
}

export class KitSummary {
    MarqueAutre: CaracConfig;
    Prix: CaracConfig;
    NotaPortail: CaracConfig;
    PortailImage: CaracConfig;
    NomProduitOuKit: CaracConfig;
    WebDescription: CaracConfig;
    DateFinFabrication: CaracConfig;
    DossierKit: CaracConfig;

    constructor(configs: CaracConfig[] = []) {
        this.MarqueAutre = this._getConfByExtID(CogedimDicocarcs.DC_MARQUE_AUTRES, configs);
        this.Prix = this._getConfByExtID(CogedimDicocarcs.DC_PRIX_PLUS_PRODUIT, configs);
        this.NotaPortail = this._getConfByExtID(CogedimDicocarcs.DC_NOTA_PORTAIL, configs);
        this.PortailImage = this._getConfByExtID(CogedimDicocarcs.DC_PORTAIL_IMAGES, configs);
        this.NomProduitOuKit = this._getConfByExtID(CogedimDicocarcs.DC_NOM_DU_PRODUIT_OU_KIT, configs);
        this.WebDescription = this._getConfByExtID(CogedimDicocarcs.DC_WEB_DESCRIPTIF, configs);
        this.DateFinFabrication = this._getConfByExtID(CogedimDicocarcs.DC_DATE_FIN_FABRICATION, configs);
        this.DossierKit = this._getConfByExtID(CogedimDicocarcs.DC_DOSSIER_KIT, configs);
    }

    private _getConfByExtID(dicoExtID: string, configs: CaracConfig[] = []) {
        return configs.find(cc => cc.DicoCaracExtID === dicoExtID);
    }
}

export interface Breadcrumb {
    name: string;
    route: string;
    params: any;
    collectionTitle?: string;
}

export class ErrorConfig {
    caracConfig: CaracConfig;
    element: NPElement;
    label: string;
    data: any;

    constructor(caracConfig: CaracConfig, element: NPElement, data: any = {}) {
        this.caracConfig = caracConfig;
        this.element = element;
        this.data = data;
    }
}

export interface ImageObject {
    src: string;
    thumbSrc: string;
    originalSrc?: string;
    alt: string;
}

export class CatalogueSummary {
    Title = '';
    Mail = '';
    Language = 'fr';
    PdfFileName = '';
    References: string[] = [];
    Mode = 1;
}

export class KitMap {
    collection: NPElement;
    kits: NPElement[] = [];

    constructor(collection: NPElement) {
        if (collection) {
            this.collection = collection;
            this.kits = this.collection.Children ?
                Object.assign([], this.collection.Children) : [];
        }
    }
}


export interface EditionHomeItem {
    vignetteName: string;
    itemType: CogedimHomeEditionItems;
    buttonLabel: string;
    toolTip: string;
}

export class UploadNotice {
    element: NPElement;
    collection: string;
    uploadDate: string;
    file: File;
    fileName: string;
    active = false;
    name: string;
    description: string;

    constructor(element?: NPElement) {
        if (element) {
            this.element = element;
            this.fileName = UploadNotice.getNoticeLabel(element);
            this.name = UploadNotice.getValueTextValue(element, DicoCarac.PRODUCT_LABEL);
            this.description = UploadNotice.getValueTextValue(element, CogedimDicocarcs.DC_WEB_NOTICE_DESCRIPTIVE_MODIFICATIONS);
        }
    }

    static getNoticeLabel(element: NPElement) {
        const mediaLink = element.getValueLien(CogedimDicocarcs.NOTICE_MEDIA_FILE);
        if (mediaLink && mediaLink.RebuildLinkedElements && mediaLink.RebuildLinkedElements.length > 0) {
            return mediaLink.RebuildLinkedElements[0].Element
                ? mediaLink.RebuildLinkedElements[0].Element.getValueTextValue(DicoCarac.MEDIA_LABEL) : '';
        } else {
            return '';
        }
    }

    static getValueTextValue(element: NPElement, dicoCaracExtId: string) {
        return element && element.getValueTextValue(dicoCaracExtId) !== '' ? element.getValueTextValue(dicoCaracExtId) : 'Inconnu';
    }

}

export class UploadNoticeConfigs {
    public dateConfig: any;
    public descriptionConfig: any;
    public mediaConfig: any;

    constructor(configs: CaracConfig[]) {
        if (configs && configs.length > 0) {
            this.dateConfig = configs.find(carac => carac.DicoCaracExtID === CogedimDicocarcs.DC_DATE_UPLOAD_NOTICE);
            this.descriptionConfig = configs.find(carac => carac.DicoCaracExtID === CogedimDicocarcs.DC_WEB_NOTICE_DESCRIPTIVE_MODIFICATIONS);
            this.mediaConfig = configs.find(carac => carac.DicoCaracExtID === CogedimDicocarcs.NOTICE_MEDIA_FILE);
        }
    }
}

export interface ExportExcelSummary {
    fileName: string;
    operationId: number;
    userEMail: string;
}


export class ItemCaracLie {
    value: number;
    att: number;
    constructor(value: number, att: number) {
        this.value = value;
        this.att = att;
    }
}

export class SummaryNavBar {
  extId: string;
  label: string;
  element: NPElement;
  caracConfig: CaracConfig;
  isOtherProductBlock = false;
  isHomeEditionBlock = false;

  constructor(value) {
    if (value.hasOwnProperty('DicoCaracExtID')) {
      this.label = value.Label;
      this.caracConfig = value;
      this.extId = value.DicoCaracExtID;
    } else if (value) {
      this.label = value.getValueTextValue(CogedimDicocarcs.DC_CHANNEL_LABEL);
      this.element = value;
      this.extId = value.ExtID;
    }
  }
}

export enum WarningDialogResponse {
    SAVE_MODIFICATIONS = '1',
    CANCEL_MODIFICATIONS = '-1',
    NOT_THING = '0',
}

export class TableRowPDF {
    familyName: string;
    subFamilyName: string;
    productName: string;
    marque: string;
    webDesc: string;
    posed: string;
    whyDesc: string;

    constructor(element2: NPElement, tableElements: TableRowItem[], buildingModuleElement?: BuildingElementWrapper) {
        if (buildingModuleElement && buildingModuleElement.kit) {
            const element = buildingModuleElement.kit;
            this.familyName = this._valueText(element.Parent.Parent || null, DicoCarac.FAMILY_LABEL);
            this.subFamilyName = this._valueText(element.Parent || null, DicoCarac.FAMILY_LABEL);
            this.productName = this._valueText(element, CogedimDicocarcs.DC_NOM_DU_PRODUIT_OU_KIT);
            this.marque = this._valueText(element, CogedimDicocarcs.DC_MARQUE_AUTRES);
            this.webDesc = this._valueText(element, CogedimDicocarcs.DC_WEB_DESCRIPTIF);
            this.posed = buildingModuleElement.getPosedValue();
            this.whyDesc = buildingModuleElement.getWhyValue();
        }

        /*if (element) {
            this.familyName = this._valueText(element.Parent.Parent || null, DicoCarac.FAMILY_LABEL);
            this.subFamilyName = this._valueText(element.Parent || null, DicoCarac.FAMILY_LABEL);
            this.productName = this._valueText(element, CogedimDicocarcs.DC_NOM_DU_PRODUIT_OU_KIT);
            this.marque = this._valueText(element, CogedimDicocarcs.DC_MARQUE_AUTRES);
            this.webDesc = this._valueText(element, CogedimDicocarcs.DC_WEB_DESCRIPTIF);

            const value: TableRowItem = this._getValueFromTable(tableElements, element.ExtID);
            this.posed = value ? value.posedCaracLabel : 'Non';
            this.whyDesc = value ? value.whyCaracLabel : '';
        }*/
    }

    private _valueText(element: NPElement, dicoCaracExtId: string) {
        return element ? element.getValueTextValue(dicoCaracExtId) : '';
    }

    private _getValueFromTable(tableElements: TableRowItem[], elementExtId: string) {
        return tableElements.find(c => c.productExtId === elementExtId);
    }
}


export class TableRowItem {
    productExtId = '';
    productLabel = '';
    posedCaracLabel = '';
    whyCaracLabel = '';
}

export class BuildingElementWrapper {
    kit: NPElement;
    buildModuleElement: NPElement;
    private _posedConfig: CaracConfig;

    constructor(kit: NPElement, buildModuleElement: NPElement, posedConfig: CaracConfig) {
        this.kit = kit;
        this.buildModuleElement = buildModuleElement;
        this._posedConfig = posedConfig;
    }

    public getPosedValue() {
        const poseValue = BuildingModuleHelper.getValueFromListCaracByValueElement(this.buildModuleElement, this._posedConfig);
        return poseValue ? poseValue.Valeur : CogedimConstantes.NOT_POSED;
    }

    public getWhyValue() {
        return this.buildModuleElement ?
            this.buildModuleElement.getValueTextValue(CogedimDicocarcs.DC_P_MODULE_CONSTRUCTION_POURQUOI) : '';
    }

    asBeenPosed() {
        const poseValue = BuildingModuleHelper.getValueFromListCaracByValueElement(this.buildModuleElement, this._posedConfig);
        return poseValue  && poseValue.Valeur === CogedimConstantes.POSED;
    }
}

