import {ModuleWithProviders, NgModule} from '@angular/core';
import {ReplaceTagDirective} from './replace-tag.directive';


@NgModule({
    declarations: [ReplaceTagDirective],
    exports: [ReplaceTagDirective],
    providers: []
})
export class ReplaceTagModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ReplaceTagModule,
            providers: [
                ReplaceTagDirective
            ]
        };
    }
}
