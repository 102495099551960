import {Injectable} from '@angular/core';
import {CaracConfigRepositoryService} from './carac-config-repository.service';
import {CogedimElementWriterService} from './cogedim-element-writer.service';
import {zip} from 'rxjs';
import {CogedimConstantes} from '../constantes/cogedim-constantes';
import {CogedimDicocarcs} from '../constantes/cogedim-dicocarcs';
import {ElementRepositoryService} from './element-repository.service';
import {CaracConfig, NPElement} from '@next-page/np-sdk-data';
import {map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable({
    providedIn: 'root'
})
export class BuildingModuleService {

    constructor(private _caracConfigRepository: CaracConfigRepositoryService,
                private _elementWriter: CogedimElementWriterService,
                private _elementRepository: ElementRepositoryService) {
    }

    public generateBuildingModuleElements(collectionOfOperation: NPElement, parent: NPElement, newBuildingModuleConfigs?: CaracConfig[], unCheckedKit?: NPElement) {

        // Si le kit existait déjà dans les modules de construction (et qu'il a été supprimé), on récupère le mudule corespondant
        if (collectionOfOperation && unCheckedKit) {
            const removedModule = this._elementWriter.hasInRemovedBuildingModule(unCheckedKit);
            if (removedModule) {
                this._elementWriter.concatValueLink(collectionOfOperation, CogedimDicocarcs.LPP_MODULE_CONSTRUCTION, removedModule);
                return of([]);
            }
        }

        if (parent && newBuildingModuleConfigs && newBuildingModuleConfigs.length > 0 && collectionOfOperation) {
            const response = this._elementWriter.createBuildingModuleElementByKit(newBuildingModuleConfigs, collectionOfOperation, parent.ID);
            return of(response);
        } else if (collectionOfOperation) {
            // Récupère la famille qui contient les modules de construction
            // et la carcacConfig pour pour lka création des modules de construction
            return zip(
                this._caracConfigRepository.getConfigsByKeyName(CogedimConstantes.NewBuildingModuleCong),
                this._elementRepository.getElementByExtID(CogedimDicocarcs.SF_MODULE_CONSTRUCTION)
            )
                .pipe(
                    map(([configs, _parent]) => {
                        let response = [];
                        if (_parent) {
                            response = this._elementWriter.createBuildingModuleElementByKit(configs, collectionOfOperation, _parent.ID);
                        }
                        return response;
                    })
                );
        }

    }

}
