import {Injectable} from '@angular/core';
import {
  CaracConfig,
  DicoCarac,
  NPCaracLien,
  NPDicoCarac,
  NPElement,
  TypeCode,
  ValueOneElementHelper
} from '@nextpage/np-sdk-data';
import {Subject} from 'rxjs';
import {CaracIdStateValue, CaracInfo} from '../graphics/np-value/Model';

@Injectable({
  providedIn: 'root'
})
export class MediaFillRateService {

  public allCaracConfigs: Map<string, CaracConfig[]> = new Map<string, CaracConfig[]>();
  private _save: Subject<boolean> = new Subject<boolean>();
  private _voe: ValueOneElementHelper = new ValueOneElementHelper();
  public selectFillRate: string[] =  [];

  constructor() {
    this._save.next(false);
  }

  /**
   * Retourne vrai, si le dicoCarac passé en param entre dans le calcul du taux de complétude global
   */
  isInToGlobalFillRate(dicoCarac: NPDicoCarac): boolean {
    return dicoCarac && dicoCarac.FillRates.some(currFillRate => currFillRate.ExtID === DicoCarac.FRT_GLOBAL && currFillRate.Selected === '1');
  }

  isNotFulledtFillRateGlobal(element: NPElement) {
    return Number(element.getValueTextValue(DicoCarac.PRODUCTFILLRATE_GLOBAL)) < 100;
  }

  isNotFullFillRate(cValue: CaracIdStateValue) {
    const dicoCarac = cValue.config.DicoCarac;

    if (this.isInToGlobalFillRate(dicoCarac)) {

      if (dicoCarac.TypeCode.toUpperCase().indexOf(TypeCode.TXT.toUpperCase()) !== -1) {
        return cValue.value ? cValue.value['Value'] === '' : false;
      }

      if (dicoCarac.TypeCode.toUpperCase().indexOf(TypeCode.SYSLABEL.toUpperCase()) !== -1) {
        return cValue.value ? cValue.value['Value'] === '' : false;
      }

      if (dicoCarac.TypeCode.toUpperCase().indexOf(TypeCode.LIEN.toUpperCase()) !== -1) {
        if (cValue.value) {
          return dicoCarac.TypeCode.toString() === TypeCode.LIENREFLEXIF ?
              this.isNotFulledtFillRateGlobal(cValue.value.Element) :
              cValue.value['RebuildLinkedElements'].length === 0;
        }
        return false;
      }

      if (dicoCarac && dicoCarac.TypeCode.toUpperCase() === TypeCode.LISTE.toUpperCase()) {
        return cValue.value ? cValue.value['Values'].length === 0 : false;
      }
    }

    return false;

    // NB: On ne gère pas les données structures ici. Elles sont gérées directement dans les composant LinkStyleArray1Component

  }

  getCaracConfig(caracInfo: CaracInfo) {
    let newCaracConfig: CaracConfig = new CaracConfig();
    this.allCaracConfigs.forEach((cValue, key) => {
      if (cValue.find(currCarac => currCarac.Bloc === caracInfo.blocName && currCarac.DicoCaracExtID === caracInfo.name)) {
        newCaracConfig = cValue.find(currCarac => currCarac.Bloc === caracInfo.blocName && currCarac.DicoCaracExtID === caracInfo.name);
        return;
      }
    });
    return newCaracConfig;
  }

  isSaving() {
    return this._save.asObservable();
  }

  startFillRateChecking() {
    return this._save.next(true);
  }

  getLinksCaracValue(element: NPElement, caracConfig: CaracConfig) {
    const tmpCaracValues = (<NPCaracLien>this._voe.getCaracValue(element, caracConfig));
    return tmpCaracValues ? tmpCaracValues.RebuildLinkedElements : [];
  }

  isRequired(dicoCarac: NPDicoCarac) {
    return dicoCarac.Required === '1';
  }

  displayFillRateWarning(element: NPElement, dicoCarac: NPDicoCarac) {

    if (this.isInToGlobalFillRate(dicoCarac)) {
      // Tous les liens (sauf lien reflexif)
      if (dicoCarac.TypeCode.toUpperCase().indexOf(TypeCode.LIEN.toUpperCase()) !== -1 && dicoCarac.TypeCode !== TypeCode.LIENREFLEXIF) {
        // Si la carac n'existe pas dans l'objet principal, c'est possible qu'elle appartienne à un objet de lien reflexible et donc on retourne faux
        const valueLien = element.getValueLien(dicoCarac.ExtID);
        return valueLien && valueLien.RebuildLinkedElements.length === 0;
      }

      //LIENREFLEXIF (uniquement)
      if (dicoCarac.TypeCode.toUpperCase().indexOf(TypeCode.LIENREFLEXIF.toUpperCase()) !== -1) {
        const linkValues = element.getValueLien(dicoCarac.ReflexiveCharExtID);
        return linkValues && linkValues.RebuildLinkedElements.some(valueLien => this.isNotFulledtFillRateGlobal(valueLien.Element));
      }

      // TEXT et SYSLABEL
      if (dicoCarac.TypeCode.toUpperCase().indexOf(TypeCode.TXT.toUpperCase()) !== -1 || dicoCarac.TypeCode.toUpperCase().indexOf(TypeCode.SYSLABEL.toUpperCase()) !== -1) {
        const textValue = element.getValueTextValue(dicoCarac.ExtID);
        return textValue && textValue === '';
      }

      if (dicoCarac && dicoCarac.TypeCode.toUpperCase() === TypeCode.LISTE.toUpperCase()) {
        const listValues = element.getValueListe(dicoCarac.ExtID);
        return listValues && listValues.Values.length === 0;
      }

      // Données structrées
      if (dicoCarac && dicoCarac.TypeCode.toUpperCase() === TypeCode.STRUCTUREDDATA.toUpperCase()) {
        const caracLien: NPCaracLien = element.getValueLien(dicoCarac.ExtID);
        return caracLien ? caracLien.RebuildLinkedElements.some(link => this.isNotFulledtFillRateGlobal(link.Element)) : false;
      }

    }

    return false;
  }
}
