import {Injectable} from '@angular/core';
import {NPElement} from '@next-page/np-sdk-data';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/internal/operators/tap';
import {first} from 'rxjs/internal/operators/first';
import * as JSZip from 'jszip';
import {flatMap} from 'rxjs/operators';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {FileReaderService} from '@next-page/np-sdk-graphics';

@Injectable({
    providedIn: 'root'
})
export class CogedimDownloadService {

    private _hasError = new BehaviorSubject<boolean>(false);

    constructor(private _httpClient: HttpClient, private _fileReaderService: FileReaderService) {
    }

    public sendError(hasError: boolean) {
        this._hasError.next(hasError);
    }

    public errorListener(): Observable<boolean> {
        return this._hasError.asObservable();
    }

    private _compileObjectFiles(zipFolder: JSZip, element: NPElement, caracExtID: string, fileName = 'DownloadedFile') {
        const _mediaPath = this._fileReaderService.toFilePath(element.getValueTextValue(caracExtID));
        return this._httpClient.get(_mediaPath, {
            responseType: 'arraybuffer'
        }).pipe(
            first(),
            tap((blob) => {
                zipFolder.file(`${fileName}.zip`, blob);
            })
        );
    }

    public downloadObjectsFiles(element: NPElement, caracExtID: string, fileName: string): Observable<any> {
        const zipFolder = new JSZip();
        const objectZipFolder = zipFolder.folder(name);
        return this._compileObjectFiles(objectZipFolder, element, caracExtID, fileName)
            .pipe(
                flatMap(() => {
                    return from(zipFolder.generateAsync({
                        type: 'blob',
                        compression: 'DEFLATE',
                        compressionOptions: {
                            level: 9
                        }
                    }));
                })
            );
    }
}
