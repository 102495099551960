import {Component, OnInit} from '@angular/core';
import {CogedimAuthenticationService} from '../../sdk/data/services/auth/cogedim-authentication.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

    constructor(private _authService: CogedimAuthenticationService) {
    }

    ngOnInit() {
    }

    logout() {
        return this._authService.logout();
    }
}
