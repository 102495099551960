import {Injectable} from '@angular/core';
import {NPElement, NPListeValues} from '@next-page/np-sdk-data';
import {Subject} from 'rxjs';
import {CaracConfigRepositoryService} from './carac-config-repository.service';
import {filter, map} from 'rxjs/operators';
import {CogedimDicocarcs} from '../constantes/cogedim-dicocarcs';
import {CogedimConstantes} from '../constantes/cogedim-constantes';

@Injectable({
    providedIn: 'root'
})
export class CollectionService {

    private _currentCollectionType: NPListeValues;
    private _subCreateCollections$ = new Subject<NPElement[]>();
    collections: NPListeValues[] = [];

    constructor(private _caracConfigRepositoryService: CaracConfigRepositoryService) {
        this._caracConfigRepositoryService.getConfigsByKeyName(CogedimConstantes.COLLECTION_VALUES)
            .pipe(
                filter(data => data.some(cc => cc.DicoCaracExtID === CogedimDicocarcs.DC_NOM_COLLECTION_CHOISIE_COLLECTION)),
                map(data => data.find(cc => cc.DicoCaracExtID === CogedimDicocarcs.DC_NOM_COLLECTION_CHOISIE_COLLECTION).DicoCarac.Values)
            )
            .subscribe(_values => {
                this.collections = _values;
            });
    }

    setCurrentCollectionType(selectedCollection: NPListeValues) {
        this._currentCollectionType = selectedCollection;
        this.setSavedCollectionTypeId(selectedCollection ? String(selectedCollection.ValeurID) : null);
    }

    getCollectionTypeByID(ID: number) {
        return this.collections.find(collection => collection.ValeurID === ID);
    }

    getCollectionType(element: NPElement) {
        const tmpValue = element ? element.getValueListe(CogedimConstantes.DC_NOM_COLLECTION_CHOISIE_COLLECTION) : undefined;
        return tmpValue && tmpValue.Values[0] ? this.collections.find(collection => collection.ValeurID === Number(tmpValue.Values[0].ValueID)).Valeur : '';
    }

    getSavedCollectionTypeId() {
        return sessionStorage.getItem(STORAGE_KEY_COLLECTION);
    }

    cleanStorage() {
        sessionStorage.removeItem(STORAGE_KEY_COLLECTION);
    }

    setSavedCollectionTypeId(id: string) {
        if (id) {
            sessionStorage.setItem(STORAGE_KEY_COLLECTION, id);
        } else {
            this.cleanStorage();
        }
    }

    getCollectionIdByExtID(operation: NPElement, collectionExtID: string) {
        if (operation && collectionExtID) {
            const tmpRebuildElements = operation.getValueLien(CogedimDicocarcs.LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION).RebuildLinkedElements;
            const tmpCollection = tmpRebuildElements ? tmpRebuildElements.find(collection => collection.Element.ExtID === collectionExtID) : null;
            return tmpCollection ? tmpCollection.Element.ID : '';
        }
        return '';
    }

    sendCollections(collections: NPElement[]) {
        this._subCreateCollections$.next(collections);
    }

    getCollections() {
        return this._subCreateCollections$.asObservable();
    }

    getCollectionValue(element: NPElement, caracExtId: string) {
        if (element && caracExtId && caracExtId !== '') {
            const linkValue = element.getValueListe(caracExtId);
            return linkValue && linkValue.Values && linkValue.Values.length > 0 ? this.getCollectionTypeByID(linkValue.Values[0].ValueID) : '';
        } else {
            return '';
        }
    }
}

const STORAGE_KEY_COLLECTION = 'STORAGE_KEY_COLLECTION_ACCESS';
