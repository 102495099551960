import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DicocaracRepository} from './data/services/dicocarac-repository';
import {DataMockingModule} from './data/utils/Data/Mocking/data-mocking.module';
import {UiTranslationService} from './data/services/ui-translation.service';
import {ElementRepository} from './data/services/element-repository';
import {NPElementRepository} from './data/services/np-element-repository';
import {SearchRepository} from './data/services/search-repository';
import {WorkflowRepository} from './data/services/workflow-repository';
import {CaracConfigRepository} from './data/services/carac-config-repository';
import {WsParamsService} from './data/services/ws-params.service';
import {EqualizeModule} from './data/directive/equalize/equalize.module';
import {NpwSearchService} from './webapi/services/npw-search-service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
    imports: [
        RouterModule,
        HttpClientModule,
        DataMockingModule,
        EqualizeModule
    ],
    declarations: [],
    exports: [RouterModule, HttpClientModule, DataMockingModule, EqualizeModule],
    providers: [
        CaracConfigRepository,
        DicocaracRepository,
        ElementRepository,
        // ElementWriterService,
        NPElementRepository,
        SearchRepository,
        WorkflowRepository,
        /*WsParamsUrlService,*/
        WsParamsService,
        UiTranslationService,
        NpwSearchService
    ]
})


export class NpSdkDataModule {
}
