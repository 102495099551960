export class SynopticQueryBody {
    OperationID: number;
    CollectionID: number;
    CollectionName: string;
    UserID: number;

    constructor(productId: number, collectionId: number, collectionType: string, userId: number) {
        this.OperationID = productId;
        this.CollectionID = collectionId;
        this.CollectionName = collectionType;
        this.UserID = userId;
    }
}

export class SynopticFileResponse {
    file: string;
    fileName: string;
    success: boolean;
    errors?: string[] = [];
    errorsNoKitSelected?: boolean;
    errorsNoKitSelectedForFamily?: string[] = [];
    errorsTooManyKitSelectedForFamily?: string[] = [];
    message?: string;
}
