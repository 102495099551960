import {ModuleWithProviders, NgModule} from '@angular/core';
import {DragAndDropDirective} from './drag-and-drop.directive';

@NgModule({
  declarations: [DragAndDropDirective],
  exports: [DragAndDropDirective],
  providers: []
})
export class DragAndDropModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DragAndDropModule,
      providers: [DragAndDropDirective]
    }
  }
}
