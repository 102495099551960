import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {map, shareReplay} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

/*export interface  WsParamsService {
    getParams(): Observable<WSParameters>;
    getSyncParams(): WSParameters;
}*/

@Injectable({
    providedIn: 'root',
})
export class WsParamsService {
    public  _params$: Observable<WSParameters>;
    private _paramsDefault: Observable<WSParameters>;

    constructor(private _route: ActivatedRoute) {
        this.Init();
    }
    protected Init() {
        this._params$ = this._route.queryParams.pipe(
            map((params) => {
                const result = new WSParameters();
                const langID =  params['LangID'];
                const contextID = params['ContextID'];
                if (langID != null) {
                    result.LangID = langID;
                }
                if (contextID != null) {
                    result.ContextID = contextID;
                }
                this._paramsDefault = new BehaviorSubject(result);
                return result;
            }),
            shareReplay(1)
        );
    }
    public getParams() {
        if (this._paramsDefault != null) {
            return this._paramsDefault;
        } else {
            return this._params$;
        }

    }

    public getSyncParams() {
        const params = this._route.snapshot.queryParams;
        const result = new WSParameters();
        const langID = params['LangID'];
        const contextID = params['ContextID'];
        if (langID != null) {
            result.LangID = langID;
        }
        if (contextID != null) {
            result.ContextID = contextID;
        }
        this._paramsDefault = new BehaviorSubject(result);
        return result;
    }
}

export class WSParameters {
    public LangID = 1;
    public ContextID = 1;
}
