
import {NpModelNaos2DicoCarac} from './np-model-naos2-dicoCarac';
import {NpModelNaos2FGC_01NEP044} from './np-model-naos2-FGC_01NEP044';
import {NpModelNaosCaracConfig} from '../ModelNaos/np-model-naos-carac-config';
import {Inject, Injectable, Optional} from '@angular/core';
import {NpModelNaosUserStatusAccess} from '../ModelNaos/np-model-naos-user-status-access';
import {AddedDataMocker, DataMockerError, DataModelMocker} from '../../utils/Data/Mocking';

@Injectable({
  providedIn: 'root'
})
export class NpModelNaos2DataMocker extends DataModelMocker {
  constructor(@Optional() @Inject(AddedDataMocker) private _addedMocker: AddedDataMocker[]) {
    super();
    if (_addedMocker != null) _addedMocker.forEach(am => super.AddMocker(am));
    super.AddMocker(new NpModelNaos2DicoCarac());
    super.AddMocker(new NpModelNaos2FGC_01NEP044());
    super.AddMocker(new NpModelNaosCaracConfig());
    super.AddMocker(new NpModelNaosUserStatusAccess());
    super.AddMocker(new DataMockerError());

  }
}
