import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CogedimConstantes} from '../../../data/constantes/cogedim-constantes';

@Component({
    selector: 'app-dialog-warning',
    templateUrl: './dialog-warning.component.html',
    styleUrls: ['./dialog-warning.component.scss']
})
export class DialogWarningComponent implements OnInit {
    deleteMode: boolean;
    saveMode: boolean;

    constructor(
        public dialogRef: MatDialogRef<DialogWarningComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }


    ngOnInit() {
        this.deleteMode = this.data && this.data['mode'] === CogedimConstantes.DELETE_MODE;
        this.saveMode = this.data && this.data['mode'] === CogedimConstantes.SAVE_MODE;
    }
}
