import {AdvancedSearchDTO} from '../model/search-model';
import {NPElementType} from '../model/np-element';

export class SearchConfigHelper {
  public static setKeyword(keyword: string, searchConfig: AdvancedSearchDTO) {
    const result = Object.assign(new AdvancedSearchDTO(), searchConfig);
    result.Filters.Keywords = keyword;
    return result;
  }

  public static setElementType(elementType: NPElementType, searchConfig: AdvancedSearchDTO) {
    const result = Object.assign(new AdvancedSearchDTO(), searchConfig);
    result.Filters.ElementType = elementType;
    return result;
  }

  public static setBaseFamily(familyID: number, searchConfig: AdvancedSearchDTO) {
    const result = Object.assign(new AdvancedSearchDTO(), searchConfig);
    result.Filters.Family = {
      ID: familyID,
      label: ''
    };
    return result;
  }
}
