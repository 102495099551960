import {Component, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {CaracInfo} from '../Model';
import {NpCaracStatus, UiTranslationService} from '@nextpage/np-sdk-data';


@Component({
    selector: 'lib-app-value-status-read-view',
    templateUrl: './np-value-status-read-view.component.html',
    styleUrls: ['./np-value-status-read-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpValueStatusReadViewComponent implements OnInit, OnChanges {

    @Input() caracInfo: CaracInfo;
    @Input() value: NpCaracStatus;

    constructor(private _tradSrv: UiTranslationService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
    }


    getFormattedValue() {
        let translation = '';
        this._tradSrv.translate(this.value.StatusExtID).subscribe(d => translation = d);
        return translation;
    }

}
