import {Component, forwardRef, Inject, OnDestroy, OnInit} from '@angular/core';

import {NavBarService} from '../../sdk/data/services/nav-bar.service';
import {CogedimConstantes} from '../../sdk/data/constantes/cogedim-constantes';
import {ElementRepositoryService} from '../../sdk/data/services/element-repository.service';
import {filter, flatMap, map} from 'rxjs/operators';
import {of, Subscription, zip} from 'rxjs';
import {KitFamilySummary, KitMap, KitSummary, SummaryNavBar} from '../../sdk/data/Model/models';
import {CaracConfigRepositoryService} from '../../sdk/data/services/carac-config-repository.service';
import {OperationService} from '../../sdk/data/services/operation.service';
import {CollectionService} from '../../sdk/data/services/collection.service';
import {ActivatedRoute} from '@angular/router';
import {COGIDIM_ROUTES} from '../../sdk/data/constantes/routes';
import {BreadcrumbService} from '../../sdk/data/services/breadcrumb.service';
import {CogedimDicocarcs} from '../../sdk/data/constantes/cogedim-dicocarcs';
import {KitService} from '../../sdk/data/services/kit.service';
import {NpUser} from '../../sdk/data/Model/np-user';
import {NPUserRepository} from '../../sdk/data/services/auth/user-respository';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {CaracConfig, NPElement, NPListeValues} from "@nextpage/np-sdk-data";

@Component({
  selector: 'app-container-edition',
  templateUrl: './container-edition.component.html',
  styleUrls: ['./container-edition.component.scss']
})
export class ContainerEditionComponent implements OnInit, OnDestroy {

  public kitConfig: KitSummary;
  public kitFamilies: KitMap[] = [];
  public children: NPElement[] = [];
  private _childrenCopy: NPElement[] = [];
  public resultsToDisplay = 3;
  public resultLength = 0;
  public maxResultsToDisplay = 3;
  public collection: NPElement;
  private subscription: Subscription;
  public selectorData: KitFamilySummary[] = [];
  public operation: NPElement;
  public loading = true;
  public currConfig: SummaryNavBar;
  private _allParams: any;
  public displayHome = true;
  private description = '';
  public othersProducts = false;
  public othersProductsConfig: CaracConfig;
  public chosenCollection: NPListeValues;
  private _operationHasBeenUpdate: boolean;
  private currentUser: NpUser;
  public buildingModuleParent: NPElement;
  public newBuildingModuleConfigs: CaracConfig[];
  public allReady = false;
  private currentNavBarSelectedItem$: Subscription;


  constructor(private _navBarService: NavBarService,
              private _elementRepository: ElementRepositoryService,
              private _caracConfigRepositoryService: CaracConfigRepositoryService,
              private _collectionService: CollectionService,
              private router: ActivatedRoute,
              private _operationService: OperationService,
              private _breadcrumbService: BreadcrumbService,
              private _kitService: KitService,
              private _user: NPUserRepository,
              @Inject(forwardRef(() => ModalService)) private _modalService: ModalService) {
  }

  ngOnInit() {

    // Chargement des élements du menu
    this._elementRepository.getDescendantsOfKits(
        CogedimDicocarcs.CW_PRODUITS, [[CogedimDicocarcs.DC_LINK_NODE_FPR]], true)
      .subscribe(data => {
        this._navBarService.sendTreeData(data);
      });

    this._caracConfigRepositoryService.getConfigsByKeyName(CogedimConstantes.KitObject)
      .subscribe(configs => {
        this.kitConfig = new KitSummary(configs);
      });
    this._listenSelectedMenu();

    // Récupération des infos utilisateur
    this._user.getCurrentUser()
      .subscribe((currUser: NpUser) => {
        this.currentUser = currUser;
      });

    // Récupère les caracConfigs et la famille contenant les modules de consttruction
    this._fetchBuildingModuleData();
  }

  private _listenSelectedMenu() {
    this._unsubscribeMe();

    if (this.currentNavBarSelectedItem$) {
      this.currentNavBarSelectedItem$.unsubscribe();
    }
    this.currentNavBarSelectedItem$ = this._navBarService.getCurrentItem()
      .pipe(
        flatMap(response => {
          // Pour ne pas recherger les dinnées exitantes
          if (this.currConfig && response && this.currConfig.extId === response.extId) {
            return of(null);
          }

          this.currConfig = response;
          this._modalService.openPreloadDialog();
          this.loading = true;
          if (this.currConfig.isHomeEditionBlock) {
            this._modalService.closePreloadDialog();
            this.loading = false;
            this.displayHome = true;
          }

          // Pour ne pas recherger les dinnées exitantes
          if (this.displayHome && this.currConfig.extId === CogedimConstantes.NAV_BAR_ITEM_PRESTATIONS_EDITION) {
            return of(null);
          }

          return this._elementRepository.getDescendantsOfKits(
              response.extId, [[CogedimDicocarcs.DC_LINK_NODE_FPR], [CogedimDicocarcs.DC_LINK_NODE_FPR, CogedimDicocarcs.DC_PORTAIL_IMAGES]])
              .pipe(
                  map((data: NPElement[]) => {
                    data.forEach(family => {
                      if (family.Children) {
                        family.Children = family.Children.sort(this._elementRepository.compareElements);
                      }
                    });
                    return data;
                  })
              );
        }),
        flatMap(familyResponse => {
          return this.router.queryParams.pipe(
            filter(params => {
              return params[CogedimConstantes.COLLECTION_EXTID_PARAM]
                && params[CogedimConstantes.PRESTATION_EXTID_PARAM]
                && params[CogedimConstantes.OPERATION_EXTID_PARAM];
            }),
              flatMap(params => {
                this._allParams = params;
                this.loading = true;
                // On recharge l'opération en cours...
                if (!this._operationHasBeenUpdate) {
                  return this._elementRepository.getElementByExtID(this._allParams[CogedimConstantes.OPERATION_EXTID_PARAM]);
                }
                return of(null);
              }),
              map(operation => {
                if (operation) {
                  this.operation = operation;
                  this._operationHasBeenUpdate = true;
                }

                this.allReady = true;
                const operationInfos = this._initOperation();
                this._initData(familyResponse, operationInfos);

                return true;
              })
          );
        }),
      )
      .subscribe(() => {
        this._modalService.closePreloadDialog();
        this.loading = false;
      }, err => {
        this._modalService.closePreloadDialog();
        this.loading = false;
      });
  }

  private _initData(descendants: NPElement[], operationInfos) {
    this.selectorData = descendants ? descendants.map(currentValue => {
      let tmpChildren = currentValue.Children
          ? currentValue.Children.map(child => child.getValueLien(CogedimDicocarcs.DC_LINK_NODE_FPR).RebuildLinkedElements[0].Element) : [];
      const links = this.collection.getValueLien(CogedimDicocarcs.LPP_KIT_DEPUIS_COLLECTIONS_OPERATIONS);
      tmpChildren = this._elementRepository.getFilteredKits3(tmpChildren, links, operationInfos.dirRegioniale, operationInfos.collectionID);
      const elementLink = currentValue.getValueLien(CogedimDicocarcs.DC_LINK_NODE_FPR);
      const tmpRebuild = elementLink.RebuildLinkedElements;
      return {
        label: currentValue.getValueTextValue(CogedimDicocarcs.DC_CHANNEL_LABEL),
        value: currentValue.ExtID,
        children: tmpChildren,
        description: tmpRebuild && tmpRebuild.length > 0 ? tmpRebuild[0].Element.getValueTextValue(CogedimDicocarcs.DC_INTRODUCTION) : ''
      };
    }) : [];
  }

  private _initOperation() {
    const tmp = this.operation.getValueLien(CogedimConstantes.LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION).RebuildLinkedElements
      .find(el => el.Element.ExtID === this._allParams[CogedimConstantes.PRESTATION_EXTID_PARAM]);

    this.collection = tmp ? tmp.Element : undefined;
    // On met à jour l'opération en cours
    this._operationService.setCurrentOperation(this.operation);
    const dirRegioniale = this.operation.getValueListe(CogedimConstantes.DC_DIRECTION_REGIONALE_OPERATION);
    const chosenCollectionID = Number(this._allParams[CogedimConstantes.COLLECTION_EXTID_PARAM]);
    const tmpObject = {
      collectionID: chosenCollectionID,
      dirRegioniale: dirRegioniale && dirRegioniale.Values.length > 0 ? dirRegioniale.Values[0].ValueID : null
    };
    this.chosenCollection = this._collectionService.getCollectionTypeByID(chosenCollectionID);
    this._initBreadcrumb(this.currConfig, this.chosenCollection.Valeur);

    return tmpObject;
  }

/*  private _initOperation() {
    const tmp = this.operation.getValueLien(CogedimConstantes.LPP_COLLECTIONS_OPERATIONS_DEPUIS_OPERATION).RebuildLinkedElements
      .find(el => el.Element.ExtID === this._allParams[CogedimConstantes.PRESTATION_EXTID_PARAM]);

    this.collection = tmp ? tmp.Element : undefined;
    // On met à jour l'opération en cours
    this._operationService.setCurrentOperation(this.operation);
    const dirRegioniale = this.operation.getValueListe(CogedimConstantes.DC_DIRECTION_REGIONALE_OPERATION);
    const chosenCollectionID = Number(this._allParams[CogedimConstantes.COLLECTION_EXTID_PARAM]);
    const tmpObject = {
      collectionID: chosenCollectionID,
      dirRegioniale: dirRegioniale && dirRegioniale.Values.length > 0 ? dirRegioniale.Values[0].ValueID : null
    };
    this.chosenCollection = this._collectionService.getCollectionTypeByID(chosenCollectionID);
    if (this.currConfig && this.chosenCollection) {
      this._initBreadcrumb(this.currConfig, this.chosenCollection.Valeur);
    }
    return tmpObject;
  }*/

  private _getChildren(elementExtID: string) {
    const tmpElement = this.kitFamilies.find(family => family.collection.ExtID === elementExtID);
    return tmpElement && tmpElement.kits ? tmpElement.kits : [];
  }

  onFamilyChanged(selectedValue: KitFamilySummary) {
    this.resultsToDisplay = 3; // On réinitialise la taille max
    this._childrenCopy = Object.assign([], selectedValue.children);
    this.resultLength = this._childrenCopy.length;
    this.children = this._childrenCopy.filter((value, index) => index < this.resultsToDisplay);
    // const _selectedSubFamily = this.kitFamilies.find(f => f.collection.ExtID === selectedValue.value);
    this.description = selectedValue.description ? selectedValue.description : '';
  }

  ngOnDestroy() {
    this._unsubscribeMe();
  }

  private _unsubscribeMe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private _initBreadcrumb(selectedMenuItem: SummaryNavBar, collectionName: string) {
    this.othersProducts = false;
    this.displayHome = false;
    const collectionLabel = this.collection ? this.collection.getValueTextValue(CogedimDicocarcs.DC_LIBELLE_COLLECTION) : '';
    const tmpTitle = `${collectionLabel} - ${collectionName}`;
    let name = '';

    // Test
    //   this.othersProducts = true;
    if (selectedMenuItem) {
      name = selectedMenuItem.label;
      if (selectedMenuItem.isOtherProductBlock) {
        this.othersProductsConfig = selectedMenuItem.caracConfig;
        this.othersProducts = true;
      } else if (selectedMenuItem.isHomeEditionBlock) {
        this.displayHome = true;
      }
    } else {
      this.displayHome = true;
    }
    const breadcrumb = [{
      name: `Prestations et édition : ${tmpTitle}`,
      route: COGIDIM_ROUTES.Operation.route,
      params: {operation: this.operation},
      collectionTitle: tmpTitle
    }, {name: name, route: '', params: {}, collectionTitle: tmpTitle}];
    this._breadcrumbService.init(breadcrumb);
    this.allReady = true;
  }

  /*  private _initBreadcrumb(caracConfig: CaracConfig, collectionName: string) {
      this.othersProducts = false;
      this.displayHome = false;
      const collectionLabel = this.collection ? this.collection.getValueTextValue(CogedimDicocarcs.DC_LIBELLE_COLLECTION) : '';
      const tmpTitle = `${collectionLabel} - ${collectionName}`;
      let name = '';
      if (caracConfig && caracConfig.DicoCarac) {
        if (caracConfig.DicoCaracExtID === CogedimDicocarcs.DC_OTHERS_PRODUCTS) {
          this.othersProductsConfig = caracConfig.ConfigurationObject;
          this.othersProducts = true;
        }
        this.displayHome = false;
        name = caracConfig.Label;
      } else {
        this.displayHome = true;
        name = caracConfig && caracConfig.DicoCaracExtIDLabel ? caracConfig.DicoCaracExtIDLabel : '';
      }
      const breadcrumb = [{
        name: `Prestations et édition : ${tmpTitle}`,
        route: COGIDIM_ROUTES.Operation.route,
        params: {operation: this.operation},
        collectionTitle: tmpTitle
      }, {name: name, route: '', params: {}, collectionTitle: tmpTitle}];
      this._breadcrumbService.init(breadcrumb);

    }*/

  displayMore() {
    this.resultsToDisplay += 3;
    this.children = this._childrenCopy.filter((value, index) => index < this.resultsToDisplay);
  }

  private _fetchBuildingModuleData() {
    return zip(
        this._caracConfigRepositoryService.getConfigsByKeyName(CogedimConstantes.NewBuildingModuleCong),
        this._elementRepository.getElementByExtID(CogedimDicocarcs.SF_MODULE_CONSTRUCTION)
    )
        .subscribe(([configs, parent]) => {
          this.newBuildingModuleConfigs = configs;
          this.buildingModuleParent = parent;
        });
  }
}
