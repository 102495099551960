import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CaracConfig, CaracConfigRepository} from '@next-page/np-sdk-data';
import {CogedimComponentResolver} from '../helpers/cogedim-component-resolver';


@Injectable({
    providedIn: 'root'
})
export class CaracConfigRepositoryService {

    private _dicoConfigs$: Observable<Map<string, CaracConfig[]>>;

    constructor(private _caracConfig: CaracConfigRepository) {
        this._initialize();
    }

    private static _treatConfiguration(key: string, inputCaracConfigs: Map<string, CaracConfig[]>
        , outputCaracConfigs: Map<string, CaracConfig[]>) {
        if (!outputCaracConfigs.has(key)) {
            if (!inputCaracConfigs.has(key)) {
                return undefined;
            }
            const newValue = inputCaracConfigs.get(key).map((caracConfig: CaracConfig) => {
                const result = Object.assign({}, caracConfig);
                if (result.Configuration != null) {
                    result.ConfigurationObject = CaracConfigRepositoryService
                        ._treatConfiguration(result.Configuration, inputCaracConfigs, outputCaracConfigs);
                }
                return result;
            });
            outputCaracConfigs.set(key, newValue);
        }
        return outputCaracConfigs.get(key);
    }

    public getConfigs(): Observable<Map<string, CaracConfig[]>> {
        return this._dicoConfigs$;
    }

    public getConfigsByKeyName(BlockName: string): Observable<CaracConfig[]> {
        return this._dicoConfigs$.pipe(
            map(data => data && data.get(BlockName) ? data.get(BlockName) : [])
        );
    }

    /**
     * Retourne un observable sur la liste de config souhaitée
     */
    public getConfig(key: string): Observable<CaracConfig[]> {
        return this._dicoConfigs$.pipe(
            map((data: Map<string, CaracConfig[]>) => {
                return data.get(key);
            }))
            ;
    }

    private _initialize() {
        this._dicoConfigs$ = this._caracConfig.getConfigs().pipe(
            map((caracConfigs) => {
                const newCaracConfigsComponents = this._setComponents(caracConfigs);
                return this._setConfigurations(newCaracConfigsComponents);
            })
        );
    }

    private _setComponents(caracConfigs: Map<string, CaracConfig[]>): Map<string, CaracConfig[]> {
        const newCaracConfig = new Map<string, CaracConfig[]>();
        caracConfigs.forEach((value, key) => {
            const newValue: CaracConfig[] = value.map((caracConfig) => {
                const result = Object.assign({}, caracConfig);
                if (caracConfig.Component != null) {
                    result.ComponentRouting = CogedimComponentResolver.getComponentRouting(caracConfig.Component);
                }
                return result;
            });
            newCaracConfig.set(key, newValue);
        });
        return newCaracConfig;
    }

    private _setConfigurations(caracConfigs: Map<string, CaracConfig[]>): Map<string, CaracConfig[]> {
        const newCaracConfigs = new Map<string, CaracConfig[]>();
        caracConfigs.forEach((value, key) => {
            CaracConfigRepositoryService._treatConfiguration(key, caracConfigs, newCaracConfigs);
        });
        return newCaracConfigs;
    }
}
