import {Component, HostListener, Input, OnInit} from '@angular/core';
import {NPElement} from '@nextpage/np-sdk-data';
import {OperationService} from '../../sdk/data/services/operation.service';
import {ElementRepositoryService} from '../../sdk/data/services/element-repository.service';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, flatMap, tap} from 'rxjs/operators';
import {CogedimConstantes, Messages} from '../../sdk/data/constantes/cogedim-constantes';
import {CogedimElementWriterService} from '../../sdk/data/services/cogedim-element-writer.service';
import {MatDialog} from '@angular/material';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {COGIDIM_ROUTES} from '../../sdk/data/constantes/routes';
import {BreadcrumbService} from '../../sdk/data/services/breadcrumb.service';
import {NavBarService} from '../../sdk/data/services/nav-bar.service';

@Component({
    selector: 'app-operation-template',
    templateUrl: './operation-template.component.html',
    styleUrls: ['./operation-template.component.scss']
})
export class OperationTemplateComponent implements OnInit {

    @Input() navBarConfigBlockName: string;
    @Input() displayEditButton: boolean;
    @Input() isEditMode = false;

    public operation: NPElement;
    public found = true;
    private _currOperationExtID: string;

    public saving = () => this._elementWriter.isSaving();

    constructor(private _operationService: OperationService,
                private _ElementRepository: ElementRepositoryService,
                private _activedRoute: ActivatedRoute,
                private _elementWriter: CogedimElementWriterService,
                private _dialogService: ModalService,
                public dialog: MatDialog,
                private _modalService: ModalService,
                private _breadcrumbService: BreadcrumbService,
                private _navBarService: NavBarService) {

    }

    ngOnInit() {
        this._operationService.getCurrentOperation()
            .subscribe(currOperation => {
                this._currOperationExtID = currOperation ? currOperation.ExtID : '';
            });
        this.fetchData();
    }

    private _initData() {
        this._activedRoute.queryParams
            .pipe(
                filter(params => params[CogedimConstantes.OPERATION_EXTID_PARAM] !== undefined),
                flatMap(params => {
                    return this._ElementRepository.getElementByExtID(params[CogedimConstantes.OPERATION_EXTID_PARAM]);
                }),
                filter(operation => {
                    if (operation) { // Opération trouvée
                        this.found = true;
                        return true;
                    }
                    this.found = false; // Opération introuvable
                    return false;
                })
            )
            .subscribe(operation => {
                this._operationService.setCurrentOperation(operation);
            });
    }

    save() {
        this._elementWriter.canSaveData()
            .pipe(
                filter(canSave => canSave),
                flatMap(() => {
                    return this._modalService.openConfirmModal(CogedimConstantes.SAVE_MODE, Messages.SAVE_QUESTION);
                }),
                filter(response => response),
                flatMap(() => {
                    return this._elementWriter.publishToNP();
                })
            )
            .subscribe(() => {
                this._modalService.openSnackBar(Messages.SAVE_SUCCES, CogedimConstantes.SNACK_CLASS);
            }, err => {
                if (err === 0) { // Aucune donnée à enregistrer
                    this._modalService.openSnackBar(Messages.NO_CHANGES_TO_SAVE, CogedimConstantes.SNACK_CLASS);
                } else if (err === -1) { // Erreurs ! données invalides
                    this._modalService.openErrorModal();
                }
            });
    }

    private fetchData() {
        this._operationService.getCurrentOperation()
            .pipe(
                tap(operation => {
                    if (!operation) {
                        // Si on ne trouve pas l'opération dans le service, on le receherche dans NextPage
                        // Ce cas d'utilisation arrive souvent quand l'utilisateur actualise la page. Il va perdre les dernières modifications si celles-ci n'ont pas été enregistrées au préhalable
                        this._initData();
                    }
                }),
                filter(operation => operation !== null && operation !== undefined)
            )
            .subscribe(operation => {
                this.found = true; // Opération trouvée
            });
    }

    gotoBack() {
        this._navBarService.gotoHomeEditionPage();
    }

    /**
     *, Affiche un message (avant de quiter la fenêtre) si l'utilisateur a des données non sauvegardées
     */
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this._elementWriter.hasModifications()) {
            $event.returnValue = true;
        }
    }
}
