import {Component, Input, OnInit} from '@angular/core';
import {CaracConfig, NPCaracLien, NPCaracLienRebuildValue, NPElement} from '@nextpage/np-sdk-data';
import {CaracIdStateValue} from '../np-value/Model';

@Component({
    selector: 'lib-app-carousel-view',
    templateUrl: './np-carousel-view.component.html',
    styleUrls: ['./np-carousel-view.component.css']
})
export class NpCarouselViewComponent implements OnInit {
    @Input() value: NPCaracLien;
    @Input() element: NPElement;
    @Input() caracConfig: CaracConfig;

    imageCount = 3;
    currentIndex = 0;
    innerCaracConfig: CaracConfig;
    innerREL: NPCaracLienRebuildValue[];
    currentCISV: CaracIdStateValue;

    constructor() {
    }

    ngOnInit() {
        if (this.value != null) {
            this._initInnerCaracs();
            this.setImage(this.currentIndex);
        }
    }

    /**
     * Changer l'image courante
     * param {number} index
     */
    public setImage(index: number) {
        this.currentIndex = index;
        const newValue = this._getValue(index);
        this.currentCISV = {
            element: this.element,
            config: this.innerCaracConfig,
            value: newValue, 
            baseElement: null // TMR : au moment de le faire, je n'ai pas su le propager partout...
        };
    }

    /**
     * Choisir l'image précédente
     */
    public setImageLower() {
        this.currentIndex = this.currentIndex - 1 < 0 ? this.imageCount - 1 : this.currentIndex - 1;
        this.setImage(this.currentIndex);
    }

    /**
     * Choisir l'image suivante
     */
    public setImageHigher() {
        this.currentIndex = this.currentIndex + 1 >= this.imageCount ? 0 : this.currentIndex + 1;
        this.setImage(this.currentIndex);
    }

    public showArrows(): boolean {
        return this.value != null && this.value.LinkedElements != null && this.value.LinkedElements.length > 0;
    }

    private _initInnerCaracs() {
        this.innerCaracConfig = Object.assign({}, this.caracConfig);
        delete this.innerCaracConfig.Component;
        delete this.innerCaracConfig.ComponentRouting;
        // Gérer le cas où le nombre d'images est inférieur à 3
        if (this.imageCount > this.value.RebuildLinkedElements.length) {
            this.imageCount = this.value.RebuildLinkedElements.length;
        }
        this.innerREL = this.value.RebuildLinkedElements.filter((rel, index) => index < this.imageCount);
    }

    private _getValue(index: number) {
        const newValue = Object.assign({}, this.value);
        newValue.RebuildLinkedElements = [this.innerREL[index]];
        newValue.LinkedElements = [this.value.LinkedElements[index]];
        return newValue;
    }

}
