import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CaracConfig, ElementRepository, NPElement, NPListeValues, ValueOneElementHelper, StructuredDataService} from '@nextpage/np-sdk-data';
import {FormControl} from '@angular/forms';
import {SummaryElement} from '../../select-list-value-lien/select-list-value-lien.component';

@Component({
    selector: 'lib-link-style-array-search',
    templateUrl: './link-style-array-search.component.html',
    styleUrls: ['./link-style-array-search.component.css']
})
export class LinkStyleArraySearchComponent implements OnInit {

    @Input() caracConfig: CaracConfig;
    @Input() element: NPElement;
    @Output() valueSelected: EventEmitter<string> = new EventEmitter<string>();

    private _initialListPossiblesFiltered: SummaryElement[] = [];

    public listPossiblesFiltered: SummaryElement[] = [];
    public selectFilterCtrl: FormControl = new FormControl();
    private voe: ValueOneElementHelper = new ValueOneElementHelper();
    public displayValues: NPListeValues[];
    public selectedValue: SummaryElement;
    public dropDownControl = new FormControl();

    constructor(private _elementRepository: ElementRepository, private _structuredDataService: StructuredDataService) {
    }

    ngOnInit() {
        // this._updateListePossible('');
        // // listen for search field value changes
        // this.selectFilterCtrl.valueChanges
        //     .subscribe(newValue => {
        //         this.listPossiblesFiltered = newValue !== '' ?
        //             this._initialListPossiblesFiltered.filter(currElement => currElement.elementName.toUpperCase().indexOf(newValue.toString().toUpperCase()) !== -1) :
        //             this._initialListPossiblesFiltered;
        //     });
    }

    public initValues() {
        if (this.element && this.caracConfig) {
            const valeur = this.element.getValueLien(this.caracConfig.DicoCaracExtID);
            if (valeur && valeur.RebuildLinkedElements && valeur.RebuildLinkedElements[0]) {
                const tmpSelectValue = this.listPossiblesFiltered.find(value => value.elementID === valeur.RebuildLinkedElements[0].Element.ID);
                this.selectedValue = tmpSelectValue ? tmpSelectValue : this.selectedValue;
                this.dropDownControl.setValue(this.selectedValue);
            }
        } else {
            this.selectedValue = null;
        }
    }

    public selectOption(valeur: SummaryElement) {

        // this._initRequiedError();

        this.valueSelected.emit(valeur ? valeur.element.ExtID : null);
        // console.log('Value : ', valeur);
    }

    /**
     * Initialisation de la liste des éléments possibles
     */
    private _updateListePossible(searchText: string) {
        this.listPossiblesFiltered = [];
        if (this.caracConfig) {
            this._elementRepository.searchForLink(this.caracConfig.DicoCarac, searchText, this.element)
                .subscribe((result: NPElement[]) => {
                    this.listPossiblesFiltered = result.map(rel => {
                        // On ajoute l'élément à la liste s'il n'est pas lié
                        return {
                            elementName: this.voe.getLabel(rel),
                            elementID: rel.ID,
                            elementOrder: rel.getValueSearchRankLevelSearchRanking(),
                            element: rel
                        };
                    });
                    this.initValues();
                    this._sortListPossibleByName();
                });
        }

    }

    /**
     * Tri de liste des éléments possibles
     */
    private _sortListPossibleByName() {
        this.listPossiblesFiltered.sort((e1, e2) => e1.elementOrder > e2.elementOrder ? -1 : 1);
        this._initialListPossiblesFiltered = Object.assign([], this.listPossiblesFiltered);
    }

}
