import {Component, forwardRef, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {
    Access,
    CaracConfig,
    ElementWriterService,
    ManagementRulesCheckerService,
    NPCaracStd,
    ValueOneElementHelper
} from '@nextpage/np-sdk-data';
import jss from 'jss';
import {Sheet, ThemingService} from '../../services';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'lib-np-number',
    templateUrl: './np-number.component.html',
    styleUrls: ['./np-number.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpNumberComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracStd;
    @Input() caracConfig: CaracConfig;

    private _voe = new ValueOneElementHelper();

    public hasUnite = false;
    public isValid = true;
    public readOnlyAccess: boolean;

    public classes: Object;

    public inputFormC = new FormControl();

    constructor(private _elementWriter: ElementWriterService,
                private _ManagementRulesChecker: ManagementRulesCheckerService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService) {
    }

    ngOnInit() {

        this.hasUnite = this._voe.hasUnite(this.caracConfig);
        this.readOnlyAccess = this.caracInfo.authorization === Access.LECTURESEULE;

        // [ngClass]="classes && classes['title'] ? classes['title']: ''"
        const override_css = this._theming.getComponentConfigStyle('NpNumberComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
            // console.log(this.classes);
        }

        this._ManagementRulesChecker.isChecking()
            .subscribe(_isChecking => {
                if (_isChecking) {
                    this._initRequiedError();
                }
            });

        this.inputFormC.valueChanges
            .subscribe(newValue => {
                this.onChange(newValue);
            });
    }

    numberOnly(event) {
        return event.which !== 46;
    }

    public onChange(newValue) {
        if (this.value.Element.getValueTextValue(this.value.DicoCaracExtID) !== newValue) {
            this._elementWriter.setValueText(this.value.Element, this.value.DicoCaracExtID, newValue ? newValue : '');
            this._initRequiedError();
        }
    }

    private _initRequiedError() {
        this.isValid = this._ManagementRulesChecker.isValueValide(this.value.Element, this.caracConfig);
    }

}
