import {AfterContentInit, Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {FormControl} from '@angular/forms';
import {Sheet, ThemingService, TreeviewService, VersionService} from '../../services';
import {
    Access,
    CaracConfig,
    ElementRepository,
    ElementWriterService,
    ManagementRulesCheckerService,
    NPCaracLien,
    NPElement,
    NPListeValues,
    StructuredDataService,
    UiTranslationService,
    ValueOneElementHelper
} from '@nextpage/np-sdk-data';

import jss from 'jss';
import {MatSnackBar} from '@angular/material';

interface SummaryElement {
    elementName: string;
    elementID: number;
    elementOrder: number;
    element: NPElement;
    isCurrentVersion?: boolean;
}

@Component({
    selector: 'lib-biocoop-version',
    templateUrl: './biocoop-version.component.html',
    styleUrls: ['./biocoop-version.component.css']
})

export class BiocoopVersionComponent implements OnInit, AfterContentInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    public selectFilterCtrl: FormControl = new FormControl();

    listPossibles: SummaryElement[] = [];
    listPossiblesFiltered: SummaryElement[] = [];
    _initialListPossiblesFiltered: SummaryElement[] = [];

    public voe: ValueOneElementHelper = new ValueOneElementHelper();
    public displayValues: NPListeValues[];
    public selectedValue: SummaryElement;

    private _translations = new Map<string, string>();

    public isValid = true;
    public readOnlyAccess: boolean;

    private _voe = new ValueOneElementHelper();
    public hasUnite = false;

    public classes: Object;
    private _currentVersion: SummaryElement;

    constructor(private _elementRepository: ElementRepository,
                private _elementWriter: ElementWriterService,
                private _translateSrv: UiTranslationService,
                private _ManagementRulesChecker: ManagementRulesCheckerService,
                private _structuredDataService: StructuredDataService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService,
                @Inject(forwardRef(() => TreeviewService)) private _treeviewService: TreeviewService,
                private _snackBar: MatSnackBar,
                @Inject(forwardRef(() => VersionService)) private _versionService: VersionService) {
    }

    ngOnInit() {

        this.readOnlyAccess = this.caracInfo.authorization === Access.LECTURESEULE;
        this.hasUnite = this._voe.hasUnite(this.caracConfig);
        this._updateListePossible();

        /**
         * Reçoit un signal lorsque l'utilisateur clique sur le bouton enregistrer
         * Le but eet de vérifier si toutes les données sont conformes
         * et d'afficher un message d'erreur elle ne le sont pas
         */
        this._ManagementRulesChecker.subjectCheck
            .subscribe(isSaving => {
                if (isSaving) {
                    this._initRequiedError();
                }
            });

        // listen for search field value changes
        this.selectFilterCtrl.valueChanges
            .subscribe(newValue => {
                this.listPossiblesFiltered = newValue && newValue !== '' ?
                    this._initialListPossiblesFiltered.filter(currElement => currElement.elementName.toUpperCase().indexOf(newValue.toString().toUpperCase()) !== -1) :
                    this._initialListPossiblesFiltered;
            });

        const override_css = this._theming.getComponentConfigStyle('BiocoopVersionComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
        }
    }

    public initValues() {
        const tmpSelectedValue = this.listPossiblesFiltered.find(cc => this._versionService.hasBeenChanged() && cc.element.ExtID === this._versionService.getVersion().ExtID);
        this.selectedValue = tmpSelectedValue ?
            this.listPossiblesFiltered.find(cc => cc.element.ExtID === tmpSelectedValue.element.ExtID) :
            this.listPossiblesFiltered.find(cc => this._currentVersion && cc.element.ExtID === this._currentVersion.element.ExtID);
    }

    /**
     * Sélectionne l'option
     * TODO interface à créer : valeur
     */
    public selectOption(selectedValue: SummaryElement) {
        // Modifie la version du produit courrant
        this.selectedValue = selectedValue;
        selectedValue = selectedValue.isCurrentVersion ? undefined : selectedValue;
        this._versionService.setVersion(selectedValue);
    }

    /**
     * Initialisation de la liste des éléments possibles
     */
    private _updateListePossible() {
        this.listPossibles = [];
        this._createCurrentVersion();
        this.listPossibles.push(this._currentVersion); // On ajoute la version actuelle
        this.listPossibles = this.listPossibles.concat(this.value.RebuildLinkedElements.map(rel => {
            // On ajoute l'élément à la liste s'il n'est pas lié
            return {
                elementName: this.voe.getLabel(rel.Element),
                elementID: rel.Element.ID,
                elementOrder: rel.Element.getValueSearchRankLevelSearchRanking(),
                element: rel.Element
            };
        }));

        this.initValues();
        this._sortListPossibleByName();
    }

    /**
     * Tri de liste des éléments possibles
     */
    private _sortListPossibleByName() {
        this.listPossibles.sort((e1, e2) => e1.elementName < e2.elementName ? -1 : 1);
        this.listPossiblesFiltered = this.listPossibles;
        this.listPossiblesFiltered.sort((e1, e2) => e1.elementOrder > e2.elementOrder ? -1 : 1);
        this._initialListPossiblesFiltered = Object.assign([], this.listPossiblesFiltered);
    }

    public translate(key: string, defaultvalue: string): string {
        if (this._translations.has(key)) {
            return this._translations.get(key);
        } else {

            this._translateSrv.translate(key).subscribe(v =>
                this._translations.set(key, v)
            );
            return defaultvalue;
        }
    }

    private _initRequiedError() {
        this.isValid = this._ManagementRulesChecker.isValueValide(this.value.Element, this.caracConfig);
    }

    onPanelToggled(isOpened: boolean) {
        if (isOpened) {
            this.selectFilterCtrl.reset();
        }
    }

    ngAfterContentInit() {
        this.initValues();
    }

    private _createCurrentVersion() {
        if (this.value.Element) {
            this._currentVersion = {
                elementName: '---- Version actuelle ----',
                elementID: this.value.Element.ID,
                elementOrder: this.value.Element.getValueSearchRankLevelSearchRanking(),
                element: this.value.Element,
                isCurrentVersion: true // il s'agit de la version attachée directement au produit en cours
            };
        }
    }
}
