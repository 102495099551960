import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-dialog-overlay',
    templateUrl: './dialog-overlay.component.html',
    styleUrls: ['./dialog-overlay.component.scss']
})
export class DialogOverlayComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DialogOverlayComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

}
