import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constantes} from '../const';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private _baseUrlLogin = '/api/auth';
    private _baseUrlInitPassword = '/api/sdk-debug/mail/SendMailPasswordForgotten';

    constructor(private http: HttpClient, private _constantes: Constantes) {
    }

    login(usernameP: string, password: string) {
        return this.http.post<string>(this._constantes.baseUrl + this._baseUrlLogin, {
            Username: usernameP,
            Password: password
        }, {responseType: 'json'});
    }

    initPassword(userMail: string, langID = 2) {
        return this.http.post<any>(this._constantes.baseUrl + this._baseUrlInitPassword, {
            MailTo: userMail,
            AbsoluteRootUrl: this._constantes.baseUrl,
            LangID: langID
        });
    }
}
