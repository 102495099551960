import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../sdk/data/services/breadcrumb.service';
import {flatMap, map} from 'rxjs/operators';
import {NPUserRepository} from '../../sdk/data/services/auth/user-respository';
import {NpUser} from '../../sdk/data/Model/np-user';
import {OperationService} from '../../sdk/data/services/operation.service';
import {CollectionService} from '../../sdk/data/services/collection.service';
import {ActivatedRoute} from '@angular/router';
import {NPElement, NPListeValues} from '@nextpage/np-sdk-data';
import {CogedimConstantes, HOME_EDITION_VIGNETTES} from '../../sdk/data/constantes/cogedim-constantes';

@Component({
    selector: 'app-home-edition',
    templateUrl: './home-edition.component.html',
    styleUrls: ['./home-edition.component.scss']
})
export class HomeEditionComponent implements OnInit {

    @Input() chosenCollection: NPListeValues;

    public pageTitle: string;
    public items =  HOME_EDITION_VIGNETTES;
    public currentUser: NpUser;
    public currentOperation: NPElement;
    public currentCollectionID: string;

    constructor(
        private _breadcrumbService: BreadcrumbService,
        private _user: NPUserRepository,
        private _operationService: OperationService,
        private _collectionService: CollectionService,
        private router: ActivatedRoute) {
    }

  ngOnInit() {

        this._initCurrentUser();
        this._breadcrumbService.getItems()
            .pipe(
                map(items => items ? items[0] : undefined)
            )
            .subscribe(item => {
                this.pageTitle = item ? item.collectionTitle : '';
            });


        this._operationService.getCurrentOperation()
            .pipe(
                flatMap(currOperation => {
                    this.currentOperation = currOperation;
                    return this.router.queryParams;
                }),
                map(params => params[CogedimConstantes.PRESTATION_EXTID_PARAM])
            )
            .subscribe(_collectionExtID => {
                this.currentCollectionID = String(this._collectionService.getCollectionIdByExtID(this.currentOperation, _collectionExtID));
            });
    }

    private _initCurrentUser() {
        this._user.getCurrentUser()
            .subscribe((currUser: NpUser) => {
                this.currentUser = currUser;
            });
    }

}
