import {Component, Input, OnInit} from '@angular/core';
import {MediaFillRateService} from '../../services/media-fill-rate.service';
import {
    CaracConfig,
    DicoCarac,
    ElementWriterService,
    NiveauAccesDonnee,
    NPCaracLien,
    NPCaracValeur,
    NPCaracValeurTypeValue,
    NPElement,
    TypeCode,
    ValueOneElementHelper,
    StructuredDataService,
    StructuredDataChanges
} from '@nextpage/np-sdk-data';
import {CaracInfo} from '../np-value/Model';
import {WarningService} from '../../services/warning.service';

@Component({
    selector: 'lib-link-style-array',
    templateUrl: './link-style-array.component.html',
    styleUrls: ['./link-style-array.component.css']
})
export class LinkStyleArrayComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    public columns: CaracConfig[] = [];
    public elements: TableRow[] = [];
    private _initElements: TableRow[] = [];
    private _tabConfigs: CaracConfig[] = [];
    private _voe = new ValueOneElementHelper();
    private DicoCaracLeftExtID: string;
    private RightElementLinkDicoCaracExtID: string;

    constructor(private _mediaFillRateService: MediaFillRateService,
                private _eltWrSrv: ElementWriterService,
                private _structuredDataService: StructuredDataService,
                private _warningService: WarningService) {
    }

    ngOnInit() {
        this._initFieldsAndConfig();
        /**
         * Recoit un évelement lorsque un élement lien à une donnée structurée est modifié et le met à jour
         */
        this._structuredDataService.getSelectChanges()
            .subscribe(selectedValue => {
                if (this.isStructuredData(selectedValue)) {
                    this._setRightElementStructuredDataRQ(this.DicoCaracLeftExtID, selectedValue.elementSD, selectedValue.elementLinked);
                }
            });
    }

    getCISV(currElement, caracConfig) {
        return {
            value: this._voe.getCaracValue(currElement, caracConfig),
            config: caracConfig, 
            baseElement: currElement
        };
    }

    isFull(currElement: NPElement) {
        // ici on ne gère que les données structutées, le reste est géré dans NPElementViewComponent
        if (this.caracConfig.DicoCarac.TypeCode === TypeCode.STRUCTUREDDATA) {
            return this._mediaFillRateService.isNotFulledtFillRateGlobal(currElement);
        }
        return false;
    }

    delete(rowToDelete: TableRow) {
        this._warningService.warningBox()
            .subscribe(toDelete => {
                if (toDelete && rowToDelete) {
                    this._eltWrSrv.deleteValueLink(this.value.Element, this.caracConfig.DicoCaracExtID, rowToDelete.element);
                    this.elements = this.elements.filter(currTableRow => currTableRow.element.ExtID !== rowToDelete.element.ExtID);
                    this._initElements = Object.assign([], this.elements);
                }
            });
    }

    /**
     * Crée une donnée strurée
     */
    addElement() {
        // Crée une donnée structurée dans l'élement de droite
        this._eltWrSrv.prepareStructuredDataRQ(DicoCarac.TP_A_DECOMPOSITION,
            this.caracConfig.DicoCaracExtID, this.value.Element)
            .subscribe(newStructuredData => {
                this.elements.push({element: this._initElement(newStructuredData), over: false});
                this._initElements = Object.assign([], this.elements);
            });
    }

    /**
     * Met à jour l'element de droite dans une donnée structurée
     * DicoCaracLeftExtID : carac de gauche dans la DS
     * DSElement : La donnée structurée à mettre à jour;
     * ElementLinked: l'élement de droite, lié à la DS
     */
    private _setRightElementStructuredDataRQ(DicoCaracLeftExtID: string, DSElement: NPElement, ElementLinked: NPElement) {
        this._eltWrSrv.setRightElementStructuredDataRQ(DicoCaracLeftExtID, DSElement, ElementLinked)
            .subscribe(result => {
            });
    }

    /**
     * Initialise l'élement avec le minimum de caracs
     */
    private _initElement(element: NPElement) {
        this._tabConfigs.forEach(currColumn => {
            const typeValue = <unknown>currColumn.DicoCarac.TypeCarac;
            const access = <unknown>currColumn.DicoCarac.Access;
            const caracValeur: NPCaracValeur = {
                ElementID: element.ID,
                ElementExtID: element.ExtID,
                DicoCaracID: currColumn.DicoCarac.ID,
                DicoCaracExtID: currColumn.DicoCaracExtID,
                Element: element,
                TypeValue: <NPCaracValeurTypeValue>typeValue,
                Access: <NiveauAccesDonnee>access,
            };
            if (currColumn.DicoCarac.TypeCode.indexOf('LIEN') !== -1) {
                const caracValeurLien: NPCaracLien = new NPCaracLien(caracValeur);
                caracValeurLien.RebuildLinkedElements = [];
                caracValeurLien.LinkedElements = [];
                element.setValue(currColumn.DicoCaracExtID, caracValeurLien);
            } else {
                element.setValue(currColumn.DicoCaracExtID, caracValeur);
            }
        });
        return element;
    }

    /**
     * Vérifie si l'élement passé en param fait partie des données structurées de la carac courant
     */
    isStructuredData(data: StructuredDataChanges) {
        return data && data.dicoCaracExtID === this.RightElementLinkDicoCaracExtID &&
            data && this.elements.some(currValue => currValue.element.ExtID === data.elementSD.ExtID) && data.dicoCaracExtID;
    }

    // onValueSelected(elementExtId: string) {
    //     this.elements = !elementExtId ? this.elements = this._initElements : this._initElements.filter(currRow => {
    //         return currRow && currRow.element.getValueLien(this.caracConfig.Specific).RebuildLinkedElements.some(r => {
    //             return r && r.Element.ExtID === elementExtId;
    //         });
    //     });
    // }

    private _initFieldsAndConfig() {
        if (this.caracConfig) {
            // Récupération des colonnes du tableau
            this.columns = this.caracConfig.ConfigurationObject.filter(column => column.BlocInfo !== DSConstantes.DS_NEW_ELEMENT_CONFIG);
            // On récupère la carac de droite et la carac lien contenant l'élément de droite
            const tmpNewElementConfigs = this.caracConfig.ConfigurationObject.find(column => column.BlocInfo === DSConstantes.DS_NEW_ELEMENT_CONFIG);
            if (tmpNewElementConfigs && tmpNewElementConfigs.ConfigurationObject) {
                const tmpDicoCaracLeftExtID = tmpNewElementConfigs.ConfigurationObject.find(cc => cc.BlocInfo === DSConstantes.DS_DICOCARAC_LEFT_EXTID);
                this.DicoCaracLeftExtID = tmpDicoCaracLeftExtID ? tmpDicoCaracLeftExtID.DicoCaracExtID : '';
                const tmpRightElementLinkDicoCaracExtID = tmpNewElementConfigs.ConfigurationObject.find(cc => cc.BlocInfo === DSConstantes.DS_RIGHT_ELEMENT_LINK_DICOCARAC_EXTID);
                this.RightElementLinkDicoCaracExtID = tmpRightElementLinkDicoCaracExtID ? tmpRightElementLinkDicoCaracExtID.DicoCaracExtID : '';
                // On récupère les caracs permettant d'initialiser les nouveaux éléments (cas de la création d'une nouvelle données structurées) en excluant la carac de droite(qui n'est pas nécessaire ici)
                this._tabConfigs = tmpNewElementConfigs.ConfigurationObject.filter(cc => cc.BlocInfo !== DSConstantes.DS_DICOCARAC_LEFT_EXTID && cc.BlocInfo !== DSConstantes.DS_RIGHT_ELEMENT_LINK_DICOCARAC_EXTID);
            }

            if (this.value && this.value.RebuildLinkedElements) {
                this.elements = this.value.RebuildLinkedElements.map(rel => ({element: rel.Element, over: false}));
                this._initElements = Object.assign([], this.elements);
            }
        }
    }
}

export interface TableRow {
    element: NPElement;
    over: boolean;
}

enum DSConstantes {
    DS_DICOCARAC_LEFT_EXTID = 'DicoCaracLeftExtID',
    DS_RIGHT_ELEMENT_LINK_DICOCARAC_EXTID = 'RightElementLinkDicoCaracExtID',
    DS_NEW_ELEMENT_CONFIG = 'newElementConfigs'
}

