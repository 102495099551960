import {Component, Input, OnInit} from '@angular/core';
import {CaracConfig, ManagementRulesCheckerService, NPElement} from '@nextpage/np-sdk-data';
import {CaracIdStateValue} from '../np-value/Model';
import {TreeviewService} from '../../services';

@Component({
    selector: 'lib-np-content-block',
    templateUrl: './np-content-block.component.html',
    styleUrls: ['./np-content-block.component.css']
})
export class NpContentBlockComponent implements OnInit {

    @Input() contentConfigs: CaracConfig[];
    @Input() element: NPElement;

    public hasInformation = true;

    constructor(private _treeeviewService: TreeviewService,
                private _managementRulesChecker: ManagementRulesCheckerService) {
    }

    ngOnInit() {
        this.hasInformation = this.contentConfigs && this.contentConfigs.some(cc => this._managementRulesChecker.exist(this.element, cc));
    }

    getCISV(caracConfig: CaracConfig): CaracIdStateValue {
        return this._treeeviewService.getCISV(caracConfig, this.element);
    }
}
