import {CaracIdStateValue} from '../graphics/np-value/Model/carac-id-state-value';
import {
  NPCaracCharTemplate,
  NPCaracLien,
  NPCaracListe,
  NpCaracStatus,
  NPCaracStd,
  NPCaracValeur,
  NPCaracValeurTypeValue
} from '@nextpage/np-sdk-data';

interface ComplexBehaviour {
  isVisible(caracIDStateValue: CaracIdStateValue): boolean;
}

class CharTemplateBehaviour implements ComplexBehaviour {
  public isVisible(caracIDStateValue: CaracIdStateValue): boolean {
    if (caracIDStateValue.config.ShowCondition !== undefined) {
        if (caracIDStateValue.config.ShowCondition.indexOf('ShowIfTP') > -1) {
            const parsed = caracIDStateValue.config.ShowCondition.split(':');
            if (parsed.length > 1) {
                const charTemplates = parsed[1].split(',');
                const elementCharTemplatesExtID = caracIDStateValue.element.getValueCharTemplate().CharTemplates
                    .map((charTemplate) => charTemplate.CharTemplateExtID);
                for (let i = 0; i < charTemplates.length; i++) {
                    // if (elementCharTemplatesExtID.includes(charTemplates[i])) { => ne passe pas à cause du compilateur des lib...
                    if (elementCharTemplatesExtID.filter(d => d === charTemplates[i]).length > 0) {
                        return true;
                    }
                }
            }
    }

    }
    return false;
  }
}

class IfAssociatedBehaviour implements ComplexBehaviour {
  public isVisible(caracIDStateValue: CaracIdStateValue): boolean {
    if (caracIDStateValue.config.ShowCondition === ('IfAssociated')) {
      return caracIDStateValue.value != null;
    }
    return false;
  }
}

class IfNotEmptyBehaviour implements ComplexBehaviour {
  private static _isEmpty(value: NPCaracValeur): boolean {
    switch (value.TypeValue) {
      case  NPCaracValeurTypeValue.Standard: {
        return (<NPCaracStd>(value)).Value != null && (<NPCaracStd>(value)).Value.length > 0;
      }
      case  NPCaracValeurTypeValue.Lien: {
        return (<NPCaracLien>(value)).LinkedElements != null && (<NPCaracLien>(value)).LinkedElements.length > 0;
      }
      case  NPCaracValeurTypeValue.Multivalo: {
        return (<NPCaracListe>(value)).Values != null && (<NPCaracListe>(value)).Values.length > 0;
      }
      case  NPCaracValeurTypeValue.DTOSystemCharTemplate: {
        return (<NPCaracCharTemplate>(value)).CharTemplates != null && (<NPCaracCharTemplate>(value)).CharTemplates.length > 0;
      }
      case  NPCaracValeurTypeValue.DTOSystemStatus: {
        return (<NpCaracStatus>(value)).StatusExtID != null && (<NpCaracStatus>(value)).StatusExtID.length > 0;
      }
    }
  }

  public isVisible(caracIDStateValue: CaracIdStateValue): boolean {
    if (caracIDStateValue.config.ShowCondition === ('IfNotEmpty')) {
      if (caracIDStateValue.value != null) {
        return IfNotEmptyBehaviour._isEmpty(caracIDStateValue.value);
      }
    }
    return false;
  }
}

/*
 * @dynamic
 */
export class ShowConditionHelper {

  private static _keyWordBehaviour = {
    'Always': () => true,
    'True': () => true,
    'False': () => false,
    'Default': (caracIDStateValue: CaracIdStateValue) => {
      return caracIDStateValue.value != null;
    }
  };

  private static _Behaviours: ComplexBehaviour[] = [
    new CharTemplateBehaviour(),
    new IfAssociatedBehaviour(),
    new IfNotEmptyBehaviour()
  ];

  public static IsShowConditionFulfilled(caracIDStateValue: CaracIdStateValue): boolean {
    if (ShowConditionHelper._keyWordBehaviour[caracIDStateValue.config.ShowCondition] != null) {
      return ShowConditionHelper._keyWordBehaviour[caracIDStateValue.config.ShowCondition](caracIDStateValue);
    } else {
      for (let i = 0; i < ShowConditionHelper._Behaviours.length; i++) {
        if (ShowConditionHelper._Behaviours[i].isVisible(caracIDStateValue)) {
          return true;
        }
      }
      return ShowConditionHelper._keyWordBehaviour['Default'](caracIDStateValue);
    }

  }
}
