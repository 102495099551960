import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DicoCarac, MediaCreationService, NPListeValues} from '@nextpage/np-sdk-data';
import {UploadNotice, UploadNoticeConfigs} from '../../sdk/data/Model/models';
import {CogedimElementWriterService} from '../../sdk/data/services/cogedim-element-writer.service';
import {CogedimDicocarcs} from '../../sdk/data/constantes/cogedim-dicocarcs';
import {UploadNoticeService} from '../../sdk/data/services/upload-notice.service';
import {ModalService} from '../../sdk/graphics/services/modal.service';
import {CogedimConstantes} from '../../sdk/data/constantes/cogedim-constantes';

@Component({
  selector: 'app-upload-notice-item',
  templateUrl: './upload-notice-item.component.html',
  styleUrls: ['./upload-notice-item.component.scss']
})
export class UploadNoticeItemComponent implements OnInit {

  @Input() noticeElement: UploadNotice;
  @Input() uploadNoticeConfig: UploadNoticeConfigs;
  @Output() delete = new EventEmitter<UploadNotice>();

  constructor(
      private _elementWriterService: CogedimElementWriterService,
      private _mediaService: MediaCreationService,
      private _noticeService: UploadNoticeService,
      private _modalService: ModalService) {
  }

  ngOnInit() {
  }

  onSelected(selectedCollection: NPListeValues) {
    if (selectedCollection) {
      this.noticeElement.collection = selectedCollection.Valeur;
      this._setCollectionType(selectedCollection);
    }
  }

  deleteNotice() {
    this.delete.emit(this.noticeElement);
  }

  private _setCollectionType(selectedCollection: NPListeValues) {
    if (selectedCollection) {
      this._elementWriterService.setValueListeByIDValues(this.noticeElement.element,
          CogedimDicocarcs.DC_NOM_COLLECTION_CHOISIE_COLLECTION, [selectedCollection.ValeurID]);
      this._elementWriterService.setValueText(this.noticeElement.element, DicoCarac.PRODUCT_LABEL, 'Test' + selectedCollection.Valeur);
    }
  }

  downloadFile() {
    this._noticeService.downloadFileFromLocal(this.noticeElement.element.ExtID, CogedimDicocarcs.NOTICE_MEDIA_FILE)
        .subscribe(response => {
          if (response && response.Messages) {
            this._modalService.openSnackBar(response.Messages, CogedimConstantes.SNACK_CLASS);
          }
        }, error => {
          if (error && error.Errors) {
            this._modalService.openSnackBar(error.Errors, CogedimConstantes.SNACK_CLASS);
          }
        });
  }
}
